export enum eRaffleType {
    NFT = "NFT",
    Token = "Token",
    WL = "WL",
}

export enum defaultRaffleNftData {
    raffleTokenId = 6
}

export enum eInstructionsType {
    buyKittyCoins = "buyKittyCoins",
    buyRaffleTicket = "buyRaffleTicket"
}