import React from 'react'
import "./style.css"
import { StakingController } from 'components/ControllerStaking';
import { MyNavBar } from "../../sharedComponent/MyNavBar";
import { Footer } from '../../sharedComponent/Footer';
import { SubFooter } from "../../sharedComponent/SubFooter";
import {Helmet} from "react-helmet-async";

const Staking = (): JSX.Element => {
    return (
        <div className="staking-page">
            <Helmet>
                <title>Staking - Sol Kitties - Web3 Tech centric NFT Project</title>
            </Helmet>
            <MyNavBar />
            <StakingController/>
            <SubFooter/>
            <Footer />
        </div>
    )
}

export default Staking;