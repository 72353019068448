import {Nft} from "../../components/Nft";
import {SOLANA_NETWORK_MAINNET} from "../../config/constants";

import React, { FC, useMemo } from 'react';
import {ConnectionProvider, WalletProvider} from '@solana/wallet-adapter-react';
import {WalletAdapterNetwork, WalletError} from '@solana/wallet-adapter-base';
import {PhantomWalletAdapter, SolflareWalletAdapter, SolletWalletAdapter} from "@solana/wallet-adapter-wallets";
import {WalletModalProvider, WalletDisconnectButton, WalletMultiButton} from '@solana/wallet-adapter-react-ui';
import {clusterApiUrl} from "@solana/web3.js";
require('@solana/wallet-adapter-react-ui/styles.css'); // Default styles that can be overridden by your app

const network = SOLANA_NETWORK_MAINNET as WalletAdapterNetwork

//Cookbook: https://solanacookbook.com/references/nfts.html#how-to-get-nft-metadata

// UseWallet: https://github.com/aragon/use-wallet
// Wallet Adapter: https://solana-labs.github.io/wallet-adapter/
const Nfts = (): JSX.Element => {
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);
    const wallets = useMemo(() => [new PhantomWalletAdapter(), new SolletWalletAdapter({ network })], [network]);
    const walletConnError = (error: WalletError) => {
        //console.log("Wallet Connection Error:", error);
    }

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect={true} onError={walletConnError}>
                <WalletModalProvider>
                        <WalletMultiButton />
                        <WalletDisconnectButton />
                        <Nft />
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    )
}

export default Nfts;