import {AESDecrypt, AESEncrypt} from "./cryptoHelper";

export const getLocalStorageData = (localSessionName: any) =>
{
    const data = localStorage.getItem(localSessionName);

    return data == null ? "" : AESDecrypt(data);
}

export const setLocalStorageData = (localSessionName: any, value: any) =>
{
    const localStorageData = localStorage.getItem(localSessionName);
    if (localStorageData == null)
    {
        localStorage.setItem(localSessionName, AESEncrypt(value));
    }
    else
    {
        deleteLocalStoreListItem(localSessionName);
        localStorage.setItem(localSessionName, AESEncrypt(value));
    }
}

export const deleteLocalStoreListItem = (localSessionName: any) =>
{
    localStorage.removeItem(localSessionName);
}