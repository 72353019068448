import React from 'react'
import {Box, Grid} from '@material-ui/core';
import HomeTitle from "../../assets/images/home/home-title.png";
import solkittyCinema from "../../assets/images/home/solkitty-cinema.png";
import List1 from "../../assets/images/list-1.png";
import List2 from "../../assets/images/list-2.png";
import Block1 from "../../assets/images/road-1.png";
import Block2 from "../../assets/images/road-2.png";
import Block3 from "../../assets/images/road-3.png";
import Block4 from "../../assets/images/road-4.png";
import Block5 from "../../assets/images/road-5.png";
import Block6 from "../../assets/images/road-6.png";
import Border from "../../assets/images/border.png";
import CollectNew from "../../assets/images/3d-collect.png";
import BulletGold from "../../assets/images/bullet-gold.png";
import Kittie1 from "../../assets/images/kittie-1.png";
import Kittie2 from "../../assets/images/kittie-2.png";
import Kittie3 from "../../assets/images/kittie-3.png";
import Kittie4 from "../../assets/images/kittie-4.png";
import Kittie5 from "../../assets/images/kittie-5.png";
import Kittie6 from "../../assets/images/kittie-6.png";
import Kittie7 from "../../assets/images/kittie-7.png";
import Kittie8 from "../../assets/images/kittie-8.png";
import Kittie9 from "../../assets/images/kittie-9.png";
import Kittie10 from "../../assets/images/kittie-10.png";
import Kittie11 from "../../assets/images/kittie-11.png";
import Kittie12 from "../../assets/images/kittie-12.png";
import Kittie13 from "../../assets/images/kittie-13.png";
import Kittie14 from "../../assets/images/kittie-14.png";
import Kittie15 from "../../assets/images/kittie-15.png";
import BulletKitty from "../../assets/images/bullet_glow.png";
import CharCTA from "../../assets/images/char1.png";
import JuneIcon from "../../assets/images/home/june-2022.png";
import JulyIcon from "../../assets/images/home/july-2022.png";
import AugIcon from "../../assets/images/home/aug-2022.png";

import {Faq} from "../../sharedComponent/Faq";
import {useHistory} from "react-router-dom";
import LazyLoad from "react-lazyload";

import PromoBanner from "../../assets/images/raffles/raffle-promo-banner.gif";
const SolKittyTrailer = "/assets/video/SolKitties_Trailer.mp4";
//const PromoBanner = "../src/assets/images/raffles/raffle-promo-banner.gif";


export const ControllerHomeBreedingPods = (): JSX.Element => {
    const history = useHistory();
    let index = history.location.hash.replace("#", "");
    const element: any = document.getElementById(index);
    if (element) {
        const topPos = element.offsetTop
        let navBarHeight: any = document.getElementById('navBar')?.offsetHeight
        navBarHeight = navBarHeight - 50
        if (navBarHeight === 0) navBarHeight = 68
        window.scrollTo({
            top: topPos - navBarHeight,
            left: 100,
            behavior: 'smooth'
        })
    }

    const kittiesImage = [Kittie1, Kittie2, Kittie3, Kittie4, Kittie5, Kittie6, Kittie7, Kittie8, Kittie9, Kittie10, Kittie11, Kittie12, Kittie13, Kittie14, Kittie15]
    /*const handleChangePage = (index: string) => {
      history.push(index)
    }*/

    return (
        <>
            <div className="head-banner gredient-color">
                <section>
                    {/*<div>*/}
                    {/*    <a href='/raffles' className="w-full flex justify-center">*/}
                    {/*        <img src={PromoBanner} alt="banner" className="w-[42%]"/>*/}
                    {/*    </a>*/}
                    {/*</div>*/}
                    <div className="title-img flex justify-center">
                        <img src={HomeTitle} alt='home title'/>
                    </div>
                    <LazyLoad height={300}>
                        <div className='kitty-main-img z-99 flex justify-center'>
                            <video className="cinema-img" poster={solkittyCinema} loop muted preload="none" controls
                                   playsInline autoPlay={true}>
                                <source src={SolKittyTrailer} type="video/mp4"/>
                            </video>
                        </div>
                    </LazyLoad>

                    {/*<div className="bottom-btn-wrapper prod-2-bottm">
              <div className="price-box prod2-price">
                <label className="text-center lato-font">Price</label>
                <input className="text-center lato-font" type="text" defaultValue="1.5"></input>
                <img style={{width: '25px', height: '25px', position: 'absolute', right: '25%', paddingTop: '10px', marginRight: '-25px'}} src={Solana1} />
                <Box className="priceGas lato-font">+ gas fee</Box>
              </div>
              <div className='sale-time sale-time2'>
                <div className="time-text" style={{"cursor": 'pointer'}} onClick={() => handleChangePage('/podspublicmint')}>
                  SOLD-OUT!
                  <div className='time-unit'>3D Air-drop in Progress</div>
                </div>
              </div>
              <div className="price-box green-value">
                <label className="text-green text-center lato-font">Remaining</label>
                <input type="text" defaultValue="?" className="text-center lato-font"></input>
              </div>
            </div>*/}
                </section>
                <section className="mt-40 main-layout roadmap-wrapper" id='roadmap'>
                    <div className="main-container">
                        <Grid container spacing={2}>
                            <Grid item lg={12} sm={12} md={12} xs={12}>
                                <h1 className="roadmap-title mt-0" data-aos="zoom-in-up">ROADMAP <sub>v3.0</sub></h1>
                            </Grid>

                            <Grid item lg={4} sm={12} md={4} xs={12}>
                                <div className='outer-box' data-aos="fade-right">
                                    <Box className='road-box road-box1'>
                                        <h5 className='box-title box-title-1'>Phase 1 – Project Init</h5>
                                        <span className='box-badge box-badge-1'>1</span>
                                        <ul className='m-h-230'>
                                            <li><img src={List1} alt="list1"/> Community Building.</li>
                                            <li><img src={List1} alt="list1"/> Partnerships & Events.</li>
                                            <li><img src={List1} alt="list1"/> Discord & Twitter Collaborations.</li>
                                            <li><img src={List1} alt="list1"/> Airdrops & Prizes.</li>
                                            <li><img src={List1} alt="list1"/> 2D Collection Mint.</li>
                                        </ul>
                                    </Box>
                                    <img className="block-img" src={Block1} alt="img"/>
                                </div>
                            </Grid>
                            <Grid item lg={4} sm={12} md={4} xs={12}>
                                <div className='outer-box' data-aos="fade-right">
                                    <Box className='road-box road-box2'>
                                        <h5 className='box-title box-title-2'>Phase 1 – Breeding Pods</h5>
                                        <span className='box-badge box-badge-2'>2</span>
                                        <ul>
                                            <li className='b2-text-head'>555 SUPPLY | 1.5 SOL</li>
                                            <li className='b2-text-sub'>2D Kitties Holders Perks:</li>
                                            <li className='middle-li'><img src={List2} alt="list2"/> Pod Price: <span
                                                className='text-l-green'>0.75 SOL.</span></li>
                                            <li className='middle-li'><img src={List2} alt="list2"/> Life time Stake
                                                Multiplier
                                                of <span className='text-l-green'>1.5x</span> to the total Staked
                                                balance.
                                            </li>
                                            <li className='middle-li'><img src={List2} alt="list2"/> Burn and Breed 2D
                                                into 3D.
                                            </li>
                                            <li className='middle-li'><img src={List2} alt="list2"/> <span
                                                className='text-l-yellow'>Elite Kitty</span> will be able to repair
                                                their pod for <span className='text-l-green'>0.5 SOL </span> granting 1
                                                more breed.
                                            </li>
                                            <li className='middle-li'><img src={List2} alt="list2"/>
                                                <div><span>More details about the event in our</span> <a
                                                    className='text-l-green2 text-upercase'
                                                    href='http://discord.gg/solkitties' target="_blank" rel="noreferrer">Discord
                                                    Server.</a>
                                                </div>
                                            </li>
                                        </ul>
                                    </Box>
                                    <img className="block-img-2 block-img-two" src={Block2} alt="img"/>
                                </div>
                            </Grid>
                            <Grid item lg={4} sm={12} md={4} xs={12}>
                                <div className='outer-box' data-aos="fade-right">
                                    <Box className='road-box road-box3'>
                                        <h5 className='box-title box-title-3'>Phase 1 - Staking Upgrades</h5>
                                        <span className='box-badge box-badge-3'>3</span>
                                        <ul className='m-h-230'>
                                            <li><img src={List2} alt="list2"/> <span
                                                className='text-l-yellow'>Elite Kitty</span> Benefits & Rewards.
                                            </li>
                                            <li><img src={List2} alt="list2"/>
                                                <span className='text-l-green'>Web 3.0</span> Tech Development.
                                            </li>
                                            <li><img src={List2} alt="list2"/>Adjusting Staking Rewards.</li>
                                            <li><img src={List2} alt="list2"/>Enhancing Staking Multipliers.</li>
                                            <li><img src={List2} alt="list2"/><span className='text-l-green'>Web 3.0</span> Staking Interface.</li>
                                        </ul>
                                    </Box>
                                    <img className="block-img-3" src={Block3} alt="img"/>
                                </div>
                            </Grid>
                            <Grid className='border-main' item lg={12} sm={12} md={12} xs={12}>
                                <img className='border-img' alt="border" src={Border} data-aos="flip-up"></img>
                            </Grid>
                            <Grid item lg={4} sm={12} md={4} xs={12}>
                                <div className='outer-box' data-aos="fade-right">
                                    <Box className='road-box road-box1'>
                                        <h5 className='box-title box-title-1'>Phase 2 – Chapter A</h5>
                                        <span className='box-badge box-badge-1'>4</span>
                                        <ul className='ul-ht'>
                                            <li className='b2-text-head'>PROJECT DEVELOPMENT</li>
                                            <li><img src={List2} alt="list2"/> Breeding of First 3D Kitties!</li>
                                            <li><img src={List2} alt="list2"/> <span className='text-l-green'>Staking v2.0 </span> Implementation.</li>
                                            <li><img src={List2} alt="list2"/> Adding Rarity & Rank.</li>
                                            <li><img src={List2} alt="list2"/> <span className='text-l-yellow'>Kitties P2E</span> Multiplayer Tech R&D.</li>
                                        </ul>
                                    </Box>
                                    <img className="block-img-2" src={Block4} alt="img"/>
                                </div>
                            </Grid>
                            <Grid item lg={4} sm={12} md={4} xs={12} className='z-index-1'>
                                <div className='outer-box' data-aos="fade-right">
                                    <Box className='road-box road-box4'>
                                        <h5 className='box-title box-title-4'>Phase 2 – Chapter B</h5>
                                        <span className='box-badge box-badge-4'>5</span>
                                        <ul className='ul-ht'>
                                            <li className='b2-text-head'>3D COLLECTION LAUNCH</li>
                                            <li><img src={List2} alt="list2"/>Top Tier Collabs & Partnerships.</li>
                                            <li><img src={List2} alt="list2"/><span className='text-l-green'>Launch of 3D Collection.</span></li>
                                            <li><img src={List2} alt="list2"/><span className='text-l-yellow'>Magic Eden Launchpad.</span></li>
                                            <li><img src={List2} alt="list2"/>Listing on Secondary Market.</li>
                                        </ul>
                                    </Box>
                                    <img className="block-img-2 block-img-5" src={Block5} alt="img"/>
                                </div>
                            </Grid>
                            <Grid item lg={4} sm={12} md={4} xs={12}>
                                <div className='outer-box' data-aos="fade-right">
                                    <Box className='road-box road-box1'>
                                        <h5 className='box-title box-title-1'>Phase 2 – Chapter C</h5>
                                        <span className='box-badge box-badge-1'>6</span>
                                        <ul className='ul-ht'>
                                            <li><img src={List2} alt="list2"/>Deepening our <span className='text-l-green'>Web v3.0 </span> Tech</li>
                                            <li><img src={List2} alt="list2"/>On-Chain Lottery System.</li>
                                            <li><img src={List2} alt="list2"/>3D Staking Implementation.</li>
                                            <li><img src={List2} alt="list2"/><span className='text-l-orange'>Community</span> Sweeping Event.</li>
                                        </ul>
                                    </Box>
                                    <img className="block-img-2" src={Block6} alt="img"/>
                                </div>
                            </Grid>
                            <Grid className='border-main' item lg={12} sm={12} md={12} xs={12}>
                                <img className='border-img' alt="border" src={Border} data-aos="flip-up"></img>
                            </Grid>

                            <Grid item lg={4} sm={12} md={4} xs={12}>
                                <div className='outer-box last-row-outer-box' data-aos="fade-right">
                                    <Box className='road-box road-box1'>
                                        <h5 className='box-title box-title-1'>Phase 3 – Chapter A</h5>
                                        <span className='box-badge box-badge-1'>7</span>
                                        <ul className='ul-ht'>
                                            <li><img src={List2} alt="list2"/><span
                                                className='text-l-yellow'>Alpha Build</span> and P2E Events
                                            </li>
                                            <li><img src={List2} alt="list2"/><span
                                                className='text-l-yellow'>Test Payments</span> from P2E Game.
                                            </li>
                                            <li><img src={List2} alt="list2"/><span
                                                className='text-l-green'>Start of 333 Airdrop Event.</span>
                                            </li>
                                            <li><img src={List2} alt="list2"/><span className='text-l-green'>Discord Game Tournaments</span> with
                                                cool Prizes for winners!
                                            </li>
                                        </ul>
                                    </Box>
                                    <img className="block-june-img" src={JuneIcon} alt="img"/>
                                </div>
                            </Grid>
                            <Grid item lg={4} sm={12} md={4} xs={12}>
                                <div className='outer-box last-row-outer-box' data-aos="fade-right">
                                    <Box className='road-box road-box4'>
                                        <h5 className='box-title box-title-4'>Phase 3 – Chapter B</h5>
                                        <span className='box-badge box-badge-4'>8</span>
                                        <ul>
                                            <li><img src={List2} alt="list2"/><span className='text-l-green'>Web 3.0</span> Raffles & WL Page.</li>
                                            <li><img src={List2} alt="list2"/><span className='text-l-green'>Beta Build and P2E Events.</span></li>
                                            <li><img src={List2} alt="list2"/><span className='text-l-yellow'>Payments from P2E Game</span> to our P2E players!</li>
                                            <li><img src={List2} alt="list2"/><span className='text-l-yellow'>Staking v3.0</span> Implementations.</li>
                                        </ul>
                                    </Box>
                                    <img className="block-july-img" src={JulyIcon} alt="img"/>
                                </div>
                            </Grid>
                            <Grid item lg={4} sm={12} md={4} xs={12}>
                                <div className='outer-box last-row-outer-box' data-aos="fade-right">
                                    <Box className='road-box road-box3'>
                                        <h5 className='box-title box-title-3'>Phase 3 – Chapter C</h5>
                                        <span className='box-badge box-badge-3'>9</span>
                                        <ul className='m-h-230'>
                                            <li><img src={List2} alt="list2"/> Expanding <span className='text-l-yellow'>Brand Awareness.</span></li>
                                            <li><img src={List2} alt="list2"/> Additional <span className='text-l-green'>Game Modes.</span></li>
                                            <li><img src={List2} alt="list2"/><span className='text-l-orange'>$SKT On-Chain Token.</span></li>
                                            <li><img src={List2} alt="list2"/> Tournaments & Social events.</li>
                                            <li><img src={List2} alt="list2"/> Further phases & project expansions.</li>
                                        </ul>
                                    </Box>
                                    <img className="block-aug-img" src={AugIcon} alt="img"/>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </section>

                <section className='new-3d-collect-wrapper wrapper-container' id='story'>
                    <div className="main-container">
                        <Grid container spacing={2}>
                            <Grid item lg={12} sm={12} md={12} xs={12}>
                                <h1 className="roadmap-title middle-title mt-0 text-yellow" data-aos="fade-up">WELCOME
                                    TO THE NEW 3D COLLECTION</h1>
                            </Grid>
                        </Grid>

                        <Grid container spacing={0} className='item-center' data-aos="fade-up">
                            <Grid item lg={8} sm={7} md={7} xs={12}>
                                <div className='collect-img'>
                                    <img src={CollectNew} alt='images'></img>
                                </div>
                            </Grid>
                            <Grid item lg={4} sm={5} md={5} xs={12}>
                                <ul className='collect-list'>
                                    <li><img src={BulletGold}
                                             alt="bullet-gold"/><span>Buy a 3D kitty breeding pod</span></li>
                                    <li><img src={BulletGold} alt="bullet-gold"/><span>Watch your kitty grow and evolve for 30 days</span>
                                    </li>
                                    <li><img src={BulletGold}
                                             alt="bullet-gold"/><span>Acquire your fully grown 3D kitty</span></li>
                                    <li><img src={BulletGold} alt="bullet-gold"/><span>Enjoy the benefits of owning a 3D kitty</span>
                                    </li>
                                </ul>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} data-aos="fade-up">
                            <Grid item lg={12} sm={12} md={12} xs={12}>
                                <p className='collect-disc'>
                                    The announcement of incredible perks for our 2D & 3D kitty holders will be made
                                    soon. As well as, our arrival in the metaverse world will make us reach unimaginable
                                    heights.
                                </p>
                            </Grid>
                        </Grid>
                    </div>
                </section>
            </div>

            <section className='kitties-img-wrapper'>
                <div className='kittie-img-list'>
                    {
                        kittiesImage.map((img, index:number) => (
                            <img key={index} src={img} alt='kittie-img'/>
                        ))
                    }
                </div>
            </section>

            <section className="join-discord-cta">
                <div className='wrapper-container'>
                    <div className="main-container" data-aos="fade-up">
                        <Grid container spacing={2} className="join-inner">
                            <Grid item lg={5} sm={12} md={5} xs={12}>
                                <div className="join-discord-kitty">
                                    <img src={CharCTA} alt="CharCTA"/>
                                </div>
                            </Grid>
                            <Grid item lg={7} sm={12} md={7} xs={12}>
                                <div className="join-section">
                                    <h2>Sol Kitties Discord</h2>
                                    <ul className="join-benifit">
                                        <li>
                                            <img src={BulletKitty} alt="BulletKitty"/>
                                            <span>Discover the friendliest Solana community.</span>
                                        </li>
                                        <li>
                                            <img src={BulletKitty} alt="BulletKitty"/>
                                            <span>Stay up to date with roadmap execution.</span>
                                        </li>
                                        <li>
                                            <img src={BulletKitty} alt="BulletKitty"/>
                                            <span>Participate in raids, games, events and more, to earn regular rewards and win special prizes.</span>
                                        </li>
                                    </ul>
                                    <div className="join-btn">
                                        <div className="stats-card-inner has-shadow-effect">
                                            <div className="stats-card">
                                                <div className="coin-cat-card">
                                                  <a>Join Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </section>

            <section className='faq-wrapper' id='faq'>
                <Grid container data-aos="fade-up">
                    <Grid item lg={12} sm={12} md={12} xs={12}>
                        <Faq faqType="home"></Faq>
                    </Grid>
                </Grid>
            </section>
        </>
    )
}
