import {WalletAdapterNetwork} from "@solana/wallet-adapter-base";
import {getLocalStorageData, setLocalStorageData} from "../service/localStorageHelper";
import { PublicKey } from "@solana/web3.js";

const CANDY_MACHINE_ID_MAINNET =  process.env.REACT_APP_CANDY_MACHINE_ID_MAINNET || ''
const CANDY_MACHINE_ID_DEVNET = process.env.REACT_APP_CANDY_MACHINE_ID_DEVNET || ''
const SOLANA_NETWORK_MAINNET = process.env.REACT_APP_SOLANA_NETWORK_MAINNET || ''
const SOLANA_NETWORK_DEVNET = process.env.REACT_APP_SOLANA_NETWORK_DEVNET || ''
const SOLANA_RPC_HOST_MAINNET = process.env.REACT_APP_SOLANA_RPC_HOST_MAINNET || ''
const SOLANA_RPC_HOST_MAINNET2 = process.env.REACT_APP_SOLANA_RPC_HOST_MAINNET2 || ''
const SOLANA_RPC_HOST_DEVNET = process.env.REACT_APP_SOLANA_RPC_HOST_DEVNET || ''

let IS_DEV_ENVIRONMENT = false;
const localStorageKey = "walletEnv";
if (window.location.hostname == "localhost" || window.location.hostname == "3d.solkitties.net")
{
    if (getLocalStorageData(localStorageKey))
    {
        IS_DEV_ENVIRONMENT = getLocalStorageData(localStorageKey) == WalletAdapterNetwork.Devnet;
    }
    else
    {
        setLocalStorageData("walletEnv", WalletAdapterNetwork.Mainnet);
    }
}

const CONNECTION_NETWORK = IS_DEV_ENVIRONMENT ?  WalletAdapterNetwork.Devnet : WalletAdapterNetwork.Mainnet;
const CONNECTION_NETWORK_RPC = CONNECTION_NETWORK == WalletAdapterNetwork.Mainnet ? SOLANA_RPC_HOST_MAINNET : SOLANA_RPC_HOST_DEVNET;
const REACT_APP_IS_ACTIVE_SK_ENDPOINT = IS_DEV_ENVIRONMENT // false means use Servica.io (production)
const DEBUG_API_CALLS = true;
const RAFFLE_STORE_BUYERS = false;
const RAFFLE_INIT_WITH_PDA = false;
const ADMIN_WALLET =
    [
        "4Ar5WXALb8BvWxrrnSRmHLWEG7BN3jET7QgxquaKwfTm",
        "7mnppfXn9zciphsuUHjsQwJuxA6et7ZBEX4ULfhX4stu",
        "SERVUJeqsyaJTuVuXAmmko6kTigJmxzTxUMSThpC2LZ",
        "5S69Gf53pnShAufDeAhpVBnq5DR4rf4xogroYguvgY8u"
    ];

const STAKING_WALLET_ADDRESS_PROD = process.env.REACT_APP_STAKING_WALLET_ADDRESS || ''
const STAKING_WALLET_ADDRESS_TEST = process.env.REACT_APP_STAKING_WALLET_ADDRESS_TEST || ''
const STAKING_WALLET_ADDRESS = STAKING_WALLET_ADDRESS_PROD;
const STAKING_STAKE_FEE = 0.007;
const STAKING_UNSTAKE_FEE = 0.0085;

const REACT_APP_RAFFLES_PROGRAM_ID = process.env.REACT_APP_RAFFLES_PROGRAM_ID || '';
const REACT_APP_SKT_TOKEN_ADDRESS = process.env.REACT_APP_SKT_TOKEN_ADDRESS || '';
const REACT_APP_RAFFLE_VAULT_WALLET_ADDRESS = process.env.REACT_APP_RAFFLE_VAULT_WALLET_ADDRESS || '';
const VAULT_ADDRESS = (IS_DEV_ENVIRONMENT ? process.env.REACT_APP_VAULT_ADDRESS_DEVNET : process.env.REACT_APP_VAULT_ADDRESS_MAINNET) || '';
const TRANSACTION_TIMEOUT = 30000
const DEBUG_WALLET_ADDRESS = process.env.REACT_APP_DEBUG_WALLET_ADDRESS || null
const GAME_WON_REWARD_COINS = process.env.REACT_APP_GAME_WON_REWARD_COINS || 0
const RAFFLE_VERSION = process.env.REACT_APP_RAFFLE_VERSION
const REACT_APP_SK_API_ENDPOINT = process.env.REACT_APP_SK_API_ENDPOINT
const REACT_APP_SK_API_ENDPOINT_V2 = process.env.REACT_APP_SK_API_ENDPOINT_V2
const REACT_APP_SERVICA_API_ENDPOINT = process.env.REACT_APP_SERVICA_API_ENDPOINT


const REACT_APP_KITTY_AUTH = process.env.REACT_APP_KITTY_AUTH || ""
const REACT_APP_RAFFLE_AUTH = process.env.REACT_APP_RAFFLE_AUTH || ""
const REACT_APP_GAME_AUTH = process.env.REACT_APP_GAME_AUTH || ""
const VAULT_SKT_SEED_PREFIX = "skt_pool";
const GLOBAL_ACCOUNT_SEED = "global_account";
const NFT_VAULT_POOL_SEED = "nft_vault_pool";
const STAKING_VAULT_ADDRESS = new PublicKey("JBGLaaPuTecDBWADsYauhd4726JUp69QcQdMeUTaUyrx");


const WALLET_DISCONNECTED = "Wallet disconnected successfully";
const WALLET_CONNECTING = "Connecting to wallet and fetching data...";
const WALLET_CONNECTED = "Wallet connected successfully.";
const WALLET_CONNECTION_FAIL = "Wallet Connection Failed! Please try again!";
const USER_NOT_FOUND = "Please click on Verify Wallet. For help, open a ticket in our Discord Server."; //"User not found, please open a ticket in Discord.";
const STAKE_IN_PROGRESS = "Staking In Progress... don't close this window.";
const API_NETWORK_ISSUE = "We are experiencing a network issue, we are investigating, please try again later...";
const DATA_NOT_FOUND = "No Data Found...";

/* burn sol page - all value are on SOL */
const FP_SOL_2D = 0.4;
const FP_SOL_3D = 0.3;
const SKT_1 = 0.00125;
export const SKT_2D_BASE = FP_SOL_2D/SKT_1;
export const SKT_3D_BASE = FP_SOL_3D/SKT_1;
const MINTPASS_1 = 3;

const OG_META =
{
    "staking" :
    {
        "title" : "Sol Kitties NFTs - Staking",
        "image" : "../assets/images/meta-images/coming_mobile.png",
        "image_title" : "og image title",
        "image_description" : "image og description"
    }
}

export enum eCurrencyType {
    SOL = "SOL",
    DUST = "DUST",
    CRECK = "CRECK",
    FORGE = "FORGE",
    SKT = "SKT",
    USDC = "USDC",
    SKBC = "SKBC",
    SCRAP = "SCRAP",
    PYJ = "PYJ",
    LINX = "LINX",
    DCODE = "DCODE",
    INK = "INK",
    RAFFL = "RAFFL",
    NEON = "NEON",
}

const SPLTOKENS_MAP: Map<string, string> = new Map();
SPLTOKENS_MAP.set(eCurrencyType.SKT, 'SKTsW8KvzopQPdamXsPhvkPfwzTenegv3c3PEX4DT1o');
SPLTOKENS_MAP.set(eCurrencyType.CRECK, 'Ao94rg8D6oK2TAq3nm8YEQxfS73vZ2GWYw2AKaUihDEY');
SPLTOKENS_MAP.set('CRECK_D', 'ASxC3n3smkcUkA7Z58EUKZ2NfHoQ8eZrkTRK7ergYr2a');
SPLTOKENS_MAP.set(eCurrencyType.DUST, 'DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ');
SPLTOKENS_MAP.set('DUST_D', '8kY8hSzXbD9uwmNtsDtRmSxQhbVwAK22kaJVbWzvQwn3');
SPLTOKENS_MAP.set(eCurrencyType.FORGE, 'FoRGERiW7odcCBGU1bztZi16osPBHjxharvDathL5eds');
SPLTOKENS_MAP.set(eCurrencyType.USDC, 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v');
SPLTOKENS_MAP.set(eCurrencyType.SOL, '11111111111111111111111111111111');
SPLTOKENS_MAP.set(eCurrencyType.SKBC, 'PXWNGZXNUQfnaa5eKhmRW7rBrUKWTW86KwyFRnGMuwR');
SPLTOKENS_MAP.set(eCurrencyType.SCRAP, '6naWDMGNWwqffJnnXFLBCLaYu1y5U9Rohe5wwJPHvf1p');
SPLTOKENS_MAP.set(eCurrencyType.PYJ, '3bYb7U7ofZzXraVUz28DpqwQG127NW3nFVoiGtC4PrH6');
SPLTOKENS_MAP.set(eCurrencyType.LINX, '6ajDhJoo1kxcEredyJWYrcq4tCQW4gRywB7LLMNtuw2U');
SPLTOKENS_MAP.set(eCurrencyType.DCODE, '7xxWqi7287GAk9eA7LDPF9c1WoAWoGLDdcQSnHz2hz6A');
SPLTOKENS_MAP.set(eCurrencyType.INK, 'inkDs2PsMDNHcjrqZGeuBb8LzwdQzQdxaf4JoUj7V5a');
SPLTOKENS_MAP.set(eCurrencyType.RAFFL, 'RAfFL9fhoqyF7ETNdP2X67jnHg4NK9212PSmao4Fgsh');
SPLTOKENS_MAP.set(eCurrencyType.NEON, '5DurkMnR3pZgA9ApWAVtg14PoV94zU4J5s6AsgK6bVcQ');


export const SPLTOKENS_MAP_GET_TOKEN_NAME: (tokenAddress: string) => { tokenAddress: string; tokenName: string } = (tokenAddress: string) =>
{
    // @ts-ignore
    const res = [...SPLTOKENS_MAP].find(([_tokenName, _tokenAddress]) => _tokenAddress == tokenAddress);

    return res ? { tokenName: res[0], tokenAddress: res[1] } : { tokenName: "", tokenAddress: "" }
}

export
{
    CANDY_MACHINE_ID_MAINNET,
    CANDY_MACHINE_ID_DEVNET,
    SOLANA_NETWORK_MAINNET,
    SOLANA_NETWORK_DEVNET,
    SOLANA_RPC_HOST_MAINNET,
    SOLANA_RPC_HOST_MAINNET2,
    SOLANA_RPC_HOST_DEVNET,
    TRANSACTION_TIMEOUT,
    STAKING_WALLET_ADDRESS,
    STAKING_WALLET_ADDRESS_TEST,
    DEBUG_WALLET_ADDRESS,
    WALLET_DISCONNECTED,
    WALLET_CONNECTING,
    WALLET_CONNECTED,
    WALLET_CONNECTION_FAIL,
    USER_NOT_FOUND,
    STAKE_IN_PROGRESS,
    GAME_WON_REWARD_COINS,
    OG_META,
    REACT_APP_SKT_TOKEN_ADDRESS,
    REACT_APP_RAFFLE_VAULT_WALLET_ADDRESS,
    VAULT_ADDRESS,
    GLOBAL_ACCOUNT_SEED,
    NFT_VAULT_POOL_SEED,
    VAULT_SKT_SEED_PREFIX,
    RAFFLE_VERSION,
    API_NETWORK_ISSUE,
    SPLTOKENS_MAP,
    REACT_APP_SK_API_ENDPOINT,
    REACT_APP_SK_API_ENDPOINT_V2,
    REACT_APP_SERVICA_API_ENDPOINT,
    REACT_APP_IS_ACTIVE_SK_ENDPOINT,
    REACT_APP_KITTY_AUTH,
    REACT_APP_RAFFLE_AUTH,
    REACT_APP_GAME_AUTH,
    DEBUG_API_CALLS,
    CONNECTION_NETWORK,
    RAFFLE_STORE_BUYERS,
    RAFFLE_INIT_WITH_PDA,
    REACT_APP_RAFFLES_PROGRAM_ID,
    ADMIN_WALLET,
    IS_DEV_ENVIRONMENT,
    DATA_NOT_FOUND,
    CONNECTION_NETWORK_RPC,
    STAKING_STAKE_FEE,
    STAKING_UNSTAKE_FEE,
    STAKING_VAULT_ADDRESS,
    FP_SOL_2D,
    FP_SOL_3D,
    SKT_1,
    MINTPASS_1
}
