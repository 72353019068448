import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { REACT_APP_IS_ACTIVE_SK_ENDPOINT, REACT_APP_SERVICA_API_ENDPOINT, REACT_APP_KITTY_AUTH, REACT_APP_SK_API_ENDPOINT_V2 } from "../config/constants";
import {callRafflesAPI} from "./rafflesServiceProvider";
import {getWalletNftData} from "./walletServiceProvider";
import {Connection, PublicKey} from "@solana/web3.js";
import {Metaplex} from "@metaplex-foundation/js";

const endPointAPI = REACT_APP_IS_ACTIVE_SK_ENDPOINT ? `${REACT_APP_SK_API_ENDPOINT_V2}kitties` : `${REACT_APP_SERVICA_API_ENDPOINT}kitties`;
export const getSoldTickets = async () => {
    try {
        return (
            await axios.post(endPointAPI, {
                endpoint: "get_tickets_sold",
            }, {
                headers: {Authorization: REACT_APP_KITTY_AUTH},
            })
        ).data;
    } catch (error) {
        console.log("Error:", error);
    }
}

export const getStakeStatsData = createAsyncThunk(
    "getStakeStatsData",
    async (_: void, {rejectWithValue}) => {
        try {
            return (
                await axios.post(endPointAPI, {
                    endpoint: "get_staked_stats"
                }, {
                    headers: {Authorization: REACT_APP_KITTY_AUTH},
                })
            ).data;
        } catch (error) {
            console.log("Error:", error);
            return rejectWithValue(error);
        }
    }
);

export const getApprovedWalletNftsData = async (isFilteringEnabled: boolean, walletNftData: any, approvedNftCollectionArray: any) =>
{
    try
    {
        let approvedWalletNfts: any = [];
        if (walletNftData && walletNftData.length > 0)
        {
            await Promise.all
            (
                walletNftData?.map(async (nft: any, index: number) =>
                {
                    let approvedCreatorAddress = "";
                    let isNftInApprovedCollection = false;
                    if(isFilteringEnabled) {
                        nft.data.creators?.map((creators: any, index: number) =>
                        {
                            if (creators.verified == 1 || creators.address == "4Ar5WXALb8BvWxrrnSRmHLWEG7BN3jET7QgxquaKwfTm" || creators.address == "2T3nPvNXML4sEKk9AErbjohKS1mgTYXiY7LXQXPRwnoG")
                            {
                                if (approvedNftCollectionArray.includes(creators.address))
                                {
                                    isNftInApprovedCollection = true;
                                    approvedCreatorAddress = creators.address;
                                }
                            }
                        });
                    } else {
                        /* if isFilteringEnabled are disable then all wallet nfts are approved to show on popup */
                        isNftInApprovedCollection = true;
                    }

                    nft.nftName = nft.data.name;
                    nft.approvedCreatorAddress = approvedCreatorAddress;
                    nft.nftTokenAddress = nft.mint;
                    nft.nftImage = nft.data.uri;
                    nft.approvedNftFlag = isNftInApprovedCollection;

                    /* fetch and save only approved nft from collection and fetch images */
                    const connectionSSC = new Connection("https://ssc-dao.genesysgo.net/", 'confirmed');
                    const metaplex = Metaplex.make(connectionSSC);

                    let nftDataResponse = await getWalletNftData(nft.data.uri);
                    let nftDataImage = nftDataResponse?.data?.data;
                    if (nftDataResponse.success) {
                        nft.nftImage = nftDataImage.image;
                    }

                    /*
                    * Seems some nfts colletion don't write the correct name on-chain
                    * Below technique will allow to fetch missing names by query their json metadata
                    */

                    /*console.log(walletNftNotFiltered);*/
                    if (nft.nftName == "") {
                        try {
                            const mintAddress = new PublicKey(nft.nftTokenAddress);
                            const nftRes = await metaplex.nfts().findByMint({mintAddress}).run();
                            nft.nftName = nftRes!.json!.name;
                        } catch (e) {
                        }
                    }
                    approvedWalletNfts.push(nft);
                })
            );

            const approvedWalletNftsSorted: any = [...approvedWalletNfts].sort((a, b) => Number(b.approvedNftFlag) - Number(a.approvedNftFlag));
            return approvedWalletNftsSorted;
        }
    }
    catch (error)
    {
        console.log("Error:", error);
    }
};
