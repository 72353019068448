import AOS from 'aos';
import "aos/dist/aos.css";
import React, { Component, useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch, useLocation, useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import OldSolKitties from './pages/OldSolKitties';
import PodsPublicMint from "./pages/PodsPublicMint";

import { SnackbarProvider } from 'context';
import NftMachine from 'pages/MyNftMachine/MyNftMachine';
import Snackbar from 'sharedComponent/Snackbar';
import API from './pages/API';
import Game from "./pages/Game";
import Gamev2 from "./pages/Gamev2";
import Gift from "./pages/Gift";
import HomeBreedingPods from "./pages/HomeBreedingPods";
import MyRust from "./pages/MyRust";
import Nfts from "./pages/Nfts";
import PublicMint from "./pages/PublicMint";
import Slots from "./pages/Slots";
import Staking from "./pages/Staking";
import Sweepers from "./pages/Sweepers";
import Verify from "./pages/Verify";
import whitelistWallets from "./pages/WhitelistWallets";

import { WalletError } from "@solana/wallet-adapter-base";
import { ConnectionProvider, WalletProvider } from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    SolletWalletAdapter
} from "@solana/wallet-adapter-wallets";
import CoinflipPage from 'pages/Coinflip';
import HowToWinPage from 'pages/Slots/howtowin';
import { toast, ToastContainer } from "react-toastify";
import {
    CONNECTION_NETWORK,
    CONNECTION_NETWORK_RPC
} from "./config/constants";
import NftPrize from "./pages/NftPrize";
import Raffles from "./pages/Raffles";
import SlotsAdmin from "./pages/Slots/admin";
import Withdrawals from "./pages/Withdrawals";
import Reveals from "./pages/Reveals";
import MintPass from "./pages/ThePrimes/MintPass";
import BurnSol from "./pages/ThePrimes/BurnSol";

const network = CONNECTION_NETWORK;
const network_rpc = CONNECTION_NETWORK_RPC;
const whitepaperPdfLink = "./assets/pdf/SOL_KITTIES_Whitepaper.pdf";
const SolKittyTrailer = "./assets/video/SolKitties_Trailer.mp4";

const confirmTransactionInitialTimeoutMS = 60 * 1000; // 120 secs

class PdfRedirect extends Component {
    render() {
        window.location.replace(whitepaperPdfLink);
        return () => {
        };
    }
}

class TrailerRedirect extends Component {
    render() {
        return (
            <video style={{objectFit: "cover", width: "100vw", height: "100vh", position: "fixed", top: 0, left: 0}}
                   autoPlay={true} muted loop={true} controls={true}>
                <source src={SolKittyTrailer} type="video/mp4"/>
            </video>
        );
    }
}

const App = () => {
    toast.dismiss();
    const [subDomain, setSubDomain] = useState<string>("");
    const location = useLocation();
    const endpoint = useMemo(() => network_rpc, [network]);
    const wallet = useMemo(() => [new PhantomWalletAdapter(), new SolflareWalletAdapter({network}), new SolletWalletAdapter({network}), new SlopeWalletAdapter({network})], [network]);
    const walletConnectionErr = (error: WalletError) => {
    }

    /****** Animation effect ******/
    useEffect(() => {
        AOS.init({
            duration: 500
        });
    }, []);

    useEffect(() => {
        const host = window.location.host;
        const arr = host.split(".");

        if (arr.length > 0) {
            setSubDomain(arr[0]);
        }
    }, []);

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider
                            // @ts-ignore
                            wallets={wallet}
                            onError={walletConnectionErr}
                            autoConnect={(!(location.pathname == "/" || location.pathname == "/whitepaper" ||
                                            location.pathname == "/trailer" || location.pathname == "/old" || location.pathname == "/whitelist"))}>
                <WalletModalProvider>
                    <HelmetProvider>
                        <ToastContainer enableMultiContainer/>
                        <ToastContainer enableMultiContainer containerId={'A'} className="fix-width-toast"/>
                        <ToastContainer enableMultiContainer containerId={'DARK'} className="fix-width-toast" theme={"dark"}/>
                        <Switch>
                            <Route path="/" exact component={HomeBreedingPods}/>
                            <Route path="/staking" exact component={Staking}/>
                            <Route path="/api" exact component={API}/>
                            <SnackbarProvider>
                                <Route path="/podspublicmint" exact render={(props) => <PodsPublicMint {...props} />}/>
                                <Route path="/publicmint" exact component={PublicMint}/>
                                <Route path="/nfts" exact component={Nfts}/>
                                <Snackbar/>
                                <Route path="*" exact component={() => <h2>Page not found</h2>}/>
                            </SnackbarProvider>
                        </Switch>
                    </HelmetProvider>
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

export default App;
