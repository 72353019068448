import axios from "axios";
import {
    REACT_APP_IS_ACTIVE_SK_ENDPOINT,
    REACT_APP_SK_API_ENDPOINT_V2,
    REACT_APP_SERVICA_API_ENDPOINT,
    REACT_APP_RAFFLE_AUTH,
    DEBUG_API_CALLS,
} from "../config/constants";

const endPointAPISK = `${REACT_APP_SK_API_ENDPOINT_V2}kitties-raffles/`;
const endPointAPIServica = `${REACT_APP_SERVICA_API_ENDPOINT}kitties-raffles`;
let endPointAPI = REACT_APP_IS_ACTIVE_SK_ENDPOINT ? endPointAPISK : endPointAPIServica;
export const callRafflesAPI = async (method: string, value: any = "", forceSKEndpoint = false) =>
{
    try
    {
        const currentEndPointAPI = forceSKEndpoint ? endPointAPISK : endPointAPI;
        if (window.location.hostname == "localhost" && DEBUG_API_CALLS)
        {
            const methodStr = `Method: ${method}`;
            //const valueStr = value ? `Value: ${JSON.stringify(value, null, 2)}` : "";
            const valueStr = value;
            console.log(`%cCalling API: ${currentEndPointAPI} ${methodStr} ==> Value Param:`, "color:blue", valueStr);
            //console.log(`%c  ==> Value Param:`, "color:blue", valueStr);
        }

        const res = (
            await axios.post(currentEndPointAPI, {
                method: method,
                value: value,
                Authorization: REACT_APP_RAFFLE_AUTH
            }, {
                headers: { Authorization: REACT_APP_RAFFLE_AUTH },
            })).data;

            // if (window.location.hostname == "localhost" && DEBUG_API_CALLS)
            // {
            //     console.log(`API result for ${method}:`, res.data);
            // }

        return res;
    }
    catch (error)
    {
        console.log("Error:", error);
    }
};

/* this function is expire and me (vishal) created new common function on utilServiceProvider function */
/*export const getApprovedWalletNftsData = async (walletNftData: any) =>
{
    try
    {
        let nftCollectionArray: any = [];
        if (walletNftData && walletNftData.length > 0)
        {
            /!* get raffles Approved Nft Collection for filter users wallet nfts *!/
            const approvedNftCollectionResponse = await callRafflesAPI("getRafflesApprovedNftCollection");
            console.log("Get raffles approved nft collections from API:", approvedNftCollectionResponse);

            if (!approvedNftCollectionResponse.success)
            {
                console.log("Error fetching Approved NFT Collection from API");
                return;
            }

            const approvedNftCollectionArray = approvedNftCollectionResponse.data;
            //console.log(approvedNftCollectionArray);

            walletNftData?.map((nft: any, index: number) =>
            {
                let isNftInApprovedCollection = false;
                let approvedCreatorAddress = "";
                nft.data.creators?.map((creators: any, index: number) =>
                {
                    if (creators.verified == 1 || creators.address == "4Ar5WXALb8BvWxrrnSRmHLWEG7BN3jET7QgxquaKwfTm" || creators.address == "2T3nPvNXML4sEKk9AErbjohKS1mgTYXiY7LXQXPRwnoG")
                    {
                        if (approvedNftCollectionArray.includes(creators.address))
                        {
                            isNftInApprovedCollection = true;
                            approvedCreatorAddress = creators.address;
                        }

                        //console.log(nft.data.name + " " + isNftInApprovedCollection + " " + creators.address);
                    }
                })

                nftCollectionArray.push
                ({
                    nftName: nft.data.name,
                    nftTokenAddress: nft.mint,
                    approvedCreatorAddress: approvedCreatorAddress,
                    nftImage: nft.data.uri,
                    approvedNftFlag: isNftInApprovedCollection
                });
            });

            return nftCollectionArray;
        }
    }
    catch (error)
    {
        console.log("Error:", error);
    }
};*/
