import React, {useEffect, useState} from "react";
import {CircularProgress, Grid} from "@material-ui/core";
import {useConnection, useWallet} from "@solana/wallet-adapter-react";
import * as anchor from "@project-serum/anchor";

import CoinCat from "../../assets/images/user-info-panel/coincat.png";
import BulletGold from "../../assets/images/staking/kitty_icon_small.png";
import CatIcon from "../../assets/images/staking/cat-icon.png";
import Chest from "../../assets/images/staking/chest.png";
import StakingMultiplier from "../../assets/images/staking/staking.png";
import refresh from "../../assets/images/staking/refresh.png";

import {KittiesList} from "./KittiesList";
import {DEBUG_WALLET_ADDRESS} from "../../config/constants";
import {SubFooter} from "../../sharedComponent/SubFooter";
import stakingTitle from "../../assets/images/staking/staking-title.png";
import {getUserDataNew, getUserStakedTokensData} from "../../service/kittyServiceProvider";
import {AESDecrypt, AESEncrypt} from "../../service/cryptoHelper";
import {getStakeStatsData} from "../../service/utilServiceProvider";
import {UserInfoPanel} from "../../sharedComponent/UserInfoPanel";
import {getWallet2d3dNfts} from "../../service/walletServiceProvider";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../redux/store";
import {
    setKittyNftHoldingAction,
    userInfoFlagAction,
    userStakedTokensAction,
    userTicketsAction
} from "../../redux/userReduxSlice";
import { isWalletConnetedAction, setStakingVaultBalanceAction, setUserWalletBalanceAction } from "../../redux/walletReduxSlice";
import {UserInfoPanelNew} from "../../sharedComponent/UserInfoPanelNew";
import {callRafflesAPI} from "../../service/rafflesServiceProvider";
import {userInfoConfigType} from "../../types/interface/UtilInterface";
import { getStakingVaultBalance, getWalletBalance } from "config/utils";

export const StakingController = (): JSX.Element => {
    const connection = useConnection();
    const wallet = useWallet();
    const anchorWallet = {
        publicKey: DEBUG_WALLET_ADDRESS ?? wallet.publicKey,
        signAllTransactions: wallet.signAllTransactions,
        signTransaction: wallet.signTransaction,
    } as anchor.Wallet;

    const userReduxStore = useSelector((state: RootState) => state.userReduxSlice);
    const walletReduxStore = useSelector((state: RootState) => state.walletReduxSlice);
    const utilReduxStore = useSelector((state: RootState) => state.utilReduxSlice);
    const dispatch: AppDispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [loadingNfts, setLoadingNfts] = useState(false);
    const [userInfoConfig, setUserInfoConfig] = useState<userInfoConfigType>({
        kittyCoinsFlag: true,
        kittyCoinConvertFlag: true,
        monthlySolFlag: true,
        solBalanceFlag: true,
        solRoundBalanceFlag: true,
        ticketBoughtFlag: false,
        sktBalanceFlag: true,
        sktToSolFlag: true,
        royaltiesFlag: true
    });

    useEffect(() => {
        if(utilReduxStore.globalStakeStats.state == "init") {
            dispatch(getStakeStatsData());
        }
    }, []);

    const fetchUserStakedTokensData = async (userId: string) => {
        if(userId && userId != "") {
            let stakedTokens = await getUserStakedTokensData(userId);
            if (stakedTokens) {
                const sortStakedTokens = stakedTokens.sort((a: any, b: any) => a.rarity > b.rarity ? 1 : -1);
                dispatch(userStakedTokensAction(sortStakedTokens));
            }
        }
        
    }

    const handleChildUpdate = async (updateFrom: string) => {
        if (updateFrom == "stakeUserData") {
            await fetchUserStakedTokensData(userReduxStore.userInfoData.userId);
        } else if (updateFrom == "walletData") {
            dispatch(getWallet2d3dNfts({ connection: connection.connection, walletAddress: anchorWallet.publicKey.toString() }));
        } else if (updateFrom == "userInfoData") {
            dispatch(getUserDataNew(anchorWallet.publicKey.toString()));
        } else if (updateFrom == "convertCoin") {
            /* TBD call convert coin function */
        }
    }

    const handlePageLoadData = () => {
        let userInfoData = userReduxStore.userInfoData;
        userInfoData = {
            ...userInfoData,
            monthlySolFlag: true,
            ticketsFlag: false
        }
        dispatch(userInfoFlagAction(userInfoData));
    }

    useEffect(() => {
        async function getData() {
            if (!loading) {
                setLoading(true);
                handlePageLoadData();

                /* get user wallet balance and store on redux store */
                const getUserWalletBalance = await getWalletBalance(connection.connection, anchorWallet?.publicKey);
                dispatch(setUserWalletBalanceAction(getUserWalletBalance));
                console.log("Wallet Balance:\n", getUserWalletBalance);

                const stakingVaultBalance = await getStakingVaultBalance(connection.connection);
                dispatch(setStakingVaultBalanceAction(stakingVaultBalance));
                console.log("Staking Vault Balance:", stakingVaultBalance, "SOL");

                if(anchorWallet?.publicKey.toString()) {
                    /* get user data from api */
                    const reduxResponse = await dispatch(getUserDataNew(anchorWallet?.publicKey.toString()));
                    if(reduxResponse.meta.requestStatus == "fulfilled") {
                        if(reduxResponse.payload.userId != "") {
                            /* fetch user stakedToken Data from api */
                            if(userReduxStore.userStakedTokensData.length <= 0) {
                                await fetchUserStakedTokensData(reduxResponse.payload.userId);
                            }
                        }
                    }
                }

                /* get wallet nfts data */
                await dispatch(getWallet2d3dNfts({ connection: connection.connection, walletAddress: anchorWallet.publicKey.toString() }));
                setLoadingNfts(true);
            }
        }

        if (wallet.connected) {
            if(!walletReduxStore.isWalletConneted) {
                dispatch(isWalletConnetedAction(true));
            }
            getData();
        } else {
            dispatch(isWalletConnetedAction(false));
            setLoading(false);
        }

        return () => {
            if (!loading) {
                setLoading(true);
            }
        };
    }, [wallet]);

    return (
        <div className="gradient">
            <div className="main-layout">
                <div className="main-container">
                    {wallet.connected ? (
                        <>
                        <div className="with-login">
                            <Grid container spacing={2} className="heading-section">
                                <Grid item lg={8} md={8} sm={12} xs={12}>
                                    <div className="title-img">
                                        <img src={stakingTitle} alt='staking title'/>
                                    </div>
                                </Grid>

                                <UserInfoPanelNew config={userInfoConfig}/>
                            </Grid>

                            <Grid container className="wallet-section" alignItems="center">
                                <Grid item lg={5} sm={12} md={5} xs={12}>
                                    <div className="wallet-image">
                                        <div className="left-bag"><img src={Chest} alt="chest"/></div>
                                        <div className="badge-text">
                                            <img src={StakingMultiplier} className='multiplier-img'
                                                    alt="multiplier"/>
                                            <div
                                                className="set-multiplier-center">x{userReduxStore.userInfoData.userCoinsMulti ? userReduxStore.userInfoData.userCoinsMulti : 1}
                                            </div>
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item className="kitty-features" lg={7} sm={12} md={7} xs={12}>
                                    <div className="kitties-intro-section">
                                        <div className="sol-kitties-staking">
                                            <ul className="collect-list collect-list-colum">
                                                <li>
                                                    <p><img src={BulletGold} alt="bullet"/>
                                                        <span>Rarity Rewards:</span></p>
                                                    <ul className="reward-sub">
                                                        <li className="legendary">Legendary (Rank
                                                            1-80): <span>  <img
                                                                src={CoinCat} alt="coincat"/> <p>0.375</p> </span>
                                                        </li>
                                                        <li className="epic">Epic (Rank 81-250): <span> <img
                                                            src={CoinCat} alt="coincat"/>  <p>0.325</p></span></li>
                                                        <li className="rare">Rare (Rank 251-750): <span>  <img
                                                            src={CoinCat} alt="coincat"/>  <p>0.3</p></span></li>
                                                        <li className="un-commmon">Uncommon (Rank
                                                            751-1500): <span>  <img
                                                                src={CoinCat} alt="coincat"/>  <p>0.275</p> </span>
                                                        </li>
                                                        <li className="common">Common (Rank 1500+): <span> <img
                                                            src={CoinCat} alt="coincat"/>  <p>0.25</p></span></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><img src={BulletGold} alt="bullet"/>
                                                        <span>2D Multiplier Incentives</span></p>
                                                    <ul className="reward-sub multiper-sub">
                                                        <li className="commmon">Stake 1-3
                                                            kitties <span> +25% </span></li>
                                                        <li className="commmon">Stake 4-6
                                                            kitties <span>  +50% </span></li>
                                                        <li className="commmon">Stake 7-14
                                                            kitties <span>    +75%</span></li>
                                                        <li className="commmon">Stake 15+
                                                            kitties <span>    +100%</span></li>
                                                        <li className="commmon pod">Hold a breeding
                                                            pod <span>  50%</span></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                            <ul className="collect-list single-list">
                                                <li>
                                                    <img src={BulletGold} alt="bullet"/>
                                                    <span>Kitties will be sent to Stake Wallet.</span>
                                                </li>
                                                <li>
                                                    <img src={BulletGold} alt="bullet"/>
                                                    <span>Kitty Coins will be distributed each day.</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid container justifyContent="space-between" alignItems="center" className="staking-stats">
                                <Grid item lg={4} sm={12} md={4} xs={12}></Grid>
                                <Grid item lg={4} sm={12} md={4} xs={12}>
                                    <Grid item xs={12}>
                                        <h5 className="staking-title">Global 2D Staking Stats</h5>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container justifyContent="space-between">
                                <Grid item lg={4} sm={12} md={4} xs={12}>
                                    <Grid container spacing={2} className="stats-margin">
                                        <Grid item xs={6}>
                                            <div className="common-stats staked-kitties">
                                                <button>
                                                    <img src={CatIcon} alt="cat-icon"/>
                                                    <span>{userReduxStore.userInfoData.stakedAmount}</span>
                                                </button>
                                                <h5>Your Staked Kitties</h5>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className="common-stats daily-earning">
                                                <button>
                                                    <img src={CoinCat} alt="coin-icon"/>
                                                    <span>{userReduxStore.userInfoData.stakedWorth}</span>
                                                </button>
                                                <h5>Your daily Kitty Coins earnings</h5>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg={4} sm={12} md={4} xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <div className="common-stats stake-percentage">
                                                <button>
                                                    <span>{utilReduxStore.globalStakeStats.totalStakedTokensPrecentages}</span>
                                                </button>
                                                <h5>Staked Percentages</h5>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className="common-stats stake-token">
                                                <button>
                                                    <span>{utilReduxStore.globalStakeStats.totalStakedTokens}</span>
                                                </button>
                                                <h5>Staked Kitties</h5>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                        <div className={`your-kittie-bg`}>
                            <section className="your-pods-wrapper your-kitties-wrapper">
                                <Grid container spacing={2}>
                                    <Grid item lg={12} sm={12} md={12} xs={12}>
                                        {
                                            !loadingNfts &&
                                            <div className="loading" >
                                                <CircularProgress />
                                            </div>                                        
                                        }

                                        {
                                            walletReduxStore.wallet2dNftData.length >= 1 &&
                                            <>
                                                <h1 className="dashed-title title-text">YOUR 2D KITTIES
                                                    <img src={refresh} alt="refresh" className="set-refresh-img"
                                                         onClick={() => handleChildUpdate("walletData")}/>
                                                </h1>
                                                <KittiesList kittiesArray={walletReduxStore.wallet2dNftData} kittyType='wallet' childUpdate={handleChildUpdate}/>
                                            </>
                                        }

                                        {
                                            walletReduxStore.wallet3dNftData.length >= 1 &&
                                            <>
                                                <h1 className="dashed-title title-text">YOUR 3D KITTIES
                                                    <img src={refresh} alt="refresh" className="set-refresh-img"
                                                         onClick={() => handleChildUpdate("walletData")}/>
                                                </h1>
                                                <KittiesList kittyType='wallet-3d' kittiesArray={walletReduxStore.wallet3dNftData} childUpdate={handleChildUpdate}/>
                                            </>
                                        }

                                        {
                                            userReduxStore.userStakedTokensData.length >=1 &&
                                            <>
                                                <h1 className="dashed-title title-text">STAKED KITTIES
                                                    <img src={refresh} alt="refresh"
                                                         className="set-refresh-img"
                                                         onClick={() => handleChildUpdate("stakeUserData")}/>
                                                </h1>
                                                <KittiesList kittyType='user' kittiesArray={userReduxStore.userStakedTokensData} childUpdate={handleChildUpdate}/>
                                            </>
                                        }
                                    </Grid>
                                </Grid>
                            </section>
                        </div>
                        </>
                    ) : (
                        <div className="without-login">
                                <Grid container justifyContent="space-between">
                                    <Grid item lg={8} md={8} sm={12} xs={12}>
                                        <div className="title-img">
                                            <img src={stakingTitle} alt='staking title'/>
                                        </div>
                                    </Grid>

                                    <UserInfoPanelNew config={userInfoConfig}/>
                                </Grid>

                                <Grid container className="wallet-section" alignItems="center">
                                    <Grid item lg={5} sm={12} md={4} xs={12}>
                                        <div className="wallet-image">
                                            <div className='chest-container'>
                                                <div className="left-bag"><img src={Chest} alt="chest"/></div>
                                                <div className="badge-text">
                                                    <img src={StakingMultiplier} className='multiplier-img'
                                                         alt="multiplier"/>
                                                    <div
                                                        className="set-multiplier-center">x{userReduxStore.userInfoData.userCoinsMulti ? userReduxStore.userInfoData.userCoinsMulti : 1}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item className="kitty-features feature-staking" lg={7} sm={12} md={8}
                                          xs={12}>
                                        <div className="kitties-intro-section">
                                            <h3>Connect Wallet to stake your kitties</h3>
                                            <div className="sol-kitties-staking">
                                                <ul className="collect-list collect-list-colum">
                                                    <li>
                                                        <p><img src={BulletGold} alt="bullet"/>
                                                            <span>Rarity Rewards:</span></p>
                                                        <ul className="reward-sub">
                                                            <li className="legendary">Legendary (Rank
                                                                1-80): <span>  <img
                                                                    src={CoinCat} alt="coincat"/> <p>0.375</p> </span>
                                                            </li>
                                                            <li className="epic">Epic (Rank 81-250): <span> <img
                                                                src={CoinCat} alt="coincat"/>  <p>0.325</p></span></li>
                                                            <li className="rare">Rare (Rank 251-750): <span>  <img
                                                                src={CoinCat} alt="coincat"/>  <p>0.3</p></span></li>
                                                            <li className="un-commmon">Uncommon (Rank
                                                                751-1500): <span>  <img
                                                                    src={CoinCat} alt="coincat"/>  <p>0.275</p> </span>
                                                            </li>
                                                            <li className="common">Common (Rank 1500+): <span> <img
                                                                src={CoinCat} alt="coincat"/>  <p>0.25</p></span></li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <p><img src={BulletGold} alt="bullet"/>
                                                            <span>2D Multiplier Incentives</span></p>
                                                        <ul className="reward-sub multiper-sub">
                                                            <li className="commmon">Stake 1-3
                                                                kitties <span> +25% </span></li>
                                                            <li className="commmon">Stake 4-6
                                                                kitties <span>  +50% </span></li>
                                                            <li className="commmon">Stake 7-14
                                                                kitties <span>    +75%</span></li>
                                                            <li className="commmon">Stake 15+
                                                                kitties <span>    +100%</span></li>
                                                            <li className="commmon pod">Hold a breeding
                                                                pod <span>  50%</span></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                                <ul className="collect-list single-list">
                                                    <li>
                                                        <img src={BulletGold} alt="bullet"/>
                                                        <span>Kitties will be sent to Stake Wallet.</span>
                                                    </li>
                                                    <li>
                                                        <img src={BulletGold} alt="bullet"/>
                                                        <span>
                                  Kitty Coins will be distributed each day.
                                </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container className="stats-section">
                                    <Grid item lg={4} md={4} sm={8} xs={11}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <h5 className="staking-title">Global 2D Staking Stats</h5>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div className="common-stats stake-percentage">
                                                    <button>
                                                        <span>{utilReduxStore.globalStakeStats.totalStakedTokensPrecentages}</span>
                                                    </button>
                                                    <h5>Staked Percentages</h5>
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div className="common-stats stake-token">
                                                    <button>
                                                        <span>{utilReduxStore.globalStakeStats.totalStakedTokens}</span>
                                                    </button>
                                                    <h5>Staked Kitties</h5>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                    )}
                </div>
            </div>
        </div>
    );
};
