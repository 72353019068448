import React, {useMemo} from 'react';
import {
    Box, createTheme,
    Grid, ThemeProvider,
    Typography
} from '@material-ui/core';

import "./index.css"
import {clusterApiUrl} from "@solana/web3.js";
import {PhantomWalletAdapter, SolflareWalletAdapter, SolletWalletAdapter} from '@solana/wallet-adapter-wallets';
import {
    SOLANA_NETWORK_MAINNET,
    SOLANA_RPC_HOST_MAINNET,
} from "../../config/constants";
import {WalletAdapterNetwork} from '@solana/wallet-adapter-base';
import * as anchor from '@project-serum/anchor';
import {ConnectionProvider, WalletProvider} from '@solana/wallet-adapter-react';
import {WalletDialogProvider} from "@solana/wallet-adapter-material-ui";
import {ControllerPodstPublicMint} from "../../components/ControllerPodsPublicMint";
const network = SOLANA_NETWORK_MAINNET as WalletAdapterNetwork

const connectionWhiteList = new anchor.web3.Connection(SOLANA_RPC_HOST_MAINNET!)

const txTimeout = 60000; // milliseconds (confirm this works for your project)

const theme = createTheme({
    overrides: {
        MuiButton: {
            root: {
                color: 'white',
            },
            label: {
                color: 'white',
            },
        },
        MuiTypography: {
            root: {
                color: 'white',
            },
        },
    },
});

const PodsPublicMint = (props: any): JSX.Element => {
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);
    const wallets = useMemo(() => [new PhantomWalletAdapter(), new SolletWalletAdapter({ network })], [network]);

    return (
        <div style={{background: 'white'}} className="pods-page">
            <ThemeProvider theme={theme}>
                <ConnectionProvider endpoint={endpoint}>
                    <WalletProvider wallets={wallets} autoConnect={true}>
                        <WalletDialogProvider>
                            {/*<MyNavBar />*/}
                            <ControllerPodstPublicMint {...props} connection={connectionWhiteList} txTimeout={txTimeout}/>
                            {/*<Footer />*/}
                        </WalletDialogProvider>
                    </WalletProvider>
                </ConnectionProvider>
            </ThemeProvider>
        </div>
    )
}

export default PodsPublicMint;
