import { Commitment, ComputeBudgetProgram, Connection, Finality, SignatureResult, Signer, Transaction, TransactionInstruction, TransactionMessage, VersionedTransaction } from "@solana/web3.js";
import { WalletContextState } from "@solana/wallet-adapter-react";
import { WalletSendTransactionError } from "@solana/wallet-adapter-base";
import { sleep } from "../config/utils";
import { waitUntil } from "./utilsGeneral";
import { txV0Result } from "../types/interface/UtilInterface";
import { buyRaffleTicketsType } from "../types/interface/RaffleInterface";

const defaultRPC = "https://cornie-tey2qg-fast-mainnet.helius-rpc.com/";
const errorCodesPPP = JSON.parse(`{"errors":[{"code":6000,"name":"EntrantsAccountTooSmallForMaxEntrants","msg":"Entrants account too small for max entrants"},{"code":6001,"name":"RaffleEnded","msg":"Raffle has ended"},{"code":6002,"name":"InvalidPrizeIndex","msg":"Invalid prize index"},{"code":6003,"name":"NoPrize","msg":"No prize"},{"code":6004,"name":"InvalidCalculation","msg":"Invalid calculation"},{"code":6005,"name":"NotEnoughTicketsLeft","msg":"Not enough tickets left"},{"code":6006,"name":"RaffleStillRunning","msg":"Raffle is still running"},{"code":6007,"name":"WinnersAlreadyDrawn","msg":"Winner already drawn"},{"code":6008,"name":"WinnerNotDrawn","msg":"Winner not drawn"},{"code":6009,"name":"InvalidRevealedData","msg":"Invalid revealed data"},{"code":6010,"name":"TokenAccountNotOwnedByWinner","msg":"Ticket account not owned by winner"},{"code":6011,"name":"TicketHasNotWon","msg":"Ticket has not won"},{"code":6012,"name":"UnclaimedPrizes","msg":"Unclaimed prizes"},{"code":6013,"name":"InvalidRecentBlockhashes","msg":"Invalid recent blockhashes"},{"code":6014,"name":"OnlyCreatorCanClaimNoEntrantRafflePrizes","msg":"Only the creator can claim no entrant raffle prizes"},{"code":6015,"name":"InvalidTreasuryTokenAccountOwner","msg":"Invalid treasury token account owner"},{"code":6016,"name":"InvalidStake","msg":"Invalid Stake"},{"code":6017,"name":"RaffleActive","msg":"Raffle active"},{"code":6018,"name":"InvalidTime","msg":"Invalid time"},{"code":6019,"name":"InvalidTxn","msg":"Invalid txn"},{"code":6020,"name":"InvalidHolder","msg":"Invalid holder info"}]}`);

const RPC_CONFIRMATIONS: ({ conn?: Connection; rpc: string; name: string })[] =
                           [{ name: "Helius", rpc: "https://cornie-tey2qg-fast-mainnet.helius-rpc.com/" }];

const PRIORITY_RATE = 500; // MICRO_LAMPORTS
const PRIORITY_FEE_IX1 = ComputeBudgetProgram.setComputeUnitPrice({microLamports: PRIORITY_RATE});
//const PRIORITY_FEE_IX2 = ComputeBudgetProgram.setComputeUnitLimit({units:10});

export type ConfirmationCommitment = 'processed' | 'confirmed' | 'finalized';

export class WrappedConnection extends Connection
{
    buyerWallet: WalletContextState;
    rpcUrl: string;

    constructor(wallet: WalletContextState, commitmentOrConfig : Commitment = "processed", rpcUrl?: string)
    {
        super(rpcUrl ?? defaultRPC, commitmentOrConfig);
        this.rpcUrl = rpcUrl ?? defaultRPC;
        this.buyerWallet = wallet;
    }

    async getParsedTransactionV0(txId: string, commitment: Finality = "confirmed")
    {
        let parsed = null;

        while (!parsed || !parsed?.meta || !parsed?.meta?.logMessages)
        {
            try
            {
                console.log("getParsedTransactionV0() fetching...");
                parsed = await this.getParsedTransaction(txId, { commitment: commitment, maxSupportedTransactionVersion: 0 });
            }
            catch (e) {}
            finally
            {
                await sleep(1000);
            }
        }

        return parsed;
    }

    async createAndSendV0TxBulk(transactions: Transaction[], payerWallet: WalletContextState, onTxSent?: (txId: string) => Promise<void>, onTxConfirmed?: (txV0Result: txV0Result) => Promise<void>, confirmationCommitment: ConfirmationCommitment = "processed", signers?: Array<Signer>, skipPreflight: boolean = false)
    {
        const result: txV0Result[] = [];//{ success: false, txId: "", signatureResult: null, commitment: "recent" };
        const transactionsV0: VersionedTransaction[] = [];

        console.log(`IN createAndSendV0Tx() | skipPreflight: ${skipPreflight}`);
        const startTime = new Date();

        // Step 1 - Fetch Latest Blockhash
        let latestBlockhash = await this.getLatestBlockhash("finalized");
        let currentBlockHeight = (await this.getBlockHeight('finalized'));
        console.log(`   ✅ - Fetched latest blockhash. Last Valid Height: ${latestBlockhash.lastValidBlockHeight} Diff: ${currentBlockHeight-(latestBlockhash.lastValidBlockHeight-150)}`);

        let txCounter = 1;
        for (const tx of transactions)
        {
            const txInstructions = tx.instructions;

            // Step 1.2 - Add priority fee IX
            //txInstructions.push(PRIORITY_FEE_IX);

            const newTxInstructions = txInstructions.filter((ix) =>
            {
                return ix.programId != ComputeBudgetProgram.programId;
            });

            // Step 2 - Generate Transaction Message
            const messageV0 = new TransactionMessage({
                payerKey: payerWallet.publicKey!,
                recentBlockhash: latestBlockhash.blockhash,
                instructions: newTxInstructions
            }).compileToV0Message();

            console.log("   ✅ - Compiled Transaction Message, txCounter:", txCounter);
            const txV0 = new VersionedTransaction(messageV0);
            if (signers) {
                txV0.sign(signers);
            }

            // for (const sig of tx.signatures)
            // {
            //     //console.log(Uint8Array.from(sig.signature!));
            //     // @ts-ignore
            //     txV0.addSignature(sig.publicKey, sig.signature);
            // }

            // console.log(tx);
            // console.log("txV0", txV0);
            transactionsV0.push(txV0);

            txCounter++;
        }

        // Step 3 - Sign your transaction with the required `Signers`
        const txsSigned: VersionedTransaction[] = await payerWallet?.signAllTransactions!(transactionsV0);
        console.log("   ✅ - Transaction Signed");

        console.log("txSigned", txsSigned);
        let i = 0;
        //for (const txSigned of txsSigned)
        await Promise.all(txsSigned.map(async (txSigned: VersionedTransaction) =>
        {
            try
            {
                console.log("Transmitting...", txSigned);

                // Step 4 - Send our v0 transaction to the cluster
                const txId = await this.sendRawTransaction(txSigned.serialize(), {maxRetries: 8, skipPreflight, preflightCommitment: "processed"});
                console.log(`   ✅ - Transaction sent to network, txId: ${txId} commitment: ${this.commitment} waiting for: ${confirmationCommitment}`);
                if (onTxSent)
                {
                    await onTxSent(txId);
                }

                // Step 5 - register signature subscription with multiple RPCs and commitments
                let signatureResult: SignatureResult = {err: null};
                let confirmStates: { processed: boolean; confirmed: boolean; finalized: boolean; } = {"processed": false, "confirmed": false, "finalized": false};
                for (const rpcInfo of RPC_CONFIRMATIONS)
                {
                    //console.log(rpcInfo.rpc);

                    rpcInfo.conn = rpcInfo.conn ?? new Connection(rpcInfo.rpc);
                    const rpcConn = rpcInfo.conn;

                    Object.entries(confirmStates).map(([commit, val]) =>
                    {
                        const commitmentState = commit as "processed" | "confirmed" | "finalized";

                        rpcConn.onSignature(txId, async (sigResult) =>
                        {
                            if (!confirmStates[commitmentState])
                            {
                                confirmStates[commitmentState] = true;

                                const elapsed = (new Date().getTime() - startTime.getTime()) / 1000;
                                currentBlockHeight = (await rpcConn.getBlockHeight('finalized'));

                                console.log(` 🐎 TX CALL BACK ${rpcInfo.name}!!! ${commitmentState} | Elapsed: ${elapsed} seconds | BlocksLeft: ${currentBlockHeight - (latestBlockhash.lastValidBlockHeight - 150)} | ${txId}`, sigResult?.err);

                                signatureResult = sigResult;

                                onTxConfirmed?.({success: true, txId, commitment: commitmentState, signatureResult});
                            }
                        }, commitmentState);
                    });
                }

                // Step 6 - Confirm Transaction (confirmed commitment)
                await waitUntil(() => confirmStates[confirmationCommitment]);

                // if (Math.floor(Math.random() * 4) == 1) {
                //     signatureResult.err = "just a demo error";
                // }

                // @ts-ignore
                const dataItem: buyRaffleTicketsType = transactions[i].dataItem;
                if (signatureResult?.err)
                {
                    throw new Error(`   ❌ - Transaction #${i} not ${this.commitment}. Error: ${JSON.stringify(signatureResult?.err)} NFT: ${dataItem.raffleNftData.nftName}`);
                }
                else
                {
                    // @ts-ignore
                    result.push({success: true, signature: txId, txId, signatureResult, commitment: confirmationCommitment, dataItem: dataItem, dataInstruction: transactions[i].dataInstruction});
                    console.log(`🎉 Transaction Successfully ${confirmationCommitment}!`);
                    console.log("   => Solscan:", `https://solscan.io/tx/${txId}`);
                }
            }
            catch (e)
            {

                // @ts-ignore
                const dataItemError: buyRaffleTicketsType = transactions[i].dataItem;

                // @ts-ignore
                result.push({success: false, signature: "", txId: "", signatureResult: null, commitment: confirmationCommitment, dataItem: dataItemError, dataInstruction: transactions[i].dataInstruction});

                console.error("Exception in createAndSendV0Tx() - 👇");
                console.error("Index:", i, "NFT:", dataItemError?.raffleNftData?.nftName ,"DataItem:", dataItemError);

                //console.error("Typeof e:", typeof e);
                //if (e instanceof WalletSendTransactionError)
                if (e?.toString().includes("0x1771"))
                {
                    console.log(errorCodesPPP);
                    console.error("Error 6001: Raffle has ended.");
                }
                if (e?.toString().includes("0x1774"))
                {
                    console.log(errorCodesPPP);
                    console.error("Error 6004: max tickets bought.");
                }
                if (e?.toString().includes("0x1775"))
                {
                    console.log(errorCodesPPP);
                    console.error("Error 6005: Not enough tickets left.");
                }
                else
                {
                    console.error(e);
                }
            }

            i++;
        }));

        return result;
    }

    async createAndSendV0Tx(txInstructions: TransactionInstruction[], payerWallet: WalletContextState, onTxSent?: (txId: string) => Promise<void>, onTxConfirmed?: (txV0Result: txV0Result) => Promise<void>, confirmationCommitment: ConfirmationCommitment = "processed", signers?: Array<Signer>, skipPreflight: boolean = false)
    {
        const transaction = new Transaction();
        for (const ix of txInstructions)
        {
            transaction.add(ix);
        }

        const results: txV0Result[] = await this.createAndSendV0TxBulk([transaction], payerWallet, onTxSent, onTxConfirmed, confirmationCommitment, signers, skipPreflight);

        return results[0];
    }
}