import axios from "axios";
import {createAsyncThunk} from "@reduxjs/toolkit";
import { REACT_APP_KITTY_AUTH, REACT_APP_SK_API_ENDPOINT_V2, } from "../config/constants";

const discordUserUrl = "https://discordapp.com/api/users/@me";
// const endPointAPI = REACT_APP_IS_ACTIVE_SK_ENDPOINT ? `${REACT_APP_SK_API_ENDPOINT}api/` : `${REACT_APP_SERVICA_API_ENDPOINT}kitties`;
// const endPointGetWalletsByUserId = REACT_APP_IS_ACTIVE_SK_ENDPOINT ? `${endPointAPI}get_wallets_by_userid/` : `${endPointAPI}get_wallets_by_userid/`;
// const endPointVerifyWallet = REACT_APP_IS_ACTIVE_SK_ENDPOINT ? `${endPointAPI}verify_wallet/` : `${endPointAPI}verify_wallet/`;

const endPointAPI = `${REACT_APP_SK_API_ENDPOINT_V2}kitties`;
const endPointGetWalletsByUserId = `${endPointAPI}get_wallets_by_userid/`;
const endPointVerifyWallet = `${endPointAPI}verify_wallet/`;

export const getUserData = async (walletAddress: string) => {
    try {
        return (
            await axios.post(endPointAPI, {
                endpoint: "get_user_info",
                walletAddress: walletAddress,
            }, {
                headers: {Authorization: REACT_APP_KITTY_AUTH},
            })
        ).data;
    } catch (error) {
        console.log("Error:", error);
    }
}

export const getUserDataNew = createAsyncThunk(
    "getUserData",
    async (walletAddress: string, { rejectWithValue }) => {
        try {
            return (
                await axios.post(endPointAPI, {
                    endpoint: "get_user_info",
                    walletAddress: walletAddress,
                }, {
                    headers: {Authorization: REACT_APP_KITTY_AUTH},
                })
            ).data;
        } catch (error) {
            console.log("Error:", error);
            return rejectWithValue(error);
        }
    }
);

export const getDiscordUserData = async (accessToken: string) => {
    try {
        return (
            await axios.get(discordUserUrl, {
                headers: {Authorization: `Bearer ${accessToken}`},
            })
        ).data;
    } catch (error) {
        console.log("Error:", error);
    }
};

export const getUserStakedTokensData = async (userId: string) => {
    return (
        await axios.post(endPointAPI, {
            endpoint: "get_user_staked_tokens",
            userId: userId,
        }, {
            headers: {Authorization: REACT_APP_KITTY_AUTH},
        })
    ).data;
}

export const getApprovedWalletData = async (userId: string) => {
    try {
        return (
            await axios.post(endPointAPI, {
                endpoint: "get_wallets_by_userid",
                userId: userId,
            }, {
                headers: {Authorization: REACT_APP_KITTY_AUTH},
            })
        ).data;
    } catch (error) {
        console.log("Error:", error);
    }
}

export const verifyUserWallet = async (jsonData: any) => {
    try {
        return (
            await axios.post(endPointAPI, {
                endpoint: "verify_wallet",
                jsonData: jsonData,
            }, {
                headers: {Authorization: REACT_APP_KITTY_AUTH},
            })
        ).data;
    } catch (error) {
        console.log("Error:", error);
    }
};

export const removeVerifyUserWallet = async (jsonData: any) => {
    try {
        return (
            await axios.post(endPointAPI, {
                endpoint: "remove_verify_wallet",
                jsonData: jsonData,
            }, {
                headers: {Authorization: REACT_APP_KITTY_AUTH},
            })
        ).data;
    } catch (error) {
        console.log("Error:", error);
    }
};

export const addCoinsToUserWallet = async (userId: string, coins: any) => {
    try {
        return (
            await axios.post(endPointAPI, {
                endpoint: "update_user_coins",
                userId: userId,
                coins: coins,
            }, {
                headers: {Authorization: REACT_APP_KITTY_AUTH},
            })
        ).data;
    } catch (error) {
        console.log("Error:", error);
    }
};

export const getUserTickets = async (walletAddress: string) => {
    try {
        return (
            await axios.post(endPointAPI, {
                endpoint: "get_tickets_data",
                walletAddress: walletAddress,
            }, {
                headers: {Authorization: REACT_APP_KITTY_AUTH},
            })
        ).data;
    } catch (error) {
        console.log("Error:", error);
    }
};

export const buyUserTickets = async (anchorWallet: any, ticketsAmount: number, txId: string) => {
    try {
        return (
            await axios.post(endPointAPI, {
                endpoint: "buy_ticket",
                walletAddress: anchorWallet,
                ticketsAmount: ticketsAmount,
                txId: txId
            }, {
                headers: {Authorization: REACT_APP_KITTY_AUTH},
            })
        ).data;
    } catch (error) {
        console.log("Error:", error);
    }
};
