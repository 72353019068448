import React  from 'react'
import "./style.css";
import {Box, Grid} from "@material-ui/core";
import KittyFooter from "../../assets/images/footer/kitty-footer.png";
import Avtar from "../../assets/images/solana-avatar.png";
import {useLocation } from 'react-router-dom';
import {RAFFLE_VERSION } from '../../config/constants'
export const SubFooter = (props: any): JSX.Element => {
  const location = useLocation()
  return (
      <>
          <div className={`solanaAvatarArea ${(props.showAvtar) ? 'hide-avtar' : ''} `}>
              <div className="main-layout">
                  <div className="main-container">
                      <Grid container>
                          <Grid item lg={5} sm={12} md={4} xs={12}>
                              <div className="kitty-footer">
                                  <img src={KittyFooter} alt="kitty-footer" />
                              </div>
                              { location.pathname.includes("raffles") && <p className="version">{RAFFLE_VERSION}</p> }
                          </Grid>
                      </Grid>
                      <Box className="solana">
                          <p style={{ fontFamily: "Orbitron", fontSize: "10.65px" }}>
                              POWERED BY
                          </p>
                          <img src={Avtar} alt="avtar" style={{ width: "158px", height: "30px" }} />
             
                      </Box>
                  </div>
              </div>
          </div>
      </>
  )
}
