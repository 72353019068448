import React, { useState, useEffect } from 'react'
import {
  FooterContainer,
  SocialIconWrapper,
  ArrowIconWrapper
} from './styles'
import discrodIcon from "../../assets/images/discrod.svg";

export const Footer = (): JSX.Element => {
  const [isArrow, setIsArrow] = useState(false)

  const handleGoTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  const redirectSite = (url: string) => {
    window.open(url, '_blank')
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 70) {
        setIsArrow(true)
      } else {
        setIsArrow(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [isArrow])

  return (
      <>
        <FooterContainer>
          {/*<SocialIconWrapper>*/}
          {/*  <img src={discrodIcon} alt='discord' onClick={() => redirectSite('http://discord.gg/solkitties')} />*/}
          {/*  /!*<img src={twitterIcon} alt='twitter' onClick={() => redirectSite('https://twitter.com/SolKitties')} />*/}
          {/*  <img src={instaIcon} alt='discord' onClick={() => redirectSite('https://www.instagram.com/solkittiesnft')} />*!/*/}
          {/*</SocialIconWrapper>*/}
          <p>All rights reserved © Sol Kitties 2023</p>
        </FooterContainer>
        {isArrow && (
            <ArrowIconWrapper onClick={handleGoTop}>
              <ArrowIcon />
            </ArrowIconWrapper>
        )}
      </>
  )
}

const ArrowIcon = () => {
  return (
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-circle-up" className="svg-inline--fa fa-chevron-circle-up fa-w-16 fa-2x footer-module--navigation--2nELV" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M8 256C8 119 119 8 256 8s248 111 248 248-111 248-248 248S8 393 8 256zm231-113.9L103.5 277.6c-9.4 9.4-9.4 24.6 0 33.9l17 17c9.4 9.4 24.6 9.4 33.9 0L256 226.9l101.6 101.6c9.4 9.4 24.6 9.4 33.9 0l17-17c9.4-9.4 9.4-24.6 0-33.9L273 142.1c-9.4-9.4-24.6-9.4-34 0z"></path></svg>
  )
}