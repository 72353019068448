import React, {useEffect, useMemo, useState} from 'react'
import { MyNavBar } from "../../sharedComponent/MyNavBar";
import { Footer } from '../../sharedComponent/Footer';
import "./style.css"
import {clusterApiUrl} from "@solana/web3.js";
import {
    SOLANA_NETWORK_DEVNET,
    SOLANA_NETWORK_MAINNET,
    SOLANA_RPC_HOST_DEVNET,
    SOLANA_RPC_HOST_MAINNET
} from "../../config/constants";
import {WalletAdapterNetwork, WalletError} from "@solana/wallet-adapter-base";
import {PhantomWalletAdapter, SolflareWalletAdapter, SolletWalletAdapter} from "@solana/wallet-adapter-wallets";
import {ConnectionProvider, WalletProvider} from "@solana/wallet-adapter-react";
import {WalletDisconnectButton, WalletModalProvider, WalletMultiButton} from "@solana/wallet-adapter-react-ui";
import {ControllerPublicMint} from "../../components/ControllerPublicMint";
import * as anchor from "@project-serum/anchor";
import {ThemeProvider} from "@material-ui/core";
import {WalletDialogProvider} from "@solana/wallet-adapter-material-ui";
import {ControllerPodstPublicMint} from "../../components/ControllerPodsPublicMint";
const network = SOLANA_NETWORK_MAINNET as WalletAdapterNetwork
const connectionWhiteList = new anchor.web3.Connection(SOLANA_RPC_HOST_MAINNET!)
const txTimeout = 60000; // milliseconds (confirm this works for your project)

// const network = SOLANA_NETWORK_DEVNET as WalletAdapterNetwork
// const connectionWhiteList = new anchor.web3.Connection(SOLANA_RPC_HOST_DEVNET!)

const PodsPublicMint = (props: any): JSX.Element => {
    const endpoint = useMemo( () => clusterApiUrl(network), [network]);
    const wallet = useMemo( () => [new PhantomWalletAdapter(), new SolletWalletAdapter({network})], [network]);
    const walletConnectionErr = (error: WalletError) => {
        //console.log("Wallet Connection Error:", error);
    }

    return (
        <div style={{background: 'white'}}>
            <ConnectionProvider endpoint={endpoint}>
                <WalletProvider wallets={wallet} autoConnect={true}>
                    <WalletDialogProvider>
                        <div className="mint-page" id='logo'>
                            <MyNavBar />
                            <ControllerPublicMint {...props} connection={connectionWhiteList} txTimeout={txTimeout}/>
                            <Footer />
                        </div>
                    </WalletDialogProvider>
                </WalletProvider>
            </ConnectionProvider>
        </div>
    )
}

export default PodsPublicMint;
