import {ActionReducerMapBuilder, createSlice, PayloadAction} from "@reduxjs/toolkit";
import LoginIcon from "../assets/images/user-info-panel/128_cat_avatar.png";
import {getUserDataNew} from "../service/kittyServiceProvider";
import {toast} from "react-toastify";
import {USER_NOT_FOUND, WALLET_CONNECTED, WALLET_CONNECTING} from "../config/constants";

export interface initialStateTypes {
    userInfoData: {
        initialUserFoundFlag: boolean,
        userFoundFlag: any,
        userCoinsFlag: boolean,
        monthlySolFlag: boolean,
        ticketsFlag: boolean,
        userId: string
        userName: string,
        tokens2D: number,
        tokens3D: number,
        userCoinsMulti: string,
        userCoins: number,
        userAvatar: string,
        superPodUpgrades: string,
        stakedAmount: number,
        stakedWorth: number,
        totalStakeHolders: number,
        solmonthlp: number,
        skttosol: number,
        stakedKittyWorth: number,
        claimablesol: number,
        claimablesolcasino: number
    },
    userStakedTokensData: any[],
    userApprovedWallets: string[],
    userTicketsData: {
        ticketsBought: number,
        ticketsData: any[],
    }
    kittyNftHolding: {
        is2dHolder: boolean,
        is3dHolder: boolean,
    }
}

const initialState: initialStateTypes = {
    userInfoData: {
        initialUserFoundFlag: true,
        userFoundFlag: false,
        userCoinsFlag: false,
        monthlySolFlag: false,
        ticketsFlag: false,
        userId: "",
        userName: "",
        tokens2D: 0,
        tokens3D: 0,
        userCoinsMulti: "",
        userCoins: 0,
        userAvatar: LoginIcon,
        superPodUpgrades: "0",
        stakedAmount: 0,
        stakedWorth: 0,
        totalStakeHolders: 0,
        solmonthlp: 0,
        skttosol: 0,
        stakedKittyWorth: 0,
        claimablesol: 0,
        claimablesolcasino: 0
    },
    userStakedTokensData: [],
    userApprovedWallets: [],
    userTicketsData: {
        ticketsBought: 0,
        ticketsData: [],
    },
    kittyNftHolding: {
        is2dHolder: false,
        is3dHolder: false,
    }
}

export const userReduxSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
        resetUserReduxState: () => initialState,
        userInfoFlagAction: (state: initialStateTypes, action: PayloadAction<initialStateTypes['userInfoData']>) => {
            state.userInfoData = action.payload;
        },
        setUserClaimableSOLAction: (state: initialStateTypes, action: PayloadAction<number>) => {
            state.userInfoData.claimablesol = action.payload;
        },
        setUserClaimableCasinoSOLAction: (state: initialStateTypes, action: PayloadAction<number>) => {
            state.userInfoData.claimablesolcasino = action.payload;
        },
        setApprovedWalletData: (state: initialStateTypes, action: PayloadAction<string[]>) => {
            state.userApprovedWallets = action.payload;
        },
        userStakedTokensAction: (state: initialStateTypes, action: PayloadAction<any>) => {
            state.userStakedTokensData = action.payload;
        },
        userTicketsAction: (state: initialStateTypes, action: PayloadAction<any>) => {
            state.userTicketsData = action.payload;
        },
        updateUserKittyCoinsAction: (state: initialStateTypes, action: PayloadAction<number>) => {
            state.userInfoData.userCoins = action.payload;
        },
        setKittyNftHoldingAction: (state: initialStateTypes, action: PayloadAction<initialStateTypes["kittyNftHolding"]>) => {
            state.kittyNftHolding = action.payload;
        },
    },
    extraReducers: (builder: ActionReducerMapBuilder<any>) => {
        /*builder.addCase(getUserDataNew.pending, (state: initialStateTypes, action) => {
            toast.info(WALLET_CONNECTING);
        })*/
        builder.addCase(getUserDataNew.fulfilled, (state: initialStateTypes, action) => {
            const userDataApi = action.payload;
            console.log("User Data:", userDataApi);

            if (userDataApi)
            {
                //toast.dismiss();
                state.userInfoData = {
                    ...state.userInfoData,
                    initialUserFoundFlag: false,
                    userFoundFlag: true,
                    userCoinsFlag: true,
                    userId: userDataApi.userId,
                    userName: userDataApi.userName,
                    tokens2D: userDataApi.tokens2D,
                    tokens3D: userDataApi.tokens3D,
                    userCoinsMulti: userDataApi.userCoinsMulti,
                    userCoins: userDataApi.userCoins,
                    userAvatar: (userDataApi.userAvatar) ? "https://cdn.discordapp.com/avatars/" + userDataApi.userId + "/" + userDataApi.userAvatar + ".png" : LoginIcon,
                    superPodUpgrades: (userDataApi.superPodUpgrades) ?? "0",
                    stakedAmount: userDataApi.stakedAmount,
                    stakedWorth: userDataApi.stakedWorth,
                    totalStakeHolders: userDataApi.totalStakeHolders,
                    solmonthlp: userDataApi.solmonthlp,
                    skttosol: userDataApi.skttosol,
                    stakedKittyWorth: userDataApi.stakedKittyWorth,
                    claimablesol: userDataApi.claimablesol,
                    claimablesolcasino: userDataApi.claimablesolcasino
                }
                /*if (state.userInfoData.userFoundFlag) {
                    toast.success(`${WALLET_CONNECTED} Welcome ${userDataApi.userName}.`);
                }*/
            }
            else
            {
                // Not needed anymore
                // /* show user not found toast for verifywalelt page */
                // if (window.location.pathname.includes("verifywallet"))
                // {
                //     toast.warning(USER_NOT_FOUND);
                // }
                state.userInfoData = {...state.userInfoData, initialUserFoundFlag: false, userFoundFlag: null}
            }
        })
        builder.addCase(getUserDataNew.rejected, (state: initialStateTypes, action) => {
            toast.dismiss();
            toast.error("Problem while fetching user data...");
        })
    }
});

/*Action creators are generated for each case reducer function*/
export const {
    resetUserReduxState,
    userInfoFlagAction,
    userStakedTokensAction,
    setApprovedWalletData,
    userTicketsAction,
    updateUserKittyCoinsAction,
    setKittyNftHoldingAction,
    setUserClaimableSOLAction,
    setUserClaimableCasinoSOLAction
} = userReduxSlice.actions

export default userReduxSlice.reducer
