import CryptoJS from "crypto-js";

export const AESEncrypt = (pureText: any) =>
{
    try
    {
        const privateKey = 'Joke@1611';
        return encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(pureText), privateKey).toString());
    }
    catch (e)
    {
        console.error("AESEncrypt error", e);
        return "";
    }
}

export const AESDecrypt = (encryptedText: any) =>
{
    try
    {
        const privateKey = 'Joke@1611';
        const bytes = CryptoJS.AES.decrypt(decodeURIComponent(encryptedText), privateKey);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    catch (e)
    {
        console.error("AESDecrypt error", e);
        return "";
    }
}

// https://stackoverflow.com/questions/24337317/encrypt-with-php-decrypt-with-javascript-cryptojs
export const MyDecrypt = (jsonEncrypted: string, key: string) =>
{
    // test:
    //jsonEncrypted = '{"ct":"TpFKguLb7lnUEqOIQJiTNZLjd\\/m5\\/Avu4nYbzbZGmCA=","iv":"ecb54ead4da806f7111c2c8b3dbe76b9","s":"99ce3576def39520"}';
    //key = "kobkey";

    return CryptoJS.AES.decrypt(jsonEncrypted, key, {format: CryptoJSAesJson}).toString(CryptoJS.enc.Utf8);
}

const CryptoJSAesJson =
{
    stringify: function (cipherParams: any)
    {
        const j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64), iv: null, s: null};
        if (cipherParams.iv) j.iv = cipherParams.iv.toString();
        if (cipherParams.salt) j.s = cipherParams.salt.toString();
        return JSON.stringify(j);
    },
    parse: function (jsonStr: any)
    {
        var j = JSON.parse(jsonStr);
        var cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(j.ct)});
        if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
        if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
        return cipherParams;
    }
}
