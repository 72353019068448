import React, { useState, useEffect, useRef } from 'react';
import './styles.css';
import {Button, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {getLocalStorageData, setLocalStorageData} from "../../service/localStorageHelper";
import {CONNECTION_NETWORK, REACT_APP_RAFFLE_VAULT_WALLET_ADDRESS} from "../../config/constants"
import {raffleFinalizeDataType} from "../../types/interface/RaffleInterface";
import {WalletAdapterNetwork} from '@solana/wallet-adapter-base';
import {callRafflesAPI} from "../../service/rafflesServiceProvider";
import {PublicKey} from "@solana/web3.js";
import { getAndPrintRaffleAccount, getProviderAndProgram, getRaffleFinalizeTransaction } from "../../service/programsHelper";
import {useAnchorWallet, useConnection, useWallet} from "@solana/wallet-adapter-react";
import * as anchor from "@project-serum/anchor";
import {useLocation} from "react-router-dom";
import axios from "axios";
import { getNftFromWallet } from "../../service/walletServiceProvider";
import { toast } from "react-toastify";
import { serializeTxToBase64 } from "../../config/utils";
import { MagicEdenIDL } from "../../idl/me";
import { BorshCoder } from '@project-serum/anchor';

export const WalletToggle = (): JSX.Element =>
{
    const connection = useConnection();
    const wallet = useWallet();
    const anchorWallet = useAnchorWallet() as anchor.Wallet;
    const location = useLocation();
    const [finalizedButtonDisabled, setFinalizedButtonDisabled] = useState(true);
    let initialEnv: any = CONNECTION_NETWORK;

    /* get initial local storage value and assign to toggle button */
    const getLocalStorageValue = getLocalStorageData("walletEnv");

    if (getLocalStorageValue)
    {
        setLocalStorageData("walletEnv", initialEnv);
    }
    else
    {
        initialEnv = getLocalStorageValue;
    }

    const [environment, setEnvironment] = useState<WalletAdapterNetwork>(initialEnv);

    const handleChange = (event: React.MouseEvent<HTMLElement>, newEnvironment: WalletAdapterNetwork) =>
    {
        /* update environment on localStorage */
        setLocalStorageData("walletEnv", newEnvironment);
        window.location.reload();
    };

    const onPageLoad = async () =>
    {
        /* Will validate if raffle token is still in raffles bank*/
        //console.log("onPageLoad");
        const raffleAddress = location.pathname.split("/")[2];
        let isNftFoundInWallet = false;
        if (raffleAddress && raffleAddress.length > 14)
        {
            try
            {
                const raffleAddressPK = new PublicKey(raffleAddress);
                const raffleAccount = await getAndPrintRaffleAccount(raffleAddressPK, connection.connection, anchorWallet);
                const rafflesBankNFTs: any[] | undefined = await getNftFromWallet(connection.connection, REACT_APP_RAFFLE_VAULT_WALLET_ADDRESS);
                isNftFoundInWallet = rafflesBankNFTs?.find(item => item.mint === raffleAccount!.nftMintAddress);
            }
            catch (e)
            {
                console.log(e);
            }
        }

        setFinalizedButtonDisabled(!isNftFoundInWallet);
    }

    useEffect(() =>
    {
        onPageLoad();
    }, [location.pathname]);

    const onDecode = async () =>
    {
        /* Decode from IDL */
        const coder = new BorshCoder(MagicEdenIDL);
        const data = "72zYCnjHU38jangWGj6bqRrALXYFmXMqoNn3JVtDzrqS2kcEaTMQbiC8Zfv5kiyxVTUB2XGD1c1D9ZTTweiFY5qijdWBJChK9EMueydMWaSwvfVWf2f1DPvguX1CjQ7Kd7LzcMv51DeS5JUgbxCYKUTFBBjAwgFqbQvzvfS6JqcP5p8XzBoJdvxF8Eh3ZgeyMRoV8XvaEhKiuAUEzXidFyPHotJXFgMiZ3iMeGz52r9q6M2eqXUJftY5iR9SQdAoMFeJvN8dCioF9KHYep8Bykm9cKdp2cmHfra3g18WS2j4fXv6ByaEqS6jgzjHHsah89zZat9cPReUrbgkTLBLuJyDBTFF1xRCfZRuU7CMmkfV3j8wiXfBNyzrvsV7ZM4ZZ99NyLPUJWMxCjCowsm1CdTwFzRDGnPbyATNGsJxm1DgznXGp2xaGcQP6SNvRVy2qSroJegekacRrVJWyo93BSfq2AHzfhsHV52qYK25CcViD7E3y4K";
        const ix = coder.instruction.decode(data, 'base58');

        console.dir(ix);
    }

    const onFinalizeClick = async (isForce = false) =>
    {
        const { program } = getProviderAndProgram(connection.connection, anchorWallet);

        const raffleAddress = new PublicKey(location.pathname.split("/")[2]);
        //console.log(raffleAddress.toString());

        const raffleAccount = await getAndPrintRaffleAccount(raffleAddress, connection.connection, anchorWallet);
        const raffleDB = await callRafflesAPI("getRaffleByRaffleAddress", raffleAddress);
        if (raffleDB.success)
        {
            const raffleWinnerWalletAddress = raffleDB.data.raffleWinnerWalletAddress.length > 0 ? raffleDB.data.raffleWinnerWalletAddress[0].userWalletAddress : "";

            console.log("Finalize Raffle...", raffleDB.data.nftName);
            console.log("Raffle in DB:", raffleDB.data);

            if (raffleDB.data.isFinalized && !isForce)
            {
                console.error(raffleDB.data.nftName, "raffle already finalized.");
                toast.error(raffleDB.data.nftName + " raffle already finalized.");
                return;
            }

            if (!raffleWinnerWalletAddress)
            {
                console.error("Winner is yet to be declared, try again later.");
                toast.error("Winner is yet to be declared, try again later.");
                return;
            }

            const owner = new PublicKey(raffleAccount!.owner);
            const winner = new PublicKey(raffleWinnerWalletAddress);
            const nftMint = new PublicKey(raffleAccount!.nftMintAddress);
            const raffleFinalizeDataType: raffleFinalizeDataType =
                {
                    provider: program.provider,
                    raffleAddress: raffleAddress,
                    raffleBank: new PublicKey(REACT_APP_RAFFLE_VAULT_WALLET_ADDRESS),
                    owner: owner, // raffle owner
                    winner: winner, // nft winner
                    splAddress: new PublicKey(raffleAccount!.tokenSplAddress),
                    nftMint: nftMint,
                    raffleRoyalties: 5,
                };

            const transaction = await getRaffleFinalizeTransaction(raffleFinalizeDataType);
            transaction.recentBlockhash = (await connection.connection.getLatestBlockhash("finalized")).blockhash;
            transaction.feePayer = anchorWallet.publicKey;
            const transactionSigned = await anchorWallet.signTransaction(transaction);
            const serializedTxBase64 = transactionSigned.serialize({ requireAllSignatures: false }).toString("base64");
            const res = await callRafflesAPI("finalizeRaffle", { serializedTxBase64, raffleAddress, owner, winner, nftMint });

            /* Decode from IDL */
            //const coder = new BorshCoder(IDL);
            //const ix = coder.instruction.decode(recoveredTx.instructions[0].data, 'base58',);

            try
            {
                if (res.status == "success")
                {
                    const txSignature = res.txId;

                    // not a must-have:
                    // await program.provider.connection.confirmTransaction(txSignature, "confirmed");
                    console.log("DONE:", txSignature);
                    console.log(`https://solscan.io/tx/${txSignature}?cluster=${CONNECTION_NETWORK == WalletAdapterNetwork.Mainnet ? "mainnet" :"devnet"}`);

                    // Announce it
                    const raffleNftWinnerTx = `https://solscan.io/tx/${txSignature}`;
                    const url = `https://api.servica.io/test?announce=${raffleAddress}&nftwinner=${raffleNftWinnerTx}&raffler=`;
                    await axios.get(url);

                    toast.success("DONE!");
                }
                else
                {
                    throw new Error(`failed response: ${JSON.stringify(res)}`);
                }
            }
            catch (e)
            {
                toast.error("FAILED!");
                console.error("Raffle Finalize failed, error:");
                console.error(e);
            }
        }
    }

    return (
        <div className="wallet-toggle">
            <ToggleButtonGroup
                orientation="vertical"
                color="primary"
                value={environment}
                exclusive
                onChange={handleChange}
                aria-label="wallet-env"
            >
                <ToggleButton value={WalletAdapterNetwork.Devnet}>Devnet</ToggleButton>
                <ToggleButton value={WalletAdapterNetwork.Mainnet}>Mainnet</ToggleButton>
            </ToggleButtonGroup>

            <Button disabled={finalizedButtonDisabled} key="finalize" onClick={() => onFinalizeClick(false)}>Finalize</Button>
            <Button disabled={finalizedButtonDisabled} key="finalizeF" onClick={() => onFinalizeClick(true)}>Finalize F</Button>

            {/*<Button key="decode" onClick={onDecode}>Decode</Button>*/}
        </div>
    )
}
