import React, {useState, useEffect} from 'react';
import './stylesnew.css';
import {Grid} from '@material-ui/core'
import {WalletDisconnectButton, WalletMultiButton} from '@solana/wallet-adapter-react-ui';
import {STAKING_VAULT_ADDRESS, WALLET_DISCONNECTED} from "../../config/constants";

import loginIcon from "../../assets/images/user-info-panel/128_cat_avatar.png";
import logout from "../../assets/images/user-info-panel/logout.png";
import redInfo from "../../assets/images/user-info-panel/red-info.png";
import coinCat from "../../assets/images/user-info-panel/coincat.png";
import convertKittyCoin from "../../assets/images/user-info-panel/convert-kitty-coin.png";
import pig from "../../assets/images/user-info-panel/pig.png";
import SKTIcon from "../../assets/images/user-info-panel/skt-icon.png";
import SOLCoinLogo from "../../assets/images/user-info-panel/sol-coin-logo.png";
import ConvertSol from "../../assets/images/user-info-panel/convert-sol.png";
import SolRoundIcon from "../../assets/images/user-info-panel/sol-round-icon.png";
import ticketIcon from "../../assets/images/user-info-panel/tickets-icon.png";
import RoyaltiesIcon from "../../assets/images/user-info-panel/royalties3.png";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { useAnchorWallet, useConnection, useWallet } from "@solana/wallet-adapter-react";
import * as anchor from "@project-serum/anchor";
import { toast} from "react-toastify";
import { callRafflesAPI} from "../../service/rafflesServiceProvider";
import { convertKCtoSKTWithBackend, freezeTokens, withdrawSOLCasinoRoyal, withdrawSOLfromSKT } from "../../service/programsHelper";
import { resetUserReduxState, setUserClaimableCasinoSOLAction, setUserClaimableSOLAction, updateUserKittyCoinsAction } from "../../redux/userReduxSlice";
import { confirmTransactionSafe, getConvertKCtoSKTTransaction, getStakingVaultBalance, getWalletBalance, sleep } from "../../config/utils";
import { resetWalletReduxState, setStakingVaultBalanceAction, setUserWalletBalanceAction } from "../../redux/walletReduxSlice";
import { ConfirmOptions, PublicKey, Signer, Transaction } from '@solana/web3.js';
import { bundlrStorage, Metaplex, TransactionBuilder, walletAdapterIdentity } from "@metaplex-foundation/js-v2";
import { getUserDataNew } from "../../service/kittyServiceProvider";
import { getNftFromWallet } from "../../service/walletServiceProvider";
import { AccountState, getAccount } from '@solana/spl-token-v2';
import { getAta } from "../../components/Coinflip/contexts/utils";
import {resetUtilReduxState} from "../../redux/utilReduxSlice";
import {resetRaffleReduxState} from "../../redux/raffleReduxSlice";
const bundlrNetwork = { address: 'https://node1.bundlr.network',  providerUrl: 'https://api.mainnet-beta.solana.com', timeout: 60000};

export const UserInfoPanelNew = (props: any) =>
{
    const connection = useConnection();
    const wallet = useWallet();
    const anchorWallet = useAnchorWallet() as anchor.Wallet;
    const dispatch: AppDispatch = useDispatch();

    const userReduxStore = useSelector((state: RootState) => state.userReduxSlice);
    const walletReduxStore = useSelector((state: RootState) => state.walletReduxSlice);
    const utilReduxStore = useSelector((state: RootState) => state.utilReduxSlice);
    const [isCopy, setIsCopy] = useState(false);
    const [kittyCoinValue, setKittyCoinValue] = useState<any>(0);
    const [convertCoinFlag, setConvertCoinFlag] = useState(false);

    const disconnectWallet = () => {
        setTimeout(() => {
            dispatch(resetUserReduxState());
            dispatch(resetWalletReduxState());
        }, 1500)
        toast.success(WALLET_DISCONNECTED);
    }

    const copyToClipboard = () => {
        setIsCopy(true);
        setTimeout(() => setIsCopy(false),1500);
        navigator.clipboard.writeText(props.anchorWallet?.publicKey?.toString());
    }

    const handleConvertCoin = async () =>
    {
        //const connectionSSC = new Connection("https://ssc-dao.genesysgo.net/", 'processed');
        //console.log(connectionSSC);

        // get tx pre/post
        /*
        {
            const sigs = await connection.connection.getSignaturesForAddress(new PublicKey("DsFbo818csEPctG7spikECo4WhHGaci6afAtpP8iuQSQ"));
            console.log(sigs)
            for (const sig of sigs.slice(0,10))
            {
                //console.log(sig.signature);

                //const y = await connection.connection.getParsedTransaction("5ue16APvJefyojJ41LWs35zzJwJm443ZUR7YX5kputejmjXncxq8oneHB62jTAJNxKLispHwEAa3PaDv2rf95x9");
                const tx = await connection.connection.getParsedTransaction(sig.signature);

                const preMint = tx!.meta!.preTokenBalances![0].mint;
                const preMintFrom = tx!.meta!.preTokenBalances![1].owner; // from at index 1
                const preMintTo = tx!.meta!.preTokenBalances![0].owner;
                const preAmount = tx!.meta!.preTokenBalances![0].uiTokenAmount.uiAmount;

                const postMint = tx!.meta!.postTokenBalances![0].mint;
                const postMintFrom = tx!.meta!.postTokenBalances![1].owner; // from at index 1
                const postMintTo = tx!.meta!.postTokenBalances![0].owner;
                const postAmount = tx!.meta!.postTokenBalances![0].uiTokenAmount.uiAmount;

                if (preMintFrom == "72UUQQkuuccRNQ9Eb6tZc7YCkCtx4wyfraXPvSWfR4aL") // vault PDA
                {
                    console.log("Sig:", sig.signature);

                    const delta = postAmount! - preAmount!;
                    console.log(`${postMintFrom} => ${postMintTo} | ${postMint}`);
                    console.log(preAmount, "==>", postAmount, " = ", delta.toFixed(2));
                    //console.log(tx);
                }
            }
        }
        //console.log(x);
        return;
        */

        if (convertCoinFlag)
        {
            toast.warning("Conversion already in progress...");
            return;
        }

        console.log(userReduxStore);
        if (userReduxStore.userInfoData.userCoins <= 0)
        {
            toast.warning("No Kitty Coins to convert.");
            return;
        }

        try
        {
            setConvertCoinFlag(true);
            console.log(`Converting ${userReduxStore.userInfoData.userCoins} KC to $SKT`);

            const convertKCtoSKTTransaction = await getConvertKCtoSKTTransaction(userReduxStore.userInfoData.userCoins, anchorWallet);
            const txSignature = await wallet.sendTransaction(convertKCtoSKTTransaction, connection.connection, { skipPreflight: false });

            toast.info("Please stand by for KC to $SKT conversion...", {autoClose: 20000});

            await confirmTransactionSafe(connection.connection, txSignature);

            toast.dismiss();
            toast.success(`${userReduxStore.userInfoData.userCoins} $SKT are being transferred to your wallet.`, {autoClose: 20000});

            console.log("Tx Confirmed:", txSignature);
            const res = await callRafflesAPI("convertUserKCtoSKTOnChainV2", txSignature, true);
            console.log(res);

            if (res && res?.success === true)
            {
                //toast.info(`Converting ${userCoinsToConvert} KC to $SKT in progress...`, {autoClose: 50000});

                await updateUserBalances();

                toast.dismiss();
                toast.success("KC to $SKT conversion done successfully.", {autoClose: 4000});
            }
            else
            {
                toast.dismiss();
                toast.error(`Error converting KC to $SKT, please try again later.`, {autoClose: 8000});
                console.error("Error converting KC to $SKT, please try again later.");
                console.error(res?.error);
            }
        }
        catch (e)
        {
            setConvertCoinFlag(false);
        }

        setConvertCoinFlag(false);
    }

    const updateUserBalances = async () =>
    {
        if (anchorWallet)
        {
            dispatch(getUserDataNew(anchorWallet.publicKey.toString()));

            /* get the latest kitty coins and store on redux store */
            const getUserKittyCoins = await callRafflesAPI("getUserKittyCoins", userReduxStore.userInfoData.userId);
            if (getUserKittyCoins.success)
            {
                dispatch(updateUserKittyCoinsAction(getUserKittyCoins.data));
            }

            /* get user wallet balance and store on redux store */
            const getUserWalletBalance = await getWalletBalance(connection.connection, anchorWallet?.publicKey);
            dispatch(setUserWalletBalanceAction(getUserWalletBalance));

            // Update staking vault balance
            const stakingVaultBalance = await getStakingVaultBalance(connection.connection);
            dispatch(setStakingVaultBalanceAction(stakingVaultBalance));
            console.log("Staking Vault Balance:", stakingVaultBalance, "SOL");
        }
    }
    
    const handleWithdrawSOL = async () =>
    {
        if (userReduxStore.userInfoData?.claimablesol <= 0) {
            toast.error(`Nothing to withdraw.`);
            return;
        }

        const SKTBalance = walletReduxStore?.userWalletBalance?.SKT;
        const SKTtoSOLRate = userReduxStore?.userInfoData?.skttosol; // 1 $SKT = 0.00263 SOL

        const SOLWorth = userReduxStore.userInfoData?.claimablesol;
        const SKTToPay = Number((SOLWorth / SKTtoSOLRate).toFixed(2));

        if (SKTToPay > SKTBalance) {
            toast.error(`Not enough $SKT to withdraw, needs ${SKTToPay} $SKT`);
            return;
        }

        console.log("User claiming SOL:", userReduxStore.userInfoData?.userId, userReduxStore.userInfoData?.userName);
        console.log("SKT Balance:", SKTBalance);
        console.log("SOL to Claim:", SOLWorth);
        console.log("SKT to SOL Rate:", SKTtoSOLRate);
        console.log("SKT To Pay:", SKTToPay);

        dispatch(setUserClaimableSOLAction(0));

        await withdrawSOLfromSKT(connection.connection, userReduxStore.userInfoData?.userId, wallet, STAKING_VAULT_ADDRESS, SKTToPay, SOLWorth);

        await sleep(1000);
        await updateUserBalances();
    }

    const handleWithdrawRoyaltiesMultiTXs = async () =>
    {
        // const txs: Transaction[] = [];
        // const metaplex = getMetaplex();
        //
        // for (let i = 1; i <= mintAmount; i++)
        // {
        //     const nftBuilder = await metaplex
        //         .nfts()
        //         .builders()
        //         .create(json);
        //
        //     const tx = nftBuilder.toTransaction();
        //
        //     const tokenSinger = nftBuilder.getSigners()[2] as Signer;
        //
        //     tx.feePayer = anchorWallet.publicKey;
        //     tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
        //     tx.sign(tokenSinger);
        //
        //     txs.push(tx);
        // }
        //
        // const signedTXs = await anchorWallet.signAllTransactions(txs);
        // console.log("signedTXs:", signedTXs);
        //
        // console.log(`Minted ${mintAmount}:`);
        // await Promise.all(signedTXs.map(async (signedTx: Transaction, index: number, array: Transaction[]) =>
        // {
        //     const txId = await connection.sendRawTransaction(signedTx.serialize());
        //     await connection.confirmTransaction(txId, 'processed');
        //     console.log(`Processed txId: https://solscan.io/tx/${txId}?cluster=devnet`);
        // }));
    }

    const handleWithdrawRoyalties = async () =>
    {
        if (userReduxStore.userInfoData?.claimablesolcasino <= 0) {
            toast.error(`No available balance to withdraw.`);
            return;
        }

        dispatch(setUserClaimableCasinoSOLAction(0));

        console.log("Withdraw Royalties in progress...");

        const delegateAuthWallet = "FSTZfpSEFbeu4WfyhmvtTSSF79LcWut86UoJ3oqfG2e8";
        const walletNFTs: any = await getNftFromWallet(connection.connection, anchorWallet.publicKey.toString());
        const walletNFTsFilter = walletNFTs.filter((nft: any) => nft.data.name.includes("SK VIP CARD"));

        // if (walletNFTsFilter.length === 0) {
        //     toast.error(`You don't hold any VIP Cards in your wallet.`);
        //     return;
        // }

        toast.info("Processing your Casino Royalties withdrawal...", {autoClose: 18000});
        console.log("Processing your Casino Royalties withdrawal...", walletNFTsFilter.length);
        console.log(walletNFTs);
        console.log(walletNFTsFilter);

        const metaplex = Metaplex.make(connection.connection).use(walletAdapterIdentity(anchorWallet)).use(bundlrStorage(bundlrNetwork));
        const delegateAuthority = new PublicKey(delegateAuthWallet);

        let txBuilder = null;
        let freezeTokensMint = [];
        for (const nft of walletNFTsFilter)
        {
            const mintAddress = new PublicKey(nft.mint);
            const tokenAddress = (await connection.connection.getTokenLargestAccounts(mintAddress)).value[0].address;
            const splToken = await metaplex.tokens().findTokenWithMintByAddress({ address: tokenAddress });
            const owner = splToken.ownerAddress;
            const isFrozen = splToken.state === AccountState.Frozen;

            console.log(" + Freezing  => ", nft.data.name, "isFrozen:", isFrozen);

            if (!isFrozen)
            {
                freezeTokensMint.push({ mint: nft.mint, name: nft.data.name, isFrozen});

                const approveDelegateTx = await metaplex.tokens().builders().approveDelegateAuthority({ mintAddress, delegateAuthority });
                txBuilder = txBuilder ? txBuilder.add(approveDelegateTx) : approveDelegateTx;
            }
        }

        try
        {
            if (freezeTokensMint.length > 0)
            {
                console.log(txBuilder);
                const txId = await txBuilder?.sendAndConfirm(metaplex);
                console.log(txId);
                console.log(`https://solscan.io/tx/${txId?.response.signature}`);

                await freezeTokens(freezeTokensMint, userReduxStore.userInfoData?.userId, userReduxStore.userInfoData?.userName, anchorWallet.publicKey);

                toast.success("Done Approving your VIP Cards!");
            }

            const SOLWorthCasino = userReduxStore.userInfoData?.claimablesolcasino;

            console.log("User claiming SOL:", userReduxStore.userInfoData?.userId, userReduxStore.userInfoData?.userName);
            console.log("Casino SOL to Claim:", SOLWorthCasino);

            const status = await withdrawSOLCasinoRoyal(connection.connection, userReduxStore.userInfoData?.userId, wallet, STAKING_VAULT_ADDRESS, SOLWorthCasino);

            if (status)
            {
                toast.dismiss();
                toast.success(`Withdrawal of ${SOLWorthCasino} SOL done successfully.`);

                await sleep(1000);
                await updateUserBalances();
            }
        }
        catch (e)
        {
            toast.dismiss();
            toast.error("Request failed, " + e?.toString());
            console.log("Request failed,", e?.toString());
        }
        // else
        // {
        //     toast.dismiss();
        //     toast.error("Error occurred, failed to withdraw.");
        // }
    }

    useEffect(() => {
        if(Number(userReduxStore.userInfoData?.userCoins) - parseInt(String(userReduxStore.userInfoData?.userCoins)) == 0) {
            setKittyCoinValue(userReduxStore.userInfoData.userCoins);
        } else {
            setKittyCoinValue(parseFloat(String(userReduxStore.userInfoData?.userCoins)).toFixed(2));
        }
    }, [userReduxStore.userInfoData]);

    useEffect(() => {
        if(!wallet.connected) {
            dispatch(resetUserReduxState());
            dispatch(resetWalletReduxState());
        }
    }, [wallet]);

    return (
        <Grid className="wallet-wrapper" item lg={4} md={4} sm={12} xs={12}>
            {
                !walletReduxStore.isWalletConneted &&
                <Grid container className="not-connected">
                    <WalletMultiButton>
                        <div className="connect-info">
                            <img src={loginIcon} alt="login"/>
                            <span>Connect<br/> Wallet</span>
                        </div>
                    </WalletMultiButton>
                </Grid>
            }

            {
                (walletReduxStore.isWalletConneted) &&
                <Grid container className="connected">
                    <WalletMultiButton>
                        <div className="connect-info">
                            <img src={userReduxStore.userInfoData.userAvatar} alt="avtar"/>
                            <span className="truncate">
                                <p>{userReduxStore.userInfoData.userName}</p>
                                <p className="subtitle">{anchorWallet?.publicKey?.toString()}</p>
                            </span>
                            {/*<div className="log-out-img" data-tip="Disconnect">
                                <WalletDisconnectButton className="log-out-img">
                                    <img src={logout} alt="Disconnect"
                                         onClick={() => disconnectWallet()}/>
                                </WalletDisconnectButton>
                            </div>*/}
                        </div>
                    </WalletMultiButton>

                    <div className="wallet-balance">
                        {
                            props.config.kittyCoinsFlag &&
                            <div className="balance-label">
                                <label data-tip="Kitty Coins Balance" className="kitty-coin">
                                    <img src={coinCat} alt="coin"/>
                                    <span style={{fontSize: (kittyCoinValue.toString().length >= 5) ? "18px" : "unset"}}>{Number(userReduxStore.userInfoData.userCoins) - parseInt(String(userReduxStore.userInfoData.userCoins)) == 0 ? userReduxStore.userInfoData.userCoins : parseFloat(String(userReduxStore.userInfoData.userCoins)).toFixed(2)}</span>
                                </label>
                                {
                                    props.config.kittyCoinConvertFlag &&
                                    <div className="convert-kitty-coin" data-tip="Convert KC to $SKT" onClick={handleConvertCoin}>
                                        <img src={convertKittyCoin} alt="convertKittyCoin"/>
                                    </div>
                                }
                            </div>
                        }
                        {
                            props.config.monthlySolFlag &&
                            <div className="balance-label">
                                <label data-tip="Monthly SOL Liquidity Balance" className="monthly-sol">
                                    <img src={pig} alt="pig"/>
                                    <span>{walletReduxStore?.stakingVaultBalance}</span>
                                </label>
                            </div>
                        }
                        {
                            props.config.ticketBoughtFlag &&
                            <div className="balance-label">
                                <label data-tip="Amount of Tickets Bought" className="monthly-sol">
                                    <img src={ticketIcon} alt="ticket"/>
                                    <span>{userReduxStore.userTicketsData?.ticketsBought ? userReduxStore.userTicketsData?.ticketsBought : 0}</span>
                                </label>
                            </div>
                        }
                        {
                            props.config.sktBalanceFlag &&
                            <div className="balance-label">
                                <label data-tip="SKT Balance" className="sol-round">
                                    <img src={SKTIcon} alt="skt"/>
                                    <span>{walletReduxStore.userWalletBalance?.SKT?.toFixed(2) ?? 0}</span>
                                </label>
                            </div>
                        }
                        {
                            props.config.solBalanceFlag &&
                            <div className="balance-label">
                                <label data-tip="SOL Balance" className="sol-balance">
                                    <img src={SOLCoinLogo} alt="sol"/>
                                    <span>{walletReduxStore.userWalletBalance?.SOL?.toFixed(2) ?? 0}</span>
                                </label>
                            </div>
                        }
                        {
                            props.config.solRoundBalanceFlag &&
                            <div className="balance-label">
                                <label data-tip="Claimable SOL Balance" className="sol-round">
                                    <img src={SolRoundIcon} alt="sol"/>
                                    <span>{userReduxStore.userInfoData?.claimablesol > 0 ? userReduxStore.userInfoData?.claimablesol : 0}</span>
                                </label>
                                <div className="convert-sol" data-tip="Withdraw SOL" onClick={handleWithdrawSOL}>
                                    <img src={ConvertSol} alt="convertSol"/>
                                </div>
                            </div>
                        }
                        {
                            props.config.royaltiesFlag &&
                            <div className="balance-label">
                                <label data-tip="SOL Royalties" className="sol-round">
                                    <img src={RoyaltiesIcon} alt="Royalties"/>
                                    <span>{userReduxStore.userInfoData?.claimablesolcasino > 0 ? userReduxStore.userInfoData?.claimablesolcasino : 0}</span>
                                </label>
                                <div className="convert-sol left-datatip" data-tip="Withdraw SOL Royalties" onClick={handleWithdrawRoyalties}>
                                    <img src={ConvertSol} alt="convertSol"/>
                                </div>
                            </div>
                        }
                    </div>

                    {/*{*/}
                    {/*    props.config.sktToSolFlag &&*/}
                    {/*    <div className="skttosol">1 $SKT = {userReduxStore.userInfoData.skttosol} SOL</div>*/}
                    {/*}*/}
                    {
                        props.config.sktToSolFlag &&
                        <div className="skttosol">1 Staked Kitty = {userReduxStore.userInfoData.stakedKittyWorth == 0 ? "?" : userReduxStore.userInfoData.stakedKittyWorth} SOL</div>
                    }
                </Grid>
            }
        </Grid>
    )
}
