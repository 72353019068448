import React from 'react'
import "./index.css";
import {ControllerHomeBreedingPods} from "../../components/ControllerHomeBreedingPods";
import { MyNavBar } from "../../sharedComponent/MyNavBar";
import { Footer } from '../../sharedComponent/Footer';
import {Helmet} from "react-helmet-async";

const HomeBreedingPods = (props: any): JSX.Element => {
    return (
        <div style={{background: 'white'}}>
            <div className="home-page" id='logo'>
                <Helmet>
                    <title>Home - Sol Kitties - Web3 Tech centric NFT Project</title>
                </Helmet>
                <MyNavBar />
                <ControllerHomeBreedingPods />
                <Footer />
            </div>
        </div>
    )
}

export default HomeBreedingPods;