const Rarity3dJson =
    [
        {
            "name": "3D Sol Kitties #0001",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0001.png",
            "rarity_rank": 1,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #1"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0002",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0002_Pod_197.png",
            "rarity_rank": 2,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Geralt"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #2"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0003",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0003_Pod_009.png",
            "rarity_rank": 3,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Optimus"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #3"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0004",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0004_Pod_022.png",
            "rarity_rank": 4,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Michael Jordan"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #4"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0005",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0005_Pod_182.png",
            "rarity_rank": 5,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Squid Legendary"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #5"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0006",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0006_Pod_059.png",
            "rarity_rank": 6,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Devil"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #6"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0007",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0007_Pod_110.png",
            "rarity_rank": 7,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Flash"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #7"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0008",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0008_Pod_025.png",
            "rarity_rank": 8,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Luffy"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #8"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0009",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0009_Pod_320.png",
            "rarity_rank": 9,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Captain America"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #9"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0010",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0010_Pod_229.png",
            "rarity_rank": 10,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Thor"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #10"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0011",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0011_Pod_027.png",
            "rarity_rank": 11,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Eminem"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #11"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0012",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0012_Pod_159.png",
            "rarity_rank": 12,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ciri"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #12"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0013",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0013_Pod_021.png",
            "rarity_rank": 13,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Scream"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #13"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0014",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0014_Pod_017.png",
            "rarity_rank": 14,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Darth Vader"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #14"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0015",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0015_Pod_013.png",
            "rarity_rank": 15,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape Legendary"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #15"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0016",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0016_Pod_045.png",
            "rarity_rank": 16,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Morpheus"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #16"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0017",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0017_Pod_098.png",
            "rarity_rank": 17,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Santa"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #17"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0018",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0018_Pod_068.png",
            "rarity_rank": 18,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Reaper"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #18"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0019",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0019_Pod_158.png",
            "rarity_rank": 19,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Samurai"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #19"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0020",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0020_Pod_63.png",
            "rarity_rank": 20,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Leafy"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #20"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0021",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0021_Pod_002.png",
            "rarity_rank": 21,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Neo"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #21"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0022",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0022_Pod_171.png",
            "rarity_rank": 22,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Venom"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #22"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0023",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0023.png",
            "rarity_rank": 23,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Joker"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #23"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0024",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0024_Pod_064.png",
            "rarity_rank": 24,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White Joker"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #24"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0025",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0025_Pod_443.png",
            "rarity_rank": 25,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Hulk"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #25"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0026",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0026.png",
            "rarity_rank": 26,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Mummy"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #26"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0027",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0027_Pod_447.png",
            "rarity_rank": 27,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blade Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #27"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0028",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0028_Pod_237.png",
            "rarity_rank": 28,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Spiky Monster"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #28"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0029",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0029_Pod_055.png",
            "rarity_rank": 29,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Scortched"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #29"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0030",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0030_Pod_156.png",
            "rarity_rank": 30,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Slimy"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #30"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0031",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0031_Pod_521.png",
            "rarity_rank": 31,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Trump"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #31"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0032",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0032_Pod_152.png",
            "rarity_rank": 32,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Mike Tyson"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #32"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0033",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0033_Pod_011.png",
            "rarity_rank": 33,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Zombie Goku"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #33"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0034",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0034_Pod_017.png",
            "rarity_rank": 34,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pablo Escobar"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #34"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0035",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0035_Pod_021.png",
            "rarity_rank": 35,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dumbledore"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #35"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0036",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0036_Pod_057.png",
            "rarity_rank": 36,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Headwear Joker"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #36"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0037",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0037_Pod_182.png",
            "rarity_rank": 37,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Glass Mask"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #37"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0038",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0038_Pod_292.png",
            "rarity_rank": 38,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "RoboKat"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #38"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0039",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0039_Pod_012.png",
            "rarity_rank": 39,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Johny"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #39"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0040",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0040_Pod_447.png",
            "rarity_rank": 40,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Deadpool"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #40"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0041",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0041.png",
            "rarity_rank": 41,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Spiderman"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #41"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0042",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0042.png",
            "rarity_rank": 42,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #42"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0043",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0043.png",
            "rarity_rank": 43,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Jake Kitty"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #43"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0044",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0044.png",
            "rarity_rank": 44,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black Glow"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #44"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0045",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0045.png",
            "rarity_rank": 45,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Christopher"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #45"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0046",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0046.png",
            "rarity_rank": 46,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Plant"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #46"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0047",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0047.png",
            "rarity_rank": 47,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Snoop"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #47"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0048",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0048.png",
            "rarity_rank": 48,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black Joker"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #48"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0049",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0049.png",
            "rarity_rank": 49,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Thanos"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #49"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0050",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0050.png",
            "rarity_rank": 50,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Nezuko"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #50"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0051",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0051.png",
            "rarity_rank": 51,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Wonderwoman"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #51"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0052",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0052.png",
            "rarity_rank": 52,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Batman"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #52"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0053",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0053.png",
            "rarity_rank": 53,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Aoki"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #53"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0054",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0054.png",
            "rarity_rank": 54,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "DojaKat"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #54"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0055",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0055.png",
            "rarity_rank": 55,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Krueger"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #55"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0056",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0056.png",
            "rarity_rank": 56,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Power Goku"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #56"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0057",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0057.png",
            "rarity_rank": 57,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Goku"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #57"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0058",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0058.png",
            "rarity_rank": 58,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ironman"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #58"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0059",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0059.png",
            "rarity_rank": 59,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Jigsaw"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #59"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0060",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0060.png",
            "rarity_rank": 60,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Johny"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #60"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0061",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0061.png",
            "rarity_rank": 61,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Laser Robot"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #61"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0062",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0062.png",
            "rarity_rank": 62,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Liquid"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #62"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0063",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0063.png",
            "rarity_rank": 63,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Solid Snake"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #63"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0064",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0064.png",
            "rarity_rank": 64,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "White Ornamental"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #64"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0065",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0065.png",
            "rarity_rank": 65,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Frankenstein"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #65"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0066",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0066.png",
            "rarity_rank": 66,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Grimes"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #66"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0067",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0067.png",
            "rarity_rank": 67,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dark Samurai"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #67"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0068",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0068.png",
            "rarity_rank": 68,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Viking"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #68"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0069",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0069.png",
            "rarity_rank": 69,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Toxic Mask"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #69"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0070",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0070.png",
            "rarity_rank": 70,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yoda"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #70"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "Captain Jack Sparrow #0071",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0071.png",
            "rarity_rank": 71,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Jack Sparrow"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #71"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0072",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0072.png",
            "rarity_rank": 72,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Half Split"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #72"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0073",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0073.png",
            "rarity_rank": 73,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Chef"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #73"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0074",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0074.png",
            "rarity_rank": 74,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoner Frog"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #74"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0075",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0075.png",
            "rarity_rank": 75,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dredd"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #75"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0076",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0076.png",
            "rarity_rank": 76,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Robot"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #76"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0077",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0077.png",
            "rarity_rank": 77,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Nick"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #77"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0078",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0078.png",
            "rarity_rank": 78,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cyber Punk"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #78"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0079",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0079.png",
            "rarity_rank": 79,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Spartan"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #79"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0080",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0080.png",
            "rarity_rank": 80,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Loki"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #80"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0081",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0081.png",
            "rarity_rank": 81,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Minion"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #81"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0082",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0082.png",
            "rarity_rank": 82,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cyborg"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #82"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0083",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0083.png",
            "rarity_rank": 83,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Charlie Chaplin"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #83"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0084",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0084.png",
            "rarity_rank": 84,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Shredder"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #84"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0085",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0085.png",
            "rarity_rank": 85,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "John Wick"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #85"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0087",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0087.png",
            "rarity_rank": 86,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cat Woman"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #87"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0088",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0088.png",
            "rarity_rank": 87,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Toxic Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #88"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0089",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0089.png",
            "rarity_rank": 88,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Leaf Kitty"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #89"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0090",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0090.png",
            "rarity_rank": 89,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Leonardo Ninja Turtle"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #90"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0091",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0091.png",
            "rarity_rank": 90,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Raphael Ninja Turtle"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #91"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0092",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0092.png",
            "rarity_rank": 91,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Michelangelo Ninja Turtle"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #92"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0093",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0093.png",
            "rarity_rank": 92,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Donatello Ninja Turtle"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #93"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0094",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0094.png",
            "rarity_rank": 93,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Obi"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #94"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0095",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0095.png",
            "rarity_rank": 94,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Genie"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #95"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0096",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0096.png",
            "rarity_rank": 95,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Sugar Skull"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #96"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0097",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0097.png",
            "rarity_rank": 96,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dr. Strange"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #97"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0098",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0098.png",
            "rarity_rank": 97,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Superman"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #98"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0099",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0099.png",
            "rarity_rank": 98,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Hasbulla"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #99"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0100",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0100.png",
            "rarity_rank": 99,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Kratos"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #100"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0086",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0086.png",
            "rarity_rank": 100,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Trinity"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Rarity",
                    "value": "Legendary #86"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0186",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0186_Pod_149_Burnt_1703.png",
            "rarity_rank": 101,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Royal Crown"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tail"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0731",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0731_Pod_151.png",
            "rarity_rank": 102,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Squid"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Shield"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1196",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1196_Pod_267.png",
            "rarity_rank": 103,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3583",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3583.png",
            "rarity_rank": 104,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "BitBoy"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0733",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0733_Pod_037.png",
            "rarity_rank": 105,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Super Mario"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0759",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0759_Pod_285.png",
            "rarity_rank": 106,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glass Mask"
                },
                {
                    "trait_type": "Background",
                    "value": "Gradient"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Flag"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Armour Shoulders"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0761",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0761_Pod_321_Burnt_0039.png",
            "rarity_rank": 107,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glass Mask"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Flag"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0760",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0760_Pod_287.png",
            "rarity_rank": 108,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glass Mask"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0747",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0747_Pod_172_Burnt_1422.png",
            "rarity_rank": 109,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Galactic"
                },
                {
                    "trait_type": "Background",
                    "value": "Checkered"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Robot Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0157",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0157_Pod_132.png",
            "rarity_rank": 110,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Mummy"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange Cow Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4060",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4060.png",
            "rarity_rank": 111,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ice Cream"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Royal Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pink Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moon Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0192",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0192_Pod_374.png",
            "rarity_rank": 112,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange Cow Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Band"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Solana Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0180",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0180_Pod_008.png",
            "rarity_rank": 113,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spiky Helmet"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Rainbow Rays"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Armour Gloves"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4055",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4055.png",
            "rarity_rank": 114,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ice Cream"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pink Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moon Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1188",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1188_Pod_140.png",
            "rarity_rank": 115,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Galactic"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Glass Brain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1176",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1176_Pod_051.png",
            "rarity_rank": 116,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Mummy"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange Cow Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Slime"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4042",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4042.png",
            "rarity_rank": 117,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Rainbow Rays"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0162",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0162_Pod_070.png",
            "rarity_rank": 118,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Red"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Star Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1195",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1195_Pod_229.png",
            "rarity_rank": 119,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Gradient"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Apple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Construction Worker Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Star Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0215",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0215_Pod_029.png",
            "rarity_rank": 120,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Gold Necklace"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 01"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4057",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4057.png",
            "rarity_rank": 121,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ice Cream"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pink Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1185",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1185_Pod_106.png",
            "rarity_rank": 122,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Glass Brain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1199",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1199_Pod_290.png",
            "rarity_rank": 123,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Skull"
                },
                {
                    "trait_type": "Background",
                    "value": "Checkered"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4014",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4014.png",
            "rarity_rank": 124,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Rainbow Rays"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4059",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4059.png",
            "rarity_rank": 125,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ice Cream"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Royal Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pink Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0191",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0191_Pod_283.png",
            "rarity_rank": 126,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange Cow Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Slime"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0785",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0785.png",
            "rarity_rank": 127,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cyan Squid"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spiky Helmet"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0726",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0726_Pod_152.png",
            "rarity_rank": 128,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Leafy"
                },
                {
                    "trait_type": "Background",
                    "value": "Checkered"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1191",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1191_Pod_161.png",
            "rarity_rank": 129,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ice Cream"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Royal Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pink Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1333",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1333.png",
            "rarity_rank": 130,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Girly Lipstick"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eye Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Paw Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Bow Dress"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3991",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3991.png",
            "rarity_rank": 131,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Rainbow Rays"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Duck Ring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0211",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0211_Pod_554.png",
            "rarity_rank": 132,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cyan Squid"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1207",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1207_Pod_059.png",
            "rarity_rank": 133,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Checkered"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1033",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1033.png",
            "rarity_rank": 134,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cyan Squid"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0721",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0721_Pod_208.png",
            "rarity_rank": 135,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Glove"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Paw"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4056",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4056.png",
            "rarity_rank": 136,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Witch Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moon Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0724",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0724_Pod_319.png",
            "rarity_rank": 137,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Deformed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Gradient"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Binoculars"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Armour Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4053",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4053.png",
            "rarity_rank": 138,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moon Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0163",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0163_Pod_010.png",
            "rarity_rank": 139,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "BB8"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Sunhat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0188",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0188_Pod_133.png",
            "rarity_rank": 140,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Galaxy"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0639",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0639.png",
            "rarity_rank": 141,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Parrot"
                },
                {
                    "trait_type": "Background",
                    "value": "Gradient"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Laser Eyewear"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Gold Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1217",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1217.png",
            "rarity_rank": 142,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cyan Squid"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0758",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0758_Pod_295.png",
            "rarity_rank": 143,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Glass Brain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0756",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0756_Pod_106.png",
            "rarity_rank": 144,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "BB8"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Bracelet"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3455",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3455.png",
            "rarity_rank": 145,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Band"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Badges"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1206",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1206_Pod_554.png",
            "rarity_rank": 146,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Glass Brain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1490",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1490.png",
            "rarity_rank": 147,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Multiple Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Helmet"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0167",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0167_Pod_494_Burnt_0532.png",
            "rarity_rank": 148,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Small Tiara"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0247",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0247.png",
            "rarity_rank": 149,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spiky Helmet"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4177",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4177.png",
            "rarity_rank": 150,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "TV Head"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0742",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0742_Pod_018.png",
            "rarity_rank": 151,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Apple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eye Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Leather Collar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0173",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0173.png",
            "rarity_rank": 152,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Mummy"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "AR Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1262",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1262.png",
            "rarity_rank": 153,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Girly Lipstick"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Helmet"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Bow Dress"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1201",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1201_Pod_319.png",
            "rarity_rank": 154,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spartan Helmet"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Armour Shoulders"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0187",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0187_Pod_191.png",
            "rarity_rank": 155,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Galaxy"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1180",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1180_Pod_068.png",
            "rarity_rank": 156,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Galaxy"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1181",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1181_Pod_070.png",
            "rarity_rank": 157,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Galaxy"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0189",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0189_Pod_464.png",
            "rarity_rank": 158,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Galaxy"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4470",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4470.png",
            "rarity_rank": 159,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Badges"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Armour Shoulders"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0521",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0521.png",
            "rarity_rank": 160,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Baby"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Apple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Stripe Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 01"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1165",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1165_Pod_006.png",
            "rarity_rank": 161,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1260",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1260.png",
            "rarity_rank": 162,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sol Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Paw Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1076",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1076.png",
            "rarity_rank": 163,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headband"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Tie"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1345",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1345.png",
            "rarity_rank": 164,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Girly Lipstick"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Bow Dress"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1317",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1317.png",
            "rarity_rank": 165,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glass Mask"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eyepatch"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1172",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1172_Pod_039.png",
            "rarity_rank": 166,
            "attributes": [
                {
                    "trait_type": "Background",
                    "value": "Gradient"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Crazy Eyes"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Piercings"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0476",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0476.png",
            "rarity_rank": 167,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Green"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1539",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1539.png",
            "rarity_rank": 168,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1229",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1229.png",
            "rarity_rank": 169,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Mummy"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Gold Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1438",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1438.png",
            "rarity_rank": 170,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Girly Lipstick"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Bow Dress"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3802",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3802.png",
            "rarity_rank": 171,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Girly Lipstick"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Duck Ring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Bow Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1166",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1166_Pod_009.png",
            "rarity_rank": 172,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Mummy"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 01"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Armour Shoulders"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0406",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0406.png",
            "rarity_rank": 173,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headband"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0214",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0214_Pod_300.png",
            "rarity_rank": 174,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "AR Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0196",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0196_Pod_039.png",
            "rarity_rank": 175,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cowboy Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Collar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1326",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1326.png",
            "rarity_rank": 176,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Punk"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spartan Helmet"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3683",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3683.png",
            "rarity_rank": 177,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Girly Lipstick"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Duck Ring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Bow Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0276",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0276.png",
            "rarity_rank": 178,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Green"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1494",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1494.png",
            "rarity_rank": 179,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Robot Mask"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0294",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0294.png",
            "rarity_rank": 180,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Laser Eyewear"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1251",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1251.png",
            "rarity_rank": 181,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Punk"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cowboy Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eyepatch"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0839",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0839.png",
            "rarity_rank": 182,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Galactic"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gem Tiara"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1209",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1209_Pod_320.png",
            "rarity_rank": 183,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Parrot"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Bracelet"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Head Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0256",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0256.png",
            "rarity_rank": 184,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4095",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4095.png",
            "rarity_rank": 185,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gem Tiara"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Face Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1435",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1435.png",
            "rarity_rank": 186,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Ape"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sailor Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1171",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1171_Pod_037.png",
            "rarity_rank": 187,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Multiple Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "TV Head"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0168",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0168_Pod_049.png",
            "rarity_rank": 188,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ornate"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gem Tiara"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1498",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1498.png",
            "rarity_rank": 189,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2726",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2726.png",
            "rarity_rank": 190,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sol Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Badges"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1321",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1321.png",
            "rarity_rank": 191,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glass Mask"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1164",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1164_004.png",
            "rarity_rank": 192,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Girly Lipstick"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Bow Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0172",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0172_Pod_224.png",
            "rarity_rank": 193,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Pattern"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "AR Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4405",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4405.png",
            "rarity_rank": 194,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Robocop"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Armour Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Armour Shoulders"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1487",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1487.png",
            "rarity_rank": 195,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Big Ears"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spartan Helmet"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Glove"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4058",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4058.png",
            "rarity_rank": 196,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0170",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0170_Pod_499_Burnt_0728.png",
            "rarity_rank": 197,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Small Tiara"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0923",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0923.png",
            "rarity_rank": 198,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headset"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Stripe Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0363",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0363.png",
            "rarity_rank": 199,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Robot Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1006",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1006.png",
            "rarity_rank": 200,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Multiple Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0103",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0103.png",
            "rarity_rank": 201,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "AR Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moon Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4054",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4054.png",
            "rarity_rank": 202,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1512",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1512.png",
            "rarity_rank": 203,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Multiple Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Head Gadget"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Glove"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0804",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0804.png",
            "rarity_rank": 204,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "BB8"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1283",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1283.png",
            "rarity_rank": 205,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2964",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2964.png",
            "rarity_rank": 206,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Axe"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Badges"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1198",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1198_Pod_285.png",
            "rarity_rank": 207,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Mummy"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Painted Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1118",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1118.png",
            "rarity_rank": 208,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Exotic"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Cape"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Armour Shoulders"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4101",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4101.png",
            "rarity_rank": 209,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sailor Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Smoking Pipe"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0727",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0727_Pod_085.png",
            "rarity_rank": 210,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Multiple Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Gradient"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Banana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0827",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0827.png",
            "rarity_rank": 211,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Galactic"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1485",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1485.png",
            "rarity_rank": 212,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Ape"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Stripe Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4229",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4229.png",
            "rarity_rank": 213,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "TV Head"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0630",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0630.png",
            "rarity_rank": 214,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0755",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0755.png",
            "rarity_rank": 215,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Pattern"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Propeller Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1350",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1350.png",
            "rarity_rank": 216,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Deformed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sol Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Leather Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0475",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0475.png",
            "rarity_rank": 217,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Star Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1311",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1311.png",
            "rarity_rank": 218,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Multiple Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Paw Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0102",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0102.png",
            "rarity_rank": 219,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "AR Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moon Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1397",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1397.png",
            "rarity_rank": 220,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Deformed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Small Tiara"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0464",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0464.png",
            "rarity_rank": 221,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sailor Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3510",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3510.png",
            "rarity_rank": 222,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Construction Worker Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Simple Gold Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1211",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1211_Pod_010.png",
            "rarity_rank": 223,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Witch Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moon Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3279",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3279.png",
            "rarity_rank": 224,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Construction Worker Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Simple Gold Piercing"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4330",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4330.png",
            "rarity_rank": 225,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Propeller Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4166",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4166.png",
            "rarity_rank": 226,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Banana"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Tie"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Armour Shoulders"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0104",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0104.png",
            "rarity_rank": 227,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "AR Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0136",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0136.png",
            "rarity_rank": 228,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Witch Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moon Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2721",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2721.png",
            "rarity_rank": 229,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Badges"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Armour"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1178",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1178_Pod_060.png",
            "rarity_rank": 230,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Deformed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Binoculars"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Paw Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0882",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0882.png",
            "rarity_rank": 231,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glass Mask"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Apple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0330",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0330.png",
            "rarity_rank": 232,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Pattern"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1239",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1239.png",
            "rarity_rank": 233,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Crazy Eyes"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1233",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1233.png",
            "rarity_rank": 234,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Multiple Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Shield"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0667",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0667.png",
            "rarity_rank": 235,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Red"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Leather Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4338",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4338.png",
            "rarity_rank": 236,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Skull"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Laser Eyewear"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Simple Gold Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0171",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0171_Pod_252_Burnt_0798.png",
            "rarity_rank": 237,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spartan Helmet"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 01"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1163",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1163_Pod_001.png",
            "rarity_rank": 238,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Girly Lipstick"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Bow Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1296",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1296.png",
            "rarity_rank": 239,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Binoculars"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Face Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1077",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1077.png",
            "rarity_rank": 240,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gem Tiara"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1295",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1295.png",
            "rarity_rank": 241,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Punk"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Axe"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Paw Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1427",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1427.png",
            "rarity_rank": 242,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0497",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0497.png",
            "rarity_rank": 243,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Royal Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1096",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1096.png",
            "rarity_rank": 244,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Mummy"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eyepatch"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1242",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1242.png",
            "rarity_rank": 245,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Redhood"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Head Gadget"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4133",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4133.png",
            "rarity_rank": 246,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Badges"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3013",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3013.png",
            "rarity_rank": 247,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eye Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Collar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4352",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4352.png",
            "rarity_rank": 248,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sailor Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Shield"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1168",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1168_Pod_014.png",
            "rarity_rank": 249,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Construction Worker Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4100",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4100.png",
            "rarity_rank": 250,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Witch Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1278",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1278.png",
            "rarity_rank": 251,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glass Mask"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1305",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1305.png",
            "rarity_rank": 252,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Band"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2846",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2846.png",
            "rarity_rank": 253,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Construction Worker Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0165",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0165_Pod_066.png",
            "rarity_rank": 254,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Stripe Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3012",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3012.png",
            "rarity_rank": 255,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spartan Helmet"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4359",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4359.png",
            "rarity_rank": 256,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Robocop"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bow"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0754",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0754.png",
            "rarity_rank": 257,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Reagge Beanie"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Two Bracelets"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1426",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1426.png",
            "rarity_rank": 258,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Ape"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0160",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0160_Pod_056.png",
            "rarity_rank": 259,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "BB8"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Apple"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1320",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1320.png",
            "rarity_rank": 260,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Axe"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Crazy Eyes"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1513",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1513.png",
            "rarity_rank": 261,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Multiple Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0669",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0669.png",
            "rarity_rank": 262,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Torn Mouth"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spiky Helmet"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4551",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4551.png",
            "rarity_rank": 263,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Robocop"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Witch Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1051",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1051.png",
            "rarity_rank": 264,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "BB8"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0954 - Groza",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0954.png",
            "rarity_rank": 265,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Torn Mouth"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Robot Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0169",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0169_Pod_081_Burnt_0605.png",
            "rarity_rank": 266,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Red"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1407",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1407.png",
            "rarity_rank": 267,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Band"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Paw Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0967",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0967.png",
            "rarity_rank": 268,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sol Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0177",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0177_Pod_092.png",
            "rarity_rank": 269,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Binoculars"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Laser Eyewear"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Armour Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0508",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0508.png",
            "rarity_rank": 270,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gem Tiara"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Paw"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0477",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0477.png",
            "rarity_rank": 271,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Red"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Solana Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1388",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1388.png",
            "rarity_rank": 272,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Deformed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0564",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0564.png",
            "rarity_rank": 273,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eyepatch"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Simple Gold Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1483",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1483.png",
            "rarity_rank": 274,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Ape"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4082",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4082.png",
            "rarity_rank": 275,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eye Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Paw Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2826",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2826.png",
            "rarity_rank": 276,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Band"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Two Bracelets"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1073",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1073.png",
            "rarity_rank": 277,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Square Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Tie"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0523",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0523.png",
            "rarity_rank": 278,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gem Tiara"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Paw"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3099",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3099.png",
            "rarity_rank": 279,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Badges"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1339",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1339.png",
            "rarity_rank": 280,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Crazy Eyes"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Neck Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1218",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1218.png",
            "rarity_rank": 281,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "TV Head"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1323",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1323.png",
            "rarity_rank": 282,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2546",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2546.png",
            "rarity_rank": 283,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "TV Head"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1337",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1337.png",
            "rarity_rank": 284,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Redhood"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eye Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0557",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0557.png",
            "rarity_rank": 285,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sailor Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Gold Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0105",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0105.png",
            "rarity_rank": 286,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "AR Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0253",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0253.png",
            "rarity_rank": 287,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Green"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eyepatch"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Leather Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4175",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4175.png",
            "rarity_rank": 288,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eye Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Collar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4525",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4525.png",
            "rarity_rank": 289,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Torn Mouth"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Witch Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moon Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3586",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3586.png",
            "rarity_rank": 290,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0101",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0101.png",
            "rarity_rank": 291,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "AR Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1302",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1302.png",
            "rarity_rank": 292,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Punk"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Apple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Armour Shoulders"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4132",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4132.png",
            "rarity_rank": 293,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Torn Mouth"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Apple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Star Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4455",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4455.png",
            "rarity_rank": 294,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Construction Worker Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1208",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1208_Pod_464.png",
            "rarity_rank": 295,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Mummy"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Robot Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0391",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0391.png",
            "rarity_rank": 296,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Winter Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Two Bracelets"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4372",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4372.png",
            "rarity_rank": 297,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Stripe Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1273",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1273.png",
            "rarity_rank": 298,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Girly Lipstick"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0640",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0640.png",
            "rarity_rank": 299,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Gradient"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1312",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1312.png",
            "rarity_rank": 300,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Redhood"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1491",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1491.png",
            "rarity_rank": 301,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Girly Lipstick"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Cape"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0400",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0400.png",
            "rarity_rank": 302,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Solana Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1306",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1306.png",
            "rarity_rank": 303,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Helmet"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1314",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1314.png",
            "rarity_rank": 304,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Paw Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0194",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0194_Pod_290.png",
            "rarity_rank": 305,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Multiple Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Duck Ring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0986",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0986.png",
            "rarity_rank": 306,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spiky Helmet"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Face Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0994",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0994.png",
            "rarity_rank": 307,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Pattern"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Painted Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0469",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0469.png",
            "rarity_rank": 308,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Helmet"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Red Glow Eyewear"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1338",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1338.png",
            "rarity_rank": 309,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eye Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2833",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2833.png",
            "rarity_rank": 310,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sol Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4322",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4322.png",
            "rarity_rank": 311,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "TV Head"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0740",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0740.png",
            "rarity_rank": 312,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Head Gadget"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Piercings"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1152",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1152.png",
            "rarity_rank": 313,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sol Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Earring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1319",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1319.png",
            "rarity_rank": 314,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Parrot"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sol Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4480",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4480.png",
            "rarity_rank": 315,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Robocop"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0828",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0828.png",
            "rarity_rank": 316,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Binoculars"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eyepatch"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1455",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1455.png",
            "rarity_rank": 317,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Reagge Beanie"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1364",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1364.png",
            "rarity_rank": 318,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Deformed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Armour"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0794",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0794.png",
            "rarity_rank": 319,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cowboy Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Armour"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0527",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0527.png",
            "rarity_rank": 320,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0626",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0626.png",
            "rarity_rank": 321,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Solana Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4145",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4145.png",
            "rarity_rank": 322,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Propeller Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4254",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4254.png",
            "rarity_rank": 323,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Witch Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1479",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1479.png",
            "rarity_rank": 324,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Girly Lipstick"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Face Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1325",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1325.png",
            "rarity_rank": 325,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Axe"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Armour Shoulders"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1256",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1256.png",
            "rarity_rank": 326,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Painted Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2108",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2108.png",
            "rarity_rank": 327,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "White Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1488",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1488.png",
            "rarity_rank": 328,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Big Ears"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Small Tiara"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Armour"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1515",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1515.png",
            "rarity_rank": 329,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Parrot"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1307",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1307.png",
            "rarity_rank": 330,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Parrot"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Painted Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1248",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1248.png",
            "rarity_rank": 331,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Banana"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1413",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1413.png",
            "rarity_rank": 332,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Ape"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0313",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0313.png",
            "rarity_rank": 333,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Red"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4155",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4155.png",
            "rarity_rank": 334,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Helmet"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Piercings"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1027",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1027.png",
            "rarity_rank": 335,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Mummy"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spiky Helmet"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Tie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0164",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0164_Pod_296.png",
            "rarity_rank": 336,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Banana"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Stripe Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1480",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1480.png",
            "rarity_rank": 337,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Big Ears"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eyepatch"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0302",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0302.png",
            "rarity_rank": 338,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Parrot"
                },
                {
                    "trait_type": "Background",
                    "value": "Red"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4426",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4426.png",
            "rarity_rank": 339,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Redhood"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1502",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1502.png",
            "rarity_rank": 340,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Redhood"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1282",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1282.png",
            "rarity_rank": 341,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Punk"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1544",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1544.png",
            "rarity_rank": 342,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eyepatch"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Cape"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0235",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0235.png",
            "rarity_rank": 343,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Pattern"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Bracelet"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0387",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0387.png",
            "rarity_rank": 344,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Leafy"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Square Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Shield"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4098",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4098.png",
            "rarity_rank": 345,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Helmet"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Bow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0917",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0917.png",
            "rarity_rank": 346,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Mummy"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sailor Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0627",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0627.png",
            "rarity_rank": 347,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Gold Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1542",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1542.png",
            "rarity_rank": 348,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Multiple Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Axe"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Duck Ring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0887",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0887.png",
            "rarity_rank": 349,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3596",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3596.png",
            "rarity_rank": 350,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Reagge Beanie"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3395",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3395.png",
            "rarity_rank": 351,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Construction Worker Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0254",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0254.png",
            "rarity_rank": 352,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Baby"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Gold Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4068",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4068.png",
            "rarity_rank": 353,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Head Gadget"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0333",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0333.png",
            "rarity_rank": 354,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0699",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0699.png",
            "rarity_rank": 355,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Red"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3329",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3329.png",
            "rarity_rank": 356,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Axe"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Star Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Two Bracelets"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1303",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1303.png",
            "rarity_rank": 357,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Crazy Eyes"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0757",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0757_Pod_124_Burnt_0907.png",
            "rarity_rank": 358,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "White Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Royal Crown"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0849",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0849.png",
            "rarity_rank": 359,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Banana"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1292",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1292.png",
            "rarity_rank": 360,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Punk"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Red Glow Eyewear"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1297",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1297.png",
            "rarity_rank": 361,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Crazy Eyes"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1424",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1424.png",
            "rarity_rank": 362,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Ape"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3993",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3993.png",
            "rarity_rank": 363,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Head Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0411",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0411.png",
            "rarity_rank": 364,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Armour Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1309",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1309.png",
            "rarity_rank": 365,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eye Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1370",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1370.png",
            "rarity_rank": 366,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Deformed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0317",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0317.png",
            "rarity_rank": 367,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Red"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0259",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0259.png",
            "rarity_rank": 368,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Pattern"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4064",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4064.png",
            "rarity_rank": 369,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ice Cream"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Stripe Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3614",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3614.png",
            "rarity_rank": 370,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0368",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0368.png",
            "rarity_rank": 371,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Head Gadget"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3981",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3981.png",
            "rarity_rank": 372,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Head Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4395",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4395.png",
            "rarity_rank": 373,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Torn Mouth"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0238",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0238.png",
            "rarity_rank": 374,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Baby"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Collar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0312",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0312.png",
            "rarity_rank": 375,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Propeller Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Face Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3779",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3779.png",
            "rarity_rank": 376,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Laser Eyewear"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Duck Ring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1343",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1343.png",
            "rarity_rank": 377,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Punk"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1310",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1310.png",
            "rarity_rank": 378,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Red Glow Eyewear"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1509",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1509.png",
            "rarity_rank": 379,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Ape"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headband"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1210",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1210_Pod_254.png",
            "rarity_rank": 380,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Punk"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0561",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0561.png",
            "rarity_rank": 381,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Slime"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1060",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1060.png",
            "rarity_rank": 382,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0289",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0289.png",
            "rarity_rank": 383,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1463",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1463.png",
            "rarity_rank": 384,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sailor Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1040",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1040.png",
            "rarity_rank": 385,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Axe"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eyepatch"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3290",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3290.png",
            "rarity_rank": 386,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Painted Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Red Glow Eyewear"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3475",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3475.png",
            "rarity_rank": 387,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gem Tiara"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Armour Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3537",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3537.png",
            "rarity_rank": 388,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Sunhat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Star Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Armour Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0356",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0356.png",
            "rarity_rank": 389,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gem Tiara"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0860",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0860.png",
            "rarity_rank": 390,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3466",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3466.png",
            "rarity_rank": 391,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3122",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3122.png",
            "rarity_rank": 392,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Axe"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Piercings"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0549",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0549.png",
            "rarity_rank": 393,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Smoking Pipe"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1351",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1351.png",
            "rarity_rank": 394,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sol Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1441",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1441.png",
            "rarity_rank": 395,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4250",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4250.png",
            "rarity_rank": 396,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Stripe Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Bow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1336",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1336.png",
            "rarity_rank": 397,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Punk"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Red Glow Eyewear"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Paw Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1570",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1570.png",
            "rarity_rank": 398,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0491",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0491.png",
            "rarity_rank": 399,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Winter Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Square Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0884",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0884.png",
            "rarity_rank": 400,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spartan Helmet"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1294",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1294.png",
            "rarity_rank": 401,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Crazy Eyes"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4448",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4448.png",
            "rarity_rank": 402,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0710",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0710.png",
            "rarity_rank": 403,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Gradient"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4144",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4144.png",
            "rarity_rank": 404,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Laser Eyewear"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4062",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4062.png",
            "rarity_rank": 405,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ice Cream"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1554",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1554.png",
            "rarity_rank": 406,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Leather Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0245",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0245.png",
            "rarity_rank": 407,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Torn Mouth"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Helmet"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4153",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4153.png",
            "rarity_rank": 408,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Winter Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1395",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1395.png",
            "rarity_rank": 409,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Parrot"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Binoculars"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1377",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1377.png",
            "rarity_rank": 410,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Axe"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Leather Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0813",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0813.png",
            "rarity_rank": 411,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Baby"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Apple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eyepatch"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0213",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0213_Pod_269_Burnt_1835.png",
            "rarity_rank": 412,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Green Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0353",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0353.png",
            "rarity_rank": 413,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Pattern"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0519",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0519.png",
            "rarity_rank": 414,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Slime"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Paw"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0482",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0482.png",
            "rarity_rank": 415,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1489",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1489.png",
            "rarity_rank": 416,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Glove"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0705",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0705.png",
            "rarity_rank": 417,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Square Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bow"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3512",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3512.png",
            "rarity_rank": 418,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headset"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1418",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1418.png",
            "rarity_rank": 419,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Star Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4329",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4329.png",
            "rarity_rank": 420,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sailor Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 01"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Armour"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2791",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2791.png",
            "rarity_rank": 421,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Helmet"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bow"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1149",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1149.png",
            "rarity_rank": 422,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eyepatch"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1234",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1234.png",
            "rarity_rank": 423,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Galactic"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1114",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1114.png",
            "rarity_rank": 424,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Apple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Red Glow Eyewear"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1238",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1238.png",
            "rarity_rank": 425,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Sunhat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Paw Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3341",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3341.png",
            "rarity_rank": 426,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Square Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Shield"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2389",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2389.png",
            "rarity_rank": 427,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Robocop"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1569",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1569.png",
            "rarity_rank": 428,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Multiple Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1394",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1394.png",
            "rarity_rank": 429,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Axe"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0517",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0517.png",
            "rarity_rank": 430,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Helmet"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0438",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0438.png",
            "rarity_rank": 431,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Propeller Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Two Bracelets"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0320",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0320.png",
            "rarity_rank": 432,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gem Tiara"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Shield"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0762",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0762_Pod_303.png",
            "rarity_rank": 433,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "BB8"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4519",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4519.png",
            "rarity_rank": 434,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Redhood"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1404",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1404.png",
            "rarity_rank": 435,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Sunhat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1170",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1170_Pod_029.png",
            "rarity_rank": 436,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Painted Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1386",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1386.png",
            "rarity_rank": 437,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Parrot"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spiky Helmet"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Paw Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0473",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0473.png",
            "rarity_rank": 438,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Simple Gold Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1555",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1555.png",
            "rarity_rank": 439,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Banana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0258",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0258.png",
            "rarity_rank": 440,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Gold Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4066",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4066.png",
            "rarity_rank": 441,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ice Cream"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Two Bracelets"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1190",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1190_Pod_151.png",
            "rarity_rank": 442,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Skull"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Robot Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1514",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1514.png",
            "rarity_rank": 443,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1402",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1402.png",
            "rarity_rank": 444,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1142",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1142.png",
            "rarity_rank": 445,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Banana"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0768",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0768.png",
            "rarity_rank": 446,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headset"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1146",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1146.png",
            "rarity_rank": 447,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Multiple Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1391",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1391.png",
            "rarity_rank": 448,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1446",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1446.png",
            "rarity_rank": 449,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Royal Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Glove"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0176",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0176_Pod_254.png",
            "rarity_rank": 450,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Torn Mouth"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Binoculars"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Laser Eyewear"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0203",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0203_Pod_036.png",
            "rarity_rank": 451,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Mummy"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0202",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0202_Pod_069.png",
            "rarity_rank": 452,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Mummy"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Armour Shoulders"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1281",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1281.png",
            "rarity_rank": 453,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Star Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Armour"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3552",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3552.png",
            "rarity_rank": 454,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1299",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1299.png",
            "rarity_rank": 455,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Green Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4228",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4228.png",
            "rarity_rank": 456,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Gold Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0818",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0818.png",
            "rarity_rank": 457,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Helmet"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4065",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4065.png",
            "rarity_rank": 458,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ice Cream"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Armour Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1129",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1129.png",
            "rarity_rank": 459,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glass Mask"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0520",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0520.png",
            "rarity_rank": 460,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4317",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4317.png",
            "rarity_rank": 461,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Star Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1437",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1437.png",
            "rarity_rank": 462,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Redhood"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0723",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0723_Pod_445.png",
            "rarity_rank": 463,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Torn Mouth"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Helmet"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4415",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4415.png",
            "rarity_rank": 464,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Square Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0270",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0270.png",
            "rarity_rank": 465,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Solana Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3219",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3219.png",
            "rarity_rank": 466,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cowboy Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Shield"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0991",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0991.png",
            "rarity_rank": 467,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Cape"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0209",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0209_Pod_079.png",
            "rarity_rank": 468,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Armour"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1316",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1316.png",
            "rarity_rank": 469,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Square Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1484",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1484.png",
            "rarity_rank": 470,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Big Ears"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sailor Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3930",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3930.png",
            "rarity_rank": 471,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0208",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0208_Pod_146_Burnt_2040.png",
            "rarity_rank": 472,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Green Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1432",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1432.png",
            "rarity_rank": 473,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cowboy Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Cape"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1268",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1268.png",
            "rarity_rank": 474,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Crazy Eyes"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Face Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2466",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2466.png",
            "rarity_rank": 475,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Robocop"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0840",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0840.png",
            "rarity_rank": 476,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Face Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Armour Shoulders"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1571",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1571.png",
            "rarity_rank": 477,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Redhood"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2937",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2937.png",
            "rarity_rank": 478,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gem Tiara"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0321",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0321.png",
            "rarity_rank": 479,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Collar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0392",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0392.png",
            "rarity_rank": 480,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Winter Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Square Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1061",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1061.png",
            "rarity_rank": 481,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1460",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1460.png",
            "rarity_rank": 482,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Apple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3608",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3608.png",
            "rarity_rank": 483,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Helmet"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1497",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1497.png",
            "rarity_rank": 484,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Big Ears"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Glove"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0871",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0871.png",
            "rarity_rank": 485,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Exotic"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Cape"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1080",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1080.png",
            "rarity_rank": 486,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0239",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0239.png",
            "rarity_rank": 487,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Propeller Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1454",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1454.png",
            "rarity_rank": 488,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cowboy Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Paw Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0859",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0859.png",
            "rarity_rank": 489,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2262",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2262.png",
            "rarity_rank": 490,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Robocop"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1563",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1563.png",
            "rarity_rank": 491,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Sunhat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2737",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2737.png",
            "rarity_rank": 492,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Square Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0937",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0937.png",
            "rarity_rank": 493,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spartan Helmet"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3565",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3565.png",
            "rarity_rank": 494,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Band"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4471",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4471.png",
            "rarity_rank": 495,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2788",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2788.png",
            "rarity_rank": 496,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Head Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Armour"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3503",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3503.png",
            "rarity_rank": 497,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Green Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0489",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0489.png",
            "rarity_rank": 498,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Apple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 01"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1179",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1179_Pod_061.png",
            "rarity_rank": 499,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Witch Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1069",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1069.png",
            "rarity_rank": 500,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1419",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1419.png",
            "rarity_rank": 501,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Ape"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1243",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1243.png",
            "rarity_rank": 502,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1417",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1417.png",
            "rarity_rank": 503,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Punk"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0906",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0906.png",
            "rarity_rank": 504,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1266",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1266.png",
            "rarity_rank": 505,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Paw Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0746",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0746_Pod_470.png",
            "rarity_rank": 506,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1147",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1147.png",
            "rarity_rank": 507,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0921",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0921.png",
            "rarity_rank": 508,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Face Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Armour"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0952",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0952.png",
            "rarity_rank": 509,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Multiple Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Tie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1255",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1255.png",
            "rarity_rank": 510,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Propeller Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Crazy Eyes"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1411",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1411.png",
            "rarity_rank": 511,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sol Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1102",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1102.png",
            "rarity_rank": 512,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Small Tiara"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Red Glow Eyewear"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0957",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0957.png",
            "rarity_rank": 513,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Apple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4159",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4159.png",
            "rarity_rank": 514,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3979",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3979.png",
            "rarity_rank": 515,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Head Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0863",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0863.png",
            "rarity_rank": 516,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1466",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1466.png",
            "rarity_rank": 517,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1505",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1505.png",
            "rarity_rank": 518,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0679",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0679.png",
            "rarity_rank": 519,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Gradient"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Leather Collar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0222",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0222.png",
            "rarity_rank": 520,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Pattern"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Sunhat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0658",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0658.png",
            "rarity_rank": 521,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Parrot"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Laser Eyewear"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4180",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4180.png",
            "rarity_rank": 522,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Face Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0432",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0432.png",
            "rarity_rank": 523,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Square Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Solana Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1332",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1332.png",
            "rarity_rank": 524,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Helmet"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0156",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0156_Pod_223.png",
            "rarity_rank": 525,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Mummy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2943",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2943.png",
            "rarity_rank": 526,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spartan Helmet"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1450",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1450.png",
            "rarity_rank": 527,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Royal Crown"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Paw Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0447",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0447.png",
            "rarity_rank": 528,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Leather Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3098",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3098.png",
            "rarity_rank": 529,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2752",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2752.png",
            "rarity_rank": 530,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headset"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0492",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0492.png",
            "rarity_rank": 531,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Two Bracelets"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0978",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0978.png",
            "rarity_rank": 532,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ornate"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spiky Helmet"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Tie"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1222",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1222.png",
            "rarity_rank": 533,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0744",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0744_Pod_174_Burnt_0128.png",
            "rarity_rank": 534,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Big Ears"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Bow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3415",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3415.png",
            "rarity_rank": 535,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Winter Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1216",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1216.png",
            "rarity_rank": 536,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4184",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4184.png",
            "rarity_rank": 537,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spiky Helmet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0499",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0499.png",
            "rarity_rank": 538,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Shield"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1382",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1382.png",
            "rarity_rank": 539,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1269",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1269.png",
            "rarity_rank": 540,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Binoculars"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1464",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1464.png",
            "rarity_rank": 541,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ornate"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1373",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1373.png",
            "rarity_rank": 542,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Paw Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0987",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0987.png",
            "rarity_rank": 543,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1468",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1468.png",
            "rarity_rank": 544,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Painted Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1204",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1204_Pod_374.png",
            "rarity_rank": 545,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Pattern"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 01"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1375",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1375.png",
            "rarity_rank": 546,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0749",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0749_Pod_043.png",
            "rarity_rank": 547,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Gradient"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Reagge Beanie"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1225",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1225.png",
            "rarity_rank": 548,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headband"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Armour"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0914",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0914.png",
            "rarity_rank": 549,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3517",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3517.png",
            "rarity_rank": 550,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Robot Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0480",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0480.png",
            "rarity_rank": 551,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Green"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Red Glow Eyewear"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4462",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4462.png",
            "rarity_rank": 552,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sol Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Neck Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0778",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0778.png",
            "rarity_rank": 553,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Mummy"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3485",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3485.png",
            "rarity_rank": 554,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Crazy Eyes"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0278",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0278.png",
            "rarity_rank": 555,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Solana Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2836",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2836.png",
            "rarity_rank": 556,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4384",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4384.png",
            "rarity_rank": 557,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eye Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Glove"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1174",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1174_Pod_043.png",
            "rarity_rank": 558,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Royal Crown"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1541",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1541.png",
            "rarity_rank": 559,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Armour Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1270",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1270.png",
            "rarity_rank": 560,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Punk"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1072",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1072.png",
            "rarity_rank": 561,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0720",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0720_Pod_488.png",
            "rarity_rank": 562,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Ape"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0116",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0116.png",
            "rarity_rank": 563,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Torn Mouth"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0769",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0769.png",
            "rarity_rank": 564,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Skull"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Tie"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4109",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4109.png",
            "rarity_rank": 565,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headband"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0449",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0449.png",
            "rarity_rank": 566,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Slime"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0150",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0150.png",
            "rarity_rank": 567,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Robocop"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1369",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1369.png",
            "rarity_rank": 568,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Deformed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Royal Crown"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0553",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0553.png",
            "rarity_rank": 569,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Leafy"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Square Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1075",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1075.png",
            "rarity_rank": 570,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Pattern"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1249",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1249.png",
            "rarity_rank": 571,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1284",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1284.png",
            "rarity_rank": 572,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Paw Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0496",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0496.png",
            "rarity_rank": 573,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Smoking Pipe"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2743",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2743.png",
            "rarity_rank": 574,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gem Tiara"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1499",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1499.png",
            "rarity_rank": 575,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Paw Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4172",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4172.png",
            "rarity_rank": 576,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Torn Mouth"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cowboy Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4370",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4370.png",
            "rarity_rank": 577,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Robot Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1347",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1347.png",
            "rarity_rank": 578,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Punk"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3973",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3973.png",
            "rarity_rank": 579,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ornate"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Head Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1322",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1322.png",
            "rarity_rank": 580,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0357",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0357.png",
            "rarity_rank": 581,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Leafy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0931",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0931.png",
            "rarity_rank": 582,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1220",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1220.png",
            "rarity_rank": 583,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3130",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3130.png",
            "rarity_rank": 584,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Red Glow Eyewear"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1481",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1481.png",
            "rarity_rank": 585,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Big Ears"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Tie"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0336",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0336.png",
            "rarity_rank": 586,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Gradient"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Simple Gold Piercing"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1235",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1235.png",
            "rarity_rank": 587,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Leafy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Smoking Pipe"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4118",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4118.png",
            "rarity_rank": 588,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Sunhat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Simple Gold Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1540",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1540.png",
            "rarity_rank": 589,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Binoculars"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Glove"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0488",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0488.png",
            "rarity_rank": 590,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Head Gadget"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1215",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1215.png",
            "rarity_rank": 591,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "White Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0879",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0879.png",
            "rarity_rank": 592,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Mummy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Winter Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4161",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4161.png",
            "rarity_rank": 593,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Head Gadget"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2840",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2840.png",
            "rarity_rank": 594,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Witch Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bow"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1276",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1276.png",
            "rarity_rank": 595,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Girly Lipstick"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Stripe Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0261",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0261.png",
            "rarity_rank": 596,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Baby"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sol Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0830",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0830.png",
            "rarity_rank": 597,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Earring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4235",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4235.png",
            "rarity_rank": 598,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0221",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0221.png",
            "rarity_rank": 599,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 01"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0511",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0511.png",
            "rarity_rank": 600,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4265",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4265.png",
            "rarity_rank": 601,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Crazy Eyes"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Armour"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0971",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0971.png",
            "rarity_rank": 602,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Cape"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1366",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1366.png",
            "rarity_rank": 603,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Parrot"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0361",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0361.png",
            "rarity_rank": 604,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Shield"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1496",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1496.png",
            "rarity_rank": 605,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Big Ears"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3180",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3180.png",
            "rarity_rank": 606,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Armour"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0178",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0178_Pod_250_Burnt_1057.png",
            "rarity_rank": 607,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Winter Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Armour"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1056",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1056.png",
            "rarity_rank": 608,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Pattern"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Head Gadget"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0846",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0846.png",
            "rarity_rank": 609,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Baby"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Sunhat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1212",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1212.png",
            "rarity_rank": 610,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Galactic"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0230",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0230.png",
            "rarity_rank": 611,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Simple Gold Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1032",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1032.png",
            "rarity_rank": 612,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1247",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1247.png",
            "rarity_rank": 613,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0688",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0688.png",
            "rarity_rank": 614,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Exotic"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Red"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4318",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4318.png",
            "rarity_rank": 615,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headband"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Piercings"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1265",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1265.png",
            "rarity_rank": 616,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Deformed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3120",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3120.png",
            "rarity_rank": 617,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1193",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1193_Pod_191.png",
            "rarity_rank": 618,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Piercings"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4134",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4134.png",
            "rarity_rank": 619,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0431",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0431.png",
            "rarity_rank": 620,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Paw"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0743",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0743_Pod_004.png",
            "rarity_rank": 621,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1184",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1184_Pod_095.png",
            "rarity_rank": 622,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moon Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0873",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0873.png",
            "rarity_rank": 623,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3354",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3354.png",
            "rarity_rank": 624,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Head Gadget"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3541",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3541.png",
            "rarity_rank": 625,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Armour"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1169",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1169_Pod_025.png",
            "rarity_rank": 626,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Mummy"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0364",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0364.png",
            "rarity_rank": 627,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Leafy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Smoking Pipe"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3755",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3755.png",
            "rarity_rank": 628,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "White Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Duck Ring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4152",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4152.png",
            "rarity_rank": 629,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3572",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3572.png",
            "rarity_rank": 630,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Crazy Eyes"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Leather Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2860",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2860.png",
            "rarity_rank": 631,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Two Bracelets"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Armour"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0442",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0442.png",
            "rarity_rank": 632,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0435",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0435.png",
            "rarity_rank": 633,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Smoking Pipe"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1214",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1214.png",
            "rarity_rank": 634,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Pattern"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0495",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0495.png",
            "rarity_rank": 635,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Pattern"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Sunhat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0342",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0342.png",
            "rarity_rank": 636,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0865",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0865.png",
            "rarity_rank": 637,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Axe"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3505",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3505.png",
            "rarity_rank": 638,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 01"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0712",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0712.png",
            "rarity_rank": 639,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Tie"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0645",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0645.png",
            "rarity_rank": 640,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Gold Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2101",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2101.png",
            "rarity_rank": 641,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Witch Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moon Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0972",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0972.png",
            "rarity_rank": 642,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Face Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0984",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0984.png",
            "rarity_rank": 643,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Baby"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Axe"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0346",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0346.png",
            "rarity_rank": 644,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Red"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Armour Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0583",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0583.png",
            "rarity_rank": 645,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Winter Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Smoking Pipe"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1444",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1444.png",
            "rarity_rank": 646,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Propeller Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Tie"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1263",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1263.png",
            "rarity_rank": 647,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Girly Lipstick"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0918",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0918.png",
            "rarity_rank": 648,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Armour Gloves"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0576",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0576.png",
            "rarity_rank": 649,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Bow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0474",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0474.png",
            "rarity_rank": 650,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Slime"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0493",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0493.png",
            "rarity_rank": 651,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sailor Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0225",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0225.png",
            "rarity_rank": 652,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Parrot"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Sunhat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0866",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0866.png",
            "rarity_rank": 653,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Green Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Tie"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3562",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3562.png",
            "rarity_rank": 654,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eyepatch"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Smoking Pipe"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0961",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0961.png",
            "rarity_rank": 655,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headband"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eye Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0439",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0439.png",
            "rarity_rank": 656,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Winter Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Shield"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0481",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0481.png",
            "rarity_rank": 657,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Bow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1078",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1078.png",
            "rarity_rank": 658,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0970",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0970.png",
            "rarity_rank": 659,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ornate"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eyepatch"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4124",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4124.png",
            "rarity_rank": 660,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Earring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4092",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4092.png",
            "rarity_rank": 661,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Witch Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4440",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4440.png",
            "rarity_rank": 662,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Binoculars"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0457",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0457.png",
            "rarity_rank": 663,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Exotic"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1079",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1079.png",
            "rarity_rank": 664,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1543",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1543.png",
            "rarity_rank": 665,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Multiple Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1173",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1173_Pod_042.png",
            "rarity_rank": 666,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 01"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1517",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1517.png",
            "rarity_rank": 667,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Redhood"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Green Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1372",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1372.png",
            "rarity_rank": 668,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Parrot"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Binoculars"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0547",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0547.png",
            "rarity_rank": 669,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Tie"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0965",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0965.png",
            "rarity_rank": 670,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Axe"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0789",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0789.png",
            "rarity_rank": 671,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4079",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4079.png",
            "rarity_rank": 672,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 01"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1244",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1244.png",
            "rarity_rank": 673,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3405",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3405.png",
            "rarity_rank": 674,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0526",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0526.png",
            "rarity_rank": 675,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Solana Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4085",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4085.png",
            "rarity_rank": 676,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Skull"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Leather Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0402",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0402.png",
            "rarity_rank": 677,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Slime"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Square Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1467",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1467.png",
            "rarity_rank": 678,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Axe"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0236",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0236.png",
            "rarity_rank": 679,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Royal Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0663",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0663.png",
            "rarity_rank": 680,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0992",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0992.png",
            "rarity_rank": 681,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Baby"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Face Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1029",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1029.png",
            "rarity_rank": 682,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Binoculars"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1545",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1545.png",
            "rarity_rank": 683,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1478",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1478.png",
            "rarity_rank": 684,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1348",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1348.png",
            "rarity_rank": 685,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ornate"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Crazy Eyes"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Leather Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2810",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2810.png",
            "rarity_rank": 686,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0881",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0881.png",
            "rarity_rank": 687,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Baby"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0219",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0219.png",
            "rarity_rank": 688,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headband"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3877",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3877.png",
            "rarity_rank": 689,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Exotic"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Duck Ring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4136",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4136.png",
            "rarity_rank": 690,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4293",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4293.png",
            "rarity_rank": 691,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1473",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1473.png",
            "rarity_rank": 692,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Cape"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4086",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4086.png",
            "rarity_rank": 693,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Banana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0355",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0355.png",
            "rarity_rank": 694,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headband"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Cape"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0896",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0896.png",
            "rarity_rank": 695,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Small Tiara"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0505",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0505.png",
            "rarity_rank": 696,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Paw"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0907",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0907.png",
            "rarity_rank": 697,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0546",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0546.png",
            "rarity_rank": 698,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Leafy"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Slime"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4350",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4350.png",
            "rarity_rank": 699,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Star Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0704",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0704.png",
            "rarity_rank": 700,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3486",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3486.png",
            "rarity_rank": 701,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Banana"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Stripe Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0522",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0522.png",
            "rarity_rank": 702,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bow"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0571",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0571.png",
            "rarity_rank": 703,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Paw"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1290",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1290.png",
            "rarity_rank": 704,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Laser Eyewear"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2757",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2757.png",
            "rarity_rank": 705,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Leather Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2862",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2862.png",
            "rarity_rank": 706,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eye Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0193",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0193_Pod_301_Burnt_2096.png",
            "rarity_rank": 707,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Helmet"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4456",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4456.png",
            "rarity_rank": 708,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sol Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4142",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4142.png",
            "rarity_rank": 709,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Paw Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Armour"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1349",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1349.png",
            "rarity_rank": 710,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Deformed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1500",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1500.png",
            "rarity_rank": 711,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1293",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1293.png",
            "rarity_rank": 712,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Square Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0847",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0847.png",
            "rarity_rank": 713,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Winter Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4433",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4433.png",
            "rarity_rank": 714,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Torn Mouth"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Helmet"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Armour Gloves"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0832",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0832.png",
            "rarity_rank": 715,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4428",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4428.png",
            "rarity_rank": 716,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Star Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Piercings"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1442",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1442.png",
            "rarity_rank": 717,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Green Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0468",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0468.png",
            "rarity_rank": 718,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Pattern"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1907",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1907.png",
            "rarity_rank": 719,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Head Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0924",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0924.png",
            "rarity_rank": 720,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0220",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0220.png",
            "rarity_rank": 721,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4267",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4267.png",
            "rarity_rank": 722,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spiky Helmet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1048",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1048.png",
            "rarity_rank": 723,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Green Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eyepatch"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1448",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1448.png",
            "rarity_rank": 724,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Glove"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4466",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4466.png",
            "rarity_rank": 725,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Smoking Pipe"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1367",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1367.png",
            "rarity_rank": 726,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Parrot"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Apple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0889",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0889.png",
            "rarity_rank": 727,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Armour Gloves"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3594",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3594.png",
            "rarity_rank": 728,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Helmet"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3829",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3829.png",
            "rarity_rank": 729,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1406",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1406.png",
            "rarity_rank": 730,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0269",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0269.png",
            "rarity_rank": 731,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Exotic"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Piercings"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2066",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2066.png",
            "rarity_rank": 732,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "White Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3176",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3176.png",
            "rarity_rank": 733,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spartan Helmet"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2418",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2418.png",
            "rarity_rank": 734,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Galactic"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3450",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3450.png",
            "rarity_rank": 735,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Red Glow Eyewear"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Leather Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1252",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1252.png",
            "rarity_rank": 736,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Slime"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0900",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0900.png",
            "rarity_rank": 737,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Baby"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3021",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3021.png",
            "rarity_rank": 738,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0539",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0539.png",
            "rarity_rank": 739,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Paw"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1416",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1416.png",
            "rarity_rank": 740,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Punk"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Apple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0708",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0708.png",
            "rarity_rank": 741,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Slime"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Bow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4143",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4143.png",
            "rarity_rank": 742,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Small Tiara"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Neck Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1549",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1549.png",
            "rarity_rank": 743,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3750",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3750.png",
            "rarity_rank": 744,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "White Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0264",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0264.png",
            "rarity_rank": 745,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Green"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2739",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2739.png",
            "rarity_rank": 746,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Armour"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3603",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3603.png",
            "rarity_rank": 747,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0764",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0764.png",
            "rarity_rank": 748,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Galactic"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0249",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0249.png",
            "rarity_rank": 749,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Royal Crown"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0506",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0506.png",
            "rarity_rank": 750,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3820",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3820.png",
            "rarity_rank": 751,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Duck Ring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0732",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0732_Pod_001.png",
            "rarity_rank": 752,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Robot Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0976",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0976.png",
            "rarity_rank": 753,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0801",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0801.png",
            "rarity_rank": 754,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Tie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0200",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0200_Pod_286_Burnt_2224.png",
            "rarity_rank": 755,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0384",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0384.png",
            "rarity_rank": 756,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Slime"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0981",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0981.png",
            "rarity_rank": 757,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0206",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0206_Pod_119.png",
            "rarity_rank": 758,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Royal Crown"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4135",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4135.png",
            "rarity_rank": 759,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sailor Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Shield"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2130",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2130.png",
            "rarity_rank": 760,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Head Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1429",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1429.png",
            "rarity_rank": 761,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Glove"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1456",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1456.png",
            "rarity_rank": 762,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Glove"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0501",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0501.png",
            "rarity_rank": 763,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Baby"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Face Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1420",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1420.png",
            "rarity_rank": 764,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Glove"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2883",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2883.png",
            "rarity_rank": 765,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Stripe Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0283",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0283.png",
            "rarity_rank": 766,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Solana Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4158",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4158.png",
            "rarity_rank": 767,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Reagge Beanie"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1261",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1261.png",
            "rarity_rank": 768,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0883",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0883.png",
            "rarity_rank": 769,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spiky Helmet"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0403",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0403.png",
            "rarity_rank": 770,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Leafy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0388",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0388.png",
            "rarity_rank": 771,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0567",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0567.png",
            "rarity_rank": 772,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Bow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1315",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1315.png",
            "rarity_rank": 773,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0707",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0707.png",
            "rarity_rank": 774,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Paw"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0512",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0512.png",
            "rarity_rank": 775,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Reagge Beanie"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Solana Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0268",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0268.png",
            "rarity_rank": 776,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Red"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0903",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0903.png",
            "rarity_rank": 777,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0430",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0430.png",
            "rarity_rank": 778,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spiky Helmet"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Armour Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1507",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1507.png",
            "rarity_rank": 779,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Reagge Beanie"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Crazy Eyes"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1445",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1445.png",
            "rarity_rank": 780,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Reagge Beanie"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4447",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4447.png",
            "rarity_rank": 781,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0329",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0329.png",
            "rarity_rank": 782,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Two Bracelets"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0250",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0250.png",
            "rarity_rank": 783,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1039",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1039.png",
            "rarity_rank": 784,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Exotic"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0440",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0440.png",
            "rarity_rank": 785,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0128",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0128.png",
            "rarity_rank": 786,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3584",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3584.png",
            "rarity_rank": 787,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1472",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1472.png",
            "rarity_rank": 788,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4176",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4176.png",
            "rarity_rank": 789,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Painted Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0524",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0524.png",
            "rarity_rank": 790,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Leafy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Binoculars"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1020",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1020.png",
            "rarity_rank": 791,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Apple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0394",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0394.png",
            "rarity_rank": 792,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Shield"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0154",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0154.png",
            "rarity_rank": 793,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Skull"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1501",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1501.png",
            "rarity_rank": 794,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Big Ears"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0257",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0257.png",
            "rarity_rank": 795,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4514",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4514.png",
            "rarity_rank": 796,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Skull"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Witch Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1342",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1342.png",
            "rarity_rank": 797,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Parrot"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1304",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1304.png",
            "rarity_rank": 798,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1228",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1228.png",
            "rarity_rank": 799,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bow"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1330",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1330.png",
            "rarity_rank": 800,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ornate"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Binoculars"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4113",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4113.png",
            "rarity_rank": 801,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Sunhat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Cape"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1153",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1153.png",
            "rarity_rank": 802,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0323",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0323.png",
            "rarity_rank": 803,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Gradient"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Leather Collar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0963",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0963.png",
            "rarity_rank": 804,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headband"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1250",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1250.png",
            "rarity_rank": 805,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sailor Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4169",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4169.png",
            "rarity_rank": 806,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0570",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0570.png",
            "rarity_rank": 807,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Square Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0284",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0284.png",
            "rarity_rank": 808,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Exotic"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Face Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3457",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3457.png",
            "rarity_rank": 809,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sol Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4257",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4257.png",
            "rarity_rank": 810,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Two Bracelets"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3500",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3500.png",
            "rarity_rank": 811,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sailor Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Paw"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0380",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0380.png",
            "rarity_rank": 812,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Winter Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0467",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0467.png",
            "rarity_rank": 813,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Solana Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0401",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0401.png",
            "rarity_rank": 814,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1548",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1548.png",
            "rarity_rank": 815,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Cape"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0367",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0367.png",
            "rarity_rank": 816,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Square Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2988",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2988.png",
            "rarity_rank": 817,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Crazy Eyes"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Paw"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1088",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1088.png",
            "rarity_rank": 818,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Reagge Beanie"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1121",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1121.png",
            "rarity_rank": 819,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0556",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0556.png",
            "rarity_rank": 820,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Bow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4115",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4115.png",
            "rarity_rank": 821,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Laser Eyewear"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1493",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1493.png",
            "rarity_rank": 822,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Big Ears"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4146",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4146.png",
            "rarity_rank": 823,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0955",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0955.png",
            "rarity_rank": 824,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0868",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0868.png",
            "rarity_rank": 825,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Skull"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4239",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4239.png",
            "rarity_rank": 826,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Red Glow Eyewear"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0706",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0706.png",
            "rarity_rank": 827,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4287",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4287.png",
            "rarity_rank": 828,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Skull"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Painted Hat"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2975",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2975.png",
            "rarity_rank": 829,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Painted Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Neck Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0255",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0255.png",
            "rarity_rank": 830,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Green"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1547",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1547.png",
            "rarity_rank": 831,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3579",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3579.png",
            "rarity_rank": 832,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Green Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1200",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1200_Pod_295.png",
            "rarity_rank": 833,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0502",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0502.png",
            "rarity_rank": 834,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1203",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1203_Pod_325.png",
            "rarity_rank": 835,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3616",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3616.png",
            "rarity_rank": 836,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Reagge Beanie"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0410",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0410.png",
            "rarity_rank": 837,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Collar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4207",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4207.png",
            "rarity_rank": 838,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1038",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1038.png",
            "rarity_rank": 839,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4077",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4077.png",
            "rarity_rank": 840,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Small Tiara"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Bow"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0407",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0407.png",
            "rarity_rank": 841,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Winter Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4345",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4345.png",
            "rarity_rank": 842,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Sunhat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Leather Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4268",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4268.png",
            "rarity_rank": 843,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0811",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0811.png",
            "rarity_rank": 844,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Small Tiara"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1433",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1433.png",
            "rarity_rank": 845,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Armour Shoulders"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0379",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0379.png",
            "rarity_rank": 846,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Winter Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3598",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3598.png",
            "rarity_rank": 847,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Propeller Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0555",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0555.png",
            "rarity_rank": 848,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1392",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1392.png",
            "rarity_rank": 849,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Parrot"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Leather Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1291",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1291.png",
            "rarity_rank": 850,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Head Gadget"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0324",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0324.png",
            "rarity_rank": 851,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Two Bracelets"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0736",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0736.png",
            "rarity_rank": 852,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Green"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Solana Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0613",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0613.png",
            "rarity_rank": 853,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Shield"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1439",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1439.png",
            "rarity_rank": 854,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ornate"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Sunhat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3449",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3449.png",
            "rarity_rank": 855,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Witch Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1258",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1258.png",
            "rarity_rank": 856,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Punk"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0996",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0996.png",
            "rarity_rank": 857,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0340",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0340.png",
            "rarity_rank": 858,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Gloves"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3225",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3225.png",
            "rarity_rank": 859,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cowboy Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3613",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3613.png",
            "rarity_rank": 860,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sailor Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1259",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1259.png",
            "rarity_rank": 861,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Armour Shoulders"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1447",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1447.png",
            "rarity_rank": 862,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Glove"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3471",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3471.png",
            "rarity_rank": 863,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spiky Helmet"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0809",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0809.png",
            "rarity_rank": 864,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Mummy"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0566",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0566.png",
            "rarity_rank": 865,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Winter Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1486",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1486.png",
            "rarity_rank": 866,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1318",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1318.png",
            "rarity_rank": 867,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ornate"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Small Tiara"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1324",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1324.png",
            "rarity_rank": 868,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Stripe Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Paw Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1057",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1057.png",
            "rarity_rank": 869,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Helmet"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1123",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1123.png",
            "rarity_rank": 870,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Redhood"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1004",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1004.png",
            "rarity_rank": 871,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0113",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0113.png",
            "rarity_rank": 872,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moon Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3874",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3874.png",
            "rarity_rank": 873,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3463",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3463.png",
            "rarity_rank": 874,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Band"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0550",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0550.png",
            "rarity_rank": 875,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Tie"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1371",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1371.png",
            "rarity_rank": 876,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3658",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3658.png",
            "rarity_rank": 877,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Duck Ring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0158",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0158_Pod_309_Burnt_0004.png",
            "rarity_rank": 878,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Multiple Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0765",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0765.png",
            "rarity_rank": 879,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Green Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4116",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4116.png",
            "rarity_rank": 880,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Propeller Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Stripe Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1352",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1352.png",
            "rarity_rank": 881,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Parrot"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0902",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0902.png",
            "rarity_rank": 882,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0231",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0231.png",
            "rarity_rank": 883,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Pattern"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sailor Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2717",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2717.png",
            "rarity_rank": 884,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Smoking Pipe"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0542",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0542.png",
            "rarity_rank": 885,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1119",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1119.png",
            "rarity_rank": 886,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Skull"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3546",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3546.png",
            "rarity_rank": 887,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spiky Helmet"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Armour"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4181",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4181.png",
            "rarity_rank": 888,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cowboy Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0703",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0703.png",
            "rarity_rank": 889,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3426",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3426.png",
            "rarity_rank": 890,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Stripe Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Paw Bandage"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2959",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2959.png",
            "rarity_rank": 891,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 01"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0528",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0528.png",
            "rarity_rank": 892,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1430",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1430.png",
            "rarity_rank": 893,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ornate"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0791",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0791.png",
            "rarity_rank": 894,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sol Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2808",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2808.png",
            "rarity_rank": 895,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Sunhat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Piercings"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3108",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3108.png",
            "rarity_rank": 896,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3701",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3701.png",
            "rarity_rank": 897,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Star Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0943",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0943.png",
            "rarity_rank": 898,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0354",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0354.png",
            "rarity_rank": 899,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Torn Mouth"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0781",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0781.png",
            "rarity_rank": 900,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4436",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4436.png",
            "rarity_rank": 901,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1028",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1028.png",
            "rarity_rank": 902,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sailor Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0500",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0500.png",
            "rarity_rank": 903,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3857",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3857.png",
            "rarity_rank": 904,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Galactic"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1403",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1403.png",
            "rarity_rank": 905,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1071",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1071.png",
            "rarity_rank": 906,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Tie"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0543",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0543.png",
            "rarity_rank": 907,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2837",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2837.png",
            "rarity_rank": 908,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Apple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0766",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0766.png",
            "rarity_rank": 909,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Banana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3891",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3891.png",
            "rarity_rank": 910,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "White Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3086",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3086.png",
            "rarity_rank": 911,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Propeller Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4070",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4070.png",
            "rarity_rank": 912,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cowboy Hat"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1453",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1453.png",
            "rarity_rank": 913,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Girly Lipstick"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1568",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1568.png",
            "rarity_rank": 914,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3483",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3483.png",
            "rarity_rank": 915,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Square Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3420",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3420.png",
            "rarity_rank": 916,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Propeller Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Two Bracelets"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0144",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0144.png",
            "rarity_rank": 917,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Witch Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3945",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3945.png",
            "rarity_rank": 918,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4107",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4107.png",
            "rarity_rank": 919,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Armour Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0201",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0201_Pod_052_Burnt_2191.png",
            "rarity_rank": 920,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Helmet"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0574",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0574.png",
            "rarity_rank": 921,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0365",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0365.png",
            "rarity_rank": 922,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Armour Gloves"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0182",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0182_Pod_089.png",
            "rarity_rank": 923,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Exotic"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4472",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4472.png",
            "rarity_rank": 924,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Glove"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0777",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0777.png",
            "rarity_rank": 925,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Star Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0389",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0389.png",
            "rarity_rank": 926,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Reagge Beanie"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Simple Gold Piercing"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0358",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0358.png",
            "rarity_rank": 927,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4400",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4400.png",
            "rarity_rank": 928,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Simple Gold Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Armour"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4252",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4252.png",
            "rarity_rank": 929,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Head Gadget"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0856",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0856.png",
            "rarity_rank": 930,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Small Tiara"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0538",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0538.png",
            "rarity_rank": 931,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1091",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1091.png",
            "rarity_rank": 932,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Baby"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4185",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4185.png",
            "rarity_rank": 933,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Torn Mouth"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2921",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2921.png",
            "rarity_rank": 934,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Axe"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1132",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1132.png",
            "rarity_rank": 935,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0265",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0265.png",
            "rarity_rank": 936,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Star Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4123",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4123.png",
            "rarity_rank": 937,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0536",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0536.png",
            "rarity_rank": 938,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Square Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1046",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1046.png",
            "rarity_rank": 939,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Mummy"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1443",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1443.png",
            "rarity_rank": 940,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4063",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4063.png",
            "rarity_rank": 941,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ice Cream"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Tie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0582",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0582.png",
            "rarity_rank": 942,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0623",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0623.png",
            "rarity_rank": 943,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Simple Gold Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0728",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0728_Pod_437.png",
            "rarity_rank": 944,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headband"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Armour"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1192",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1192_Pod_164_Burnt_2213.png",
            "rarity_rank": 945,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1329",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1329.png",
            "rarity_rank": 946,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eyepatch"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0941",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0941.png",
            "rarity_rank": 947,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Big Ears"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Royal Crown"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4377",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4377.png",
            "rarity_rank": 948,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Propeller Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0484",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0484.png",
            "rarity_rank": 949,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Leafy"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Solana Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3561",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3561.png",
            "rarity_rank": 950,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spartan Helmet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0606",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0606.png",
            "rarity_rank": 951,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Paw"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3591",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3591.png",
            "rarity_rank": 952,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0945",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0945.png",
            "rarity_rank": 953,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3462",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3462.png",
            "rarity_rank": 954,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Simple Gold Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1354",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1354.png",
            "rarity_rank": 955,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1379",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1379.png",
            "rarity_rank": 956,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1008",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1008.png",
            "rarity_rank": 957,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headband"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1353",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1353.png",
            "rarity_rank": 958,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0461",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0461.png",
            "rarity_rank": 959,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Leafy"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1041",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1041.png",
            "rarity_rank": 960,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3000",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3000.png",
            "rarity_rank": 961,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Royal Crown"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1565",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1565.png",
            "rarity_rank": 962,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Multiple Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0792",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0792.png",
            "rarity_rank": 963,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3501",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3501.png",
            "rarity_rank": 964,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Sunhat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Bow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3521",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3521.png",
            "rarity_rank": 965,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0135",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0135.png",
            "rarity_rank": 966,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Witch Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0948",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0948.png",
            "rarity_rank": 967,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Royal Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4340",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4340.png",
            "rarity_rank": 968,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Banana"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2813",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2813.png",
            "rarity_rank": 969,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Paw"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2903",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2903.png",
            "rarity_rank": 970,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Head Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1106",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1106.png",
            "rarity_rank": 971,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Skull"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1586",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1586.png",
            "rarity_rank": 972,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Apple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1328",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1328.png",
            "rarity_rank": 973,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ornate"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1393",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1393.png",
            "rarity_rank": 974,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Apple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Leather Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3499",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3499.png",
            "rarity_rank": 975,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bow"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3810",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3810.png",
            "rarity_rank": 976,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Mummy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4174",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4174.png",
            "rarity_rank": 977,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2795",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2795.png",
            "rarity_rank": 978,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Royal Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Bracelet"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0808",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0808.png",
            "rarity_rank": 979,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sailor Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1341",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1341.png",
            "rarity_rank": 980,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ornate"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Small Tiara"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2719",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2719.png",
            "rarity_rank": 981,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sailor Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4178",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4178.png",
            "rarity_rank": 982,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Redhood"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0835",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0835.png",
            "rarity_rank": 983,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Skull"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1131",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1131.png",
            "rarity_rank": 984,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Helmet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3266",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3266.png",
            "rarity_rank": 985,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Shield"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4328",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4328.png",
            "rarity_rank": 986,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2781",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2781.png",
            "rarity_rank": 987,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Bracelet"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0823",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0823.png",
            "rarity_rank": 988,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Skull"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0287",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0287.png",
            "rarity_rank": 989,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Gold Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3605",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3605.png",
            "rarity_rank": 990,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Reagge Beanie"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3577",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3577.png",
            "rarity_rank": 991,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Gloves"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0145",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0145.png",
            "rarity_rank": 992,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moon Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3289",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3289.png",
            "rarity_rank": 993,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Helmet"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4127",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4127.png",
            "rarity_rank": 994,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0788",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0788.png",
            "rarity_rank": 995,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Mummy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2246",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2246.png",
            "rarity_rank": 996,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Multiple Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1288",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1288.png",
            "rarity_rank": 997,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2198",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2198.png",
            "rarity_rank": 998,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1024",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1024.png",
            "rarity_rank": 999,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0580",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0580.png",
            "rarity_rank": 1000,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Bow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0366",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0366.png",
            "rarity_rank": 1001,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0227",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0227.png",
            "rarity_rank": 1002,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eye Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3774",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3774.png",
            "rarity_rank": 1003,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eye Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0891",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0891.png",
            "rarity_rank": 1004,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Royal Crown"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Armour"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1477",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1477.png",
            "rarity_rank": 1005,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4311",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4311.png",
            "rarity_rank": 1006,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Torn Mouth"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4164",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4164.png",
            "rarity_rank": 1007,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Piercings"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3262",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3262.png",
            "rarity_rank": 1008,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Laser Eyewear"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Tie"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4280",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4280.png",
            "rarity_rank": 1009,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headband"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Laser Eyewear"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3681",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3681.png",
            "rarity_rank": 1010,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "White Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0373",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0373.png",
            "rarity_rank": 1011,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1018",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1018.png",
            "rarity_rank": 1012,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hair"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0285",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0285.png",
            "rarity_rank": 1013,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Piercings"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4378",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4378.png",
            "rarity_rank": 1014,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Winter Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2954",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2954.png",
            "rarity_rank": 1015,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Head Gadget"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3581",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3581.png",
            "rarity_rank": 1016,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Piercings"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1465",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1465.png",
            "rarity_rank": 1017,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0909",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0909.png",
            "rarity_rank": 1018,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Mummy"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0601",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0601.png",
            "rarity_rank": 1019,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Bow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4019",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4019.png",
            "rarity_rank": 1020,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eye Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0592",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0592.png",
            "rarity_rank": 1021,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3767",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3767.png",
            "rarity_rank": 1022,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Duck Ring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0966",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0966.png",
            "rarity_rank": 1023,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ornate"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Banana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0563",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0563.png",
            "rarity_rank": 1024,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0307",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0307.png",
            "rarity_rank": 1025,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Winter Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3243",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3243.png",
            "rarity_rank": 1026,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0864",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0864.png",
            "rarity_rank": 1027,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Reagge Beanie"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0958",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0958.png",
            "rarity_rank": 1028,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Exotic"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0753",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0753.png",
            "rarity_rank": 1029,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Propeller Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0916",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0916.png",
            "rarity_rank": 1030,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0381",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0381.png",
            "rarity_rank": 1031,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2731",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2731.png",
            "rarity_rank": 1032,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Propeller Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0825",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0825.png",
            "rarity_rank": 1033,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2799",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2799.png",
            "rarity_rank": 1034,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Green Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0419",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0419.png",
            "rarity_rank": 1035,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2815",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2815.png",
            "rarity_rank": 1036,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Crazy Eyes"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4083",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4083.png",
            "rarity_rank": 1037,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2802",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2802.png",
            "rarity_rank": 1038,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Helmet"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1002",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1002.png",
            "rarity_rank": 1039,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0418",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0418.png",
            "rarity_rank": 1040,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1384",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1384.png",
            "rarity_rank": 1041,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0983",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0983.png",
            "rarity_rank": 1042,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Axe"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3660",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3660.png",
            "rarity_rank": 1043,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Mummy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Duck Ring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0487",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0487.png",
            "rarity_rank": 1044,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0622",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0622.png",
            "rarity_rank": 1045,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4130",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4130.png",
            "rarity_rank": 1046,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cowboy Hat"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0443",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0443.png",
            "rarity_rank": 1047,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Leafy"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Winter Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1011",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1011.png",
            "rarity_rank": 1048,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2926",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2926.png",
            "rarity_rank": 1049,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3978",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3978.png",
            "rarity_rank": 1050,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0273",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0273.png",
            "rarity_rank": 1051,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1414",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1414.png",
            "rarity_rank": 1052,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3508",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3508.png",
            "rarity_rank": 1053,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Helmet"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0716",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0716.png",
            "rarity_rank": 1054,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3663",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3663.png",
            "rarity_rank": 1055,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1344",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1344.png",
            "rarity_rank": 1056,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0771",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0771.png",
            "rarity_rank": 1057,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3442",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3442.png",
            "rarity_rank": 1058,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Leather Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0478",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0478.png",
            "rarity_rank": 1059,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0233",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0233.png",
            "rarity_rank": 1060,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Star Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Solana Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1285",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1285.png",
            "rarity_rank": 1061,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3617",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3617.png",
            "rarity_rank": 1062,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Head Gadget"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0483",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0483.png",
            "rarity_rank": 1063,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Leafy"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3733",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3733.png",
            "rarity_rank": 1064,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Pattern"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4314",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4314.png",
            "rarity_rank": 1065,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0290",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0290.png",
            "rarity_rank": 1066,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Head Gadget"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3221",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3221.png",
            "rarity_rank": 1067,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Propeller Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1277",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1277.png",
            "rarity_rank": 1068,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4106",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4106.png",
            "rarity_rank": 1069,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4061",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4061.png",
            "rarity_rank": 1070,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ice Cream"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1966",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1966.png",
            "rarity_rank": 1071,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Teeth"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1005",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1005.png",
            "rarity_rank": 1072,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0207",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0207_Pod_006.png",
            "rarity_rank": 1073,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0159",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0159_Pod_019.png",
            "rarity_rank": 1074,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Multiple Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Sunhat"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4110",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4110.png",
            "rarity_rank": 1075,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4126",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4126.png",
            "rarity_rank": 1076,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headband"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4305",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4305.png",
            "rarity_rank": 1077,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0398",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0398.png",
            "rarity_rank": 1078,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 01"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4451",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4451.png",
            "rarity_rank": 1079,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Axe"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1043",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1043.png",
            "rarity_rank": 1080,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0308",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0308.png",
            "rarity_rank": 1081,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Slime"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1227",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1227.png",
            "rarity_rank": 1082,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1469",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1469.png",
            "rarity_rank": 1083,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3331",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3331.png",
            "rarity_rank": 1084,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Earring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4170",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4170.png",
            "rarity_rank": 1085,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3413",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3413.png",
            "rarity_rank": 1086,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Shield"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0351",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0351.png",
            "rarity_rank": 1087,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0331",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0331.png",
            "rarity_rank": 1088,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Square Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3872",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3872.png",
            "rarity_rank": 1089,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Exotic"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2844",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2844.png",
            "rarity_rank": 1090,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Red Glow Eyewear"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0584",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0584.png",
            "rarity_rank": 1091,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2896",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2896.png",
            "rarity_rank": 1092,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1081",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1081.png",
            "rarity_rank": 1093,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Small Tiara"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0793",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0793.png",
            "rarity_rank": 1094,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Apple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0614",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0614.png",
            "rarity_rank": 1095,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3334",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3334.png",
            "rarity_rank": 1096,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Shield"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3548",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3548.png",
            "rarity_rank": 1097,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Collar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0610",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0610.png",
            "rarity_rank": 1098,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0343",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0343.png",
            "rarity_rank": 1099,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Paw"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2822",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2822.png",
            "rarity_rank": 1100,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Bracelet"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4137",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4137.png",
            "rarity_rank": 1101,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1022",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1022.png",
            "rarity_rank": 1102,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Baby"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Square Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2393",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2393.png",
            "rarity_rank": 1103,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Galactic"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3487",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3487.png",
            "rarity_rank": 1104,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Glove"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1111",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1111.png",
            "rarity_rank": 1105,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sailor Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1359",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1359.png",
            "rarity_rank": 1106,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1356",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1356.png",
            "rarity_rank": 1107,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2874",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2874.png",
            "rarity_rank": 1108,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Banana"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0829",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0829.png",
            "rarity_rank": 1109,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4545",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4545.png",
            "rarity_rank": 1110,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moon Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0807",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0807.png",
            "rarity_rank": 1111,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Baby"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Green Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1583",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1583.png",
            "rarity_rank": 1112,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3445",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3445.png",
            "rarity_rank": 1113,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Reagge Beanie"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2735",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2735.png",
            "rarity_rank": 1114,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headband"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Star Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0437",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0437.png",
            "rarity_rank": 1115,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Shield"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1226",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1226.png",
            "rarity_rank": 1116,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0799",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0799.png",
            "rarity_rank": 1117,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Baby"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Green Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0979",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0979.png",
            "rarity_rank": 1118,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Multiple Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4542",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4542.png",
            "rarity_rank": 1119,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3504",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3504.png",
            "rarity_rank": 1120,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Small Tiara"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Star Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1254",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1254.png",
            "rarity_rank": 1121,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Parrot"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0985",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0985.png",
            "rarity_rank": 1122,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2760",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2760.png",
            "rarity_rank": 1123,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0460",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0460.png",
            "rarity_rank": 1124,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Smoking Pipe"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0376",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0376.png",
            "rarity_rank": 1125,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Leafy"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0812",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0812.png",
            "rarity_rank": 1126,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Binoculars"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0690",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0690.png",
            "rarity_rank": 1127,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Gold Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0861",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0861.png",
            "rarity_rank": 1128,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Skull"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Royal Crown"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3618",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3618.png",
            "rarity_rank": 1129,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Green Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0450",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0450.png",
            "rarity_rank": 1130,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2979",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2979.png",
            "rarity_rank": 1131,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Helmet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1287",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1287.png",
            "rarity_rank": 1132,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3606",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3606.png",
            "rarity_rank": 1133,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Star Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0503",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0503.png",
            "rarity_rank": 1134,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Slime"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3555",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3555.png",
            "rarity_rank": 1135,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Slime"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1561",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1561.png",
            "rarity_rank": 1136,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0332",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0332.png",
            "rarity_rank": 1137,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 01"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0925",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0925.png",
            "rarity_rank": 1138,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Red Glow Eyewear"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3574",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3574.png",
            "rarity_rank": 1139,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Crazy Eyes"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Armour"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1387",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1387.png",
            "rarity_rank": 1140,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0451",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0451.png",
            "rarity_rank": 1141,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Smoking Pipe"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4163",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4163.png",
            "rarity_rank": 1142,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Laser Eyewear"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1241",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1241.png",
            "rarity_rank": 1143,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3467",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3467.png",
            "rarity_rank": 1144,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sailor Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Armour Shoulders"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3280",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3280.png",
            "rarity_rank": 1145,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Square Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3006",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3006.png",
            "rarity_rank": 1146,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Paw Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1346",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1346.png",
            "rarity_rank": 1147,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Paw Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0949",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0949.png",
            "rarity_rank": 1148,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0339",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0339.png",
            "rarity_rank": 1149,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Square Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0534",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0534.png",
            "rarity_rank": 1150,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Leafy"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Apple"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0530",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0530.png",
            "rarity_rank": 1151,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0224",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0224.png",
            "rarity_rank": 1152,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1148",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1148.png",
            "rarity_rank": 1153,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Propeller Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0408",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0408.png",
            "rarity_rank": 1154,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1557",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1557.png",
            "rarity_rank": 1155,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0226",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0226.png",
            "rarity_rank": 1156,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Pattern"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0318",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0318.png",
            "rarity_rank": 1157,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Solana Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4388",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4388.png",
            "rarity_rank": 1158,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Banana"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1219",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1219.png",
            "rarity_rank": 1159,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0951",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0951.png",
            "rarity_rank": 1160,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1003",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1003.png",
            "rarity_rank": 1161,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3187",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3187.png",
            "rarity_rank": 1162,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 01"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0885",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0885.png",
            "rarity_rank": 1163,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headband"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3215",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3215.png",
            "rarity_rank": 1164,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0911",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0911.png",
            "rarity_rank": 1165,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Stripe Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3514",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3514.png",
            "rarity_rank": 1166,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0870",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0870.png",
            "rarity_rank": 1167,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "White Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3588",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3588.png",
            "rarity_rank": 1168,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Reagge Beanie"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2235",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2235.png",
            "rarity_rank": 1169,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3489",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3489.png",
            "rarity_rank": 1170,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Bow"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1495",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1495.png",
            "rarity_rank": 1171,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3620",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3620.png",
            "rarity_rank": 1172,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0385",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0385.png",
            "rarity_rank": 1173,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0246",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0246.png",
            "rarity_rank": 1174,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0959",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0959.png",
            "rarity_rank": 1175,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Propeller Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0314",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0314.png",
            "rarity_rank": 1176,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Two Bracelets"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3440",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3440.png",
            "rarity_rank": 1177,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Slime"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1145",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1145.png",
            "rarity_rank": 1178,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1327",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1327.png",
            "rarity_rank": 1179,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3940",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3940.png",
            "rarity_rank": 1180,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0514",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0514.png",
            "rarity_rank": 1181,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0964",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0964.png",
            "rarity_rank": 1182,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Axe"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2722",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2722.png",
            "rarity_rank": 1183,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Propeller Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0975",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0975.png",
            "rarity_rank": 1184,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Mummy"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1363",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1363.png",
            "rarity_rank": 1185,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Apple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2801",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2801.png",
            "rarity_rank": 1186,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Tie"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0851",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0851.png",
            "rarity_rank": 1187,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Helmet"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3465",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3465.png",
            "rarity_rank": 1188,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Banana"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Laser Eyewear"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3127",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3127.png",
            "rarity_rank": 1189,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Laser Eyewear"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Bow"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3609",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3609.png",
            "rarity_rank": 1190,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Reagge Beanie"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Paw"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0843",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0843.png",
            "rarity_rank": 1191,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0228",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0228.png",
            "rarity_rank": 1192,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Parrot"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sailor Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1331",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1331.png",
            "rarity_rank": 1193,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0938",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0938.png",
            "rarity_rank": 1194,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0814",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0814.png",
            "rarity_rank": 1195,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Banana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Tie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0569",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0569.png",
            "rarity_rank": 1196,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Solana Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3172",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3172.png",
            "rarity_rank": 1197,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2894",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2894.png",
            "rarity_rank": 1198,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3206",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3206.png",
            "rarity_rank": 1199,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0752",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0752.png",
            "rarity_rank": 1200,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Reagge Beanie"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0946",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0946.png",
            "rarity_rank": 1201,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1097",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1097.png",
            "rarity_rank": 1202,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Exotic"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1286",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1286.png",
            "rarity_rank": 1203,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Parrot"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4320",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4320.png",
            "rarity_rank": 1204,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3556",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3556.png",
            "rarity_rank": 1205,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Star Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Armour"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1274",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1274.png",
            "rarity_rank": 1206,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headband"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3451",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3451.png",
            "rarity_rank": 1207,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Royal Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1516",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1516.png",
            "rarity_rank": 1208,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1068",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1068.png",
            "rarity_rank": 1209,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0819",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0819.png",
            "rarity_rank": 1210,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Helmet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3029",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3029.png",
            "rarity_rank": 1211,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Sunhat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Tie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0243",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0243.png",
            "rarity_rank": 1212,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1537",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1537.png",
            "rarity_rank": 1213,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4108",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4108.png",
            "rarity_rank": 1214,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4418",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4418.png",
            "rarity_rank": 1215,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eye Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3390",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3390.png",
            "rarity_rank": 1216,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Two Bracelets"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0370",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0370.png",
            "rarity_rank": 1217,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Solana Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0441",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0441.png",
            "rarity_rank": 1218,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1189",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1189_Pod_145.png",
            "rarity_rank": 1219,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Binoculars"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0386",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0386.png",
            "rarity_rank": 1220,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Smoking Pipe"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1361",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1361.png",
            "rarity_rank": 1221,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Parrot"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Armour Shoulders"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0455",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0455.png",
            "rarity_rank": 1222,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Leafy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2817",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2817.png",
            "rarity_rank": 1223,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Apple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Piercings"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2930",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2930.png",
            "rarity_rank": 1224,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Head Gadget"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Armour Gloves"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0635",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0635.png",
            "rarity_rank": 1225,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Baby"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Leather Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3142",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3142.png",
            "rarity_rank": 1226,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3051",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3051.png",
            "rarity_rank": 1227,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Shield"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3270",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3270.png",
            "rarity_rank": 1228,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Head Gadget"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Glove"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1167",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1167_Pod_013.png",
            "rarity_rank": 1229,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cowboy Hat"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2842",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2842.png",
            "rarity_rank": 1230,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Bow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1482",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1482.png",
            "rarity_rank": 1231,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0445",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0445.png",
            "rarity_rank": 1232,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0700",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0700.png",
            "rarity_rank": 1233,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1452",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1452.png",
            "rarity_rank": 1234,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Sunhat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2236",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2236.png",
            "rarity_rank": 1235,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1340",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1340.png",
            "rarity_rank": 1236,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3566",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3566.png",
            "rarity_rank": 1237,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Helmet"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3723",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3723.png",
            "rarity_rank": 1238,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Duck Ring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0390",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0390.png",
            "rarity_rank": 1239,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3165",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3165.png",
            "rarity_rank": 1240,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Simple Gold Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1415",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1415.png",
            "rarity_rank": 1241,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0692",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0692.png",
            "rarity_rank": 1242,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Armour Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1374",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1374.png",
            "rarity_rank": 1243,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0504",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0504.png",
            "rarity_rank": 1244,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Paw"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0748",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0748_Pod_534.png",
            "rarity_rank": 1245,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Skull"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4160",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4160.png",
            "rarity_rank": 1246,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Banana"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2254",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2254.png",
            "rarity_rank": 1247,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Big Ears"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4129",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4129.png",
            "rarity_rank": 1248,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Bow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3132",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3132.png",
            "rarity_rank": 1249,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Binoculars"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1157",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1157.png",
            "rarity_rank": 1250,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4122",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4122.png",
            "rarity_rank": 1251,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Skull"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Hair"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1365",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1365.png",
            "rarity_rank": 1252,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3913",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3913.png",
            "rarity_rank": 1253,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3469",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3469.png",
            "rarity_rank": 1254,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Slime"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3275",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3275.png",
            "rarity_rank": 1255,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Smoking Pipe"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0518",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0518.png",
            "rarity_rank": 1256,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Paw"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0922",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0922.png",
            "rarity_rank": 1257,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0429",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0429.png",
            "rarity_rank": 1258,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0805",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0805.png",
            "rarity_rank": 1259,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0671",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0671.png",
            "rarity_rank": 1260,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Piercings"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0822",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0822.png",
            "rarity_rank": 1261,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Royal Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0459",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0459.png",
            "rarity_rank": 1262,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Bow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0915",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0915.png",
            "rarity_rank": 1263,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1236",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1236.png",
            "rarity_rank": 1264,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Sunhat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0248",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0248.png",
            "rarity_rank": 1265,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Simple Gold Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1449",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1449.png",
            "rarity_rank": 1266,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Reagge Beanie"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1049",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1049.png",
            "rarity_rank": 1267,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3097",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3097.png",
            "rarity_rank": 1268,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Axe"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Leather Collar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3464",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3464.png",
            "rarity_rank": 1269,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Apple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Paw"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0510",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0510.png",
            "rarity_rank": 1270,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0548",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0548.png",
            "rarity_rank": 1271,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2308",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2308.png",
            "rarity_rank": 1272,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0417",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0417.png",
            "rarity_rank": 1273,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moon Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1422",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1422.png",
            "rarity_rank": 1274,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Reagge Beanie"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1042",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1042.png",
            "rarity_rank": 1275,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Head Gadget"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1223",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1223.png",
            "rarity_rank": 1276,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Skull"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3294",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3294.png",
            "rarity_rank": 1277,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1431",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1431.png",
            "rarity_rank": 1278,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ornate"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3852",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3852.png",
            "rarity_rank": 1279,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Baby"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3558",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3558.png",
            "rarity_rank": 1280,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Laser Eyewear"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2763",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2763.png",
            "rarity_rank": 1281,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Piercings"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0562",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0562.png",
            "rarity_rank": 1282,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2249",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2249.png",
            "rarity_rank": 1283,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0912",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0912.png",
            "rarity_rank": 1284,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Binoculars"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0988",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0988.png",
            "rarity_rank": 1285,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0867",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0867.png",
            "rarity_rank": 1286,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0448",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0448.png",
            "rarity_rank": 1287,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headband"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3621",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3621.png",
            "rarity_rank": 1288,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headband"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0901",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0901.png",
            "rarity_rank": 1289,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Big Ears"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Face Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2905",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2905.png",
            "rarity_rank": 1290,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Reagge Beanie"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4006",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4006.png",
            "rarity_rank": 1291,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ornate"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2984",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2984.png",
            "rarity_rank": 1292,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Stripe Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0241",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0241.png",
            "rarity_rank": 1293,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Solana Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1383",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1383.png",
            "rarity_rank": 1294,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4319",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4319.png",
            "rarity_rank": 1295,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Leather Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4427",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4427.png",
            "rarity_rank": 1296,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2007",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2007.png",
            "rarity_rank": 1297,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eyepatch"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3057",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3057.png",
            "rarity_rank": 1298,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Shield"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0360",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0360.png",
            "rarity_rank": 1299,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Tie"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4301",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4301.png",
            "rarity_rank": 1300,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Winter Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0458",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0458.png",
            "rarity_rank": 1301,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1389",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1389.png",
            "rarity_rank": 1302,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Sunhat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0874",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0874.png",
            "rarity_rank": 1303,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Pattern"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3054",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3054.png",
            "rarity_rank": 1304,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Smoking Pipe"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2746",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2746.png",
            "rarity_rank": 1305,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Leather Collar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1025",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1025.png",
            "rarity_rank": 1306,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Multiple Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4190",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4190.png",
            "rarity_rank": 1307,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2941",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2941.png",
            "rarity_rank": 1308,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4335",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4335.png",
            "rarity_rank": 1309,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headband"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0980",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0980.png",
            "rarity_rank": 1310,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Stripe Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0989",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0989.png",
            "rarity_rank": 1311,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Baby"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4029",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4029.png",
            "rarity_rank": 1312,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0998",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0998.png",
            "rarity_rank": 1313,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Head Gadget"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3551",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3551.png",
            "rarity_rank": 1314,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Small Tiara"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Armour"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4189",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4189.png",
            "rarity_rank": 1315,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Skull"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3664",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3664.png",
            "rarity_rank": 1316,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Duck Ring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0913",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0913.png",
            "rarity_rank": 1317,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Propeller Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0350",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0350.png",
            "rarity_rank": 1318,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Paw"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3540",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3540.png",
            "rarity_rank": 1319,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3544",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3544.png",
            "rarity_rank": 1320,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0212",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0212_Pod_108_Burnt_1308.png",
            "rarity_rank": 1321,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0434",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0434.png",
            "rarity_rank": 1322,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Solana Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3476",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3476.png",
            "rarity_rank": 1323,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Armour Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3693",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3693.png",
            "rarity_rank": 1324,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Duck Ring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1470",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1470.png",
            "rarity_rank": 1325,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Glove"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1784",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1784.png",
            "rarity_rank": 1326,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Teeth"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3592",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3592.png",
            "rarity_rank": 1327,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0838",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0838.png",
            "rarity_rank": 1328,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0282",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0282.png",
            "rarity_rank": 1329,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Simple Gold Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0644",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0644.png",
            "rarity_rank": 1330,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1086",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1086.png",
            "rarity_rank": 1331,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Baby"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0893",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0893.png",
            "rarity_rank": 1332,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Green Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0767",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0767.png",
            "rarity_rank": 1333,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0897",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0897.png",
            "rarity_rank": 1334,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Royal Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4398",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4398.png",
            "rarity_rank": 1335,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Helmet"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3980",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3980.png",
            "rarity_rank": 1336,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ornate"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3610",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3610.png",
            "rarity_rank": 1337,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Winter Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1267",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1267.png",
            "rarity_rank": 1338,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Royal Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0216",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0216_Pod_222_Burnt_1771.png",
            "rarity_rank": 1339,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0525",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0525.png",
            "rarity_rank": 1340,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Solana Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0130",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0130.png",
            "rarity_rank": 1341,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0529",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0529.png",
            "rarity_rank": 1342,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4138",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4138.png",
            "rarity_rank": 1343,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0950",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0950.png",
            "rarity_rank": 1344,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Multiple Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0412",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0412.png",
            "rarity_rank": 1345,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0472",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0472.png",
            "rarity_rank": 1346,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0969",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0969.png",
            "rarity_rank": 1347,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0786",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0786.png",
            "rarity_rank": 1348,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Face Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3639",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3639.png",
            "rarity_rank": 1349,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0462",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0462.png",
            "rarity_rank": 1350,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3615",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3615.png",
            "rarity_rank": 1351,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1009",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1009.png",
            "rarity_rank": 1352,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Head Gadget"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3697",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3697.png",
            "rarity_rank": 1353,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Girly Lipstick"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0204",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0204_Pod_051.png",
            "rarity_rank": 1354,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Stripe Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Paw"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3345",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3345.png",
            "rarity_rank": 1355,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sailor Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3694",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3694.png",
            "rarity_rank": 1356,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Duck Ring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3676",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3676.png",
            "rarity_rank": 1357,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0894",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0894.png",
            "rarity_rank": 1358,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Face Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0854",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0854.png",
            "rarity_rank": 1359,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3389",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3389.png",
            "rarity_rank": 1360,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2440",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2440.png",
            "rarity_rank": 1361,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Multiple Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3932",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3932.png",
            "rarity_rank": 1362,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1275",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1275.png",
            "rarity_rank": 1363,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0372",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0372.png",
            "rarity_rank": 1364,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Solana Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0378",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0378.png",
            "rarity_rank": 1365,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0341",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0341.png",
            "rarity_rank": 1366,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0513",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0513.png",
            "rarity_rank": 1367,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2848",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2848.png",
            "rarity_rank": 1368,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Stripe Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2218",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2218.png",
            "rarity_rank": 1369,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Big Ears"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3901",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3901.png",
            "rarity_rank": 1370,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0183",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0183_Pod_275.png",
            "rarity_rank": 1371,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0244",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0244.png",
            "rarity_rank": 1372,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spiky Helmet"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3994",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3994.png",
            "rarity_rank": 1373,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3656",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3656.png",
            "rarity_rank": 1374,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0371",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0371.png",
            "rarity_rank": 1375,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Solana Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0399",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0399.png",
            "rarity_rank": 1376,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Winter Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1127",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1127.png",
            "rarity_rank": 1377,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4416",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4416.png",
            "rarity_rank": 1378,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bow"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0486",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0486.png",
            "rarity_rank": 1379,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0413",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0413.png",
            "rarity_rank": 1380,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0267",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0267.png",
            "rarity_rank": 1381,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Leather Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3061",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3061.png",
            "rarity_rank": 1382,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Collar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1137",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1137.png",
            "rarity_rank": 1383,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3274",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3274.png",
            "rarity_rank": 1384,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Square Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Piercings"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2769",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2769.png",
            "rarity_rank": 1385,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Binoculars"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4104",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4104.png",
            "rarity_rank": 1386,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Armour Shoulders"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3773",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3773.png",
            "rarity_rank": 1387,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0597",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0597.png",
            "rarity_rank": 1388,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Shield"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0446",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0446.png",
            "rarity_rank": 1389,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3437",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3437.png",
            "rarity_rank": 1390,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1457",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1457.png",
            "rarity_rank": 1391,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4292",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4292.png",
            "rarity_rank": 1392,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1409",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1409.png",
            "rarity_rank": 1393,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Parrot"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1272",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1272.png",
            "rarity_rank": 1394,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Punk"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0305",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0305.png",
            "rarity_rank": 1395,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Solana Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0404",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0404.png",
            "rarity_rank": 1396,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Bow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0251",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0251.png",
            "rarity_rank": 1397,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sol Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4154",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4154.png",
            "rarity_rank": 1398,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3972",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3972.png",
            "rarity_rank": 1399,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0334",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0334.png",
            "rarity_rank": 1400,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0179",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0179.png",
            "rarity_rank": 1401,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moon Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2120",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2120.png",
            "rarity_rank": 1402,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0377",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0377.png",
            "rarity_rank": 1403,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0796",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0796.png",
            "rarity_rank": 1404,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2107",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2107.png",
            "rarity_rank": 1405,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0409",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0409.png",
            "rarity_rank": 1406,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Bow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4532",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4532.png",
            "rarity_rank": 1407,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moon Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3509",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3509.png",
            "rarity_rank": 1408,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Cape"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0974",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0974.png",
            "rarity_rank": 1409,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Stripe Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3124",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3124.png",
            "rarity_rank": 1410,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Apple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4402",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4402.png",
            "rarity_rank": 1411,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0306",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0306.png",
            "rarity_rank": 1412,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0452",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0452.png",
            "rarity_rank": 1413,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0837",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0837.png",
            "rarity_rank": 1414,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Royal Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0783",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0783.png",
            "rarity_rank": 1415,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3472",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3472.png",
            "rarity_rank": 1416,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0509",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0509.png",
            "rarity_rank": 1417,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Stripe Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3635",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3635.png",
            "rarity_rank": 1418,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eye Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1240",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1240.png",
            "rarity_rank": 1419,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1989",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1989.png",
            "rarity_rank": 1420,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Teeth"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0310",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0310.png",
            "rarity_rank": 1421,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headband"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0118",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0118.png",
            "rarity_rank": 1422,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0271",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0271.png",
            "rarity_rank": 1423,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Green"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0240",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0240.png",
            "rarity_rank": 1424,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eyepatch"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0272",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0272.png",
            "rarity_rank": 1425,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3828",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3828.png",
            "rarity_rank": 1426,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1423",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1423.png",
            "rarity_rank": 1427,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4121",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4121.png",
            "rarity_rank": 1428,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0185",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0185_Pod_235.png",
            "rarity_rank": 1429,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Helmet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1421",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1421.png",
            "rarity_rank": 1430,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0374",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0374.png",
            "rarity_rank": 1431,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0545",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0545.png",
            "rarity_rank": 1432,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4387",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4387.png",
            "rarity_rank": 1433,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4494",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4494.png",
            "rarity_rank": 1434,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Torn Mouth"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1434",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1434.png",
            "rarity_rank": 1435,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ornate"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1474",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1474.png",
            "rarity_rank": 1436,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1036",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1036.png",
            "rarity_rank": 1437,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eyepatch"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4162",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4162.png",
            "rarity_rank": 1438,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Witch Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0463",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0463.png",
            "rarity_rank": 1439,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0880",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0880.png",
            "rarity_rank": 1440,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cowboy Hat"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4067",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4067.png",
            "rarity_rank": 1441,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ice Cream"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0933",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0933.png",
            "rarity_rank": 1442,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3726",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3726.png",
            "rarity_rank": 1443,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Girly Lipstick"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0568",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0568.png",
            "rarity_rank": 1444,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3875",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3875.png",
            "rarity_rank": 1445,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Exotic"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0205",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0205_Pod_048_Burnt_1299.png",
            "rarity_rank": 1446,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Tie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2224",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2224.png",
            "rarity_rank": 1447,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1160",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1160.png",
            "rarity_rank": 1448,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0908",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0908.png",
            "rarity_rank": 1449,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Slime"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0507",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0507.png",
            "rarity_rank": 1450,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Solana Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2827",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2827.png",
            "rarity_rank": 1451,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spiky Helmet"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3027",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3027.png",
            "rarity_rank": 1452,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Helmet"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2987",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2987.png",
            "rarity_rank": 1453,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Witch Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2200",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2200.png",
            "rarity_rank": 1454,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Big Ears"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3977",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3977.png",
            "rarity_rank": 1455,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0993",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0993.png",
            "rarity_rank": 1456,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Baby"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Green Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1577",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1577.png",
            "rarity_rank": 1457,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2963",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2963.png",
            "rarity_rank": 1458,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0844",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0844.png",
            "rarity_rank": 1459,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Stripe Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0337",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0337.png",
            "rarity_rank": 1460,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0352",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0352.png",
            "rarity_rank": 1461,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Face Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4128",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4128.png",
            "rarity_rank": 1462,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0232",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0232.png",
            "rarity_rank": 1463,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Torn Mouth"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0956",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0956.png",
            "rarity_rank": 1464,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Reagge Beanie"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3719",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3719.png",
            "rarity_rank": 1465,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0820",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0820.png",
            "rarity_rank": 1466,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0348",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0348.png",
            "rarity_rank": 1467,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3408",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3408.png",
            "rarity_rank": 1468,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Laser Eyewear"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2269",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2269.png",
            "rarity_rank": 1469,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0479",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0479.png",
            "rarity_rank": 1470,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Leafy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3923",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3923.png",
            "rarity_rank": 1471,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Red Glow Eyewear"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4232",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4232.png",
            "rarity_rank": 1472,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spiky Helmet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0199",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0199.png",
            "rarity_rank": 1473,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moon Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0309",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0309.png",
            "rarity_rank": 1474,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Solana Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0237",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0237.png",
            "rarity_rank": 1475,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Collar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4117",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4117.png",
            "rarity_rank": 1476,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1271",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1271.png",
            "rarity_rank": 1477,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Paw Bandage"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4165",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4165.png",
            "rarity_rank": 1478,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0161",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0161.png",
            "rarity_rank": 1479,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Torn Mouth"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3222",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3222.png",
            "rarity_rank": 1480,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0359",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0359.png",
            "rarity_rank": 1481,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Reagge Beanie"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4097",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4097.png",
            "rarity_rank": 1482,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1015",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1015.png",
            "rarity_rank": 1483,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4332",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4332.png",
            "rarity_rank": 1484,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4039",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4039.png",
            "rarity_rank": 1485,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Star Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3627",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3627.png",
            "rarity_rank": 1486,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Duck Ring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2868",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2868.png",
            "rarity_rank": 1487,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Small Tiara"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0466",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0466.png",
            "rarity_rank": 1488,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1023",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1023.png",
            "rarity_rank": 1489,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0541",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0541.png",
            "rarity_rank": 1490,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Tie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1021",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1021.png",
            "rarity_rank": 1491,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1279",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1279.png",
            "rarity_rank": 1492,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2495",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2495.png",
            "rarity_rank": 1493,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Green Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3516",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3516.png",
            "rarity_rank": 1494,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0470",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0470.png",
            "rarity_rank": 1495,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4483",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4483.png",
            "rarity_rank": 1496,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0633",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0633.png",
            "rarity_rank": 1497,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0930",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0930.png",
            "rarity_rank": 1498,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0114",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0114.png",
            "rarity_rank": 1499,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0347",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0347.png",
            "rarity_rank": 1500,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0888",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0888.png",
            "rarity_rank": 1501,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0869",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0869.png",
            "rarity_rank": 1502,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4111",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4111.png",
            "rarity_rank": 1503,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Binoculars"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0940",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0940.png",
            "rarity_rank": 1504,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Tie"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0602",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0602.png",
            "rarity_rank": 1505,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3678",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3678.png",
            "rarity_rank": 1506,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3401",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3401.png",
            "rarity_rank": 1507,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Red Glow Eyewear"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0153",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0153.png",
            "rarity_rank": 1508,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0494",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0494.png",
            "rarity_rank": 1509,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0604",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0604.png",
            "rarity_rank": 1510,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0730",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0730_Pod_323.png",
            "rarity_rank": 1511,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Propeller Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0898",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0898.png",
            "rarity_rank": 1512,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0490",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0490.png",
            "rarity_rank": 1513,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1355",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1355.png",
            "rarity_rank": 1514,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1070",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1070.png",
            "rarity_rank": 1515,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3823",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3823.png",
            "rarity_rank": 1516,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0218",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0218_Pod_338.png",
            "rarity_rank": 1517,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3835",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3835.png",
            "rarity_rank": 1518,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Star Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2403",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2403.png",
            "rarity_rank": 1519,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2415",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2415.png",
            "rarity_rank": 1520,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0776",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0776.png",
            "rarity_rank": 1521,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3997",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3997.png",
            "rarity_rank": 1522,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0531",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0531.png",
            "rarity_rank": 1523,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0895",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0895.png",
            "rarity_rank": 1524,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3238",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3238.png",
            "rarity_rank": 1525,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Propeller Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0516",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0516.png",
            "rarity_rank": 1526,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Leafy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Shield"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1990",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1990.png",
            "rarity_rank": 1527,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3026",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3026.png",
            "rarity_rank": 1528,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Laser Eyewear"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2882",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2882.png",
            "rarity_rank": 1529,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2740",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2740.png",
            "rarity_rank": 1530,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0573",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0573.png",
            "rarity_rank": 1531,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1231",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1231.png",
            "rarity_rank": 1532,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4237",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4237.png",
            "rarity_rank": 1533,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Glove"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0454",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0454.png",
            "rarity_rank": 1534,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3807",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3807.png",
            "rarity_rank": 1535,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0295",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0295.png",
            "rarity_rank": 1536,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Solana Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0836",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0836.png",
            "rarity_rank": 1537,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2312",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2312.png",
            "rarity_rank": 1538,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4423",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4423.png",
            "rarity_rank": 1539,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Apple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3081",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3081.png",
            "rarity_rank": 1540,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4325",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4325.png",
            "rarity_rank": 1541,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4173",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4173.png",
            "rarity_rank": 1542,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Stripe Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2465",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2465.png",
            "rarity_rank": 1543,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Big Ears"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3301",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3301.png",
            "rarity_rank": 1544,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4150",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4150.png",
            "rarity_rank": 1545,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4414",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4414.png",
            "rarity_rank": 1546,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Glove"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0544",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0544.png",
            "rarity_rank": 1547,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headband"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4331",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4331.png",
            "rarity_rank": 1548,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Green Hat"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0600",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0600.png",
            "rarity_rank": 1549,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0725",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0725_Pod_258.png",
            "rarity_rank": 1550,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0824",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0824.png",
            "rarity_rank": 1551,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1232",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1232.png",
            "rarity_rank": 1552,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Bow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4534",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4534.png",
            "rarity_rank": 1553,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2136",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2136.png",
            "rarity_rank": 1554,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0719",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0719_Pod_014.png",
            "rarity_rank": 1555,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Redhood"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0242",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0242.png",
            "rarity_rank": 1556,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sailor Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2898",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2898.png",
            "rarity_rank": 1557,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Piercings"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Armour"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1891",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1891.png",
            "rarity_rank": 1558,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Teeth"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1436",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1436.png",
            "rarity_rank": 1559,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1197",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1197_Pod_275.png",
            "rarity_rank": 1560,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Propeller Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0195",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0195_Pod_192_Burnt_2275.png",
            "rarity_rank": 1561,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Head Gadget"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2100",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2100.png",
            "rarity_rank": 1562,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3769",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3769.png",
            "rarity_rank": 1563,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1471",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1471.png",
            "rarity_rank": 1564,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2744",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2744.png",
            "rarity_rank": 1565,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Painted Hat"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2875",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2875.png",
            "rarity_rank": 1566,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3522",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3522.png",
            "rarity_rank": 1567,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Painted Hat"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1951",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1951.png",
            "rarity_rank": 1568,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Teeth"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0780",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0780.png",
            "rarity_rank": 1569,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Exotic"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1087",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1087.png",
            "rarity_rank": 1570,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0928",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0928.png",
            "rarity_rank": 1571,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3138",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3138.png",
            "rarity_rank": 1572,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0585",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0585.png",
            "rarity_rank": 1573,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4368",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4368.png",
            "rarity_rank": 1574,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Torn Mouth"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Simple Gold Piercing"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0292",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0292.png",
            "rarity_rank": 1575,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Armour Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2213",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2213.png",
            "rarity_rank": 1576,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1221",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1221.png",
            "rarity_rank": 1577,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1177",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1177_Pod_311.png",
            "rarity_rank": 1578,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Royal Crown"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0375",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0375.png",
            "rarity_rank": 1579,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0277",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0277.png",
            "rarity_rank": 1580,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Leather Collar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0106",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0106.png",
            "rarity_rank": 1581,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1213",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1213.png",
            "rarity_rank": 1582,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3495",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3495.png",
            "rarity_rank": 1583,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0905",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0905.png",
            "rarity_rank": 1584,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0939",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0939.png",
            "rarity_rank": 1585,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3824",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3824.png",
            "rarity_rank": 1586,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0877",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0877.png",
            "rarity_rank": 1587,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Binoculars"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3850",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3850.png",
            "rarity_rank": 1588,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Baby"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2217",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2217.png",
            "rarity_rank": 1589,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0108",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0108.png",
            "rarity_rank": 1590,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4231",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4231.png",
            "rarity_rank": 1591,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0787",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0787.png",
            "rarity_rank": 1592,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eye Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2088",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2088.png",
            "rarity_rank": 1593,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2283",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2283.png",
            "rarity_rank": 1594,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1074",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1074.png",
            "rarity_rank": 1595,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0471",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0471.png",
            "rarity_rank": 1596,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3944",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3944.png",
            "rarity_rank": 1597,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1459",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1459.png",
            "rarity_rank": 1598,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0886",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0886.png",
            "rarity_rank": 1599,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1796",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1796.png",
            "rarity_rank": 1600,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Teeth"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2741",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2741.png",
            "rarity_rank": 1601,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Piercings"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3427",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3427.png",
            "rarity_rank": 1602,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0362",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0362.png",
            "rarity_rank": 1603,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0325",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0325.png",
            "rarity_rank": 1604,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3168",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3168.png",
            "rarity_rank": 1605,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headband"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0286",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0286.png",
            "rarity_rank": 1606,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Torn Mouth"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4112",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4112.png",
            "rarity_rank": 1607,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Piercings"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0926",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0926.png",
            "rarity_rank": 1608,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Sunhat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1182",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1182_Pod_079.png",
            "rarity_rank": 1609,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Shield"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0327",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0327.png",
            "rarity_rank": 1610,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Cape"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2237",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2237.png",
            "rarity_rank": 1611,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Big Ears"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3587",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3587.png",
            "rarity_rank": 1612,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Banana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4312",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4312.png",
            "rarity_rank": 1613,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0338",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0338.png",
            "rarity_rank": 1614,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1639",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1639.png",
            "rarity_rank": 1615,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Teeth"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3539",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3539.png",
            "rarity_rank": 1616,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Neck Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3585",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3585.png",
            "rarity_rank": 1617,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4036",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4036.png",
            "rarity_rank": 1618,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2441",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2441.png",
            "rarity_rank": 1619,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2405",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2405.png",
            "rarity_rank": 1620,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0841",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0841.png",
            "rarity_rank": 1621,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2733",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2733.png",
            "rarity_rank": 1622,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4473",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4473.png",
            "rarity_rank": 1623,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2962",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2962.png",
            "rarity_rank": 1624,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Painted Hat"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3860",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3860.png",
            "rarity_rank": 1625,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3115",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3115.png",
            "rarity_rank": 1626,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1187",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1187_Pod_133.png",
            "rarity_rank": 1627,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Head Gadget"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3397",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3397.png",
            "rarity_rank": 1628,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1143",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1143.png",
            "rarity_rank": 1629,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1461",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1461.png",
            "rarity_rank": 1630,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1841",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1841.png",
            "rarity_rank": 1631,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0465",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0465.png",
            "rarity_rank": 1632,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Piercings"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3786",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3786.png",
            "rarity_rank": 1633,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2263",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2263.png",
            "rarity_rank": 1634,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Big Ears"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1264",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1264.png",
            "rarity_rank": 1635,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0947",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0947.png",
            "rarity_rank": 1636,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0532",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0532.png",
            "rarity_rank": 1637,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2220",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2220.png",
            "rarity_rank": 1638,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2430",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2430.png",
            "rarity_rank": 1639,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0316",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0316.png",
            "rarity_rank": 1640,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 01"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3567",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3567.png",
            "rarity_rank": 1641,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0857",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0857.png",
            "rarity_rank": 1642,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0842",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0842.png",
            "rarity_rank": 1643,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Skull"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0300",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0300.png",
            "rarity_rank": 1644,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3319",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3319.png",
            "rarity_rank": 1645,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2715",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2715.png",
            "rarity_rank": 1646,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Face Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0936",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0936.png",
            "rarity_rank": 1647,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4439",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4439.png",
            "rarity_rank": 1648,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Stripe Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2949",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2949.png",
            "rarity_rank": 1649,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Banana"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1927",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1927.png",
            "rarity_rank": 1650,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0322",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0322.png",
            "rarity_rank": 1651,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3922",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3922.png",
            "rarity_rank": 1652,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Red Glow Eyewear"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3884",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3884.png",
            "rarity_rank": 1653,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Duck Ring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1300",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1300.png",
            "rarity_rank": 1654,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3436",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3436.png",
            "rarity_rank": 1655,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headband"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3844",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3844.png",
            "rarity_rank": 1656,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0763",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0763.png",
            "rarity_rank": 1657,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Red Glow Eyewear"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0146",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0146.png",
            "rarity_rank": 1658,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3481",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3481.png",
            "rarity_rank": 1659,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Sunhat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4375",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4375.png",
            "rarity_rank": 1660,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2048",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2048.png",
            "rarity_rank": 1661,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Teeth"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3491",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3491.png",
            "rarity_rank": 1662,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0396",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0396.png",
            "rarity_rank": 1663,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2215",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2215.png",
            "rarity_rank": 1664,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0147",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0147.png",
            "rarity_rank": 1665,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4120",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4120.png",
            "rarity_rank": 1666,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Red Glow Eyewear"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1629",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1629.png",
            "rarity_rank": 1667,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3933",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3933.png",
            "rarity_rank": 1668,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0234",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0234.png",
            "rarity_rank": 1669,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3299",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3299.png",
            "rarity_rank": 1670,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0853",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0853.png",
            "rarity_rank": 1671,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2671",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2671.png",
            "rarity_rank": 1672,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1462",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1462.png",
            "rarity_rank": 1673,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2725",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2725.png",
            "rarity_rank": 1674,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3622",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3622.png",
            "rarity_rank": 1675,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4306",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4306.png",
            "rarity_rank": 1676,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Royal Crown"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1158",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1158.png",
            "rarity_rank": 1677,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3381",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3381.png",
            "rarity_rank": 1678,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spiky Helmet"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2472",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2472.png",
            "rarity_rank": 1679,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3793",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3793.png",
            "rarity_rank": 1680,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Exotic"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3752",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3752.png",
            "rarity_rank": 1681,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Torn Mouth"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Duck Ring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2732",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2732.png",
            "rarity_rank": 1682,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Square Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0326",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0326.png",
            "rarity_rank": 1683,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1112",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1112.png",
            "rarity_rank": 1684,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3659",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3659.png",
            "rarity_rank": 1685,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0810",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0810.png",
            "rarity_rank": 1686,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0934",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0934.png",
            "rarity_rank": 1687,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3490",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3490.png",
            "rarity_rank": 1688,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0817",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0817.png",
            "rarity_rank": 1689,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Armour Gloves"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0393",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0393.png",
            "rarity_rank": 1690,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Leafy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0111",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0111.png",
            "rarity_rank": 1691,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4461",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4461.png",
            "rarity_rank": 1692,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0197",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0197_Pod_203_Burnt_2303.png",
            "rarity_rank": 1693,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3488",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3488.png",
            "rarity_rank": 1694,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3970",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3970.png",
            "rarity_rank": 1695,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Red Glow Eyewear"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1909",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1909.png",
            "rarity_rank": 1696,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Teeth"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0770",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0770.png",
            "rarity_rank": 1697,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3637",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3637.png",
            "rarity_rank": 1698,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2922",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2922.png",
            "rarity_rank": 1699,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3511",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3511.png",
            "rarity_rank": 1700,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1175",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1175_Pod_049.png",
            "rarity_rank": 1701,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sailor Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3309",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3309.png",
            "rarity_rank": 1702,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headband"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4027",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4027.png",
            "rarity_rank": 1703,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0790",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0790.png",
            "rarity_rank": 1704,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2256",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2256.png",
            "rarity_rank": 1705,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eyepatch"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1237",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1237.png",
            "rarity_rank": 1706,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3386",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3386.png",
            "rarity_rank": 1707,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3866",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3866.png",
            "rarity_rank": 1708,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1401",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1401.png",
            "rarity_rank": 1709,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3619",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3619.png",
            "rarity_rank": 1710,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3136",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3136.png",
            "rarity_rank": 1711,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2927",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2927.png",
            "rarity_rank": 1712,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Red Glow Eyewear"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4490",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4490.png",
            "rarity_rank": 1713,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2382",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2382.png",
            "rarity_rank": 1714,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2332",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2332.png",
            "rarity_rank": 1715,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3074",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3074.png",
            "rarity_rank": 1716,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3535",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3535.png",
            "rarity_rank": 1717,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Armour Gloves"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2044",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2044.png",
            "rarity_rank": 1718,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3589",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3589.png",
            "rarity_rank": 1719,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Propeller Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0641",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0641.png",
            "rarity_rank": 1720,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Simple Gold Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1362",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1362.png",
            "rarity_rank": 1721,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3626",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3626.png",
            "rarity_rank": 1722,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Duck Ring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3929",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3929.png",
            "rarity_rank": 1723,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Duck Ring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1589",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1589.png",
            "rarity_rank": 1724,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eyepatch"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3634",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3634.png",
            "rarity_rank": 1725,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ape Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2790",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2790.png",
            "rarity_rank": 1726,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2966",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2966.png",
            "rarity_rank": 1727,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0990",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0990.png",
            "rarity_rank": 1728,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2793",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2793.png",
            "rarity_rank": 1729,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3216",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3216.png",
            "rarity_rank": 1730,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Green Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3765",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3765.png",
            "rarity_rank": 1731,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2077",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2077.png",
            "rarity_rank": 1732,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1773",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1773.png",
            "rarity_rank": 1733,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Teeth"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1425",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1425.png",
            "rarity_rank": 1734,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0540",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0540.png",
            "rarity_rank": 1735,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2335",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2335.png",
            "rarity_rank": 1736,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1047",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1047.png",
            "rarity_rank": 1737,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Oriental Sunhat"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1298",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1298.png",
            "rarity_rank": 1738,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0942",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0942.png",
            "rarity_rank": 1739,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3805",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3805.png",
            "rarity_rank": 1740,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1903",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1903.png",
            "rarity_rank": 1741,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4011",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4011.png",
            "rarity_rank": 1742,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0872",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0872.png",
            "rarity_rank": 1743,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3100",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3100.png",
            "rarity_rank": 1744,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Stripe Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Leather Collar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0944",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0944.png",
            "rarity_rank": 1745,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Leafy"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2396",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2396.png",
            "rarity_rank": 1746,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2911",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2911.png",
            "rarity_rank": 1747,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1368",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1368.png",
            "rarity_rank": 1748,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3575",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3575.png",
            "rarity_rank": 1749,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Glove"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2789",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2789.png",
            "rarity_rank": 1750,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2839",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2839.png",
            "rarity_rank": 1751,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3595",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3595.png",
            "rarity_rank": 1752,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0603",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0603.png",
            "rarity_rank": 1753,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2738",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2738.png",
            "rarity_rank": 1754,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Glove"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0190",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0190.png",
            "rarity_rank": 1755,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0335",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0335.png",
            "rarity_rank": 1756,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2252",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2252.png",
            "rarity_rank": 1757,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1064",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1064.png",
            "rarity_rank": 1758,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headband"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3527",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3527.png",
            "rarity_rank": 1759,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0537",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0537.png",
            "rarity_rank": 1760,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Paw"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0252",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0252.png",
            "rarity_rank": 1761,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2754",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2754.png",
            "rarity_rank": 1762,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Red Glow Eyewear"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0834",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0834.png",
            "rarity_rank": 1763,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2142",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2142.png",
            "rarity_rank": 1764,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0802",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0802.png",
            "rarity_rank": 1765,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3599",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3599.png",
            "rarity_rank": 1766,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0927",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0927.png",
            "rarity_rank": 1767,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2847",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2847.png",
            "rarity_rank": 1768,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Simple Gold Piercing"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0779",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0779.png",
            "rarity_rank": 1769,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3480",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3480.png",
            "rarity_rank": 1770,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0892",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0892.png",
            "rarity_rank": 1771,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Face Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2728",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2728.png",
            "rarity_rank": 1772,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2775",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2775.png",
            "rarity_rank": 1773,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Tool Belt"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3524",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3524.png",
            "rarity_rank": 1774,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Paw"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0397",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0397.png",
            "rarity_rank": 1775,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Solana Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2175",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2175.png",
            "rarity_rank": 1776,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4114",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4114.png",
            "rarity_rank": 1777,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2764",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2764.png",
            "rarity_rank": 1778,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Axe"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3308",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3308.png",
            "rarity_rank": 1779,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Star Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1919",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1919.png",
            "rarity_rank": 1780,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2326",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2326.png",
            "rarity_rank": 1781,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0304",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0304.png",
            "rarity_rank": 1782,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0995",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0995.png",
            "rarity_rank": 1783,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1886",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1886.png",
            "rarity_rank": 1784,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1944",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1944.png",
            "rarity_rank": 1785,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3604",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3604.png",
            "rarity_rank": 1786,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3717",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3717.png",
            "rarity_rank": 1787,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4249",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4249.png",
            "rarity_rank": 1788,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0572",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0572.png",
            "rarity_rank": 1789,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Shield"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0559",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0559.png",
            "rarity_rank": 1790,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1947",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1947.png",
            "rarity_rank": 1791,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2046",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2046.png",
            "rarity_rank": 1792,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2933",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2933.png",
            "rarity_rank": 1793,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Hair"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0260",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0260.png",
            "rarity_rank": 1794,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eyepatch"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2152",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2152.png",
            "rarity_rank": 1795,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3236",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3236.png",
            "rarity_rank": 1796,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1475",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1475.png",
            "rarity_rank": 1797,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0301",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0301.png",
            "rarity_rank": 1798,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0973",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0973.png",
            "rarity_rank": 1799,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3602",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3602.png",
            "rarity_rank": 1800,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4016",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4016.png",
            "rarity_rank": 1801,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2222",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2222.png",
            "rarity_rank": 1802,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Big Ears"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3661",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3661.png",
            "rarity_rank": 1803,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1694",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1694.png",
            "rarity_rank": 1804,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Teeth"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3506",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3506.png",
            "rarity_rank": 1805,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0166",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0166_Pod_277.png",
            "rarity_rank": 1806,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1357",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1357.png",
            "rarity_rank": 1807,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1107",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1107.png",
            "rarity_rank": 1808,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0485",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0485.png",
            "rarity_rank": 1809,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2879",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2879.png",
            "rarity_rank": 1810,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0997",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0997.png",
            "rarity_rank": 1811,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1034",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1034.png",
            "rarity_rank": 1812,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1603",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1603.png",
            "rarity_rank": 1813,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0383",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0383.png",
            "rarity_rank": 1814,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1633",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1633.png",
            "rarity_rank": 1815,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0109",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0109.png",
            "rarity_rank": 1816,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Torn Mouth"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1120",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1120.png",
            "rarity_rank": 1817,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4051",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4051.png",
            "rarity_rank": 1818,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0803",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0803.png",
            "rarity_rank": 1819,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4025",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4025.png",
            "rarity_rank": 1820,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ornate"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2747",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2747.png",
            "rarity_rank": 1821,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2451",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2451.png",
            "rarity_rank": 1822,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Big Ears"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4090",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4090.png",
            "rarity_rank": 1823,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Laser Eyewear"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4102",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4102.png",
            "rarity_rank": 1824,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Winter Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1853",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1853.png",
            "rarity_rank": 1825,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1634",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1634.png",
            "rarity_rank": 1826,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Teeth"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2729",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2729.png",
            "rarity_rank": 1827,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2323",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2323.png",
            "rarity_rank": 1828,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1095",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1095.png",
            "rarity_rank": 1829,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3520",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3520.png",
            "rarity_rank": 1830,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0107",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0107.png",
            "rarity_rank": 1831,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0999",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0999.png",
            "rarity_rank": 1832,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2420",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2420.png",
            "rarity_rank": 1833,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0395",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0395.png",
            "rarity_rank": 1834,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0552",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0552.png",
            "rarity_rank": 1835,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rocket"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1205",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1205_Pod_443.png",
            "rarity_rank": 1836,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1085",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1085.png",
            "rarity_rank": 1837,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Reagge Beanie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0588",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0588.png",
            "rarity_rank": 1838,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1428",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1428.png",
            "rarity_rank": 1839,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0498",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0498.png",
            "rarity_rank": 1840,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3549",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3549.png",
            "rarity_rank": 1841,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4435",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4435.png",
            "rarity_rank": 1842,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3473",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3473.png",
            "rarity_rank": 1843,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4253",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4253.png",
            "rarity_rank": 1844,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3001",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3001.png",
            "rarity_rank": 1845,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headband"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2986",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2986.png",
            "rarity_rank": 1846,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1786",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1786.png",
            "rarity_rank": 1847,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3947",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3947.png",
            "rarity_rank": 1848,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0132",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0132.png",
            "rarity_rank": 1849,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0782",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0782.png",
            "rarity_rank": 1850,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Tie"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3312",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3312.png",
            "rarity_rank": 1851,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Red Bow"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0862",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0862.png",
            "rarity_rank": 1852,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray One Eyed Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3557",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3557.png",
            "rarity_rank": 1853,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1390",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1390.png",
            "rarity_rank": 1854,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3999",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3999.png",
            "rarity_rank": 1855,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0664",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0664.png",
            "rarity_rank": 1856,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0175",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0175_Pod_023.png",
            "rarity_rank": 1857,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Laser Eyewear"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3821",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3821.png",
            "rarity_rank": 1858,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1695",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1695.png",
            "rarity_rank": 1859,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Teeth"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0953",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0953.png",
            "rarity_rank": 1860,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Torn Mouth"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4026",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4026.png",
            "rarity_rank": 1861,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2176",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2176.png",
            "rarity_rank": 1862,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Teeth"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0565",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0565.png",
            "rarity_rank": 1863,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0280",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0280.png",
            "rarity_rank": 1864,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Exotic"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0426",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0426.png",
            "rarity_rank": 1865,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0210",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0210_Pod_112_Burnt_1478.png",
            "rarity_rank": 1866,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3076",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3076.png",
            "rarity_rank": 1867,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0977",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0977.png",
            "rarity_rank": 1868,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2017",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2017.png",
            "rarity_rank": 1869,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1597",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1597.png",
            "rarity_rank": 1870,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Teeth"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2488",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2488.png",
            "rarity_rank": 1871,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2374",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2374.png",
            "rarity_rank": 1872,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1063",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1063.png",
            "rarity_rank": 1873,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1280",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1280.png",
            "rarity_rank": 1874,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3519",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3519.png",
            "rarity_rank": 1875,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Face Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3968",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3968.png",
            "rarity_rank": 1876,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3630",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3630.png",
            "rarity_rank": 1877,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1405",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1405.png",
            "rarity_rank": 1878,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4380",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4380.png",
            "rarity_rank": 1879,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2387",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2387.png",
            "rarity_rank": 1880,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1037",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1037.png",
            "rarity_rank": 1881,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3261",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3261.png",
            "rarity_rank": 1882,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Armour Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2900",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2900.png",
            "rarity_rank": 1883,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3926",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3926.png",
            "rarity_rank": 1884,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4009",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4009.png",
            "rarity_rank": 1885,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ornate"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4167",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4167.png",
            "rarity_rank": 1886,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Reagge Beanie"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0694",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0694.png",
            "rarity_rank": 1887,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0831",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0831.png",
            "rarity_rank": 1888,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3796",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3796.png",
            "rarity_rank": 1889,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Exotic"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3813",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3813.png",
            "rarity_rank": 1890,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2154",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2154.png",
            "rarity_rank": 1891,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3414",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3414.png",
            "rarity_rank": 1892,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt With Flag"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3239",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3239.png",
            "rarity_rank": 1893,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Reagge Beanie"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3912",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3912.png",
            "rarity_rank": 1894,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Duck Ring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3497",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3497.png",
            "rarity_rank": 1895,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Green Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2160",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2160.png",
            "rarity_rank": 1896,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0428",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0428.png",
            "rarity_rank": 1897,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3244",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3244.png",
            "rarity_rank": 1898,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Armour"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4399",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4399.png",
            "rarity_rank": 1899,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3410",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3410.png",
            "rarity_rank": 1900,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3461",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3461.png",
            "rarity_rank": 1901,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0345",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0345.png",
            "rarity_rank": 1902,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1839",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1839.png",
            "rarity_rank": 1903,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0751",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0751_Pod_378.png",
            "rarity_rank": 1904,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0774",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0774.png",
            "rarity_rank": 1905,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2232",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2232.png",
            "rarity_rank": 1906,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2457",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2457.png",
            "rarity_rank": 1907,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0229",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0229.png",
            "rarity_rank": 1908,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1135",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1135.png",
            "rarity_rank": 1909,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0875",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0875.png",
            "rarity_rank": 1910,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2643",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2643.png",
            "rarity_rank": 1911,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3632",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3632.png",
            "rarity_rank": 1912,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0816",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0816.png",
            "rarity_rank": 1913,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1186",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1186_Pod_119.png",
            "rarity_rank": 1914,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3848",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3848.png",
            "rarity_rank": 1915,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2498",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2498.png",
            "rarity_rank": 1916,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0263",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0263.png",
            "rarity_rank": 1917,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1065",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1065.png",
            "rarity_rank": 1918,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0297",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0297.png",
            "rarity_rank": 1919,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Cape"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1001",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1001.png",
            "rarity_rank": 1920,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0515",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0515.png",
            "rarity_rank": 1921,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Dripping Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2653",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2653.png",
            "rarity_rank": 1922,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2276",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2276.png",
            "rarity_rank": 1923,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3246",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3246.png",
            "rarity_rank": 1924,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0575",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0575.png",
            "rarity_rank": 1925,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3720",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3720.png",
            "rarity_rank": 1926,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1151",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1151.png",
            "rarity_rank": 1927,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3570",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3570.png",
            "rarity_rank": 1928,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2524",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2524.png",
            "rarity_rank": 1929,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2630",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2630.png",
            "rarity_rank": 1930,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eyepatch"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2480",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2480.png",
            "rarity_rank": 1931,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3806",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3806.png",
            "rarity_rank": 1932,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Pattern"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3742",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3742.png",
            "rarity_rank": 1933,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Baby"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2132",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2132.png",
            "rarity_rank": 1934,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0621",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0621.png",
            "rarity_rank": 1935,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3775",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3775.png",
            "rarity_rank": 1936,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0560",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0560.png",
            "rarity_rank": 1937,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Apple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1130",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1130.png",
            "rarity_rank": 1938,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3564",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3564.png",
            "rarity_rank": 1939,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2478",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2478.png",
            "rarity_rank": 1940,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1458",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1458.png",
            "rarity_rank": 1941,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0444",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0444.png",
            "rarity_rank": 1942,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3890",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3890.png",
            "rarity_rank": 1943,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3223",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3223.png",
            "rarity_rank": 1944,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3946",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3946.png",
            "rarity_rank": 1945,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2360",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2360.png",
            "rarity_rank": 1946,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2482",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2482.png",
            "rarity_rank": 1947,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1758",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1758.png",
            "rarity_rank": 1948,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Duck Ring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3563",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3563.png",
            "rarity_rank": 1949,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3093",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3093.png",
            "rarity_rank": 1950,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3881",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3881.png",
            "rarity_rank": 1951,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0382",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0382.png",
            "rarity_rank": 1952,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Shield"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0288",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0288.png",
            "rarity_rank": 1953,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2281",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2281.png",
            "rarity_rank": 1954,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1921",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1921.png",
            "rarity_rank": 1955,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1726",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1726.png",
            "rarity_rank": 1956,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1979",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1979.png",
            "rarity_rank": 1957,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Teeth"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0149",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0149.png",
            "rarity_rank": 1958,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3554",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3554.png",
            "rarity_rank": 1959,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Slime"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0344",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0344.png",
            "rarity_rank": 1960,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1044",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1044.png",
            "rarity_rank": 1961,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0929",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0929.png",
            "rarity_rank": 1962,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2714",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2714.png",
            "rarity_rank": 1963,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Pearl Bracelet"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0798",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0798.png",
            "rarity_rank": 1964,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3062",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3062.png",
            "rarity_rank": 1965,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Piercings"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0143",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0143.png",
            "rarity_rank": 1966,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0535",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0535.png",
            "rarity_rank": 1967,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2388",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2388.png",
            "rarity_rank": 1968,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2625",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2625.png",
            "rarity_rank": 1969,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2358",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2358.png",
            "rarity_rank": 1970,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3787",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3787.png",
            "rarity_rank": 1971,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0935",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0935.png",
            "rarity_rank": 1972,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Side Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0628",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0628.png",
            "rarity_rank": 1973,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Bracelet"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0797",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0797.png",
            "rarity_rank": 1974,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Small Tiara"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1594",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1594.png",
            "rarity_rank": 1975,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2483",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2483.png",
            "rarity_rank": 1976,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4478",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4478.png",
            "rarity_rank": 1977,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0311",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0311.png",
            "rarity_rank": 1978,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0775",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0775.png",
            "rarity_rank": 1979,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2807",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2807.png",
            "rarity_rank": 1980,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3479",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3479.png",
            "rarity_rank": 1981,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Leather Collar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1031",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1031.png",
            "rarity_rank": 1982,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1058",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1058.png",
            "rarity_rank": 1983,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3747",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3747.png",
            "rarity_rank": 1984,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2290",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2290.png",
            "rarity_rank": 1985,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3878",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3878.png",
            "rarity_rank": 1986,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Duck Ring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1202",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1202_Pod_323.png",
            "rarity_rank": 1987,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3776",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3776.png",
            "rarity_rank": 1988,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1289",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1289.png",
            "rarity_rank": 1989,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0750",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0750_Pod_060.png",
            "rarity_rank": 1990,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3671",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3671.png",
            "rarity_rank": 1991,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3104",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3104.png",
            "rarity_rank": 1992,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3568",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3568.png",
            "rarity_rank": 1993,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2518",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2518.png",
            "rarity_rank": 1994,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2727",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2727.png",
            "rarity_rank": 1995,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2379",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2379.png",
            "rarity_rank": 1996,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2206",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2206.png",
            "rarity_rank": 1997,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3745",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3745.png",
            "rarity_rank": 1998,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1224",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1224.png",
            "rarity_rank": 1999,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4493",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4493.png",
            "rarity_rank": 2000,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0433",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0433.png",
            "rarity_rank": 2001,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3689",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3689.png",
            "rarity_rank": 2002,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Baby"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1122",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1122.png",
            "rarity_rank": 2003,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0139",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0139.png",
            "rarity_rank": 2004,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3597",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3597.png",
            "rarity_rank": 2005,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0858",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0858.png",
            "rarity_rank": 2006,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3624",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3624.png",
            "rarity_rank": 2007,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0181",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0181_Pod_020_Burnt_1339.png",
            "rarity_rank": 2008,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0127",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0127.png",
            "rarity_rank": 2009,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3628",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3628.png",
            "rarity_rank": 2010,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4017",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4017.png",
            "rarity_rank": 2011,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1724",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1724.png",
            "rarity_rank": 2012,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Teeth"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3576",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3576.png",
            "rarity_rank": 2013,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0174",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0174_Pod_475.png",
            "rarity_rank": 2014,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2713",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2713.png",
            "rarity_rank": 2015,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2792",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2792.png",
            "rarity_rank": 2016,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0425",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0425.png",
            "rarity_rank": 2017,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2399",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2399.png",
            "rarity_rank": 2018,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1017",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1017.png",
            "rarity_rank": 2019,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1772",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1772.png",
            "rarity_rank": 2020,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2398",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2398.png",
            "rarity_rank": 2021,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3741",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3741.png",
            "rarity_rank": 2022,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2221",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2221.png",
            "rarity_rank": 2023,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3348",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3348.png",
            "rarity_rank": 2024,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3607",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3607.png",
            "rarity_rank": 2025,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3502",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3502.png",
            "rarity_rank": 2026,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1806",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1806.png",
            "rarity_rank": 2027,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4302",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4302.png",
            "rarity_rank": 2028,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1230",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1230.png",
            "rarity_rank": 2029,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0652",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0652.png",
            "rarity_rank": 2030,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1019",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1019.png",
            "rarity_rank": 2031,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3680",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3680.png",
            "rarity_rank": 2032,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Torn Mouth"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3700",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3700.png",
            "rarity_rank": 2033,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Duck Ring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0137",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0137.png",
            "rarity_rank": 2034,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3996",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3996.png",
            "rarity_rank": 2035,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ornate"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3043",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3043.png",
            "rarity_rank": 2036,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3740",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3740.png",
            "rarity_rank": 2037,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Baby"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3849",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3849.png",
            "rarity_rank": 2038,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1360",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1360.png",
            "rarity_rank": 2039,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1751",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1751.png",
            "rarity_rank": 2040,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2427",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2427.png",
            "rarity_rank": 2041,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4034",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4034.png",
            "rarity_rank": 2042,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ornate"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4245",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4245.png",
            "rarity_rank": 2043,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4476",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4476.png",
            "rarity_rank": 2044,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Red Glow Eyewear"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0405",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0405.png",
            "rarity_rank": 2045,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3526",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3526.png",
            "rarity_rank": 2046,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3655",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3655.png",
            "rarity_rank": 2047,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0899",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0899.png",
            "rarity_rank": 2048,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Dotted Pattern"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1183",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1183_Pod_089.png",
            "rarity_rank": 2049,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0112",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0112.png",
            "rarity_rank": 2050,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4091",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4091.png",
            "rarity_rank": 2051,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0110",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0110.png",
            "rarity_rank": 2052,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3724",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3724.png",
            "rarity_rank": 2053,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1892",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1892.png",
            "rarity_rank": 2054,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Teeth"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3417",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3417.png",
            "rarity_rank": 2055,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3350",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3350.png",
            "rarity_rank": 2056,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0581",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0581.png",
            "rarity_rank": 2057,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3759",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3759.png",
            "rarity_rank": 2058,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3892",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3892.png",
            "rarity_rank": 2059,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Duck Ring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0533",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0533.png",
            "rarity_rank": 2060,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Leafy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0598",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0598.png",
            "rarity_rank": 2061,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4149",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4149.png",
            "rarity_rank": 2062,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0586",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0586.png",
            "rarity_rank": 2063,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3382",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3382.png",
            "rarity_rank": 2064,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3684",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3684.png",
            "rarity_rank": 2065,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2730",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2730.png",
            "rarity_rank": 2066,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Armour"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3515",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3515.png",
            "rarity_rank": 2067,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Black Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3580",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3580.png",
            "rarity_rank": 2068,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4015",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4015.png",
            "rarity_rank": 2069,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ornate"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0152",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0152.png",
            "rarity_rank": 2070,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1984",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1984.png",
            "rarity_rank": 2071,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2445",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2445.png",
            "rarity_rank": 2072,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2995",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2995.png",
            "rarity_rank": 2073,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3340",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3340.png",
            "rarity_rank": 2074,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Red Glow Eyewear"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3066",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3066.png",
            "rarity_rank": 2075,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3571",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3571.png",
            "rarity_rank": 2076,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0125",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0125.png",
            "rarity_rank": 2077,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3470",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3470.png",
            "rarity_rank": 2078,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3384",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3384.png",
            "rarity_rank": 2079,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3967",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3967.png",
            "rarity_rank": 2080,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2439",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2439.png",
            "rarity_rank": 2081,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2145",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2145.png",
            "rarity_rank": 2082,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3531",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3531.png",
            "rarity_rank": 2083,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Tie"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3166",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3166.png",
            "rarity_rank": 2084,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3832",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3832.png",
            "rarity_rank": 2085,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1646",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1646.png",
            "rarity_rank": 2086,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3771",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3771.png",
            "rarity_rank": 2087,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Exotic"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2151",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2151.png",
            "rarity_rank": 2088,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0119",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0119.png",
            "rarity_rank": 2089,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0795",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0795.png",
            "rarity_rank": 2090,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0845",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0845.png",
            "rarity_rank": 2091,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Leafy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4353",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4353.png",
            "rarity_rank": 2092,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3477",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3477.png",
            "rarity_rank": 2093,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Witch Hat"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0274",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0274.png",
            "rarity_rank": 2094,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Purple Solana Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2800",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2800.png",
            "rarity_rank": 2095,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Shark Hoodie"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Dark Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0962",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0962.png",
            "rarity_rank": 2096,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Diagonal Striped Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1760",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1760.png",
            "rarity_rank": 2097,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Duck Ring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2891",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2891.png",
            "rarity_rank": 2098,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Small Tiara"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4139",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4139.png",
            "rarity_rank": 2099,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2226",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2226.png",
            "rarity_rank": 2100,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3880",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3880.png",
            "rarity_rank": 2101,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3716",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3716.png",
            "rarity_rank": 2102,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2890",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2890.png",
            "rarity_rank": 2103,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3785",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3785.png",
            "rarity_rank": 2104,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1013",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1013.png",
            "rarity_rank": 2105,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1673",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1673.png",
            "rarity_rank": 2106,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1867",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1867.png",
            "rarity_rank": 2107,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2250",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2250.png",
            "rarity_rank": 2108,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1781",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1781.png",
            "rarity_rank": 2109,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4313",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4313.png",
            "rarity_rank": 2110,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2762",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2762.png",
            "rarity_rank": 2111,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4217",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4217.png",
            "rarity_rank": 2112,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Torn Mouth"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0275",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0275.png",
            "rarity_rank": 2113,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Custom"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4168",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4168.png",
            "rarity_rank": 2114,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3058",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3058.png",
            "rarity_rank": 2115,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3593",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3593.png",
            "rarity_rank": 2116,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0138",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0138.png",
            "rarity_rank": 2117,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0198",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0198.png",
            "rarity_rank": 2118,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Duck"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3600",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3600.png",
            "rarity_rank": 2119,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3896",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3896.png",
            "rarity_rank": 2120,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4221",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4221.png",
            "rarity_rank": 2121,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eyepatch"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3928",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3928.png",
            "rarity_rank": 2122,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3867",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3867.png",
            "rarity_rank": 2123,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Candycane"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3837",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3837.png",
            "rarity_rank": 2124,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Baby"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4007",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4007.png",
            "rarity_rank": 2125,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ornate"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1836",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1836.png",
            "rarity_rank": 2126,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3493",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3493.png",
            "rarity_rank": 2127,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Shield"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1793",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1793.png",
            "rarity_rank": 2128,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1865",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1865.png",
            "rarity_rank": 2129,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3528",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3528.png",
            "rarity_rank": 2130,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1622",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1622.png",
            "rarity_rank": 2131,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Teeth"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2104",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2104.png",
            "rarity_rank": 2132,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3601",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3601.png",
            "rarity_rank": 2133,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2920",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2920.png",
            "rarity_rank": 2134,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Monocle"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1089",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1089.png",
            "rarity_rank": 2135,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Leafy"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3799",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3799.png",
            "rarity_rank": 2136,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2255",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2255.png",
            "rarity_rank": 2137,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4187",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4187.png",
            "rarity_rank": 2138,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Hair"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1755",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1755.png",
            "rarity_rank": 2139,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2742",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2742.png",
            "rarity_rank": 2140,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3065",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3065.png",
            "rarity_rank": 2141,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2203",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2203.png",
            "rarity_rank": 2142,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3739",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3739.png",
            "rarity_rank": 2143,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3886",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3886.png",
            "rarity_rank": 2144,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3030",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3030.png",
            "rarity_rank": 2145,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2288",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2288.png",
            "rarity_rank": 2146,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4103",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4103.png",
            "rarity_rank": 2147,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Leather Collar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4227",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4227.png",
            "rarity_rank": 2148,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Stripe Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3106",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3106.png",
            "rarity_rank": 2149,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3582",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3582.png",
            "rarity_rank": 2150,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3144",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3144.png",
            "rarity_rank": 2151,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3815",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3815.png",
            "rarity_rank": 2152,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0890",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0890.png",
            "rarity_rank": 2153,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3654",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3654.png",
            "rarity_rank": 2154,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3547",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3547.png",
            "rarity_rank": 2155,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Spiky Helmet"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2529",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2529.png",
            "rarity_rank": 2156,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Eyepatch"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3474",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3474.png",
            "rarity_rank": 2157,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Headband"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2174",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2174.png",
            "rarity_rank": 2158,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Sci-fi Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2885",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2885.png",
            "rarity_rank": 2159,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1702",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1702.png",
            "rarity_rank": 2160,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1778",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1778.png",
            "rarity_rank": 2161,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1939",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1939.png",
            "rarity_rank": 2162,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1637",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1637.png",
            "rarity_rank": 2163,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4141",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4141.png",
            "rarity_rank": 2164,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1788",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1788.png",
            "rarity_rank": 2165,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2022",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2022.png",
            "rarity_rank": 2166,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3020",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3020.png",
            "rarity_rank": 2167,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bow"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3113",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3113.png",
            "rarity_rank": 2168,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Simple Gold Piercing"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2432",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2432.png",
            "rarity_rank": 2169,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2474",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2474.png",
            "rarity_rank": 2170,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3070",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3070.png",
            "rarity_rank": 2171,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1925",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1925.png",
            "rarity_rank": 2172,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Teeth"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1671",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1671.png",
            "rarity_rank": 2173,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0122",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0122.png",
            "rarity_rank": 2174,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2783",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2783.png",
            "rarity_rank": 2175,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4000",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4000.png",
            "rarity_rank": 2176,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ornate"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2105",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2105.png",
            "rarity_rank": 2177,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3578",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3578.png",
            "rarity_rank": 2178,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Banana"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2086",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2086.png",
            "rarity_rank": 2179,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0414",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0414.png",
            "rarity_rank": 2180,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0876",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0876.png",
            "rarity_rank": 2181,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2605",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2605.png",
            "rarity_rank": 2182,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4430",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4430.png",
            "rarity_rank": 2183,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Armour"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4198",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4198.png",
            "rarity_rank": 2184,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 03"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0142",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0142.png",
            "rarity_rank": 2185,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Torn Mouth"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0151",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0151.png",
            "rarity_rank": 2186,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4431",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4431.png",
            "rarity_rank": 2187,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cones"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1884",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1884.png",
            "rarity_rank": 2188,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3705",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3705.png",
            "rarity_rank": 2189,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2089",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2089.png",
            "rarity_rank": 2190,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4148",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4148.png",
            "rarity_rank": 2191,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3424",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3424.png",
            "rarity_rank": 2192,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Duck Ring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2955",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2955.png",
            "rarity_rank": 2193,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2300",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2300.png",
            "rarity_rank": 2194,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1125",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1125.png",
            "rarity_rank": 2195,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3888",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3888.png",
            "rarity_rank": 2196,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3956",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3956.png",
            "rarity_rank": 2197,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4379",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4379.png",
            "rarity_rank": 2198,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4544",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4544.png",
            "rarity_rank": 2199,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4425",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4425.png",
            "rarity_rank": 2200,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3533",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3533.png",
            "rarity_rank": 2201,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1859",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1859.png",
            "rarity_rank": 2202,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1811",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1811.png",
            "rarity_rank": 2203,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0369",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0369.png",
            "rarity_rank": 2204,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2392",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2392.png",
            "rarity_rank": 2205,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0717",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0717.png",
            "rarity_rank": 2206,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4515",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4515.png",
            "rarity_rank": 2207,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1016",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1016.png",
            "rarity_rank": 2208,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Gold Leaves"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0815",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0815.png",
            "rarity_rank": 2209,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2723",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2723.png",
            "rarity_rank": 2210,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3625",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3625.png",
            "rarity_rank": 2211,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0291",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0291.png",
            "rarity_rank": 2212,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Money Pattern"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4504",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4504.png",
            "rarity_rank": 2213,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3034",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3034.png",
            "rarity_rank": 2214,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3865",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3865.png",
            "rarity_rank": 2215,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4539",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4539.png",
            "rarity_rank": 2216,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1253",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1253.png",
            "rarity_rank": 2217,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4081",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4081.png",
            "rarity_rank": 2218,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2141",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2141.png",
            "rarity_rank": 2219,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1747",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1747.png",
            "rarity_rank": 2220,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1598",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1598.png",
            "rarity_rank": 2221,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3985",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3985.png",
            "rarity_rank": 2222,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ornate"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2675",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2675.png",
            "rarity_rank": 2223,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4438",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4438.png",
            "rarity_rank": 2224,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Gem"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1066",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1066.png",
            "rarity_rank": 2225,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0415",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0415.png",
            "rarity_rank": 2226,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0850",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0850.png",
            "rarity_rank": 2227,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2678",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2678.png",
            "rarity_rank": 2228,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1851",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1851.png",
            "rarity_rank": 2229,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2756",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2756.png",
            "rarity_rank": 2230,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Ripped Green Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0115",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0115.png",
            "rarity_rank": 2231,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4321",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4321.png",
            "rarity_rank": 2232,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3079",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3079.png",
            "rarity_rank": 2233,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3358",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3358.png",
            "rarity_rank": 2234,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0124",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0124.png",
            "rarity_rank": 2235,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3636",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3636.png",
            "rarity_rank": 2236,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2694",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2694.png",
            "rarity_rank": 2237,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0456",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0456.png",
            "rarity_rank": 2238,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0123",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0123.png",
            "rarity_rank": 2239,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3941",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3941.png",
            "rarity_rank": 2240,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Baby"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3757",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3757.png",
            "rarity_rank": 2241,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4260",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4260.png",
            "rarity_rank": 2242,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2631",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2631.png",
            "rarity_rank": 2243,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4446",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4446.png",
            "rarity_rank": 2244,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0453",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0453.png",
            "rarity_rank": 2245,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3768",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3768.png",
            "rarity_rank": 2246,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0910",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0910.png",
            "rarity_rank": 2247,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1849",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1849.png",
            "rarity_rank": 2248,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3569",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3569.png",
            "rarity_rank": 2249,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3893",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3893.png",
            "rarity_rank": 2250,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1761",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1761.png",
            "rarity_rank": 2251,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Teeth"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3937",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3937.png",
            "rarity_rank": 2252,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1804",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1804.png",
            "rarity_rank": 2253,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3662",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3662.png",
            "rarity_rank": 2254,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2064",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2064.png",
            "rarity_rank": 2255,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3258",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3258.png",
            "rarity_rank": 2256,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4518",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4518.png",
            "rarity_rank": 2257,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2561",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2561.png",
            "rarity_rank": 2258,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2496",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2496.png",
            "rarity_rank": 2259,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4334",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4334.png",
            "rarity_rank": 2260,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Neck Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2572",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2572.png",
            "rarity_rank": 2261,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3669",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3669.png",
            "rarity_rank": 2262,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4224",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4224.png",
            "rarity_rank": 2263,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2242",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2242.png",
            "rarity_rank": 2264,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2866",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2866.png",
            "rarity_rank": 2265,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Ear Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2205",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2205.png",
            "rarity_rank": 2266,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0960",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0960.png",
            "rarity_rank": 2267,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2171",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2171.png",
            "rarity_rank": 2268,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2494",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2494.png",
            "rarity_rank": 2269,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0800",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0800.png",
            "rarity_rank": 2270,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Apple"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2582",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2582.png",
            "rarity_rank": 2271,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3145",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3145.png",
            "rarity_rank": 2272,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Shield"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2219",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2219.png",
            "rarity_rank": 2273,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1035",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1035.png",
            "rarity_rank": 2274,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3834",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3834.png",
            "rarity_rank": 2275,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3387",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3387.png",
            "rarity_rank": 2276,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4119",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4119.png",
            "rarity_rank": 2277,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0293",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0293.png",
            "rarity_rank": 2278,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1684",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1684.png",
            "rarity_rank": 2279,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4553",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4553.png",
            "rarity_rank": 2280,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2960",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2960.png",
            "rarity_rank": 2281,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0612",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0612.png",
            "rarity_rank": 2282,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0904",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0904.png",
            "rarity_rank": 2283,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Leafy"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0184",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0184_Pod_076_Burnt_1454.png",
            "rarity_rank": 2284,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1400",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1400.png",
            "rarity_rank": 2285,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ornate"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1026",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1026.png",
            "rarity_rank": 2286,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1821",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1821.png",
            "rarity_rank": 2287,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0148",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0148.png",
            "rarity_rank": 2288,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3770",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3770.png",
            "rarity_rank": 2289,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3801",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3801.png",
            "rarity_rank": 2290,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2677",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2677.png",
            "rarity_rank": 2291,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2257",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2257.png",
            "rarity_rank": 2292,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0155",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0155.png",
            "rarity_rank": 2293,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2736",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2736.png",
            "rarity_rank": 2294,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Baby"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2895",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2895.png",
            "rarity_rank": 2295,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Pink Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3879",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3879.png",
            "rarity_rank": 2296,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3668",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3668.png",
            "rarity_rank": 2297,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2277",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2277.png",
            "rarity_rank": 2298,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2578",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2578.png",
            "rarity_rank": 2299,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1730",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1730.png",
            "rarity_rank": 2300,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4105",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4105.png",
            "rarity_rank": 2301,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1711",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1711.png",
            "rarity_rank": 2302,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3435",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3435.png",
            "rarity_rank": 2303,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3169",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3169.png",
            "rarity_rank": 2304,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2716",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2716.png",
            "rarity_rank": 2305,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2554",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2554.png",
            "rarity_rank": 2306,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2324",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2324.png",
            "rarity_rank": 2307,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2718",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2718.png",
            "rarity_rank": 2308,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Face Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0558",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0558.png",
            "rarity_rank": 2309,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1986",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1986.png",
            "rarity_rank": 2310,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3170",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3170.png",
            "rarity_rank": 2311,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0729",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0729_Pod_147.png",
            "rarity_rank": 2312,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Clown"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1115",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1115.png",
            "rarity_rank": 2313,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Baby"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3611",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3611.png",
            "rarity_rank": 2314,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0140",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0140.png",
            "rarity_rank": 2315,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0319",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0319.png",
            "rarity_rank": 2316,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1599",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1599.png",
            "rarity_rank": 2317,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3631",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3631.png",
            "rarity_rank": 2318,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2543",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2543.png",
            "rarity_rank": 2319,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0920",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0920.png",
            "rarity_rank": 2320,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2214",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2214.png",
            "rarity_rank": 2321,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Big Ears"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2489",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2489.png",
            "rarity_rank": 2322,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3002",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3002.png",
            "rarity_rank": 2323,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3518",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3518.png",
            "rarity_rank": 2324,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Metal Horns"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0436",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0436.png",
            "rarity_rank": 2325,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3317",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3317.png",
            "rarity_rank": 2326,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Belt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3530",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3530.png",
            "rarity_rank": 2327,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0134",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0134.png",
            "rarity_rank": 2328,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1710",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1710.png",
            "rarity_rank": 2329,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2305",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2305.png",
            "rarity_rank": 2330,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Big Ears"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3846",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3846.png",
            "rarity_rank": 2331,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1014",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1014.png",
            "rarity_rank": 2332,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Green Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0701",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0701.png",
            "rarity_rank": 2333,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2513",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2513.png",
            "rarity_rank": 2334,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1628",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1628.png",
            "rarity_rank": 2335,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Teeth"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0932",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0932.png",
            "rarity_rank": 2336,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4283",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4283.png",
            "rarity_rank": 2337,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3353",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3353.png",
            "rarity_rank": 2338,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2658",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2658.png",
            "rarity_rank": 2339,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2027",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2027.png",
            "rarity_rank": 2340,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2856",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2856.png",
            "rarity_rank": 2341,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3198",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3198.png",
            "rarity_rank": 2342,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3423",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3423.png",
            "rarity_rank": 2343,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4001",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4001.png",
            "rarity_rank": 2344,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1763",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1763.png",
            "rarity_rank": 2345,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3962",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3962.png",
            "rarity_rank": 2346,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0421",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0421.png",
            "rarity_rank": 2347,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2319",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2319.png",
            "rarity_rank": 2348,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2492",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2492.png",
            "rarity_rank": 2349,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2542",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2542.png",
            "rarity_rank": 2350,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0299",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0299.png",
            "rarity_rank": 2351,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2734",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2734.png",
            "rarity_rank": 2352,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3853",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3853.png",
            "rarity_rank": 2353,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3388",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3388.png",
            "rarity_rank": 2354,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Green Scarf"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2586",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2586.png",
            "rarity_rank": 2355,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2170",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2170.png",
            "rarity_rank": 2356,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2475",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2475.png",
            "rarity_rank": 2357,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4183",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4183.png",
            "rarity_rank": 2358,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2291",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2291.png",
            "rarity_rank": 2359,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2624",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2624.png",
            "rarity_rank": 2360,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2563",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2563.png",
            "rarity_rank": 2361,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3612",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3612.png",
            "rarity_rank": 2362,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stoned"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0982",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0982.png",
            "rarity_rank": 2363,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2149",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2149.png",
            "rarity_rank": 2364,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2689",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2689.png",
            "rarity_rank": 2365,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3525",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3525.png",
            "rarity_rank": 2366,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0745",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0745_Pod_071.png",
            "rarity_rank": 2367,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Stripe Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2510",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2510.png",
            "rarity_rank": 2368,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1045",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1045.png",
            "rarity_rank": 2369,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0120",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0120.png",
            "rarity_rank": 2370,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3800",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3800.png",
            "rarity_rank": 2371,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3529",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3529.png",
            "rarity_rank": 2372,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2182",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2182.png",
            "rarity_rank": 2373,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4463",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4463.png",
            "rarity_rank": 2374,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Torn Mouth"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1691",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1691.png",
            "rarity_rank": 2375,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3876",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3876.png",
            "rarity_rank": 2376,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2505",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2505.png",
            "rarity_rank": 2377,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0328",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0328.png",
            "rarity_rank": 2378,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2514",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2514.png",
            "rarity_rank": 2379,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0772",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0772.png",
            "rarity_rank": 2380,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bone Necklace"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2117",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2117.png",
            "rarity_rank": 2381,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2602",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2602.png",
            "rarity_rank": 2382,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3894",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3894.png",
            "rarity_rank": 2383,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2458",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2458.png",
            "rarity_rank": 2384,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1012",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1012.png",
            "rarity_rank": 2385,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4023",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4023.png",
            "rarity_rank": 2386,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3400",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3400.png",
            "rarity_rank": 2387,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2063",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2063.png",
            "rarity_rank": 2388,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4171",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4171.png",
            "rarity_rank": 2389,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4125",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4125.png",
            "rarity_rank": 2390,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2555",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2555.png",
            "rarity_rank": 2391,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2803",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2803.png",
            "rarity_rank": 2392,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1952",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1952.png",
            "rarity_rank": 2393,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4099",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4099.png",
            "rarity_rank": 2394,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Torn Mouth"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0217",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0217_Pod_181_Burnt_1780.png",
            "rarity_rank": 2395,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Crown"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3375",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3375.png",
            "rarity_rank": 2396,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Reagge Beanie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2001",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2001.png",
            "rarity_rank": 2397,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3513",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3513.png",
            "rarity_rank": 2398,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0349",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0349.png",
            "rarity_rank": 2399,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Pattern"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2054",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2054.png",
            "rarity_rank": 2400,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3641",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3641.png",
            "rarity_rank": 2401,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3089",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3089.png",
            "rarity_rank": 2402,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3710",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3710.png",
            "rarity_rank": 2403,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1895",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1895.png",
            "rarity_rank": 2404,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1834",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1834.png",
            "rarity_rank": 2405,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1742",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1742.png",
            "rarity_rank": 2406,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Knife Prop"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2871",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2871.png",
            "rarity_rank": 2407,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1770",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1770.png",
            "rarity_rank": 2408,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Teeth"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3318",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3318.png",
            "rarity_rank": 2409,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2133",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2133.png",
            "rarity_rank": 2410,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0878",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0878.png",
            "rarity_rank": 2411,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2804",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2804.png",
            "rarity_rank": 2412,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Dangling Gold Earring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0133",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0133.png",
            "rarity_rank": 2413,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1746",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1746.png",
            "rarity_rank": 2414,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2471",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2471.png",
            "rarity_rank": 2415,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Orange Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1030",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1030.png",
            "rarity_rank": 2416,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Tiara"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1082",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1082.png",
            "rarity_rank": 2417,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3955",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3955.png",
            "rarity_rank": 2418,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2674",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2674.png",
            "rarity_rank": 2419,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3534",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3534.png",
            "rarity_rank": 2420,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3843",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3843.png",
            "rarity_rank": 2421,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Galaxy"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4022",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4022.png",
            "rarity_rank": 2422,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Ornate"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1819",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1819.png",
            "rarity_rank": 2423,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Teeth"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4432",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4432.png",
            "rarity_rank": 2424,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4308",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4308.png",
            "rarity_rank": 2425,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2069",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2069.png",
            "rarity_rank": 2426,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0126",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0126.png",
            "rarity_rank": 2427,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0131",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0131.png",
            "rarity_rank": 2428,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2573",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2573.png",
            "rarity_rank": 2429,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2915",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2915.png",
            "rarity_rank": 2430,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Apple"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0826",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0826.png",
            "rarity_rank": 2431,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2173",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2173.png",
            "rarity_rank": 2432,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3311",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3311.png",
            "rarity_rank": 2433,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Pan"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2161",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2161.png",
            "rarity_rank": 2434,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3629",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3629.png",
            "rarity_rank": 2435,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1732",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1732.png",
            "rarity_rank": 2436,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1985",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1985.png",
            "rarity_rank": 2437,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0303",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0303.png",
            "rarity_rank": 2438,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Silver Earring"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1810",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1810.png",
            "rarity_rank": 2439,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2724",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2724.png",
            "rarity_rank": 2440,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Striped Jacket"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2476",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2476.png",
            "rarity_rank": 2441,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0315",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0315.png",
            "rarity_rank": 2442,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Galaxy Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2306",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2306.png",
            "rarity_rank": 2443,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4497",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4497.png",
            "rarity_rank": 2444,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3507",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3507.png",
            "rarity_rank": 2445,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Royal Crown"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2683",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2683.png",
            "rarity_rank": 2446,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3478",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3478.png",
            "rarity_rank": 2447,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3646",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3646.png",
            "rarity_rank": 2448,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3416",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3416.png",
            "rarity_rank": 2449,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2635",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2635.png",
            "rarity_rank": 2450,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1904",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1904.png",
            "rarity_rank": 2451,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3494",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3494.png",
            "rarity_rank": 2452,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1799",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1799.png",
            "rarity_rank": 2453,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2676",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2676.png",
            "rarity_rank": 2454,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3657",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3657.png",
            "rarity_rank": 2455,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3542",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3542.png",
            "rarity_rank": 2456,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1699",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1699.png",
            "rarity_rank": 2457,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Teeth"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3482",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3482.png",
            "rarity_rank": 2458,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4131",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4131.png",
            "rarity_rank": 2459,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3523",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3523.png",
            "rarity_rank": 2460,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3784",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3784.png",
            "rarity_rank": 2461,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2159",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2159.png",
            "rarity_rank": 2462,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0821",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0821.png",
            "rarity_rank": 2463,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3713",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3713.png",
            "rarity_rank": 2464,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2665",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2665.png",
            "rarity_rank": 2465,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2294",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2294.png",
            "rarity_rank": 2466,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2864",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2864.png",
            "rarity_rank": 2467,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4179",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4179.png",
            "rarity_rank": 2468,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Weed"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1000",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1000.png",
            "rarity_rank": 2469,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3695",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3695.png",
            "rarity_rank": 2470,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Checkered"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1672",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1672.png",
            "rarity_rank": 2471,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3536",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3536.png",
            "rarity_rank": 2472,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2702",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2702.png",
            "rarity_rank": 2473,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1779",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1779.png",
            "rarity_rank": 2474,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3271",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3271.png",
            "rarity_rank": 2475,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Black Earphones"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1934",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1934.png",
            "rarity_rank": 2476,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3193",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3193.png",
            "rarity_rank": 2477,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4393",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4393.png",
            "rarity_rank": 2478,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Moon Skin"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1602",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1602.png",
            "rarity_rank": 2479,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2060",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2060.png",
            "rarity_rank": 2480,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bubble Gum"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2633",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2633.png",
            "rarity_rank": 2481,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Cyan Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3692",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3692.png",
            "rarity_rank": 2482,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2074",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2074.png",
            "rarity_rank": 2483,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2884",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2884.png",
            "rarity_rank": 2484,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4522",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4522.png",
            "rarity_rank": 2485,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Cheese"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3939",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3939.png",
            "rarity_rank": 2486,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2685",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2685.png",
            "rarity_rank": 2487,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3623",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3623.png",
            "rarity_rank": 2488,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2033",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2033.png",
            "rarity_rank": 2489,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1995",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1995.png",
            "rarity_rank": 2490,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Hipster Hat"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3732",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3732.png",
            "rarity_rank": 2491,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Colorful Urban"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4151",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4151.png",
            "rarity_rank": 2492,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Orange"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1693",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1693.png",
            "rarity_rank": 2493,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3135",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3135.png",
            "rarity_rank": 2494,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3393",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3393.png",
            "rarity_rank": 2495,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1920",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1920.png",
            "rarity_rank": 2496,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2500",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2500.png",
            "rarity_rank": 2497,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2618",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2618.png",
            "rarity_rank": 2498,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0129",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0129.png",
            "rarity_rank": 2499,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2693",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2693.png",
            "rarity_rank": 2500,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1809",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1809.png",
            "rarity_rank": 2501,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3897",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3897.png",
            "rarity_rank": 2502,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0833",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0833.png",
            "rarity_rank": 2503,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1960",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1960.png",
            "rarity_rank": 2504,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1654",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1654.png",
            "rarity_rank": 2505,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Teeth"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3673",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3673.png",
            "rarity_rank": 2506,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3714",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3714.png",
            "rarity_rank": 2507,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Telletuby"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0422",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0422.png",
            "rarity_rank": 2508,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Blue Paw Band"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3203",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3203.png",
            "rarity_rank": 2509,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0296",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0296.png",
            "rarity_rank": 2510,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3760",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3760.png",
            "rarity_rank": 2511,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3545",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3545.png",
            "rarity_rank": 2512,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Glowing Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3898",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3898.png",
            "rarity_rank": 2513,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0919",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0919.png",
            "rarity_rank": 2514,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0852",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0852.png",
            "rarity_rank": 2515,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Bandage"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1665",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1665.png",
            "rarity_rank": 2516,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1915",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1915.png",
            "rarity_rank": 2517,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1592",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1592.png",
            "rarity_rank": 2518,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2179",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2179.png",
            "rarity_rank": 2519,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1930",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1930.png",
            "rarity_rank": 2520,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Teeth"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1094",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1094.png",
            "rarity_rank": 2521,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Winter Gloves"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4084",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4084.png",
            "rarity_rank": 2522,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3560",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3560.png",
            "rarity_rank": 2523,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3863",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3863.png",
            "rarity_rank": 2524,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2533",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2533.png",
            "rarity_rank": 2525,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1940",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1940.png",
            "rarity_rank": 2526,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0741",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0741_Pod_084_Burnt_0206.png",
            "rarity_rank": 2527,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Striped Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Brown Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2592",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2592.png",
            "rarity_rank": 2528,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "White Earphones"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2552",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2552.png",
            "rarity_rank": 2529,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0855",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0855.png",
            "rarity_rank": 2530,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Ornate Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1689",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1689.png",
            "rarity_rank": 2531,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1868",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1868.png",
            "rarity_rank": 2532,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2520",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2520.png",
            "rarity_rank": 2533,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3573",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3573.png",
            "rarity_rank": 2534,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1688",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1688.png",
            "rarity_rank": 2535,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0117",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0117.png",
            "rarity_rank": 2536,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4093",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4093.png",
            "rarity_rank": 2537,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Torn Mouth"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1961",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1961.png",
            "rarity_rank": 2538,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2402",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2402.png",
            "rarity_rank": 2539,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3084",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3084.png",
            "rarity_rank": 2540,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3023",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3023.png",
            "rarity_rank": 2541,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0806",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0806.png",
            "rarity_rank": 2542,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Red Overalls"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2128",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2128.png",
            "rarity_rank": 2543,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Neck Bandana"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1737",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1737.png",
            "rarity_rank": 2544,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2311",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2311.png",
            "rarity_rank": 2545,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2617",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2617.png",
            "rarity_rank": 2546,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1124",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1124.png",
            "rarity_rank": 2547,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Sea Hat"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0773",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0773.png",
            "rarity_rank": 2548,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Modern Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1741",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1741.png",
            "rarity_rank": 2549,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4140",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4140.png",
            "rarity_rank": 2550,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4210",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4210.png",
            "rarity_rank": 2551,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Solana Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Green"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2663",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2663.png",
            "rarity_rank": 2552,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2400",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2400.png",
            "rarity_rank": 2553,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1723",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1723.png",
            "rarity_rank": 2554,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3550",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3550.png",
            "rarity_rank": 2555,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3315",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3315.png",
            "rarity_rank": 2556,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Ear Ornate"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2080",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2080.png",
            "rarity_rank": 2557,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie Up"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2567",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2567.png",
            "rarity_rank": 2558,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0266",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0266.png",
            "rarity_rank": 2559,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Moustache 02"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0416",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0416.png",
            "rarity_rank": 2560,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4230",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4230.png",
            "rarity_rank": 2561,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Small Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2622",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2622.png",
            "rarity_rank": 2562,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0121",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0121.png",
            "rarity_rank": 2563,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3492",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3492.png",
            "rarity_rank": 2564,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Floating Crown"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3688",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3688.png",
            "rarity_rank": 2565,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3498",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3498.png",
            "rarity_rank": 2566,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Baseball Hat"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2645",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2645.png",
            "rarity_rank": 2567,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2607",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2607.png",
            "rarity_rank": 2568,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2642",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2642.png",
            "rarity_rank": 2569,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2530",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2530.png",
            "rarity_rank": 2570,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2644",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2644.png",
            "rarity_rank": 2571,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2698",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2698.png",
            "rarity_rank": 2572,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4048",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4048.png",
            "rarity_rank": 2573,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Cigar"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2591",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2591.png",
            "rarity_rank": 2574,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0848",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0848.png",
            "rarity_rank": 2575,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1922",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1922.png",
            "rarity_rank": 2576,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2501",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2501.png",
            "rarity_rank": 2577,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2629",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2629.png",
            "rarity_rank": 2578,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0262",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0262.png",
            "rarity_rank": 2579,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3819",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3819.png",
            "rarity_rank": 2580,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Voodoo"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2696",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2696.png",
            "rarity_rank": 2581,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2776",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2776.png",
            "rarity_rank": 2582,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Tech Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3808",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3808.png",
            "rarity_rank": 2583,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4383",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4383.png",
            "rarity_rank": 2584,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Monocle"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1588",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1588.png",
            "rarity_rank": 2585,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2606",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2606.png",
            "rarity_rank": 2586,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2493",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2493.png",
            "rarity_rank": 2587,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3404",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3404.png",
            "rarity_rank": 2588,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3679",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3679.png",
            "rarity_rank": 2589,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Bird"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1194",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1194_Pod_195_Burnt_1850.png",
            "rarity_rank": 2590,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4047",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4047.png",
            "rarity_rank": 2591,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Silly Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1978",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1978.png",
            "rarity_rank": 2592,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4410",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4410.png",
            "rarity_rank": 2593,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3682",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3682.png",
            "rarity_rank": 2594,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Eyed Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2639",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2639.png",
            "rarity_rank": 2595,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3452",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3452.png",
            "rarity_rank": 2596,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1845",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1845.png",
            "rarity_rank": 2597,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3751",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3751.png",
            "rarity_rank": 2598,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4454",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4454.png",
            "rarity_rank": 2599,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Purple Bat"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3778",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3778.png",
            "rarity_rank": 2600,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2577",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2577.png",
            "rarity_rank": 2601,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3543",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3543.png",
            "rarity_rank": 2602,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Devil Costume"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1653",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1653.png",
            "rarity_rank": 2603,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Teeth"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1626",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1626.png",
            "rarity_rank": 2604,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4467",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4467.png",
            "rarity_rank": 2605,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Yellow Green"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3722",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3722.png",
            "rarity_rank": 2606,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3559",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3559.png",
            "rarity_rank": 2607,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2538",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2538.png",
            "rarity_rank": 2608,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2521",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2521.png",
            "rarity_rank": 2609,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2668",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2668.png",
            "rarity_rank": 2610,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1916",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1916.png",
            "rarity_rank": 2611,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2010",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2010.png",
            "rarity_rank": 2612,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Nose Piercing"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2600",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2600.png",
            "rarity_rank": 2613,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1655",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1655.png",
            "rarity_rank": 2614,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2075",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2075.png",
            "rarity_rank": 2615,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2690",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2690.png",
            "rarity_rank": 2616,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Shoulder Pads"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2548",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2548.png",
            "rarity_rank": 2617,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Zebra Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2614",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2614.png",
            "rarity_rank": 2618,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0223",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0223.png",
            "rarity_rank": 2619,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3367",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3367.png",
            "rarity_rank": 2620,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Metal Mask"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0968",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0968.png",
            "rarity_rank": 2621,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Solana Pattern Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3532",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3532.png",
            "rarity_rank": 2622,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Halo"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1896",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1896.png",
            "rarity_rank": 2623,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Stone"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1831",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1831.png",
            "rarity_rank": 2624,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cheetah Print"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1010",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1010.png",
            "rarity_rank": 2625,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1838",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1838.png",
            "rarity_rank": 2626,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2662",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2662.png",
            "rarity_rank": 2627,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3496",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3496.png",
            "rarity_rank": 2628,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3392",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3392.png",
            "rarity_rank": 2629,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2626",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2626.png",
            "rarity_rank": 2630,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Red"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0141",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0141.png",
            "rarity_rank": 2631,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Axe Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2720",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2720.png",
            "rarity_rank": 2632,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Bat Weapon"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2487",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2487.png",
            "rarity_rank": 2633,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3538",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3538.png",
            "rarity_rank": 2634,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2490",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2490.png",
            "rarity_rank": 2635,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2345",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2345.png",
            "rarity_rank": 2636,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Triangle Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1134",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1134.png",
            "rarity_rank": 2637,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Yellow Cape"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2058",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2058.png",
            "rarity_rank": 2638,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Zombie"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Black Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4304",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4304.png",
            "rarity_rank": 2639,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Magenta Ghost"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2646",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2646.png",
            "rarity_rank": 2640,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Rainbow Hair"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1604",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1604.png",
            "rarity_rank": 2641,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Pixel Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2509",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2509.png",
            "rarity_rank": 2642,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Purple Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2712",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2712.png",
            "rarity_rank": 2643,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3394",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3394.png",
            "rarity_rank": 2644,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Round Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1593",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1593.png",
            "rarity_rank": 2645,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Purple Hairdo"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2090",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2090.png",
            "rarity_rank": 2646,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1651",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1651.png",
            "rarity_rank": 2647,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "VR Headset"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2140",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2140.png",
            "rarity_rank": 2648,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Purple"
                },
                {
                    "trait_type": "Accessories",
                    "value": "Gold Chain"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #4147",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_4147.png",
            "rarity_rank": 2649,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Pineapple"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1858",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1858.png",
            "rarity_rank": 2650,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Purple Bandana"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3553",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3553.png",
            "rarity_rank": 2651,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gray Tech Suit"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1933",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1933.png",
            "rarity_rank": 2652,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Purple"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Blue Hoodie"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3783",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3783.png",
            "rarity_rank": 2653,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cute Colorful"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Rainbow Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3869",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3869.png",
            "rarity_rank": 2654,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Blue Hairdo"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1007",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1007.png",
            "rarity_rank": 2655,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Flowers"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3210",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3210.png",
            "rarity_rank": 2656,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Gold Dress"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2611",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2611.png",
            "rarity_rank": 2657,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Panda Cap"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1595",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1595.png",
            "rarity_rank": 2658,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Blue Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Striped Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2497",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2497.png",
            "rarity_rank": 2659,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1774",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1774.png",
            "rarity_rank": 2660,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Green"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2502",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2502.png",
            "rarity_rank": 2661,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0784",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0784.png",
            "rarity_rank": 2662,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Cactus"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Heart Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #0279",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_0279.png",
            "rarity_rank": 2663,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gold Drip"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2479",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2479.png",
            "rarity_rank": 2664,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2537",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2537.png",
            "rarity_rank": 2665,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Graffiti Gray"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Cat Ghost"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2697",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2697.png",
            "rarity_rank": 2666,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Fire"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1676",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1676.png",
            "rarity_rank": 2667,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Yellow"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1666",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1666.png",
            "rarity_rank": 2668,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Black And White"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #1683",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_1683.png",
            "rarity_rank": 2669,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Gray Trippy"
                },
                {
                    "trait_type": "Background",
                    "value": "Glow Blue"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2983",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2983.png",
            "rarity_rank": 2670,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Purple Sunset"
                },
                {
                    "trait_type": "Eyewear",
                    "value": "Hipster Glasses"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2486",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2486.png",
            "rarity_rank": 2671,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Blue"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Strawberry Ice Cream"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2564",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2564.png",
            "rarity_rank": 2672,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Pink"
                },
                {
                    "trait_type": "Background",
                    "value": "Orange"
                },
                {
                    "trait_type": "Headwear",
                    "value": "Vanilla Ice Cream"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2512",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2512.png",
            "rarity_rank": 2673,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "White Dress Shirt"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2820",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2820.png",
            "rarity_rank": 2674,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Glossy Black"
                },
                {
                    "trait_type": "Background",
                    "value": "Wiggly Blue"
                },
                {
                    "trait_type": "Clothes",
                    "value": "Two Belts"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #3446",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_3446.png",
            "rarity_rank": 2675,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Graffiti"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Orange"
                }
            ],
            "rarity": null
        },
        {
            "name": "3D Sol Kitties #2985",
            "image": "https:\/\/ewr1.vultrobjects.com\/nfts\/3DSolKittie_2985.png",
            "rarity_rank": 2676,
            "attributes": [
                {
                    "trait_type": "Skin",
                    "value": "Red Alien"
                },
                {
                    "trait_type": "Background",
                    "value": "Kitty Pattern Blue"
                }
            ],
            "rarity": null
        }
    ];

export default Rarity3dJson;