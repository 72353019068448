import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { PublicKey } from "@solana/web3.js";

interface initialStateTypes {
    raffleStatistics: {
        raffleWinners: number,
        soldTickets: number,
        uniqueRaffleWallets: number,
        liveRaffles: number
    },
    activeFilterTab: string,
    searchParamFilterTabFlag: boolean,
    customFilterData: any,
    customFilterApplyCount: number | string,
    getRaffleNftDataFlag: boolean,
    transactionStatusMultipleBuyTickets: { raffleId: string, isSuccess: boolean },
    isBuyTicketsRunning: { raffleAddress: PublicKey|undefined, isRunning: boolean }
}

const initialState: initialStateTypes = {
    raffleStatistics: {
        raffleWinners: 0,
        soldTickets: 0,
        uniqueRaffleWallets: 0,
        liveRaffles: 0
    },
    activeFilterTab: "",
    searchParamFilterTabFlag: false,
    customFilterData: [
        {"key": "solkitty", "value": "Sol Kitties Raffles", "checked": false},
        {"key": "fff", "value": "FFF Raffles", "checked": false},
        {"key": "monet", "value": "Monet Raffles", "checked": false}
    ],
    customFilterApplyCount: 0,
    getRaffleNftDataFlag: false,
    transactionStatusMultipleBuyTickets: { raffleId: "", isSuccess: false },
    isBuyTicketsRunning: { raffleAddress: undefined, isRunning: false }
}

export const raffleReduxSlice = createSlice({
    name: 'utilInfo',
    initialState,
    reducers: {
        resetRaffleReduxState: () => initialState,
        raffleStatisticsAction: (state: initialStateTypes, action: PayloadAction<any>) => {
            state.raffleStatistics = {
                ...state.raffleStatistics,
                raffleWinners: action.payload.raffleWinners,
                soldTickets: action.payload.soldTickets,
                uniqueRaffleWallets: action.payload.uniqueRaffleWallets,
                liveRaffles: action.payload.liveRaffles
            }
        },
        activeFilterTabAction: (state: initialStateTypes, action: PayloadAction<string>) => {
            state.activeFilterTab = action.payload;
        },
        searchParamFilterTabFlagAction: (state: initialStateTypes, action: PayloadAction<boolean>) => {
            state.searchParamFilterTabFlag = action.payload;
        },
        customFilterDataAction: (state: initialStateTypes, action: PayloadAction<any>) => {
            state.customFilterData = action.payload;
        },
        customFilterApplyCountAction: (state: initialStateTypes, action: PayloadAction<number | string>) => {
            state.customFilterApplyCount = action.payload;
        },
        getRaffleNftDataFlagAction: (state: initialStateTypes, action: PayloadAction<boolean>) => {
            state.getRaffleNftDataFlag = action.payload;
        },
        setTransactionStatusMultipleBuyTicketsAction: (state: initialStateTypes, action: PayloadAction<{ raffleId: string, isSuccess: boolean }>) => {
            state.transactionStatusMultipleBuyTickets = action.payload;
        },
        setIsBuyTicketsRunning: (state: initialStateTypes, action: PayloadAction<{ raffleAddress: PublicKey|undefined, isRunning: boolean }>) => {
            state.isBuyTicketsRunning = action.payload;
        },
    },
});

/*Action creators are generated for each case reducer function*/
export const {
    resetRaffleReduxState,
    raffleStatisticsAction,
    activeFilterTabAction,
    searchParamFilterTabFlagAction,
    customFilterDataAction,
    customFilterApplyCountAction,
    getRaffleNftDataFlagAction,
    setTransactionStatusMultipleBuyTicketsAction,
    setIsBuyTicketsRunning,
} = raffleReduxSlice.actions

export default raffleReduxSlice.reducer
