import { TOKEN_PROGRAM_ID, Token } from '@solana/spl-token';
import { clusterApiUrl, Connection, PublicKey, Keypair, SystemProgram, Transaction, TransactionSignature, sendAndConfirmTransaction, TransactionInstruction, LAMPORTS_PER_SOL } from "@solana/web3.js";
import * as anchor from '@project-serum/anchor'
import {
	STAKING_WALLET_ADDRESS,
	SOLANA_RPC_HOST_MAINNET,
	SOLANA_RPC_HOST_MAINNET2,
	STAKE_IN_PROGRESS, STAKING_STAKE_FEE
} from './constants'
import {toast} from "react-toastify";
import axios from "axios";
import {web3} from "@project-serum/anchor";
import bs58 from "bs58";
import {getNetworkFromConnection} from "./utils";

const sleep = (ms: number): Promise<void> => {
	return new Promise(resolve => setTimeout(resolve, ms));
};

export const sendNft = async (wallet: anchor.Wallet, tokenAddress: string, connection: Connection): Promise<anchor.web3.SignatureStatus | null | void> =>
{
	//console.log(SOLANA_RPC_HOST_MAINNET);
	//console.log(STAKING_WALLET_ADDRESS);

	//const connection = new Connection("https://solana-api.projectserum.com");
	//const connection = new Connection("https://api.mainnet-beta.solana.com", "confirmed");
	//const connection = new Connection(SOLANA_RPC_HOST_MAINNET2, "confirmed");

	const networkConnection = getNetworkFromConnection(connection);
	//console.log(connection['_rpcEndpoint']);
	console.log("Connection Network:", networkConnection);

	const tokenPublicKey = new PublicKey(tokenAddress);
	const destPublicKey = new PublicKey(STAKING_WALLET_ADDRESS);

	console.log(`#0 Staking Token ${tokenAddress} into ${STAKING_WALLET_ADDRESS}`);

	console.log("#1 Create Token");
	const nftToken = new Token(
		connection,
		tokenPublicKey,
		TOKEN_PROGRAM_ID,
		wallet.payer // the wallet owner will pay to transfer and to create recipients associated token account if it does not yet exist.
	);

	let response = await connection.getTokenAccountsByOwner(
		wallet.publicKey, // owner here
		{
			mint: tokenPublicKey,
		}
	);

	let fromTokenAccountAddress = "";
	if (response.value && response.value[0])
	{
		console.log("#2.1 Option-1: Get TokenAccount");
		fromTokenAccountAddress = response.value[0].pubkey.toBase58();
	}
	else
	{
		console.log("#2.2 Option-2: Get TokenAccount");
		const fromTokenAccount = await nftToken.getOrCreateAssociatedAccountInfo(
			wallet.publicKey
		);
		fromTokenAccountAddress = fromTokenAccount?.address.toBase58();
	}

	console.log(fromTokenAccountAddress);

	// Get the derived address of the destination wallet which will hold the custom token
	console.log("#3 Destination associatedTokenAddress");
	const associatedDestinationTokenAddr = await Token.getAssociatedTokenAddress(
		nftToken.associatedProgramId,
		nftToken.programId,
		tokenPublicKey,
		destPublicKey
	);
	
	const receiverAccount = await connection.getAccountInfo(associatedDestinationTokenAddr);
				
	const instructions: TransactionInstruction[] = [];

	if (receiverAccount === null)
	{
		console.log("#4.1 Create Receiver Account");
		instructions.push(
			Token.createAssociatedTokenAccountInstruction(
				nftToken.associatedProgramId,
				nftToken.programId,
				tokenPublicKey,
				associatedDestinationTokenAddr,
				destPublicKey,
				wallet.publicKey
			)
		)
	}

	console.log("#4.2 Create Transfer Instruction");
	instructions.push(
		Token.createTransferInstruction(
			TOKEN_PROGRAM_ID,
			new PublicKey(fromTokenAccountAddress),
			associatedDestinationTokenAddr,
			wallet.publicKey,
			[],
			1
		)
	);

	console.log("#4.3 TX Fees");
	const lamportsToSend = STAKING_STAKE_FEE * LAMPORTS_PER_SOL;
	instructions.push(
		SystemProgram.transfer({
			fromPubkey: wallet.publicKey,
			toPubkey: destPublicKey,
			lamports: lamportsToSend,
		})
	);

	console.log("#5 Adding Transaction Instructions");
	const transaction = new Transaction().add(...instructions);

	// // Sign transaction, broadcast, and confirm
	// console.log("#6 Sign transaction, broadcast, and confirm");
	// const signature = await sendAndConfirmTransaction(
	// 	connection,
	// 	transaction,
	// 	[wallet.payer]
	// );
	//
	// console.log("SIGNATURE", signature);
	// console.log("SUCCESS");

	console.log("#6 Get Latest Block Hash");
	const latestBlockHash = await connection.getRecentBlockhash();// getLatestBlockhash();
	console.log("#6.1 Recent BlockHash: ", latestBlockHash);
	transaction.feePayer = wallet.publicKey;
	transaction.recentBlockhash = latestBlockHash.blockhash;

	console.log("#7 Sign Transaction");
	let signed = await wallet.signTransaction(transaction);
	console.log(signed);

	let toastId = toast.info(STAKE_IN_PROGRESS, {autoClose: 50000});

	console.log("#8 Send RawTransaction");
	let txid = await connection.sendRawTransaction(signed.serialize());
	console.log("Txid:", txid);

	let success = false;
	let retries = 10;
	while (!success && retries > 0)
	{
		try
		{
			console.log("#9 Confirm Transaction");
			await connection.confirmTransaction(txid);

			toast.dismiss();
			toastId = toast.info("Transaction sent successfully, waiting for chain confirmation.", {autoClose: 35000});
			console.log(`Transaction sent with Signature: ${txid}`);
			success = true;
		}
		catch (error: any)
		{
			console.log("Error:", error.message);
			toast.dismiss(toastId);
			toastId = toast.error(`Staking failed, trying again: ${retries}/10`, {autoClose: 35000});
			retries--;
			await sleep(1000);
		}
	}

	//toast.dismiss(toastId);
}