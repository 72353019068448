import React from 'react'
import {Box, Button} from '@material-ui/core';
import { useContext, useEffect, useState } from 'react'
import * as utilsHelper from '../../config/utils';
import * as anchor from '@project-serum/anchor'
import {useWallet} from '@solana/wallet-adapter-react'
import {WalletMultiButton} from '@solana/wallet-adapter-material-ui'

import {
  awaitTransactionSignatureConfirmation,
  CandyMachineAccount,
  getCandyMachineState,
  mintOneToken,
} from 'config/candyMachine'
import { SnackbarContext } from 'context'

// styles
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import axios from 'axios';
import {MyNavBar} from "../../sharedComponent/MyNavBar";
import TitleProd from "../../assets/images/title.png";
import pods from "../../assets/images/pods.png";
import Avtar from "../../assets/images/solana-avatar.png";
import {Footer} from "../../sharedComponent/Footer";
import {utils} from "@project-serum/anchor";
import * as QueryString from "query-string";
import {SubFooter} from "../../sharedComponent/SubFooter";

const candyDefault = "2gt5oP4ttWJDd32RxqbhPHQeFGZwwgxDGFKhYRy4PjhY";//8rLhXceSCTGEbFeXdj59hJMbQ5EFyL5Kd4vrLJ4XbXXX"; // 16 more
const candy20 = "5j6rMZngkp4bBa9s1dixb4YQzKcFKWJJnJ4eFzHZayG8";
const candy30 = "5mZRop5XnfauQqf7QNe7ndoQoV8xyB8reBdaWzRHK3aW";

const prickly_a = "8HgXjLtiNjeoiHafBYV5sFrmG7Dnb5PQPEGqNXJ7uvNn"
const prickly_b = "wjBbYY9TnMqjiQFDhW1wa4om2wE8svN3KZUw5HrsavZ"
const prickly_c = "6EY1LyCNoM16hUZbvB2jHnBLUt46FxyQxrTcTF9NyPLL"

const robot_burger = "BkTY1QznhTUzsT31soWSbU3VwtUjqbeUSkCo7xX4S9Y9";
let candyMachineId = new anchor.web3.PublicKey(candyDefault!);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        background: "url('../../assets/part1/bg-mint2.png'), linear-gradient(180deg, #224F93 0%, #161131 78.83%)",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
        width: '100%'
      },
      welcomeArea: {
        width: '353px',
        marginTop: '45px',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        [theme.breakpoints.down('sm')]: {
          width: 'calc(100% - 60px)',
          paddingLeft: '40px'
        },
        [theme.breakpoints.down('xs')]: {
          width: 'calc(100% - 40px)',
          paddingLeft: '20px'
        }
      },
      welcomeTitle: {
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '39px',
        color: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
          fontSize: '27px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '25px',
        }
      },
      welcomeSol: {
        fontWeight: 'bold',
        fontSize: '58px',
        lineHeight: '80px',
        color: '#FFD029',
        [theme.breakpoints.down('sm')]: {
          fontSize: '48px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '37px',
        }
      },
      welcomeSubTitle: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '22px',
        color: '#A7D5FF',
        [theme.breakpoints.down('sm')]: {
          fontSize: '14px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '13px',
        }
      },
      mintTitle: {
        marginTop: '29px',
        fontWeight: 'bold',
        fontSize: '35px',
        lineHeight: '48px',
        color: '#E16BFF',
        [theme.breakpoints.down('sm')]: {
          fontSize: '30px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '25px',
        }
      },
      mintSubTitle: {
        fontWeight: 600,
        fontSize: '17px',
        lineHeight: '24px',
        color: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
          fontSize: '14px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '13px',
        }
      },
      mintAvatarArea: {
        position: 'relative',
        width: '213px',
        height: '213px',
        background: '#FFD029',
        marginTop: '52px',
        borderRadius: '32px',
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
          marginTop: '150px',
          width: '180px',
          height: '180px'
        },
        [theme.breakpoints.down('xs')]: {
          marginTop: '100px',
          width: '120px',
          height: '120px'
        }
      },
      mintAvatar: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        width: '213px',
        height: '213px',
        background: '#FFD029',
        borderRadius: '32px',
        border: '4px solid #000000',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
          width: '180px',
          height: '180px'
        },
        [theme.breakpoints.down('xs')]: {
          width: '120px',
          height: '120px'
        }
      },
      mintImg: {
        width: '137px',
        height: '136px',
        background: "url('../../assets/images/cat_shiluette.png')",
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
          width: '100px',
          height: '100px'
        }
      },
      askText: {
        fontWeight: 'bold',
        fontSize: '85px',
        lineHeight: '120px',
        color: '#C08A13',
        [theme.breakpoints.down('xs')]: {
          fontSize: '65px',
        }
      },
      mintAvatars: {
        position: 'absolute',
        width: '480px',
        height: '360px',
        background: "url('../../assets/part1/mint-avatars.png')",
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
        top: '-20%',
        left: '-65%',
        [theme.breakpoints.down('sm')]: {
          width: '450px',
          height: '300px',
          top: '-25px',
          left: '-125px',
        },
        [theme.breakpoints.down('xs')]: {
          width: '280px',
          height: '200px',
          top: '-20px',
          left: '-70px',
        }
      },
      mintButton: {
        position: 'absolute',
        width: '220.25px',
        height: '61.85px',
        background: '#FFD029',
        borderRadius: '16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        bottom: '-82px',
        left: '0px',
        '&:hover': {
          background: '#FFD029cc',
        },
        '&[disabled]': {
          background: '#FFD02966',
        },
        color: '#161131',
        fontSize: '29px',
        fontWeight: 'bold',
        lineHeight: '53px',
        textTransform: 'none',
        boxShadow: 'none',

        [theme.breakpoints.down('sm')]: {
          width: '190px',
          fontSize: '35px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '28px',
          width: '135px',
          height: '45px',
          bottom: '-65px',
          borderRadius: '13px',
        }
      },
      mintButtonLabel: {
        fontWeight: 'bold',
        fontSize: '39px',
        lineHeight: '53px',
        color: '#161131',
        [theme.breakpoints.down('sm')]: {
          fontSize: '35px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '28px',
        }
      },
      mintDescription: {
        width: '253.7px',
        color: '#7B9AD6',
        fontWeight: 'bold',
        fontSize: '17.8px',
        lineHeight: '21px',
        margin: 'auto',
        marginTop: '88.15px',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
          fontSize: '15px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '14px',
        }
      },
      mintDescriptionPadding: {
        paddingLeft: '90px',
        marginTop: '0px',
        marginBottom: '0px',
        [theme.breakpoints.down('sm')]: {
          paddingLeft: '30px',
          paddingRight: '30px',
          marginBottom: '50px',
        }
      },
      howToMint: {
        fontWeight: 'bold',
        fontSize: '32.3px',
        lineHeight: '44px',
        color: '#7BF4FC',
        marginBottom: '6px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '25px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '20px',
        }
      },
      mintText: {
        fontWeight: 600,
        fontSize: '17px',
        lineHeight: '23px',
        color: '#FFFFFF',
        display: 'flex',
        justifyContent: 'flex-start',
        marginBottom: '13px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '14px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '12px',
        }
      },
      priceArea: {
        width: '80%',
        marginTop: '11px',
        marginBottom: '50px',
        [theme.breakpoints.down('sm')]: {
          width: 'calc(100% - 70px)',
          paddingLeft: '30px',
          paddingRight: '30px',
          marginBottom: '0px',
        }
      },
      priceLabel: {
        fontWeight: 'bold',
        fontSize: '32px',
        lineHeight: '44px',
        color: '#FFD74A',
        [theme.breakpoints.down('xs')]: {
          fontSize: '24px',
        }
      },
      priceInput: {
        width: '100%',
        height: '46px',
        border: '2px solid #FFD74A',
        borderRadius: '13.5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: '5px',

      },
      priceInputBox: {
        width: '90%',
        height: '60%',
        background: 'transparent',
        border: 'none',
        outline: 'none',
        fontSize: '25px',
        color: '#ffffff',
        '&::placeholder': {
          color: '#FFFFFF'
        }
      },
      priceGas: {
        textAlign: 'right',
        fontWeight: 'bold',
        fontSize: '17.5px',
        lineHeight: '21px',
        color: '#FFFFFF',
      },
      remainingLabel: {
        fontWeight: 'bold',
        fontSize: '32px',
        lineHeight: '44px',
        color: '#7165B2',
        [theme.breakpoints.down('xs')]: {
          fontSize: '24px',
        }
      },
      remainingInput: {
        width: '100%',
        height: '46px',
        border: '2px solid #7165B2',
        borderRadius: '13.5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: '5px',
      },
      solanaAvatarArea: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      },
      solana: {
        marginTop: '50px',
        marginLeft: '50px',
        width: '158px',
        fontWeight: 'bold',
        fontSize: '10.5px',
        lineHeight: '13px',
        color: '#43A4FF',
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
          marginBottom: '30px',
          marginLeft: '0px',
        }
      }
    }),
);

export function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export interface PodsMintProps {
  connection: anchor.web3.Connection,
  txTimeout: number
  location: any
}

export const ControllerPodstPublicMint = (props: PodsMintProps): JSX.Element => {
  const classes = useStyles()
  let params = QueryString.parse(props.location.search);

  const {showMessage} = useContext(SnackbarContext)

  const mintPrice = "? SOL";
  // const [balance, setBalance] = useState<number>()
  const [isActive] = useState(true) // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false) // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false) // true when user got to press MINT
  const [isCandyMachineRefreshNeeded, setIsCandyMachineRefreshNeeded] = useState(false) // true when user got to press MINT

  const [itemPriceSOL, setItemPriceSOL] = useState(0);
  const [remainingCount, setRemainingCount] = useState(0)
  // const [redeemdedCount, setRedeemedCount] = useState(0)
  const [availableCount, setAvailableCount] = useState(0)

  const wallet = useWallet()
  const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>()

  useEffect(() => {
    ;(async () => {
      //testTracker();

      if (isCandyMachineRefreshNeeded)
      {
        await loadCandyMachineState();

        setIsCandyMachineRefreshNeeded(false);
      }
    })();
  }, [])


  useEffect(() => {
    ;(async () => {
      if (
          !wallet ||
          !wallet.publicKey ||
          !wallet.signAllTransactions ||
          !wallet.signTransaction
      ) {
        return;
      }

      const params2 = new URLSearchParams(window.location.search);
      const group = params2.get('mint');
      if (group == "groupa")
      {
          candyMachineId = new anchor.web3.PublicKey(candy20!);
      }
      else if (group == "groupb")
      {
          candyMachineId = new anchor.web3.PublicKey(candy30!);
      }

      if (group == "pricklya")
      {
        candyMachineId = new anchor.web3.PublicKey(prickly_a!);
      }
      else if (group == "pricklyb")
      {
        candyMachineId = new anchor.web3.PublicKey(prickly_b!);
      }
      else if (group == "pricklyc")
      {
        candyMachineId = new anchor.web3.PublicKey(prickly_c!);
      }

      if (group == "robotburger")
      {
        candyMachineId = new anchor.web3.PublicKey(robot_burger!);
      }

      //console.log(group);
      //const accessToken = params.get('access_token');
      //const accessToken = params.arg.get params.get('access_token');

      await loadCandyMachineState();
    })()
  }, [wallet, candyMachineId, props.connection])

  const testTracker = async () => {
    console.log("Tracker Test");

    axios.post('https://dev--kittiesbot.solkitties.autocode.gg/kitties/hooks/mint-tracker/',
        {
          'State': 'After Mint',
          'publicKey': "5xwFhQkxANJQCid21Cwf2GKMwWNSzXLZLmT1EZpvRubB",
          'mintTxId': "2XLbZ2Cb8wxbFa8KopxCKgfcxQVX9upfQEUfuXSp4b5zDxD2j5FjwkqsuXoNaXCrrAUVYikp86w1G2agLu8fEzfU",
          'tokenMint': null,
          'itemsAvailable': 325,
          'itemsRedeemed': 26,
          'itemsRemaining': 299
        });
  }

  const anchorWallet = {
    publicKey: wallet.publicKey,
    signAllTransactions: wallet.signAllTransactions,
    signTransaction: wallet.signTransaction
  } as anchor.Wallet

  const mintOne = async () => {
    try
    {
      setIsMinting(true);
      document.getElementById('#identity')?.click();
      if (wallet.connected && candyMachine?.program && wallet.publicKey)
      {
        const mintTxId: any = (
            await mintOneToken(candyMachine, wallet.publicKey)
        )[0];
        console.log('TxID: ', mintTxId);

        let status: any = { err: true };
        if (mintTxId) {
          status = await awaitTransactionSignatureConfirmation(
              mintTxId,
              props.txTimeout,
              props.connection,
              true,
          );
        }

        console.log('Status:');
        console.log(status);
        if (status && !status.err)
        {
          // manual update since the refresh might not detect
          // the change immediately
          candyMachine.state.itemsRemaining -= 1;
          candyMachine.state.itemsRedeemed += 1;
          setRemainingCount(candyMachine.state.itemsRemaining)
          setIsSoldOut(candyMachine.state.itemsRemaining === 0);
          setCandyMachine(candyMachine);

          showMessage('Congratulations! Mint succeeded!', 'success')
          loadCandyMachineStateAfterMint(mintTxId);

          // //await OldSolKitties Promise(resolve => setTimeout(resolve, 13000));
          // //const response: any = await solanaConn.getParsedConfirmedTransaction(mintTxId);
          // const response = await axios.get(`https://public-api.solscan.io/transaction/${mintTxId}`);
          // console.log(response);
          // if (response.data && response.data.tokenBalanes && response.data.tokenBalanes[0] && response.data.tokenBalanes[0].token && response.data.tokenBalanes[0].token.tokenAddress)
          // {
          //     const tokenAddress = response.data.tokenBalanes[0].token.tokenAddress;
          //     console.log(`TokenAddress: ${tokenAddress}`);
          //
          //     const tokenMetadata = await axios.get(`https://api-mainnet.magiceden.io/rpc/getNFTByMintAddress/${tokenAddress}`);
          //     console.log(tokenMetadata);
          //
          //     let {id, owner, img, title, mintAddress, price} = tokenMetadata['data']['results'];
          //     axios.post('https://dev--kittiesbot.solkitties.autocode.gg/kitties/mint/',{mintTxId, id, owner, img, title, mintAddress, price});
          // }
          // else
          // {
          // const pubKey = wallet.publicKey.toString();
          // axios.post('https://dev--kittiesbot.solkitties.autocode.gg/kitties/mint/',{mintTxId, pubKey});
          // }
        }
        else
        {
          showMessage('Mint failed #1! Please try again!', 'error');
        }
      }
    }
    catch (error: any)
    {
      console.log(error);
      // TODO: blech:
      let message = error.msg || 'Minting failed #2! Please try again!';
      if (!error.msg) {
        if (!error.message) {
          message = 'Transaction Timeout! Please try again.';
        } else if (error.message.indexOf('0x138')) {
        } else if (error.message.indexOf('0x137')) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf('0x135')) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          window.location.reload();
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      showMessage(message, 'error');
    } finally {
      setIsMinting(false);
    }
  }
 useEffect(()=>{  showMessage('Congratulations! Mint succeeded!', 'success')},[])
  const handleMint = async () => {
    setIsMinting(true);

    console.log("mint one");
    await mintOne();

    setIsMinting(false);
  }

  const loadCandyMachineState = async () => {
    // if (wallet.publicKey) {
    //    axios.post('https://dev--kittiesbot.solkitties.autocode.gg/kitties/mint/', { 'State': 'Load', 'publicKey': wallet.publicKey.toString() });
    // }

    const candyMachine = await getCandyMachineState(anchorWallet, candyMachineId, props.connection);
    const itemPriceInSOL = utilsHelper.formatNumber.asNumber(candyMachine.state.price);
    const itemsRemaining = candyMachine.state.itemsRemaining;
    const itemsRedeemed = candyMachine.state.itemsRedeemed;
    const itemsAvailable = candyMachine.state.itemsAvailable;
    console.log(itemPriceInSOL, itemsRemaining, itemsRedeemed, itemsAvailable)

    // TEMP: flash mint only 10 per flash
    // const resp = await axios.get(`https://dev--kittiesbot.solkitties.autocode.gg/kitties/flash-mint/`);
    // const flashMintLive = resp.data;
    //
    // console.log(flashMintLive.islive == true);
    //
    // if (flashMintLive.islive)
    // {
    //     var maxAllowed = flashMintLive.maxAllowed;
    //     var itemsRemainingValue = maxAllowed - (itemsAvailable - itemsRemaining + flashMintLive.itemsRemainingOffset);
    //
    //     itemsRemainingValue = itemsRemainingValue > 0 ? itemsRemainingValue : 0;
    // }
    // else
    // {
    //     itemsRemainingValue = 0;
    // }

    setItemPriceSOL(itemPriceInSOL!);
    setRemainingCount(itemsRemaining)
    setIsSoldOut(itemsRemaining === 0);
    setCandyMachine(candyMachine)
  }

  const loadCandyMachineStateAfterMint = async (mintTxId: anchor.web3.PublicKey) =>
  {
    console.log("after mint");

    await sleep(500);

    //const candyMachine = await getCandyMachineState(anchorWallet, candyMachineId, props.connection);
    if (candyMachine)
    {
      const tokenMint = candyMachine.state.tokenMint;
      const itemsRemaining = candyMachine.state.itemsRemaining;
      const itemsRedeemed = candyMachine.state.itemsRedeemed;
      const itemsAvailable = candyMachine.state.itemsAvailable;
      console.log(candyMachine);
      console.log(tokenMint, itemsRemaining, itemsRedeemed, itemsAvailable)

      setIsCandyMachineRefreshNeeded(true);

      if (wallet.publicKey)
      {
        axios.post('https://dev--kittiesbot.solkitties.autocode.gg/kitties/hooks/mint-tracker/',
            {
              'State': 'After Mint',
              'publicKey': wallet.publicKey.toString(),
              'mintTxId': mintTxId,
              'tokenMint': tokenMint,
              'itemsAvailable': itemsAvailable,
              'itemsRedeemed': itemsRedeemed,
              'itemsRemaining': itemsRemaining
            });
      }
    }
  }

  return (
      <div className="main-head" style={{background: 'linear-gradient(180deg, #224F93 0%, #161131 78.83%)'}}>
        <MyNavBar />
        <div className="head-banner">
          <div className="title-img">
            <img src={TitleProd} alt='title text image' />
          </div>
          <img className="prod-img mt-30 mb-10" src={pods} alt='twitter' />
          <div className="bottom-btn-wrapper">
            <div className="price-box">
              <label>Price</label>
              <input type="text" readOnly={true} value={itemPriceSOL == 0 ? mintPrice : itemPriceSOL + " SOL"}></input>
              <Box className="priceGas">+ gas fee</Box>
            </div>
            <div className="price-box mint-box">
              <p>
                {wallet.connected &&
                    <Button
                        disabled={ !wallet.connected || isSoldOut || isMinting || !isActive }
                        onClick={handleMint}
                    >
                      {isMinting ? 'Minting...' : isSoldOut ? 'SOLD OUT' : 'Mint'}
                    </Button>
                }

                {!wallet.connected &&
                    <WalletMultiButton>
                      Connect
                    </WalletMultiButton>
                }
              </p>
              <div className="repeat-tras">1 per transaction.<br/> Need more? Just repeat.</div>
            </div>
            <div className="price-box green-value">
              <label className="text-green text-right">Remaining</label>
              <input type="text" readOnly={true} value={remainingCount == 0 ? "?" : remainingCount} className="text-center"></input>
            </div>
          </div>
        </div>

        <SubFooter/>
        <Footer />
      </div>
  )
}
