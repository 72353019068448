import React from 'react'
import {Box, Button} from '@material-ui/core';
import { useContext, useEffect, useRef, useState } from 'react'
import * as utilsHelper from '../../config/utils';
import * as anchor from '@project-serum/anchor'
import {useWallet} from '@solana/wallet-adapter-react'
import {WalletMultiButton} from '@solana/wallet-adapter-material-ui'

import {
  awaitTransactionSignatureConfirmation,
  CandyMachineAccount,
  getCandyMachineState,
  mintOneToken,
} from 'config/candyMachine'
import { SnackbarContext } from 'context'

// styles
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import axios from 'axios';
import MintingTitle from "../../assets/images/minting/minting-title.png";
import MintingTemplate from "../../assets/images/minting/mint-template.png";
import * as QueryString from "query-string";
import {SubFooter} from "../../sharedComponent/SubFooter";

const endPoint_API = `https://api.solkitties.net/kitties/hooks/api/`;
const mintConfig_endPoint = `https://api.solkitties.net/kitties/hooks/mint-config/`;
const mintTracker_endPoint = `https://api.solkitties.net/kitties/hooks/mint-tracker/`;

const candyDefault = "6fqjfrai2ZoApYa83uT3hiBojyrfHbhjmJPQ9563SJ6E"; // 25_2_2
const candy_30_1_5 = "FmGmy9ZKX72zN6waJqcsYg8iaWvgGdYGP8Tyjx9pWKNB";
const candy_40_1_75 = "4XDXSk1ZTHUuZeC2ipGNgEhvFoVfEoPYLtLYDW2NoAsj";
const candy_40_2_75 = "9jhzwyMoaE91CqThgEEbV8DKF8WNpnvqcnkjxCJLPXwv";
const candy_30_1_9 = "5ZwG8WQCVeArcX4iaqLL3Hs7yLe3zNruiribQ1EpBEDj";
const candy_30_2_1_9 = "FmGmy9ZKX72zN6waJqcsYg8iaWvgGdYGP8Tyjx9pWKNB";

//const offset = 0;

let candyMachineId = new anchor.web3.PublicKey(candyDefault!);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        background: "url('../../assets/part1/bg-mint2.png'), linear-gradient(180deg, #224F93 0%, #161131 78.83%)",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
        width: '100%'
      },
      welcomeArea: {
        width: '353px',
        marginTop: '45px',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        [theme.breakpoints.down('sm')]: {
          width: 'calc(100% - 60px)',
          paddingLeft: '40px'
        },
        [theme.breakpoints.down('xs')]: {
          width: 'calc(100% - 40px)',
          paddingLeft: '20px'
        }
      },
      welcomeTitle: {
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '39px',
        color: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
          fontSize: '27px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '25px',
        }
      },
      welcomeSol: {
        fontWeight: 'bold',
        fontSize: '58px',
        lineHeight: '80px',
        color: '#FFD029',
        [theme.breakpoints.down('sm')]: {
          fontSize: '48px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '37px',
        }
      },
      welcomeSubTitle: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '22px',
        color: '#A7D5FF',
        [theme.breakpoints.down('sm')]: {
          fontSize: '14px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '13px',
        }
      },
      mintTitle: {
        marginTop: '29px',
        fontWeight: 'bold',
        fontSize: '35px',
        lineHeight: '48px',
        color: '#E16BFF',
        [theme.breakpoints.down('sm')]: {
          fontSize: '30px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '25px',
        }
      },
      mintSubTitle: {
        fontWeight: 600,
        fontSize: '17px',
        lineHeight: '24px',
        color: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
          fontSize: '14px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '13px',
        }
      },
      mintAvatarArea: {
        position: 'relative',
        width: '213px',
        height: '213px',
        background: '#FFD029',
        marginTop: '52px',
        borderRadius: '32px',
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
          marginTop: '150px',
          width: '180px',
          height: '180px'
        },
        [theme.breakpoints.down('xs')]: {
          marginTop: '100px',
          width: '120px',
          height: '120px'
        }
      },
      mintAvatar: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        width: '213px',
        height: '213px',
        background: '#FFD029',
        borderRadius: '32px',
        border: '4px solid #000000',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
          width: '180px',
          height: '180px'
        },
        [theme.breakpoints.down('xs')]: {
          width: '120px',
          height: '120px'
        }
      },
      mintImg: {
        width: '137px',
        height: '136px',
        background: "url('../../assets/images/cat_shiluette.png')",
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
          width: '100px',
          height: '100px'
        }
      },
      askText: {
        fontWeight: 'bold',
        fontSize: '85px',
        lineHeight: '120px',
        color: '#C08A13',
        [theme.breakpoints.down('xs')]: {
          fontSize: '65px',
        }
      },
      mintAvatars: {
        position: 'absolute',
        width: '480px',
        height: '360px',
        background: "url('../../assets/part1/mint-avatars.png')",
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
        top: '-20%',
        left: '-65%',
        [theme.breakpoints.down('sm')]: {
          width: '450px',
          height: '300px',
          top: '-25px',
          left: '-125px',
        },
        [theme.breakpoints.down('xs')]: {
          width: '280px',
          height: '200px',
          top: '-20px',
          left: '-70px',
        }
      },
      mintButton: {
        position: 'absolute',
        width: '220.25px',
        height: '61.85px',
        background: '#FFD029',
        borderRadius: '16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        bottom: '-82px',
        left: '0px',
        '&:hover': {
          background: '#FFD029cc',
        },
        '&[disabled]': {
          background: '#FFD02966',
        },
        color: '#161131',
        fontSize: '29px',
        fontWeight: 'bold',
        lineHeight: '53px',
        textTransform: 'none',
        boxShadow: 'none',

        [theme.breakpoints.down('sm')]: {
          width: '190px',
          fontSize: '35px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '28px',
          width: '135px',
          height: '45px',
          bottom: '-65px',
          borderRadius: '13px',
        }
      },
      mintButtonLabel: {
        fontWeight: 'bold',
        fontSize: '39px',
        lineHeight: '53px',
        color: '#161131',
        [theme.breakpoints.down('sm')]: {
          fontSize: '35px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '28px',
        }
      },
      mintDescription: {
        width: '253.7px',
        color: '#7B9AD6',
        fontWeight: 'bold',
        fontSize: '17.8px',
        lineHeight: '21px',
        margin: 'auto',
        marginTop: '88.15px',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
          fontSize: '15px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '14px',
        }
      },
      mintDescriptionPadding: {
        paddingLeft: '90px',
        marginTop: '0px',
        marginBottom: '0px',
        [theme.breakpoints.down('sm')]: {
          paddingLeft: '30px',
          paddingRight: '30px',
          marginBottom: '50px',
        }
      },
      howToMint: {
        fontWeight: 'bold',
        fontSize: '32.3px',
        lineHeight: '44px',
        color: '#7BF4FC',
        marginBottom: '6px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '25px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '20px',
        }
      },
      mintText: {
        fontWeight: 600,
        fontSize: '17px',
        lineHeight: '23px',
        color: '#FFFFFF',
        display: 'flex',
        justifyContent: 'flex-start',
        marginBottom: '13px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '14px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '12px',
        }
      },
      priceArea: {
        width: '80%',
        marginTop: '11px',
        marginBottom: '50px',
        [theme.breakpoints.down('sm')]: {
          width: 'calc(100% - 70px)',
          paddingLeft: '30px',
          paddingRight: '30px',
          marginBottom: '0px',
        }
      },
      priceLabel: {
        fontWeight: 'bold',
        fontSize: '32px',
        lineHeight: '44px',
        color: '#FFD74A',
        [theme.breakpoints.down('xs')]: {
          fontSize: '24px',
        }
      },
      priceInput: {
        width: '100%',
        height: '46px',
        border: '2px solid #FFD74A',
        borderRadius: '13.5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: '5px',

      },
      priceInputBox: {
        width: '90%',
        height: '60%',
        background: 'transparent',
        border: 'none',
        outline: 'none',
        fontSize: '25px',
        color: '#ffffff',
        '&::placeholder': {
          color: '#FFFFFF'
        }
      },
      priceGas: {
        textAlign: 'right',
        fontWeight: 'bold',
        fontSize: '17.5px',
        lineHeight: '21px',
        color: '#FFFFFF',
      },
      remainingLabel: {
        fontWeight: 'bold',
        fontSize: '32px',
        lineHeight: '44px',
        color: '#7165B2',
        [theme.breakpoints.down('xs')]: {
          fontSize: '24px',
        }
      },
      remainingInput: {
        width: '100%',
        height: '46px',
        border: '2px solid #7165B2',
        borderRadius: '13.5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: '5px',
      },
      solanaAvatarArea: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      },
      solana: {
        marginTop: '50px',
        marginLeft: '50px',
        width: '158px',
        fontWeight: 'bold',
        fontSize: '10.5px',
        lineHeight: '13px',
        color: '#43A4FF',
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
          marginBottom: '30px',
          marginLeft: '0px',
        }
      }
    }),
);

export function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export interface PodsMintProps {
  connection: anchor.web3.Connection,
  txTimeout: number
  location: any
}

export const ControllerPublicMint = (props: PodsMintProps): JSX.Element => {
  const [timer, setTimer] = useState({
    day: '00',
    hour: '00',
    minute: '00',
    second: '00'
  })
  const classes = useStyles()
  let params = QueryString.parse(props.location.search);
  const {showMessage} = useContext(SnackbarContext)
  const mintPrice = "? SOL";
  const [isMintActive, setIsMintActive] = useState(false) // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false) // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false) // true when user got to press MINT
  const [isCandyMachineRefreshNeeded, setIsCandyMachineRefreshNeeded] = useState(false) // true when user got to press MINT
  const itemPriceSOL = useRef(0);
  const remainingCount = useRef(0)
  const mintedCount = useRef(0)
  const [availableCount, setAvailableCount] = useState(0)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const isLoadFromCM = useRef(true);
  const [isRdyToShow, setIsRdyToShow] = useState(false)
  const [isConnected, setIsConnected] = useState(false)

  const wallet = useWallet()
  const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>()

  const countDown = () => {
    var now = new Date();
    var nowUtc = new Date(now.getTime() + now.getTimezoneOffset() * 60000).getTime();
    const downDate = new Date("Mar 30, 2022 19:00:00").getTime()
    var timeleft = downDate - nowUtc;

    timeleft = timeleft > 0 ? timeleft : 0;

    if (timeleft == 0) {
      setIsMintActive(true);
    }

    const days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
    setTimer({
      day: days > 9 ? `${days}` : `0${days}`,
      hour: hours > 9 ? `${hours}` : `0${hours}`,
      minute: minutes > 9 ? `${minutes}` : `0${minutes}`,
      second: seconds > 9 ? `${seconds}` : `0${seconds}`
    })
  }

  // useEffect(() => {
  //   const countdownFunc = setInterval(function() {
  //     countDown();
  //   }, 1000);
  //
  //   countDown();
  // }, [])

  useEffect(() => {
    ;(async () => {
      //await testTracker();

      if (isCandyMachineRefreshNeeded)
      {
        console.log("Candy Refresh");

        await loadCandyMachineState();

        setIsCandyMachineRefreshNeeded(false);
      }
    })();
  }, [])

  useEffect(() => {
    ;(async () => {
      if (
          !wallet ||
          !wallet.publicKey ||
          !wallet.signAllTransactions ||
          !wallet.signTransaction //|| isConnected
      ) {
        return;
      }

      setIsConnected(true);

      const params2 = new URLSearchParams(window.location.search);
      const group = params2.get('group');
      const mint = params2.get('mint');

      const res = (await axios.post(mintConfig_endPoint,
          {
            'group': group,
            'mint': mint
          })).data;

      console.log(res);

      if (res && res.group != 'None')
      {
          let mintFound = false;
          if (mint == "wl1")
          {
              candyMachineId = new anchor.web3.PublicKey(candyDefault!);
              mintFound = true;
          }
          else if (mint == "wl2")
          {
              candyMachineId = new anchor.web3.PublicKey(candy_40_2_75!);
              mintFound = true;
          }
          else if (mint == "wl2_2")
          {
            candyMachineId = new anchor.web3.PublicKey(candy_40_2_75!);
            mintFound = true;
          }
          else if (mint == "wl3")
          {
              candyMachineId = new anchor.web3.PublicKey(candy_30_2_1_9!);
              mintFound = true;
          }
          else if (res.group == "public")
          {
            candyMachineId = new anchor.web3.PublicKey(candyDefault!);
            mintFound = true;
          }

          if (mintFound) {
            remainingCount.current = res.supply - res.minted;
            mintedCount.current = res.minted;
            isLoadFromCM.current = false;
          }
      }
      else
      {
        candyMachineId = new anchor.web3.PublicKey(candyDefault!);
      }

      await loadCandyMachineState();
      setIsRdyToShow(true);
    })()
  }, [wallet, props.connection])

  useEffect(() =>
  {
    ;(async () =>
    {
      const params2 = new URLSearchParams(window.location.search);
      const group = params2.get('group');
      const mint = params2.get('mint');

      setIsMintActive(true);

      if (
          !wallet ||
          !wallet.publicKey ||
          !wallet.signAllTransactions ||
          !wallet.signTransaction
      ) {
        return;
      }

      if (!group || !mint) {
        setIsRdyToShow(true);
      }

      //await loadCandyMachineState();
    })()
  }, [wallet, candyMachineId, props.connection])

  const testTracker = async () => {
    console.log("Tracker Test");

    axios.post(mintTracker_endPoint,
        {
          'State': 'After Mint',
          'publicKey': "5xwFhQkxANJQCid21Cwf2GKMwWNSzXLZLmT1EZpvRubB",
          'mintTxId': "2RjYUJnmtZfH3qjwAj3fgJweUJePyjUbLWKRut5dUbH12wASjxKLyxfcPXmH8Tg63jfcsUfAzULmdZicwPXn15Qv",
          'tokenMint': null,
          'price': 1.5,
          'itemsAvailable': 325,
          'itemsRedeemed': 26,
          'itemsRemaining': 299
        });
  }

  const anchorWallet = {
    publicKey: wallet.publicKey,
    signAllTransactions: wallet.signAllTransactions,
    signTransaction: wallet.signTransaction
  } as anchor.Wallet

  const mintOne = async () => {
    try
    {
      setIsMinting(true);
      document.getElementById('#identity')?.click();
      if (wallet.connected && candyMachine?.program && wallet.publicKey)
      {
        const mintTxId: any = (
            await mintOneToken(candyMachine, wallet.publicKey)
        )[0];
        console.log('TxID: ', mintTxId);

        let status: any = { err: true };
        if (mintTxId) {
          status = await awaitTransactionSignatureConfirmation(
              mintTxId,
              props.txTimeout,
              props.connection,
              true,
          );
        }

        console.log('Status:');
        console.log(status);
        if (status && !status.err)
        {
          // manual update since the refresh might not detect
          // the change immediately
          //setRemainingCount(remainingCount - 1);
          remainingCount.current = remainingCount.current - 1;
          setIsSoldOut(remainingCount.current <= 0);
          setCandyMachine(candyMachine);

          showMessage('Congratulations! Mint succeeded!', 'success')
          loadCandyMachineStateAfterMint(mintTxId);
        }
        else
        {
          showMessage('Mint failed #1! Please try again!', 'error');
        }
      }
    }
    catch (error: any)
    {
      console.log(error);
      // TODO: blech:
      let message = error.msg || 'Minting failed #2! Please try again!';
      if (!error.msg) {
        if (!error.message) {
          message = 'Transaction Timeout! Please try again.';
        } else if (error.message.indexOf('0x138')) {
        } else if (error.message.indexOf('0x137')) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf('0x135')) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          window.location.reload();
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      showMessage(message, 'error');
    } finally {
      setIsMinting(false);
    }
  }

  const handleMint = async () =>
  {
    setIsMinting(true);

    console.log("mint one");
    await mintOne();

    setIsMinting(false);
  }

  const loadCandyMachineState = async () =>
  {
    const candyMachine = await getCandyMachineState(anchorWallet, candyMachineId, props.connection);
    const itemPriceInSOL = utilsHelper.formatNumber.asNumber(candyMachine.state.price);
    const itemsRemaining = isLoadFromCM.current ? candyMachine.state.itemsRemaining : remainingCount.current;
    const itemsRedeemed = candyMachine.state.itemsRedeemed;
    const itemsAvailable = candyMachine.state.itemsAvailable;

    itemPriceSOL.current = itemPriceInSOL!;
    remainingCount.current = Math.max(0, itemsRemaining);
    setIsSoldOut(itemsRemaining <= 0);
    setCandyMachine(candyMachine)

    console.log(`Loaded CM | Price: ${itemPriceInSOL} Remaining: ${itemsRemaining} (${remainingCount.current}) Redeemed: ${itemsRedeemed} Avail: ${itemsAvailable}`);
  }

  const loadCandyMachineStateAfterMint = async (mintTxId: anchor.web3.PublicKey) =>
  {
    console.log("after mint");
    await sleep(500);

    if (candyMachine)
    {
        const tokenMint = candyMachine.state.tokenMint;
        const itemsRemaining = isLoadFromCM.current ? candyMachine.state.itemsRemaining : remainingCount.current;
        const itemsRedeemed = candyMachine.state.itemsRedeemed;
        const itemsAvailable = candyMachine.state.itemsAvailable;
        console.log(candyMachine);
        console.log(`AfterMint | Remaining: ${itemsRemaining} Redeemed: ${itemsRedeemed} Avail: ${itemsAvailable}`);

        setIsCandyMachineRefreshNeeded(true);

        if (wallet.publicKey)
        {
          axios.post(mintTracker_endPoint,
              {
                'State': 'After Mint',
                'publicKey': wallet.publicKey.toString(),
                'mintTxId': mintTxId,
                'tokenMint': tokenMint,
                'price': itemPriceSOL.current,
                'itemsAvailable': itemsAvailable,
                'itemsRedeemed': itemsRedeemed,
                'itemsRemaining': itemsRemaining
              });

          const params2 = new URLSearchParams(window.location.search);
          const group = params2.get('group');
          const mint = params2.get('mint');
          await axios.post(mintConfig_endPoint,
              {
                'group': group,
                'mint': mint,
                'amount': 1
              });
      }
    }
  }

  return (
      <>
      <div className="head-banner" style={{background: 'linear-gradient( 180deg, rgba(10, 91, 169, 1) 0%, rgba(22, 18, 49, 1) 54% )'}}>
        <div className="title-img">
          <img src={MintingTitle} alt='minting title' />
        </div>
        <div className="mint-image">
          <img className="minting-img mt-30 mb-10" src={MintingTemplate} alt='minting' />
        </div>
        <div className="bottom-btn-wrapper">
          <div className="price-box">
            <label>Price</label>
            <input type="text" readOnly={true} value={itemPriceSOL.current == 0 ? mintPrice : itemPriceSOL.current + " SOL"}></input>
            <Box className="priceGas">+ gas fee</Box>
          </div>
          { !isMintActive ?
              (
                  <>
                    <div className='sale-time'>
                      {/*<div>*/}
                      {/*  {timer.day}*/}
                      {/*  <div className='time-unit'>days</div>*/}
                      {/*</div>*/}
                      {/*<div> : </div>*/}
                      <div>
                        {timer.hour}
                        <div className='time-unit'>hrs</div>
                      </div>
                      <div> : </div>
                      <div>
                        {timer.minute}
                        <div className='time-unit'>min</div>
                      </div>
                      <div> : </div>
                      <div>
                        {timer.second}
                        <div className='time-unit'>sec</div>
                      </div>
                    </div>
                  </>
              )
              :
              (
                  <>
                    <div className="price-box mint-box">
                      <p>
                        {wallet.connected &&
                            <Button
                                disabled={ !wallet.connected || isSoldOut || isMinting || !isMintActive }
                                onClick={handleMint}
                            >
                              {isMinting ? 'Minting...' : isSoldOut ? 'SOLD OUT' : 'Mint'}
                            </Button>
                        }

                        {!wallet.connected &&
                             <WalletMultiButton>
                               Connect
                             </WalletMultiButton>
                        }
                      </p>
                      <div className="repeat-tras">1 per transaction.<br/> Need more? Just repeat.</div>
                    </div>
                  </>
              )
          }
          <div className="price-box green-value">
            <label className="text-green text-center">Remaining</label>
            <input type="text" readOnly={true} value={!isRdyToShow || remainingCount.current == 0 ? "?" : remainingCount.current} className="text-center"></input>
          </div>
        </div>
      </div>

      <SubFooter showAvtar={true}/>
      </>
  )
}
