import React, { useEffect, useState } from "react";
import {clusterApiUrl, Connection, PublicKey, Keypair, SystemProgram, Transaction, TransactionInstruction, TransactionSignature, sendAndConfirmTransaction} from "@solana/web3.js";
import {createConnectionConfig, getParsedNftAccountsByOwner, isValidSolanaAddress} from "@nfteyez/sol-rayz";
import axios from "axios";
import {Typography} from "@material-ui/core";
import {useConnection, useWallet} from '@solana/wallet-adapter-react';
import {WalletDialogButton} from '@solana/wallet-adapter-material-ui'
import * as anchor from "@project-serum/anchor";
import { Button } from '@mui/material';
import { FC, useCallback } from 'react';
import { useNotify } from './notify';
import {SnackbarProvider} from 'notistack'
import {sendNft} from "../../config/nftSend";
import {MyDecrypt} from "../../service/cryptoHelper";

const test = () =>
{
    const data = MyDecrypt(
        '{"ct":"TpFKguLb7lnUEqOIQJiTNZLjd\\/m5\\/Avu4nYbzbZGmCA=","iv":"ecb54ead4da806f7111c2c8b3dbe76b9","s":"99ce3576def39520"}',
        'kobkey');

    console.log(data);
}

export const SendTransaction: FC = (): JSX.Element => {
    const { connection } = useConnection();
    const { publicKey, sendTransaction } = useWallet();
    const notify = useNotify();

    const onClick = useCallback(async () => {
        if (!publicKey) {
            notify('error', 'Wallet not connected!');
            return;
        }

        let signature: TransactionSignature = '';
        try {
            const transaction = new Transaction().add(
                SystemProgram.transfer({
                    fromPubkey: publicKey,
                    toPubkey: publicKey,
                    lamports: 1,
                })
            );

            await transaction.add(
                new TransactionInstruction({
                    keys: [{ pubkey: publicKey, isSigner: true, isWritable: true }],
                    data: Buffer.from('Unstake #2213', 'utf-8'),
                    programId: new PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
                })
            )

            signature = await sendTransaction(transaction, connection);
            notify('info', 'Transaction sent:', signature);

            await connection.confirmTransaction(signature, 'processed');
            notify('success', 'Transaction successful!', signature);
        } catch (error: any) {
            notify('error', `Transaction failed! ${error?.message}`, signature);
            return;
        }
    }, [publicKey, notify, connection, sendTransaction]);

    return (
        <Button variant="contained" color="secondary" onClick={onClick} disabled={!publicKey}>
            Send Transaction
        </Button>
    );
};

export const Nft = () =>
{
    const { connection } = useConnection();
    const wallet = useWallet();
    const [nftData, setNftData] = useState([]);
    const [loading, setLoading] = useState(false);

    const anchorWallet =
    {
        publicKey: wallet.publicKey,
        signAllTransactions: wallet.signAllTransactions,
        signTransaction: wallet.signTransaction
    } as anchor.Wallet

    const myClick = async () => {
        let nftToSend = "8SRVvPqxqmAk9AFWy15M5RSEXT4Y9h1xvnqkXzXePbws";
        console.log("clicked ", anchorWallet.publicKey, nftToSend);
        await sendNft(anchorWallet, nftToSend, connection);
    }

    const getAllNftData = async (walletAddress : string = "") =>
    {
        try
        {
            // wallet example: "BGaYyXT5Unj9Y5GCb1zywf6zrEoT6BDJQMdRpM6fhPGL;"
            const pubWalletAddress = wallet.connected ? anchorWallet.publicKey.toString() : walletAddress;
            console.log(pubWalletAddress);
            const nfts = await getParsedNftAccountsByOwner({
                publicAddress: pubWalletAddress
            });

            console.log(nfts);
            return nfts;
        }
        catch (error)
        {
            console.log("Error:", error);
        }
    }

    const getNftTokenData = async () =>
    {
        try
        {
            console.log("yo")
            let nftData = await getAllNftData();
            let arr = [];
            var data = Object.keys(nftData!).map((key: any) => nftData![key]);
            let n = data.length;
            for (let i = 0; i < n; i++) {
                console.log(data[i].data.uri);
                let val = await axios.get(data[i].data.uri);
                arr.push(val);
            }
            return arr;
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        async function data()
        {
            if (!loading)
            {
                setLoading(true);
                let res: any = await getNftTokenData();
                console.log("Res:", res);
                setNftData(res);
                setLoading(false);

                const domain = `https://dev--kittiesbot.solkitties.autocode.gg/kitties/hooks/cm-id/`;
                const result = (await axios.get(`${domain}`)).data.data;
                //const getStakedHall_Endpoint = `${domain}/get_staked_hall`;
                //const res = (await axios.get(`${getStakedHall_Endpoint}`)).data.data;

                console.log(result);
            }
            else
            {
                //console.log("Wallet not connected.");
            }
        }

        data();
    }, [wallet]);

    return (
        <>
            <section className="nft mt-2 my-5">
                <div className="container">
                    <p>
                        {wallet.connected &&
                            <label className="text-green text-right">Connected</label>
                        }

                        {!wallet.connected &&
                            <WalletDialogButton>
                                Connect
                            </WalletDialogButton>
                        }
                    </p>
                    <div className="row text-center">
                        <div className="col-12">
                            <h4 className="title">NFT</h4>
                        </div>
                    </div>
                    <div>
                        <SnackbarProvider>
                            <SendTransaction></SendTransaction>
                        </SnackbarProvider>
                    </div>
                    <div>
                        <Button variant="contained" color="secondary" onClick={myClick}>
                            Click Me
                        </Button>
                    </div>
                    <div>
                        <Button variant="contained" color="secondary" onClick={test}>
                            Click Me3
                        </Button>
                    </div>
                    {/*<div className="row  d-flex justify-content-center">*/}
                    {/*    /!*{console.log(nftData)}*!/*/}
                    {/*    {nftData ? (*/}
                    {/*        <>*/}
                    {/*            {nftData &&*/}
                    {/*                nftData.length > 0 &&*/}
                    {/*                nftData.map((val: any, ind: number) => {*/}
                    {/*                    // @ts-ignore*/}
                    {/*                    return (*/}
                    {/*                        <div className="col-4 mt-3" key={ind}>*/}
                    {/*                            <div className="cart text-center">*/}
                    {/*                                <div className="img mt-4 pt-3">*/}
                    {/*                                    <img src={val.data.image} style={{width: '64px', height: '64px'}} alt="loading..."/>*/}
                    {/*                                    /!*<p className="mt-1">temp2</p>*!/*/}
                    {/*                                    <p className="mt-1">{val.data.name}</p>*/}
                    {/*                                    <h6 className=" mt-2">*/}
                    {/*                                        /!*{console.log("sup", val.data)}*!/*/}
                    {/*                                        /!*{console.log(val)}*!/*/}
                    {/*                                        /!*temp3*!/*/}
                    {/*                                        {val.data.description}*/}
                    {/*                                    </h6>*/}
                    {/*                                </div>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    );*/}
                    {/*                })}*/}
                    {/*        </>*/}
                    {/*    ) : (*/}
                    {/*        <>*/}
                    {/*            <p className="text-center">loading...</p>*/}
                    {/*        </>*/}
                    {/*    )}*/}
                    {/*</div>*/}
                </div>
            </section>
        </>
    );
};
