import React, { useEffect, useRef, useState } from 'react'
import {Grid} from "@material-ui/core";
import PlaceholderImage from "../../sharedComponent/PlaceholderImage";

import CoinCat from "../../assets/images/user-info-panel/coincat.png";
import solscanIcon from "../../assets/images/staking/solscan.png";
import LoadingIcon from "../../assets/images/common/loading.svg";

import {sendNft} from "../../config/nftSend";
import * as anchor from "@project-serum/anchor";
import {useConnection, useWallet} from '@solana/wallet-adapter-react';
import {toast} from "react-toastify";
import axios from "axios";

import { LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction, TransactionInstruction, TransactionSignature } from "@solana/web3.js";
import { SOLANA_RPC_HOST_MAINNET, STAKING_UNSTAKE_FEE, STAKING_WALLET_ADDRESS, USER_NOT_FOUND } from "../../config/constants";
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

const stakeEndpoint_API = `https://api.servica.io:8080/stake`;
const unstakeEndpoint_API = `https://api.servica.io:8080/unstake`;

export const KittiesList = (props: any): JSX.Element => {
    const wallet = useWallet();
    const connection = useConnection();
    const { sendTransaction } = useWallet();
    const anchorWallet = {
        publicKey: wallet.publicKey,
        signAllTransactions: wallet.signAllTransactions,
        signTransaction: wallet.signTransaction
    } as anchor.Wallet

    const userReduxStore = useSelector((state: RootState) => state.userReduxSlice);
    const [loadingImgBox, setLoadingImgBox] = useState(0);
    const loadingRef = useRef(null);

    const stakeNft = async (tokenAddress: string, nftName: string, userData: any) =>
    {
        //console.log("clicked ", anchorWallet.publicKey, tokenAddress);
        try
        {
            //console.log(connection);
            await sendNft(anchorWallet, tokenAddress, connection.connection);
            const stakeConfirmation = (await axios.post(stakeEndpoint_API, {
                'userId': userData.userId,
                'userName': userData.userName,
                'nftName' : nftName,
                'nftTokenAddress': tokenAddress
            })).data;

            if (stakeConfirmation == false)
            {
                throw new Error("try again.");
            }

            toast.dismiss();
            toast.success(`${nftName} Staked Successfully.`);
            console.log("stakeConfirmation:", stakeConfirmation);

            props.childUpdate("userInfoData");
            props.childUpdate("stakeUserData");
            props.childUpdate("walletData");
        }
        catch (error: any)
        {
            toast.dismiss();
            toast.error(`Staking failed: ${error?.message}`);
        }
    }

    const sendUnstakeNFTMemo = async (tokenAddress: string, nftId: string) => {
        let signature: TransactionSignature = '';
        try
        {
            //console.log(connection);
            //console.log(anchorWallet.publicKey.toString());

            const memo = { walletAddress: `${anchorWallet.publicKey}`, tokenAddress: `${tokenAddress}`, nftId: `${nftId}` };
            console.log("Unstake Memo", memo);

            if (!memo)
            {
                throw new Error("No memo transaction");
            }

            const transaction = new Transaction().add(
                new TransactionInstruction({
                    keys: [{ pubkey: anchorWallet.publicKey, isSigner: true, isWritable: true }],
                    data: Buffer.from(JSON.stringify(memo), 'utf-8'),
                    programId: new PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
                })
            );

            console.log("Unstake TX Fees");
            const destPublicKey = new PublicKey(STAKING_WALLET_ADDRESS);
            const lamportsToSend = STAKING_UNSTAKE_FEE * LAMPORTS_PER_SOL;
            transaction.add(
                SystemProgram.transfer({
                    fromPubkey: anchorWallet.publicKey,
                    toPubkey: destPublicKey,
                    lamports: lamportsToSend,
                })
            );

            const toastId = toast.info("Sending Unstake Request...", {autoClose: 35000});
            signature = await sendTransaction(transaction, connection.connection);
            await connection.connection.confirmTransaction(signature, 'confirmed');
            toast.dismiss(toastId);

            console.log('Success:', `Transaction completed! Memo:`, signature);
        }
        catch (error: any)
        {
            console.log('Error:', `Transaction failed! ${error?.message}`);
            signature = "";
        }

        return signature;
    }

    const unstakeNft = async (nftValue: any, userData: any) =>
    {
        const nftTokenAddress = nftValue.tokenAddress;

        console.log("Unstake:", nftValue.nftName, nftValue.nftId, nftValue.tokenAddress);
        const signatureMemo = await sendUnstakeNFTMemo(nftValue.tokenAddress, nftValue.nftId);

        if (!signatureMemo)
        {
            toast.dismiss();
            toast.error(`Sending Unstake request failed, please try again.`);
            return
        }

        const toastId = toast.info(`Unstaking ${nftValue.nftName} ${nftValue.nftId} in Progress... don't close this window.`, {autoClose: 55000});
        const isStakeSuccess = await axios.post(unstakeEndpoint_API, {
            'userId': userData.userId,
            'userName': userData.userName,
            'nftName' : `${nftValue.nftName} ${nftValue.nftId}`,
            'nftTokenAddress': nftTokenAddress,
            'signatureMemo': signatureMemo
        });

        toast.dismiss(toastId);
        let out = "";
        if (isStakeSuccess.data === true)
        {
            out = `Unstaking ${nftValue.nftName} ${nftValue.nftId} done successfully.`;
            toast.success(out);

            props.childUpdate("userInfoData");
            props.childUpdate("stakeUserData");
            props.childUpdate("walletData");
        }
        else
        {
            out = `Unstaking ${nftValue.nftName} failed, please try again.`;
            toast.error(out);
        }
        console.log(out);
    }

    const handleLoadingImgBox = () => {
        // @ts-ignore
        setLoadingImgBox(loadingRef.current?.offsetWidth);
    }

    useEffect(() =>
    {
        window.addEventListener('resize', handleLoadingImgBox);
        return () =>
        {
            window.removeEventListener('resize', handleLoadingImgBox)
        }
    }, []);

    return (
        <Grid className="pods-box-wrapper" container spacing={4}>
        {
            props.kittiesArray?.length > 0 &&
            props.kittiesArray.map((val: any, ind: number) => {
                let stakeValue = 0;
                let rarityRank:any = "";
                let rarityValue = val.rarity;

                if (val.data?.name) // Coming from NFTs in wallet
                {
                    if (val.data?.name.includes("3D Sol Kitties"))
                    {
                        // 3D Kitties
                        if (rarityValue >= 1 && rarityValue <= 100) {
                            stakeValue = 0.375;
                            rarityRank = (<span className='legendary'>(Legendary)</span>);
                        } else if (rarityValue >= 101 && rarityValue <= 350) {
                            stakeValue = 0.325;
                            rarityRank = (<span className='epic'>(Epic)</span>);
                        } else if (rarityValue >= 351 && rarityValue <= 850) {
                            stakeValue = 0.3;
                            rarityRank = (<span className='rare'>(Rare)</span>);
                        } else if (rarityValue >= 851 && rarityValue <= 1600) {
                            stakeValue = 0.275;
                            rarityRank = (<span className='un-commmon'>(Uncommon)</span>);
                        } else if (rarityValue >= 1601) {
                            stakeValue = 0.25;
                            rarityRank = (<span className='common'>(Common)</span>);
                        }
                    }
                    else
                    {
                        // 2D Kitties
                        if (rarityValue >= 1 && rarityValue <= 80) {
                            stakeValue = 0.375;
                            rarityRank = (<span className='legendary'>(Legendary)</span>);
                        } else if (rarityValue >= 81 && rarityValue <= 250) {
                            stakeValue = 0.325;
                            rarityRank = (<span className='epic'>(Epic)</span>);
                        } else if (rarityValue >= 251 && rarityValue <= 750) {
                            stakeValue = 0.3;
                            rarityRank = (<span className='rare'>(Rare)</span>);
                        } else if (rarityValue >= 751 && rarityValue <= 1500) {
                            stakeValue = 0.275;
                            rarityRank = (<span className='un-commmon'>(Uncommon)</span>);
                        } else if (rarityValue >= 1500) {
                            stakeValue = 0.25;
                            rarityRank = (<span className='common'>(Common)</span>);
                        }
                    }
                }

                if (val.rarityRank) // Coming from API
                {
                    rarityRank = val.rarityRank;
                    stakeValue = val.stakeValue;
                    if (rarityRank == "Legendary") {
                        rarityRank = (<span className='legendary'>(Legendary)</span>);
                    } else if (rarityRank == "Epic") {
                        rarityRank = (<span className='epic'>(Epic)</span>);
                    } else if (rarityRank == "Rare") {
                        rarityRank = (<span className='rare'>(Rare)</span>);
                    } else if (rarityRank == "Uncommon") {
                        rarityRank = (<span className='un-commmon'>(Uncommon)</span>);
                    } else {
                        rarityRank = (<span className='common'>(Common)</span>);
                    }
                }

                return (
                    <Grid item lg={3} md={4} sm={6} xs={12} key={ind}>
                        <div className="pods-box pods-blue">
                            {
                                props.kittyType && (props.kittyType == 'wallet' || props.kittyType == 'wallet-3d') &&
                                <>
                                    <h4>
                                        <a target="_blank" href={`https://solscan.io/token/${val.mint}`}>
                                            <img src={solscanIcon} alt="solscan" className="solscan"/>{val.data.name}
                                        </a>
                                    </h4>
                                    <div className='image-wrapper' ref={loadingRef} style={{height: loadingImgBox}}>
                                        <a target="_blank" href={`https://magiceden.io/item-details/${val.mint}`}>
                                            <PlaceholderImage onLoad={handleLoadingImgBox} placeholderImg={LoadingIcon} src={val.data.uri} />
                                        </a>
                                    </div>

                                    <label>
                                        {
                                            props.kittyType == 'wallet' ? (
                                                <a target="_blank" href={`https://nftsniper.net/collection/sol-kitties?s=${val.data.name.split("#")[1]}&sort_by=rarity`}>
                                                    <p>Rarity:<span className="span-green">{rarityValue}</span>{rarityRank}</p>
                                                </a>
                                            ) : (
                                                <span>
                                                    <p>Rarity:<span className="span-green">{rarityValue}</span>{rarityRank}</p>
                                                </span>
                                            )
                                        }

                                        {
                                            props.kittyType == 'wallet' ? (
                                                <p>Stake value:<span className="span-yellow"><img src={CoinCat} alt="coin"/> {stakeValue}</span></p>
                                            ) : (
                                                <p>Stake value:<span className="span-yellow"><img src={CoinCat} alt="coin"/> {stakeValue}</span></p>
                                            )
                                        }
                                    </label>

                                    {
                                        props.kittyType == 'wallet' ? (
                                            <div data-tip={(!userReduxStore.userInfoData.userFoundFlag) ? USER_NOT_FOUND : ''} className={`${(userReduxStore.userInfoData.userFoundFlag) ? 'data-tip-position' : ''}`}>
                                                <button className={`stake ${(!userReduxStore.userInfoData.userFoundFlag) ? 'disable-btn' : ''}`} onClick={() => stakeNft(val.mint, val.data.name, userReduxStore.userInfoData)} disabled={!userReduxStore.userInfoData.userFoundFlag}>Stake</button>
                                            </div>
                                        ) : (
                                            <div data-tip={(!userReduxStore.userInfoData.userFoundFlag) ? USER_NOT_FOUND : ''} className={`${(userReduxStore.userInfoData.userFoundFlag) ? 'data-tip-position' : ''}`}>
                                                <button className={`stake ${(!userReduxStore.userInfoData.userFoundFlag) ? 'disable-btn' : ''}`} onClick={() => stakeNft(val.mint, val.data.name, userReduxStore.userInfoData)} disabled={!userReduxStore.userInfoData.userFoundFlag}>Stake</button>
                                            </div>
                                        )
                                    }
                                </>
                            }

                            {
                                props.kittyType && props.kittyType == 'user' &&
                                <>
                                    <h4>
                                        <a target="_blank" href={`https://solscan.io/token/${val.tokenAddress}`}>
                                            <img src={solscanIcon} alt="solscan" className="solscan"/>{val.nftName} {val.nftId}
                                        </a>
                                    </h4>
                                    <div className='image-wrapper' ref={loadingRef} style={{height: loadingImgBox}}>
                                        <a target="_blank" href={`https://magiceden.io/item-details/${val.tokenAddress}`}>
                                            <PlaceholderImage onLoad={handleLoadingImgBox} placeholderImg={LoadingIcon} src={val.nftImageArweaveUrl} />
                                        </a>
                                    </div>
                                    <label>
                                        <a target="_blank" href={`https://nftsniper.net/collection/sol-kitties?s=${val.nftId.split("#")[1]}&sort_by=rarity`}>
                                            <p>Rarity:<span className="span-green">{rarityValue}</span>{rarityRank}</p>
                                        </a>
                                        <p>
                                            Stake value:<span className="span-yellow"><img src={CoinCat} alt="coin" /> {stakeValue}</span>
                                        </p>
                                    </label>

                                    <button className="un-stake" onClick={() => unstakeNft(val, userReduxStore.userInfoData)}>Un-Stake</button>
                                </>
                            }
                        </div>
                    </Grid>
                );
            })
        }
    </Grid>
  )
}
