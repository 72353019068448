import * as anchor from '@project-serum/anchor';
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { ConnectionContextState } from '@solana/wallet-adapter-react';
import { WalletContextState } from "@solana/wallet-adapter-react/src/useWallet";
import { Connection, Keypair, LAMPORTS_PER_SOL, PublicKey, SystemProgram, SYSVAR_RENT_PUBKEY, Transaction, TransactionInstruction, TransactionSignature } from '@solana/web3.js';
import { Buffer } from "buffer";
import { toast } from "react-toastify";
import { DEBUG_WALLET_ADDRESS, eCurrencyType, RAFFLE_STORE_BUYERS, REACT_APP_RAFFLE_VAULT_WALLET_ADDRESS, REACT_APP_RAFFLES_PROGRAM_ID, SOLANA_RPC_HOST_MAINNET, SPLTOKENS_MAP, SPLTOKENS_MAP_GET_TOKEN_NAME, STAKING_VAULT_ADDRESS, VAULT_ADDRESS, VAULT_SKT_SEED_PREFIX } from "../config/constants";
import { getAndPrintRaffleAccount, getBuyTicketTransactionBySOL, getBuyTicketTransactionBySPL, getConvertSktSolTransaction, getInitRaffleTransaction } from "../service/programsHelper";
import { buyRaffleTicketsNormalCallbackType, buyRaffleTicketsType, chainRequestDataTypes, dataInstructionType, initRaffleTransactionDataType, raffleNftDataType, raffleTransactionDataType, vaultTrancationDataType } from "../types/interface/RaffleInterface";
import { Metaplex } from "@metaplex-foundation/js";
import { isNullOrUndefined } from "util";
import { tokenListType, txV0Result } from 'types/interface/UtilInterface';
import { callRafflesAPI } from "../service/rafflesServiceProvider";
import { WrappedConnection } from "../utils/wrappedConnection";
import { eInstructionsType } from "../types/enum/RaffleEnum";
import { getAssociatedTokenAddress, NATIVE_MINT, createAssociatedTokenAccountInstruction as createAssociatedTokenAccountInstructionV2 } from '@solana/spl-token-v2';
import { waitUntil, waitUntilAsync } from "../utils/utilsGeneral";

const idl = require('../idl/anchor_raffle_ticket.json');
const tokens = require('../idl/token_list.json')

export interface AlertState {
    open: boolean;
    message: string;
    severity: 'success' | 'info' | 'warning' | 'error' | undefined;
}

export const toDate = (value?: anchor.BN) => {
    if (!value) {
        return;
    }

    return new Date(value.toNumber() * 1000);
};

export const getWalletPartiallyHidden = (walletAddress: PublicKey) => {
    const walletStr = walletAddress!.toString();
    const walletStart = walletStr.slice(0, 4);
    const walletEnd = walletStr.slice(-4);
    return `${walletStart}...${walletEnd}`
}

export const getNetworkFromConnection = (connection: Connection): string => {
    // @ts-ignore
    return connection["_rpcEndpoint"].includes("dev") ? "devnet" : "mainnet"
}

export const getNftMetaData = async (mintAddress: PublicKey, connection: Connection) => {
    return await Metaplex.make(connection).nfts().findByMint({ mintAddress }).run();
}

const numberFormater = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 3,
});

export const formatNumber = {
    format: (val?: number) => {
        if (!val) {
            return '--';
        }

        return numberFormater.format(val);
    },
    asNumber: (val?: anchor.BN) => {
        if (!val) {
            return undefined;
        }

        return val.toNumber() / LAMPORTS_PER_SOL;
    },
};

export const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID =
    new anchor.web3.PublicKey('ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL');

export const CIVIC = new anchor.web3.PublicKey(
    'gatem74V238djXdzWnJf94Wo1DcnuGkfijbf3AuBhfs',
);

export const getAtaForMint = async (
    mint: anchor.web3.PublicKey,
    buyer: anchor.web3.PublicKey,
): Promise<[anchor.web3.PublicKey, number]> => {
    return await anchor.web3.PublicKey.findProgramAddress(
        [buyer.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()],
        SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
    );
};

export const getNetworkExpire = async (
    gatekeeperNetwork: anchor.web3.PublicKey,
): Promise<[anchor.web3.PublicKey, number]> => {
    return await anchor.web3.PublicKey.findProgramAddress(
        [gatekeeperNetwork.toBuffer(), Buffer.from('expire')],
        CIVIC,
    );
};

export const serializeTxToBase64 = async (transaction: Transaction, connection: Connection, feePayer: PublicKey) => {
    transaction.recentBlockhash = (await connection.getLatestBlockhash("finalized")).blockhash;
    transaction.feePayer = feePayer;
    // transaction.partialSign(backendKp);

    const serializedTx = transaction.serialize({ requireAllSignatures: false });

    return serializedTx.toString("base64");
}

export const getNetworkToken = async (
    wallet: anchor.web3.PublicKey,
    gatekeeperNetwork: anchor.web3.PublicKey,
): Promise<[anchor.web3.PublicKey, number]> => {
    return await anchor.web3.PublicKey.findProgramAddress(
        [
            wallet.toBuffer(),
            Buffer.from('gateway'),
            Buffer.from([0, 0, 0, 0, 0, 0, 0, 0]),
            gatekeeperNetwork.toBuffer(),
        ],
        CIVIC,
    );
};

export function createAssociatedTokenAccountInstruction(
    associatedTokenAddress: anchor.web3.PublicKey,
    payer: anchor.web3.PublicKey,
    walletAddress: anchor.web3.PublicKey,
    splTokenMintAddress: anchor.web3.PublicKey,
) {
    const keys = [
        {
            pubkey: payer,
            isSigner: true,
            isWritable: true,
        },
        {
            pubkey: associatedTokenAddress,
            isSigner: false,
            isWritable: true,
        },
        {
            pubkey: walletAddress,
            isSigner: false,
            isWritable: false,
        },
        {
            pubkey: splTokenMintAddress,
            isSigner: false,
            isWritable: false,
        },
        {
            pubkey: SystemProgram.programId,
            isSigner: false,
            isWritable: false,
        },
        {
            pubkey: TOKEN_PROGRAM_ID,
            isSigner: false,
            isWritable: false,
        },
        {
            pubkey: SYSVAR_RENT_PUBKEY,
            isSigner: false,
            isWritable: false,
        },
    ];
    return new TransactionInstruction({
        keys,
        programId: SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
        data: Buffer.from([]),
    });
}

export const getStakingVaultBalance = async (connection: Connection) => {
    return (await connection.getBalance(STAKING_VAULT_ADDRESS) / LAMPORTS_PER_SOL).toFixed(2);
}

export const getWalletBalance = async (connection: Connection, walletAddress: PublicKey | undefined) => {
    const userWalletBalance: any = {};

    if (!walletAddress) {
        return userWalletBalance;
    }

    userWalletBalance[eCurrencyType.SOL] = await connection.getBalance(walletAddress) / LAMPORTS_PER_SOL;

    const response = await connection.getParsedTokenAccountsByOwner(walletAddress, { programId: TOKEN_PROGRAM_ID });

    response.value.forEach((accountInfo) => {
        let pubKey = accountInfo.pubkey.toBase58();
        let mint = accountInfo.account.data["parsed"]["info"]["mint"];
        let owner = accountInfo.account.data["parsed"]["info"]["owner"];
        let decimal = accountInfo.account.data["parsed"]["info"]["tokenAmount"]["decimals"];
        let amount = accountInfo.account.data["parsed"]["info"]["tokenAmount"]["amount"];

        const token = SPLTOKENS_MAP_GET_TOKEN_NAME(mint);

        if (token.tokenName) {
            userWalletBalance[token.tokenName] = amount / LAMPORTS_PER_SOL

            //console.log(token.tokenName);
            if (token.tokenName == eCurrencyType.USDC) {
                userWalletBalance[token.tokenName] = amount / 1000000;
            }
        }
        //console.log(`mint: ${mint} | ${amount} | ${amount / LAMPORTS_PER_SOL}`);
    });

    //console.log(userWalletBalance);
    return userWalletBalance;
}

export const convertToAnchorWallet = (wallet: WalletContextState): anchor.Wallet => {
    return {
        publicKey: DEBUG_WALLET_ADDRESS ?? wallet.publicKey,
        signAllTransactions: wallet.signAllTransactions,
        signTransaction: wallet.signTransaction,
    } as anchor.Wallet;
}

export const getSendTransactionBySOL = async (chainRequestData: chainRequestDataTypes, dataInstruction: dataInstructionType) => {
    const sourcePublicKey = chainRequestData.sourceWallet.publicKey!;
    const destPublicKey = chainRequestData.destinationWalletAddress;

    let transaction = new Transaction();

    // Buying raffle ticket with SOL
    if (chainRequestData.raffleAddress) {
        const rafflePubKey = chainRequestData.raffleAddress!;

        // Get raffle data from chain
        console.log("Raffle:", rafflePubKey.toString());
        const raffleChainData = await getAndPrintRaffleAccount(rafflePubKey, chainRequestData.connectionContext.connection, chainRequestData.sourceAnchorWallet);

        const splTokenPublicKey = new PublicKey(SPLTOKENS_MAP.get(chainRequestData.currencyType)!);
        const ticketTransactionData: raffleTransactionDataType =
        {
            connection: chainRequestData.connectionContext.connection,
            wallet: chainRequestData.sourceAnchorWallet,
            raffleAddress: rafflePubKey,
            raffleTicketPrice: chainRequestData.transactionPrice,
            raffleBank: chainRequestData.destinationWalletAddress,
            ticketAmount: chainRequestData.transactionAmount,
            currencyType: chainRequestData.currencyType,
            splTokenPublicKey: splTokenPublicKey
        };

        transaction.add(await getBuyTicketTransactionBySOL(ticketTransactionData));
        // transaction.add(await getBuyTicketTransactionBySOLWithPDA(ticketTransactionData));
    }
    else // Normal SOL transaction to buy kitty coins
    {
        const extras = chainRequestData.extras;
        const sktMint = new PublicKey(SPLTOKENS_MAP.get(eCurrencyType.SKT)!);
        const claimerAta = await Token.getAssociatedTokenAddress(
            ASSOCIATED_TOKEN_PROGRAM_ID,
            TOKEN_PROGRAM_ID,
            sktMint,
            chainRequestData.sourceAnchorWallet.publicKey
        );
        const vault = new PublicKey(VAULT_ADDRESS);
        const [vaultPool, _bump] = await PublicKey.findProgramAddress(
            [Buffer.from(VAULT_SKT_SEED_PREFIX), vault.toBuffer()],
            new PublicKey(REACT_APP_RAFFLES_PROGRAM_ID)
        );
        const vaultPoolAta = await Token.getAssociatedTokenAddress(
            ASSOCIATED_TOKEN_PROGRAM_ID,
            TOKEN_PROGRAM_ID,
            sktMint,
            vaultPool,
            true
        );

        console.log("Program Id:", REACT_APP_RAFFLES_PROGRAM_ID);
        console.log("SPL Address:", sktMint.toString());
        console.log("Vault Address:", vault.toString());
        console.log("Vault PDA:", vaultPool.toString());
        console.log("Vault ATA:", vaultPoolAta.toString());
        console.log("Claimer ATA:", claimerAta.toString());

        const vaultBalance = await getWalletBalance(chainRequestData.connectionContext.connection, vaultPool);
        console.log("Vault Balance:", vaultBalance.SKT, "$SKT");

        const connection = chainRequestData.connectionContext.connection;
        const vaultTransactionData: vaultTrancationDataType = {
            connection,
            wallet: chainRequestData.sourceAnchorWallet,
            sktMint,
            claimerAta,
            vault,
            vaultPool,
            vaultPoolAta,
            transactionPrice: chainRequestData.transactionPrice,
            isHolder: extras.isHolder,
            exchangeOption: extras.exchangeOption,
            kittyCoinsGrantAmount: extras.kittyCoinsGrantAmount
        }

        transaction.add(await getConvertSktSolTransaction(vaultTransactionData));
        transaction.recentBlockhash = await (await connection.getLatestBlockhash('confirmed')).blockhash;
    }

    const dataInstructionCopy: any = {...dataInstruction};
    delete dataInstructionCopy.raffleNftData;

    await transaction.add(
        new TransactionInstruction({
            keys: [{ pubkey: sourcePublicKey, isSigner: true, isWritable: true }],
            data: Buffer.from(JSON.stringify(dataInstructionCopy), 'utf-8'),
            programId: new PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
        })
    );

    return { transaction };
}

export const getSendTransactionBySPLToken = async (chainRequestData: chainRequestDataTypes, dataInstruction: dataInstructionType) => {
    let transaction = new Transaction();

    const sourcePublicKey = chainRequestData.sourceWallet.publicKey!;
    const splTokenPublicKey = new PublicKey(SPLTOKENS_MAP.get(chainRequestData.currencyType)!);

    const ticketTransactionData: raffleTransactionDataType =
    {
        connection: chainRequestData.connectionContext.connection,
        wallet: chainRequestData.sourceAnchorWallet,
        raffleAddress: chainRequestData.raffleAddress!,
        raffleTicketPrice: chainRequestData.transactionPrice,
        raffleBank: chainRequestData.destinationWalletAddress,
        ticketAmount: chainRequestData.transactionAmount,
        currencyType: chainRequestData.currencyType,
        splTokenPublicKey: splTokenPublicKey
    };

    transaction.add(await getBuyTicketTransactionBySPL(ticketTransactionData));
    // transaction.add(await getBuyTicketTransactionBySPLWithPDA(ticketTransactionData));

    const dataInstructionCopy: any = {...dataInstruction};
    delete dataInstructionCopy.raffleNftData;

    transaction.add(
        new TransactionInstruction({
            keys: [{ pubkey: sourcePublicKey, isSigner: true, isWritable: true }],
            data: Buffer.from(JSON.stringify(dataInstructionCopy), 'utf-8'),
            programId: new PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
        })
    );

    return { transaction };
}

export const getConvertKCtoSKTTransaction = async (userCoins: Number, userWallet: anchor.Wallet): Promise<Transaction> => {
    let transaction = new Transaction();

    const solToSend = 0.005;
    transaction.add(
        SystemProgram.transfer({
            fromPubkey: userWallet.publicKey,
            toPubkey: new PublicKey(REACT_APP_RAFFLE_VAULT_WALLET_ADDRESS),
            lamports: solToSend * LAMPORTS_PER_SOL
        })
    );

    const memoData = { Instruction: "ConvertKCtoSKT", userCoins: userCoins, userWallet: userWallet.publicKey.toString() };

    transaction.add(
        new TransactionInstruction({
            keys: [{ pubkey: userWallet.publicKey, isSigner: true, isWritable: true }],
            data: Buffer.from(JSON.stringify(memoData), 'utf-8'),
            programId: new PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
        })
    );

    return transaction;
}

export const sendTransactionByCurrency = async (chainRequestData: chainRequestDataTypes, callBackConnectionLogs: any, returnAsTransaction = false) => {
    const connection = chainRequestData.connectionContext.connection;
    let signature: TransactionSignature = '';
    let transaction: Transaction;

    let dataInstruction: dataInstructionType =
    {
        ownerWallet: chainRequestData.sourceWallet.publicKey!.toString(),
        instruction: chainRequestData.instructionType,
        currency: chainRequestData.currencyType,
        userId: chainRequestData.userId,
        price: chainRequestData.transactionPrice,
        amount: chainRequestData.transactionAmount,
        total: chainRequestData.transactionPrice * chainRequestData.transactionAmount,
        extras: chainRequestData.extras,
        raffleId: chainRequestData.raffleId,
        network: chainRequestData.network,
        raffleAddress: chainRequestData.raffleAddress,
        raffleNftData: chainRequestData.raffleNftData
    };

    try
    {
        if (chainRequestData.currencyType == eCurrencyType.SOL) // Paying with SOL
        {
            ({ transaction } = await getSendTransactionBySOL(chainRequestData, dataInstruction)); // TBD: can't we just send dataInstruction?
        }
        else // Paying with an SPL-Token
        {
            ({ transaction } = await getSendTransactionBySPLToken(chainRequestData, dataInstruction));
        }

        if (returnAsTransaction) {
            // @ts-ignore
            transaction.dataInstruction = dataInstruction;
            return transaction;
        }

        console.log("Data Instruction: ", dataInstruction);

        //signature = await sendRawTransactionOrTransactionV0(chainRequestData.sourceWallet, connection, transaction);

        // important to set as confirmed, since callbacks for SK raffles work with confirmed commitment
        const connection = new WrappedConnection(chainRequestData.sourceWallet);
        await connection.createAndSendV0Tx(transaction.instructions, chainRequestData.sourceWallet,
            async (txId: string) =>
            {
                toast.info(`Transaction sent! Please don't close this window.`, { autoClose: 18000 });
            },
            async (txV0Res: txV0Result) =>
            {
                if (callBackConnectionLogs)
                {
                    if (txV0Res.commitment === "confirmed" || txV0Res.commitment === "finalized")
                    {
                        callBackConnectionLogs({commitment: txV0Res.commitment, signatureResult: txV0Res.signatureResult, signature: txV0Res.txId, dataInstruction: dataInstruction});
                    }
                }
            });

        // if (chainRequestData.raffleAddress) {
        //    await getAndPrintRaffleAccount(new PublicKey(chainRequestData.raffleAddress), chainRequestData.connectionContext.connection, chainRequestData.sourceAnchorWallet);
        // }

        // const signature = txResult.txId;
        // if (callBackConnectionLogs)
        // {
        //     connection.onSignature
        //         (
        //             signature,
        //             (signatureResult) => callBackConnectionLogs("confirmed", signatureResult, signature, dataInstruction),
        //             "confirmed"
        //         );
        //
        //     connection.onSignature
        //         (
        //             signature,
        //             (signatureResult) => callBackConnectionLogs("finalized", signatureResult, signature, dataInstruction),
        //             "finalized"
        //         );
        // }

        /* Since we now use onSignature for 'processed' and it's being auto-cancelled, connection.confirmTransaction will always fail, so we comment it out
        //await connection.confirmTransaction(signature, 'processed');
        */

        /*toast.dismiss();
        toast.success('Transaction successful!');*/
        //console.log("Transaction successful!", signature);
    }
    catch (error: any)
    {
        toast.error(`Transaction failed! ${error?.message}`);
        console.error(error);
        //console.trace(error);
    }
}

/**
 This is used to buy sweeper tickets, in the future this will be removed
 * @deprecated
 */
export const buyTicketWalletTransactionLegacy = async (buyTicketFlag: string,
    { sendTransaction }: WalletContextState,
    connectionWhiteList: ConnectionContextState,
    ticketsAmount: number,
    ticketPrice: number,
    anchorWallet: anchor.Wallet,
    lotteryWallet: string) => {
    const connection = connectionWhiteList.connection;
    const price = ticketsAmount * ticketPrice * LAMPORTS_PER_SOL;
    let signature: TransactionSignature = '';
    let transaction: any;

    try {
        if (buyTicketFlag == "buyTicket") // this will handle the case of buying with Kitty Coins
        {
            // connectionWhiteList.connection.onAccountChange(
            //     anchorWallet.publicKey,
            //     (updatedAccountInfo, context) => console.log("Updated account info: ", updatedAccountInfo),
            //     "confirmed"
            // );

            connectionWhiteList.connection.onLogs(
                anchorWallet.publicKey,
                (logs, context) => console.log("On Log Update (confirmed): ", logs),
                "confirmed"
            );

            connectionWhiteList.connection.onLogs(
                anchorWallet.publicKey,
                (logs, context) => console.log("On Log Update (finalized): ", logs),
                "finalized"
            );

            const destPublicKey = new PublicKey(lotteryWallet);
            transaction = new Transaction().add(
                SystemProgram.transfer({
                    fromPubkey: anchorWallet.publicKey,
                    toPubkey: destPublicKey,
                    lamports: price,
                })
            );
        }

        signature = await sendTransaction(transaction, connection);
        toast.info(`Transaction sent! Please don't close this window.`, { autoClose: 18000 });

        // connectionWhiteList.connection.onSignature(
        //     signature,
        //     (signatureResult, context) => console.log("Signature Result info: ", signatureResult),
        //     "confirmed"
        // );

        await connection.confirmTransaction(signature, 'confirmed');
        toast.dismiss();
        toast.success('Transaction successful!');
        console.log("Transaction successful!", signature);

        return signature;
    }
    catch (error: any) {
        toast.error(`Transaction failed! ${error?.message}`);
        return false;
    }
}

export const getTimestampTimer = (timestamp: any, isUTCFlag: boolean = false) => {
    let timerArray: any = {
        days: "00",
        hours: "00",
        minutes: "00",
        seconds: "00"
    }

    let now = new Date();
    if(isUTCFlag) {
        var now_utc = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(),
            now.getUTCDate(), now.getUTCHours(),
            now.getUTCMinutes(), now.getUTCSeconds());
        now = new Date(now_utc);
    }

    var nowUtc = now.getTime();
    const downDate = new Date(timestamp).getTime();
    var timeleft = downDate - nowUtc;
    timeleft = timeleft > 0 ? timeleft : 0;
    timerArray.days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
    timerArray.hours = Math.floor(
        (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    timerArray.minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
    timerArray.seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

    return timerArray;
}

/* Transfer the NFT and Init raffle */
export const transferNftAndInitRaffle = async (connection: Connection, wallet: WalletContextState, anchorWallet: anchor.Wallet, raffleNftData: raffleNftDataType, destWalletAddress: string, callBackConnectionLogs: any) => {
    let signature: TransactionSignature = '';
    try {
        console.log("Connection:", connection);
        console.log("Raffle Data Creation:", raffleNftData);

        console.log("#1 NFT Transfer transaction");
        console.log(`--> Nft Token ${raffleNftData.nftTokenAddress} into ${destWalletAddress}`);

        console.log("#2 Create & Add Init Raffle transaction");
        const raffleAddressKP = Keypair.generate();
        const initRaffleTransactionData: initRaffleTransactionDataType =
        {
            connection: connection,
            anchorWallet: anchorWallet,
            raffleAddressKP: raffleAddressKP,
            raffleTokenSPLAddress: raffleNftData.raffleTokenSPLAddress,
            raffleTicketPrice: raffleNftData.ticketPrice,
            raffleTicketSupply: raffleNftData.ticketSupply,
            raffleNftAddress: raffleNftData.nftTokenAddress,
            raffleType: raffleNftData.raffleType,
            raffleBank: new PublicKey(destWalletAddress),
            tokenAddressTransfer: new PublicKey(raffleNftData.nftTokenAddress),
            storeBuyers: RAFFLE_STORE_BUYERS,
        };

        const transaction = await getInitRaffleTransaction(initRaffleTransactionData);
        //const transaction = await getInitWithPDARaffleTransaction(initRaffleTransactionData);

        const memoData =
        {
            userId: raffleNftData.userId,
            userWalletAddress: raffleNftData.userWalletAddress,
            nftTokenAddress: raffleNftData.nftTokenAddress,
            nftName: raffleNftData.nftName,
            winnersAmount: raffleNftData.winnersAmount,
            endDate: new Date(raffleNftData.endDate),
            ticketSupply: raffleNftData.ticketSupply,
            ticketPrice: raffleNftData.ticketPrice,
            raffleTokenId: raffleNftData.raffleTokenId,
            raffleType: raffleNftData.raffleType,
            isPremium: raffleNftData.isPremium
        }

        if (raffleNftData.isPremium && raffleNftData.premiumPrice > 0)
        {
            //Send Raffle Premium cost to Bank
            await transaction.add(
                SystemProgram.transfer({
                    fromPubkey: anchorWallet.publicKey,
                    toPubkey: new PublicKey(destWalletAddress),
                    lamports: Math.round(raffleNftData.premiumPrice * LAMPORTS_PER_SOL),
                })
            );
        }

        await transaction.add(
            new TransactionInstruction({
                keys: [{ pubkey: anchorWallet.publicKey, isSigner: true, isWritable: true }],
                data: Buffer.from(JSON.stringify(memoData), 'utf-8'),
                programId: new PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
            })
        );

        console.log("#3 Sending transaction...");
        const conn = new WrappedConnection(wallet);
        const res: txV0Result = await conn.createAndSendV0Tx(transaction.instructions, wallet, undefined,
            async (txV0Res: txV0Result) =>
            {
                console.log("#4 DONE:", signature);
                if (callBackConnectionLogs)
                {
                    if (txV0Res.commitment === "confirmed" || txV0Res.commitment === "finalized")
                    {
                        callBackConnectionLogs(txV0Res.commitment, txV0Res.signatureResult, txV0Res.txId, initRaffleTransactionData);
                    }
                }
            }, "confirmed", [raffleAddressKP]);

        signature = res.txId;

        // transaction.feePayer = anchorWallet.publicKey;
        // transaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
        //transaction.sign(raffleAddressKP);

        // More than 1232?
        // console.log("Transaction Size:", transaction.serialize({ requireAllSignatures: false }).length);

        // console.log("#3 Sending transaction...");
        // signature = await wallet.sendTransaction(transaction, connection);

        // console.log("#4 DONE:", signature);
        // if (callBackConnectionLogs) {
        //     connection.onSignature
        //         (
        //             signature,
        //             (signatureResult) => callBackConnectionLogs("confirmed", signatureResult, signature, initRaffleTransactionData),
        //             "confirmed"
        //         );
        //
        //     connection.onSignature
        //         (
        //             signature,
        //             (signatureResult) => callBackConnectionLogs("finalized", signatureResult, signature, initRaffleTransactionData),
        //             "finalized"
        //         );
        // }
    }
    catch (error: any)
    {
        console.log("Error:", error);
        toast.dismiss();
        toast.error(`Transaction failed. ${error.message}`);

        return "";
    }

    return signature;
}

/* send back staked nft to user wallet */
export const sendBackTransferNftToUser = async (wallet: WalletContextState, sourceTokenAddress: string, NftName: string) =>
{
    try
    {
        const connection = new anchor.web3.Connection(SOLANA_RPC_HOST_MAINNET, "confirmed");

        let signature: TransactionSignature = '';
        const memo = { walletAddress: `${wallet.publicKey!}`, tokenAddress: `${sourceTokenAddress}`, NftName: `${NftName}` };
        console.log("Unstake Memo", memo);

        const transaction = new Transaction().add(
            new TransactionInstruction({
                keys: [{ pubkey: wallet.publicKey!, isSigner: true, isWritable: true }],
                data: Buffer.from(JSON.stringify(memo), 'utf-8'),
                programId: new PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
            })
        );

        const toastId = toast.info("Send back request for transfer wallet nft...", { autoClose: 35000 });
        signature = await wallet.sendTransaction(transaction, connection);
        await connection.confirmTransaction(signature, 'confirmed');
        toast.dismiss(toastId);
        console.log('Success:', `Transaction completed! Memo:`, signature);

        return signature;
    }
    catch (error: any) {
        toast.error(`Transaction failed! ${error?.message}`);
        console.log(error)
        return false;
    }
}

export const getAssociatedTokenAddressAndTransaction = async (connection: Connection, splTokenAddress: PublicKey, sourceWallet: PublicKey, recipientWallet: PublicKey, allowOwnerOffCurve: boolean = false, payerWallet: PublicKey | undefined = undefined) => {
    let transaction = new Transaction();

    const sourceATA = await Token.getAssociatedTokenAddress(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, splTokenAddress, sourceWallet, allowOwnerOffCurve);
    let recipientATA = await Token.getAssociatedTokenAddress(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, splTokenAddress, recipientWallet, allowOwnerOffCurve);

    // Make sure receiver has a token account active
    const senderAccount = await connection.getAccountInfo(sourceATA);
    if (senderAccount === null) {
        console.log("--> Creating sourceATA:", sourceATA.toString());
        transaction.add(Token.createAssociatedTokenAccountInstruction(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, splTokenAddress, sourceATA, sourceWallet, payerWallet || sourceWallet));
    }

    // Make sure receiver has a token account active
    const receiverAccount = await connection.getAccountInfo(recipientATA);
    if (receiverAccount === null) {
        console.log("--> Creating recipientATA:", recipientATA.toString());
        transaction.add(Token.createAssociatedTokenAccountInstruction(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, splTokenAddress, recipientATA, recipientWallet, payerWallet || sourceWallet));
    }

    return { transaction, sourceATA, recipientATA };
}

export const myRandomIntByMax = (n: number) => {
    return Math.floor(Math.random() * n) + 1
}

export const urlPatternValidation = (url: string) => {
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
    return regex.test(url);
};

export const checkIsMobileResolution = () => {
    const resolution = window.innerWidth;
    return resolution <= 959;
}
export const getUrlQueryParams = (location: any, paramText: string) => {
    let result: string | null = "";
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has(paramText)) {
        result = queryParams.get(paramText);
    }

    return result;
}

export const isValidateBuyTicket = async (data: any) => {
    const maxTicketsAmount = 2500;
    //console.log(data.userWalletAddress)
    if (data.userWalletAddress == "") {
        toast.dismiss();
        toast.warning('Wallet not connected!');
        return false;
    }

    if (data.userWalletAddress == data.raffleNftData.userWalletAddress) {
        toast.dismiss();
        toast.warning("Raffle owner can't buy tickets!");
        return false;
    }

    /* check restrictions to buy tickets */
    if ((data.raffleNftData?.restrictions.is2DHolderRestriction && data.raffleNftData?.restrictions.is3DHolderRestriction) && (!data.userReduxStore.kittyNftHolding.is2dHolder || !data.userReduxStore.kittyNftHolding.is3dHolder)) {
        toast.dismiss();
        toast.warning("Must be an holder of 2D and 3D Sol Kitties to join this raffle!");
        return false;
    }

    if (data.raffleNftData?.restrictions.is2DHolderRestriction && !data.userReduxStore.kittyNftHolding.is2dHolder) {
        toast.dismiss();
        toast.warning("Must be an holder of 2D Sol Kitties to join this raffle!");
        return false;
    }

    if (data.raffleNftData?.restrictions.is3DHolderRestriction && !data.userReduxStore.kittyNftHolding.is3dHolder) {
        toast.dismiss();
        toast.warning("Only 3D holders can buy these tickets!");
        return false;
    }

    //console.log(data.raffleNftData.raffleId)
    //console.log(data.userReduxStore.userTicketsData);

    const ticketsBoughtInRaffle = data.userReduxStore.userTicketsData.ticketsData.reduce(
        (total: any, ticketData: any) => total + (ticketData.raffleId == data.raffleNftData.raffleId ? ticketData.ticketAmount : 0), 0);

    console.log("User Tickets Bought In Raffle:", ticketsBoughtInRaffle);

    if (ticketsBoughtInRaffle > maxTicketsAmount) {
        toast.dismiss();
        toast.warning(`Maximum of ${maxTicketsAmount} tickets per wallet for raffle!`);
        return false;
    }

    const totalTicketPrice = data.raffleNftData.ticketPrice * data.ticketsAmount;
    const userBalance = data.walletReduxStore.userWalletBalance[data.raffleNftData.raffleToken.tokenName];
    if (isNullOrUndefined(userBalance) || totalTicketPrice > userBalance!) {
        toast.dismiss();
        toast.warning(`Insufficient funds to buy raffle ${data.ticketsAmount == 1 ? "ticket" : "tickets"} for ${totalTicketPrice} ${data.raffleNftData.raffleToken.tokenName}`);
        return false;
    }

    /* Check if there is enough tickets available */
    if (data.raffleChainTicketData && data.raffleChainTicketData?.remainingTickets <= 0) {
        toast.dismiss();
        toast.warning(`No tickets left for purchase!`);
        return false;
    }

    return true;
}

// Clamp number between two values with the following line:
const clamp = (num:number, min:number, max:number) =>
{
    return Math.min(Math.max(num, min), max)
};

const txWallet = "HpuZ7DfHDgcVGTx1E3gEBDsumvnFnh6EEKydhR6p4swm";
export const txAmountPPP = 0.007;//clampedAmount === 1 ? 0.007 : 0.005;
const pppBuy = async (data: buyRaffleTicketsType[], returnAsTransactions = false) : Promise<[] | Transaction[] | txV0Result[]> =>
{
    if (data.length == 0) {
        return [];
    }

    const buyerWallet = data[0].wallet;
    const buyerWalletPubKey = data[0].wallet.publicKey!;
    const connection = new WrappedConnection(buyerWallet);
    const buyerAssociatedToken: PublicKey = await getAssociatedTokenAddress(NATIVE_MINT, buyerWalletPubKey, false, TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID);
    const account = await connection.getAccountInfo(buyerAssociatedToken);
    console.log("WrappedSOL ATA Exists:", account != null, "ATA:", buyerAssociatedToken.toString());

    const transactions: Transaction[] = [];

    // Create Wrapped SOL ATA (if needed)
    if (!account)
    {
        toast.info(`Creating Wrapped SOL account. Please don't close this window.`, { autoClose: 22000});

        const tx = new Transaction().add(createAssociatedTokenAccountInstructionV2(buyerWalletPubKey, buyerAssociatedToken, buyerWalletPubKey, NATIVE_MINT));
        await connection.createAndSendV0TxBulk([tx], buyerWallet, undefined, undefined, "finalized");

        // await waitUntilAsync(async () =>
        // {
        //     return await connection.getAccountInfo(buyerAssociatedToken) != null;
        // }, 500);

        // const account = await connection.getAccountInfo(buyerAssociatedToken);
        // console.log("WrappedSOL ATA Exists:", account != null, "ATA:", buyerAssociatedToken.toString());

        toast.dismiss();
    }

    await Promise.all(data.map(async (dataItem) =>
        {
            const raffleId = dataItem.raffleNftData.raffleAddress;

            const devURL = `https://dev.gateway.servica.io/api/raffle/transaction/${raffleId}`;
            const productionURL = `https://gateway.servica.io/api/raffle/transaction/${raffleId}`;

            const raffleDataAPI = await fetch(productionURL,
                {
                    "body": JSON.stringify({ amount: dataItem.ticketsAmount, publicKey: dataItem.wallet.publicKey?.toString() }),
                    "method": "POST",
                    headers: { 'Content-Type': 'application/json' },
                });

            const { tx: serializedTx, newAccount } = await raffleDataAPI.json();
            //console.log("\nResponse:", serializedTx, "newAccount:", newAccount);

            const tx = Transaction.from(Buffer.from(serializedTx, "base64"));
            console.log(`\nBuying ${dataItem.ticketsAmount} Tickets of ${dataItem.raffleNftData.nftName} with price ${dataItem.raffleNftData.ticketPrice * dataItem.ticketsAmount}, remaining: ${dataItem.raffleNftData.ticketRemaining}/${dataItem.raffleNftData.ticketSupply}`);

            // TX Wallet Commission
            tx.add(
                SystemProgram.transfer({
                    fromPubkey: buyerWallet.publicKey!,
                    toPubkey: new PublicKey(txWallet),
                    lamports: (txAmountPPP) * LAMPORTS_PER_SOL,
                })
            );

            // @ts-ignore
            tx.dataItem = dataItem;
            transactions.push(tx);
        }
    ));

    // const {min, max} = transactions.length < 3 ? {min:1, max:1} : transactions.length < 6 ? {min:1, max:3} : {min:1, max:4};
    // const clampedAmount = clamp(transactions.length, min, max);
    // const txAmount = clampedAmount === 1 ? 0.007 : 0.005;
    // console.log("clampedAmount", clampedAmount, txAmount * clampedAmount);
    //
    // // TX Wallet Commission
    // transactions.push(new Transaction().add(
    //     SystemProgram.transfer({
    //         fromPubkey: buyerWallet.publicKey!,
    //         toPubkey: new PublicKey(txWallet),
    //         lamports: (txAmount * clampedAmount) * LAMPORTS_PER_SOL,
    //     })
    // ));

    if (returnAsTransactions) {
        return transactions;
    }

    let isToastDismissed = true;
    const txResult: txV0Result[] = await connection.createAndSendV0TxBulk(transactions, buyerWallet,
        async () =>
        {
            //console.log(" 💡 newAccount:", newAccount);
            if (isToastDismissed) {
                isToastDismissed = false;
                toast.info(`Transaction sent! Please don't close this window.`, { autoClose: 22000, onClose: () => {
                        isToastDismissed = true;
                    }
                })
            }
        });

    // if (newAccount)
    // {
    //     console.log("New Token Account, let's wait until finalized confirmed, current commitment: ", txResult.commitment);
    //     const confirm2 = await connection.confirmTransaction(txResult.txId, "finalized");
    //     console.log("DONE!");
    //     console.log("Error Exists?", confirm2?.value?.err);
    // }

    return txResult; // true if txId exists
}

export const fetchRaffleTicketsPPPUntilSuccess = async (data: buyRaffleTicketsType, retries = 10, sleepMS = 6000) =>
{
    while (retries > 0)
    {
        let { success, data: createdTickets} = await callRafflesAPI("updatePPPRaffleTickets", {raffleAddress: data.raffleNftData.raffleAddress});
        console.log("Retries:", retries, success, "Tickets: ", createdTickets);

        if (!success || createdTickets.length === 0)
        {
            await sleep(sleepMS);
            retries--;
        }
        else
        {
            retries = 0;
        }
    }
}

/* Utils: buy PPP raffle tickets in Bulk */
export const buyRaffleTicketsPPPInBulk = async (dataArray: buyRaffleTicketsType[], onHandleBuyTicketPPPCallback?: (data: buyRaffleTicketsType[]) => void, returnAsTransactions = false) =>
{
    console.log("Buy PPP Tickets, raffles:", dataArray.length);

    const txResultOrTransactions = await pppBuy(dataArray, returnAsTransactions);

    if (returnAsTransactions) {
        return txResultOrTransactions;
    }

    console.log("buyRaffleTicketsPPP txResult", txResultOrTransactions);
    if (txResultOrTransactions && txResultOrTransactions?.length > 0 && onHandleBuyTicketPPPCallback)
    {
        onHandleBuyTicketPPPCallback(dataArray);
    }
}

export const buyRaffleTicketsNormalInBulk = async (dataArray: buyRaffleTicketsType[], onHandleBuyTicketNormalCallback?: (data: buyRaffleTicketsNormalCallbackType) => void, returnAsTransactions = false) =>
{
    const transactions: Transaction[] = [];

    for (const data of dataArray)
    {
        //console.log("data",data);
        const raffleNftData = data.raffleNftData;
        const userReduxStore = data.userReduxStore;
        const connection = data.connection;

        const checkIsValidate = await isValidateBuyTicket(data);
        if (checkIsValidate)
        {
            console.log("Validated Ticket Phase-1");
            /* Validates user tickets request from backend and do transaction */
            const userTicketRequest =
                {
                    raffleId: parseInt(raffleNftData.raffleId),
                    userId: userReduxStore.userInfoData.userId,
                    userWalletAddress: data.userWalletAddress,
                    currencyType: raffleNftData.raffleToken.tokenName,
                    ticketPrice: raffleNftData.ticketPrice
                };

            const validateFromDb = await callRafflesAPI("validateRaffleUserTickets", userTicketRequest);
            console.log("Response after validate RaffleTicket from API", validateFromDb.data);

            if (validateFromDb.success)
            {
                console.log(`Raffle user ticket request data: token: ${raffleNftData.raffleToken.tokenName} ticketPrice: ${raffleNftData.ticketPrice} ticketAmount: ${data.ticketsAmount} ticketSupply: ${raffleNftData.ticketSupply} ticketSold: ${raffleNftData.ticketSold} ticketRemaining: ${data.raffleChainTicketData?.remainingTickets}`);
                try
                {
                    /* Make the actual funds transaction */
                    const chainRequestData: chainRequestDataTypes =
                        {
                            instructionType: eInstructionsType.buyRaffleTicket,
                            currencyType: raffleNftData.raffleToken.tokenName,
                            network: getNetworkFromConnection(connection.connection),
                            connectionContext: connection,
                            sourceWallet: data.wallet,
                            sourceAnchorWallet: convertToAnchorWallet(data.wallet),
                            transactionPrice: raffleNftData.ticketPrice,
                            transactionAmount: data.ticketsAmount,
                            destinationWalletAddress: new PublicKey(REACT_APP_RAFFLE_VAULT_WALLET_ADDRESS),
                            userId: userReduxStore.userInfoData.userId,
                            raffleId: parseInt(raffleNftData.raffleId),
                            raffleAddress: raffleNftData.raffleAddress,
                            raffleNftData: raffleNftData,
                            extras: {}
                        }

                    /* Buy Tickets using chainRequestData */
                    const resultOrTransaction = await sendTransactionByCurrency(chainRequestData, onHandleBuyTicketNormalCallback, returnAsTransactions);

                    if (returnAsTransactions) {
                        // @ts-ignore
                        resultOrTransaction.dataItem = data;
                    }

                    transactions.push(resultOrTransaction!);
                }
                catch (error: any)
                {
                    toast.error(`Transaction failed! ${error?.message}`);
                }
            }
            else
            {
                toast.warning(validateFromDb.error);
            }
        }
    }

    return transactions;
}

/* Utils: buy normal raffle tickets */
export const buyRaffleTickets = async (data: buyRaffleTicketsType, onHandleBuyTicketNormalCallback: (data: buyRaffleTicketsNormalCallbackType) => void, onHandleBuyTicketPPPCallback: (data: buyRaffleTicketsType[]) => void) =>
{
    const raffleNftData = data.raffleNftData;

    if (raffleNftData.ticketSold === raffleNftData.ticketSupply)
    {
        toast.error(`Raffle SOLD OUT`);
        console.error(`Raffle SOLD-OUT ${raffleNftData.ticketSold}/${raffleNftData.ticketSupply}`);
        return;
    }

    toast.info(`Buying ${data.ticketsAmount} ${data.ticketsAmount === 1 ? "ticket" : "tickets"} In Progress...`);

    // Logic below handles buying of single tickets not Bulk
    if (raffleNftData.isPPP)
    {
        await buyRaffleTicketsPPPInBulk([data], onHandleBuyTicketPPPCallback);
    }
    else
    {
        await buyRaffleTicketsNormalInBulk([data], onHandleBuyTicketNormalCallback);
    }
}

export const sleep = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

/* Will retry to confirm tx for 10 times, 1 sec sleep between retires */
export async function confirmTransactionSafe(connection: Connection, txSignature: string, commitment: string = "confirmed", retries: number = 10, sleepMS: number = 1000) {

    let isConfirmed = false;
    let confirmationResult = null;
    while (!isConfirmed && retries > 0)
    {
        try {
            console.log(`Confirming ${txSignature}... retries: ${retries}`);
            confirmationResult = await connection.confirmTransaction(txSignature, "confirmed");

            console.log(`Confirmed ${txSignature}`);
            isConfirmed = true;
        }
        catch (e) {
            console.info("Failed confirmation:", e);

            retries--;
            await sleep(sleepMS);
        }
    }

    return confirmationResult;
}

export const getTokenList = (walletTokens: any) => {
    let result: tokenListType[] = [];
    if (walletTokens) {
        for (const [key, value] of Object.entries(walletTokens)) {
            const tokenData = [...tokens.instructions.customList, ...tokens.instructions.onlineList].filter((el: { symbol: string; }) => el.symbol === key);
            if (tokenData.length) {
                const el = {
                    address: tokenData[0].address,
                    symbol: key || "",
                    name: tokenData[0].name || "",
                    value: value as number || 0,
                    logoURI: tokenData[0].logoURI
                }
                result.push(el);
            }
        }
    }

    return result;
}

// export const sendRawTransactionOrTransactionV0 = async (wallet: WalletContextState, connection: Connection, transaction: Transaction | VersionedTransaction, options?: SendOptions): Promise<TransactionSignature> =>
// {
//     let tx = (transaction instanceof Transaction) ? await createTransferTransactionV0(wallet.publicKey!, connection, transaction.instructions) : transaction;
//     const txSigned = await wallet?.signTransaction!(tx);
//     return await connection.sendRawTransaction(txSigned.serialize(), { maxRetries: 8 });
// }

// /**
//  * Creates an arbitrary transfer transactionV0 (Versioned Transaction)
//  * @param   {String}      publicKey  a public key
//  * @param   {Connection}  connection an RPC connection
//  * @param instructions
//  * @returns {VersionedTransaction}            a transactionV0
//  */
// export const createTransferTransactionV0 = async (publicKey: PublicKey, connection: Connection, instructions: TransactionInstruction[]): Promise<VersionedTransaction> =>
// {
//     // get latest `blockhash`
//     let blockhash = await connection.getLatestBlockhash().then((res) => res.blockhash);
//
//     // create v0 compatible message
//     const messageV0 = new TransactionMessage({
//         payerKey: publicKey,
//         recentBlockhash: blockhash,
//         instructions,
//     }).compileToV0Message();
//
//     // make a versioned transaction
//     const transactionV0 = new VersionedTransaction(messageV0);
//
//     return transactionV0;
// };