import {ActionReducerMapBuilder, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getWallet2d3dNfts} from "../service/walletServiceProvider";
import {toast} from "react-toastify";
import {getWalletBalance} from "../config/utils";
import { Connection, PublicKey } from "@solana/web3.js";
import { RafflesManager } from "../components/ControllerRaffles/RaffleManager/rafflesManager";

export interface initialStateTypes {
    isWalletConneted: boolean,
    wallet2dNftData: any[],
    wallet3dNftData: any[],
    userWalletBalance: any,
    stakingVaultBalance : number,
}

const initialState: initialStateTypes = {
    isWalletConneted: false,
    wallet2dNftData: [],
    wallet3dNftData: [],
    userWalletBalance: null,
    stakingVaultBalance : 0,
}

export const walletReduxSlice = createSlice({
    name: 'walletInfo',
    initialState,
    reducers: {
        resetWalletReduxState: () => initialState,
        isWalletConnetedAction: (state: initialStateTypes, action: PayloadAction<boolean>) => {
            state.isWalletConneted = action.payload;
        },
        setUserWalletBalanceAction: (state: initialStateTypes, action: PayloadAction<any>) => {
            state.userWalletBalance = action.payload;
        },
        setStakingVaultBalanceAction: (state: initialStateTypes, action: PayloadAction<any>) => {
            state.stakingVaultBalance = action.payload;
        },
    },
    extraReducers: (builder:ActionReducerMapBuilder<any>) => {
        builder.addCase(getWallet2d3dNfts.fulfilled, (state: initialStateTypes, action) => {
            if(action.payload) {
                console.log("Fetch NFTs in wallet | getWalletNftTokenData()");
                console.log("Wallet Data:", action.payload);
                /* sort wallet array by rarity */
                state.wallet2dNftData = action.payload.wallet2dNft.sort((a: any, b: any) => a.rarity > b.rarity ? 1 : -1);
                state.wallet3dNftData = action.payload.wallet3dNft.sort((a: any, b: any) => a.rarity > b.rarity ? 1 : -1);
            }
        })
        builder.addCase(getWallet2d3dNfts.rejected, (state: initialStateTypes, action) => {
            toast.dismiss();
            toast.error("Problem while fetching wallet data...");
        })
    }
});

/*Action creators are generated for each case reducer function*/
export const {resetWalletReduxState, isWalletConnetedAction, setUserWalletBalanceAction, setStakingVaultBalanceAction} = walletReduxSlice.actions

export default walletReduxSlice.reducer
