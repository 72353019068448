import React, { useState } from 'react'
import './styles.css';

import {useLocation, useParams} from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useAnchorWallet, useWallet } from '@solana/wallet-adapter-react';
import { ADMIN_WALLET, REACT_APP_RAFFLE_VAULT_WALLET_ADDRESS } from 'config/constants';
import { WalletToggle } from 'sharedComponent/WalletToggle';
import * as anchor from "@project-serum/anchor";
import {SocialMediaBar} from "../SocialMediaBar";

import logo from '../../assets/topbar/logo.png';
import joinDiscord from '../../assets/images/header/1y_v2.png';

export const MyNavBar = (): JSX.Element => {
  const location = useLocation();
  const history = useHistory();
  let { raffleAddress } = useParams<{raffleAddress: string}>();

  const wallet = useWallet();
  const anchorWallet = useAnchorWallet() as anchor.Wallet;

  const [isMenu, setIsMenu] = useState(false)

  const handleGoSection = (index: string) => {
    if (location.pathname === '/') {
      const element: any = document.getElementById(index)
      if (element) {
        const topPos = element.offsetTop
        let navBarHeight: any = document.getElementById('navBar')?.offsetHeight
        navBarHeight = navBarHeight - 50
        if (navBarHeight === 0) navBarHeight = 68
        window.scrollTo({
          top: topPos - navBarHeight,
          left: 100,
          behavior: 'smooth'
        })
        setIsMenu(false)
        history.push('/#' + index)
      }
    } else {
      history.push('/#' + index)
    }
  }

  const handleChangePage = (index: string) => {
    history.push(index)
    setIsMenu(false)
  }

  return (
      <>
        {
          (
              wallet.connected &&
              ((ADMIN_WALLET.includes(anchorWallet?.publicKey.toString() as string) ||
                  REACT_APP_RAFFLE_VAULT_WALLET_ADDRESS == anchorWallet?.publicKey.toString())) &&
              (window.location.host.includes("raffles.") || history.location.pathname == "/raffles" || history.location.pathname == `/raffles/${raffleAddress}` && history.location.pathname != "/raffles/create")
          ) &&
          <WalletToggle />
        }
        <SocialMediaBar />

        <div className='top-bar' id='navBar'>
          <div className='main-layout'>
            <div className='main-container display-flex-center'>
              <div className='logo-main'>
                <img className='logo' src={logo} alt='logo' onClick={() => handleGoSection('logo')} />
                <span className='logo-title' onClick={() => handleGoSection('logo')}>Sol Kitties</span>
              </div>
              <div className='menus'>
                <span className='top-menu ' onClick={() => handleChangePage('/staking')}>Staking</span>
                <span className='top-menu ' onClick={() => handleGoSection('roadmap')}>Roadmap</span>
                <span className='top-menu ' onClick={() => handleGoSection('faq')}>FAQ</span>
              </div>
              <div className='top-social'>
              </div>
            </div>
          </div>
        </div>
        <div className='mobile-nav' id='mobileNav'>
          <div className='mobile-left-logo'>
            <div className='menu-list' onClick={() => setIsMenu(true)}>
              <MenuIcon />
            </div>
            <div className='logo-main'>
              <img className='logo' src={logo} alt='logo' onClick={() => handleGoSection('logo')} />
              <span className='logo-title' onClick={() => handleGoSection('logo')}>Sol kittes</span>
            </div>
          </div>
          <div className='top-social'>
          </div>
          <div className='sub-menu' style={{ width: isMenu ? '70%' : '0px' }}>
            <div>
              <div className='logo-main'>
                <img className='logo' src={logo} alt='logo' onClick={() => handleGoSection('logo')} />
                <span className='logo-title' onClick={() => handleGoSection('logo')}>Sol Kittes</span>
              </div>
              <div className="mobile-item" onClick={() => handleChangePage('/staking')}><span>Staking</span></div>
              <div className="mobile-item" onClick={() => handleGoSection('roadmap')}><span>Roadmap</span></div>
              <div className="mobile-item" onClick={() => handleGoSection('faq')}><span>Faq</span></div>
            </div>
          </div>
          {isMenu && (
              <div className='overlay' onClick={() => setIsMenu(false)} />
          )}
        </div>
      </>
  )
}

const MenuIcon = () => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="#FFD029" className="bi bi-list" viewBox="0 0 16 16">
        <path fillRule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"></path>
      </svg>
  )
}
