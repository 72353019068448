import {configureStore} from "@reduxjs/toolkit";
import userReduxSlice from "./userReduxSlice";
import walletReduxSlice from "./walletReduxSlice";
import utilReduxSlice from "./utilReduxSlice";
import raffleReduxSlice from "./raffleReduxSlice";

export const store = configureStore({
    reducer: {
        userReduxSlice: userReduxSlice,
        walletReduxSlice: walletReduxSlice,
        utilReduxSlice: utilReduxSlice,
        raffleReduxSlice: raffleReduxSlice
    }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
