import React, {useEffect, useState} from 'react'
import {Accordion, AccordionDetails, AccordionSummary, Typography} from '@material-ui/core';
import {FaqContainer} from './styles'

// styles
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import downArrow from "../../assets/images/common/down-arrow.png";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        accordionRoot: {
            position: 'relative',
            width: '100%',
            '& a': {
                color: '#23ffc4'
            },
            '&.MuiPaper-root': {
                background: '#fea7a0'
            },
            '& .MuiAccordion-rounded:first-child': {
                borderTopLeftRadius: '14px',
                borderTopRightRadius: '14px'
            },
            '& .MuiAccordion-rounded': {
                marginTop: '17px',
                borderRadius: '15px',
                background: '#243562',
                '&:before': {
                    height: '0px'
                }
            },
            '& .MuiAccordionDetails-root': {
                padding: '0px 16px 16px'
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: '20px 0px 7px'
            },
            '& .MuiAccordionSummary-root.Mui-expanded': {
                minHeight: '50px'
            },
            marginBottom: '30px',
            [theme.breakpoints.down('xs')]: {
                marginBottom: '30px'
            },
        },
        expandIconImg: {
            width: '22px',
            [theme.breakpoints.down('xs')]: {
                width: '17px',
            },
        },
        secondaryHeading1: {
            color: '#ffffff',
            textAlign: 'left',
            fontWeight: 600,
            fontSize: '17px',
            fontFamily: 'Open Sans',
            [theme.breakpoints.down('xs')]: {
                fontSize: '13px'
            },
        },
        secondaryHeading2: {
            color: '#ffffff',
            textAlign: 'left',
            fontWeight: 300,
            fontSize: '17px',
            fontFamily: 'Open Sans',
            [theme.breakpoints.down('xs')]: {
                fontSize: '13px'
            },
        },
        a: {
            color: 'red',
        },
    }),
);

const termsCondition = `<ul>
                            <li>
                                When you create a raffle, the chosen NFT prize will be transferred from your wallet into our vault.
                            </li>
                            <li>
                                Raffles will proceed regardless if all tickets are sold or not.
                            </li>
                            <li>Raffles cannot be edited or cancelled once started / posted.</li>
                            <li>
                                Raffles will end automatically and a winner will be randomly chosen from participants.
                            </li>
                            <li>
                                Raffles must run for a minimum of 24 hours.
                            </li>
                            <li>
                                Sol Kitties will take a 5% commission fee from ticket sales.
                            </li>
                            <li>
                                Your NFT will be returned to you at the end of the raffle if there are no ticket sales.
                            </li>
                            <li>
                                Purchasing your own tickets with current or alternative wallets is strictly prohibited.
                            </li>
                            <li>
                                Sol Kitties does not take responsibility for promoting or marketing the raffles.
                            </li>
                        </ul>`;

const FAQ_HOME = [
    {
        question: 'Q: What are Sol Kitties 3D?',
        answer: ' Sol Kitties is a prime quality and innovative Solana NFT project that comes with two collections, 2D for passive earnings and 3D for active earnings. The latter is following the enormous success of the first one which sold out in just 55 seconds.'
    },
    {
        question: 'Q: Will Sol Kitties Collection have a utility?',
        answer: ' Yes! Staking, On-Chain Lottery, Raffles, $SKT IDO and more! P2E Video Game is coming soon! '
    },
    {
        question: 'Q: Is this a generative NFT project?',
        answer: ' It is, 2,888 3D SolKitties in total.'
    },
    {
        question: 'Q: What blockchain does SolKitties 3D belong to?',
        answer: ' Both 2D and 3D Sol Kitties live in peace on the Solana blockchain.'
    },
    {
        question: 'Q: What about the secondary market?',
        answer: ' The 2D Collection of Sol Kitties is already listed on Magic Eden.'
    },
    {
        question: 'Q: Where can I see the Sol Kitties that I’ve acquired?',
        answer: ' You can witness both your 2D and 3D Sol Kitties in their full glory in your wallet (e.g. Phantom) in your collectibles; or on Magic Eden and Digital Eyes.'
    },
    {
        question: 'Q: How do I create and fund my Solana wallet?',
        answer: 'Using Chrome, go to <a href="https://phantom.app/" target="_blank"><b>Phantom</b></a>, download and install the Phantom wallet extension. Once the extension is installed, run the program and create a wallet. Then, to fund it, go to <a href="https://www.coinbase.com/" target="_blank"><b>Coinbase</b></a> or <a href="https://www.binance.com/en" target="_blank"><b>Binance</b></a> (any exchange that sells and supports Solana token transfer) and purchase SOL. Once you have SOL, send it to your SOL address in your Phantom wallet.'
    },
    {
        question: 'Q: Do I own the Sol Kitties that I’m paying for?',
        answer: ' 2D or 3D, kitties that you purchase are fully yours. Treat them with love!'
    },
    {
        question: 'Q: Is there a limit on how many kitties I can mint?',
        answer: ' No! You can mint as many as you like.'},
    {
        question: 'Q: Where are the digital artworks stored?',
        answer: ' Sol Kitties 3D are being permanently stored on Arweave, a decentralized data storage. Arweave backs data with sustainable and perpetual endowments. It all means you can store data for eternity. Your Kitty will live forever!'
    },
    {
        question: 'Q: Why stake your kitties?',
        answer: ' Staking your kitties will give you daily stake rewards in Kitty Coins, the project’s own currency that can be exchanged for SOL.'
    },
];

const FAQ_LOTTERY = [
    {
        question: 'Q: What are Sol Kitties 3D?',
        answer: ' Sol Kitties is a prime quality and innovative Solana NFT project that comes with two collections, 2D for passive earnings and 3D for active earnings. The latter is following the enourmous success of the first one which sold out in just 55 seconds.'
    },
    {
        question: 'Q: What is Sol Kitties\' Lottery 2.0?',
        answer: ' It is our unique Web 3 based Lottery system for the Solana Blockchain, where everyone can participate.'
    },
    {
        question: 'Q: How can I participate in the Lottery?',
        answer: ' To participate, please visit https://solkitties.net/lottery and purchase a ticket for only 0.15 SOL.'
    },
    {question: 'Q: How many tickets can I get?', answer: 'You can purchase up to 10 tickets per wallet.'},
    {
        question: 'Q: What are the prizes that I can win?',
        answer: ' Our current Lottery offers 4 NFTs from renowned projects with a combined value of ~100 SOL. NFTs are from Cet, Bohemian, Tombstoned High Society and Sol Kitties.'
    },
    {question: 'Q: How many tickets are there?', answer: ' There are unlimited tickets.'},
    {
        question: 'Q: How do I know if I won?',
        answer: ' Winners will be announced prior to our 3D mint events, on April 21.'
    },
    {
        question: 'Q: Where will winners be announced?',
        answer: ' Winners will be announced in Sol Kitties Discord channel: https://discord.gg/solkitties \n'
    },
];

const FAQ_SWEEPERS = [
    {
        question: 'Q: What is Kitties Sweepers?',
        answer: 'Kitties Sweepers is a special community sweep event for 3D Sol Kitties on Magic Eden.'
    },
    {
        question: 'Q: How do I create and fund my Solana wallet?',
        answer: 'Using Chrome, go to <a href="https://phantom.app/" target="_blank"><b>Phantom</b></a>, download and install the Phantom wallet extension. Once the extension is installed, run the program and create a wallet. Then, to fund it, go to <a href="https://www.coinbase.com/" target="_blank"><b>Coinbase</b></a> or <a href="https://www.binance.com/en" target="_blank"><b>Binance</b></a> (any exchange that sells and supports Solana token transfer) and purchase SOL. Once you have SOL, send it to your SOL address in your Phantom wallet.'
    },
    {
        question: 'Q: Why would I want to buy a sweeper ticket?',
        answer: 'Post event, we will be airdropping 50% of the swept kitties to sweeper ticket owners (the other 50% will be burnt).'
    },
    {
        question: 'Q: What was the thought behind Kitties Sweepers?',
        answer: 'Our aim is to develop a community centric approach for market stabilization that is sustainable long-term, unlike individual sweeps.'
    },
    {
        question: 'Q: What would the project gain from this event?',
        answer: 'Kitties Sweepers would reduce the supply for the 3D Collection and develop higher demand, likely resulting an increase of the Floor Price.'
    },
];

const FAQ_RAFFLES_CREATE = [
    {
        question: '<span>Terms and Conditions</span>',
        answer: termsCondition
    },
];

export const Faq = (props: any): JSX.Element => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(-1);
    const [faqData, setFaqData]: any = useState([]);

    const redirectSite = (url: string) => {
        window.open(url, '_blank')
    }
    useEffect(() => {
        if (props.faqType == "home") {
            setFaqData(FAQ_HOME);
        } else if (props.faqType == "lottery") {
            setFaqData(FAQ_LOTTERY);
        } else if (props.faqType == "sweepers") {
            setFaqData(FAQ_SWEEPERS);
        } else if (props.faqType == "rafflesCreate") {
            setFaqData(FAQ_RAFFLES_CREATE);
        }
    }, []);

    return (
        <FaqContainer id='faq'>
            <div className='main-layout'>
                <div className='main-container'>
                    {
                        props.faqType != "rafflesCreate" &&
                        <h1 className="roadmap-title">FAQ</h1>
                    }
                    <div className={`accordion-general ${classes.accordionRoot}`}>
                        {
                            faqData.map((item: any, index: number) => {
                                return (
                                    <Accordion
                                        key={index}
                                        defaultExpanded={true}
                                        expanded={expanded === index}
                                        onChange={() => {
                                            if (expanded !== index)
                                                setExpanded(index);
                                            else
                                                setExpanded(-1);
                                        }}>
                                        <AccordionSummary
                                            expandIcon={<img src={downArrow} alt="downArrow"
                                                             className={classes.expandIconImg}/>}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className={classes.secondaryHeading1}
                                                        dangerouslySetInnerHTML={{__html: item.question}}></Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography className={classes.secondaryHeading1}
                                                        style={{marginRight: '5px'}}>
                                                {(props.faqType == "rafflesCreate") ? "" : "A:"}
                                            </Typography>
                                            <Typography className={classes.secondaryHeading2}
                                                        dangerouslySetInnerHTML={{__html: item.answer}}></Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            {/*<div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "-80px"}}>
            <span className="logo-title" style={{color:"white"}}>As seen on</span>
            <img src={nftCalendar} alt='NFT Calendar .io' style={{cursor: "pointer"}} height={150} width={150} onClick={() => redirectSite('https://nftcalendar.io/event/sol-kitties-3d/')}/>
        </div>*/}
        </FaqContainer>
    )
}
