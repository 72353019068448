import React, {useEffect, useState} from "react";
import * as QueryString from "query-string";
import {ParsedQuery} from "query-string";
import PodsPublicMint from "../PodsPublicMint";
import axios from "axios";

const API = (props: any): JSX.Element =>
{
    const [response, setResponse]: any = useState();
    const params = QueryString.parse(props.location.search);
    console.log(params.id);

    const fetchME = async () =>
    {
        const addr = params.id;//"cyberlinxnft";
        const url = `https://api-mainnet.magiceden.io/rpc/getCollectionHolderStats/${addr}?edge_cache=true`;
        console.log(url);

        const res = await axios.get(url);
        console.log(res.data.results);

        // TBD
        // print/return res.data.results to page like an API
        if(res.data.results) {
            setResponse(res.data.results)
        } else {
            setResponse("no data available!")
        }
    }

    useEffect(() =>
    {
        async function onLoad()
        {
            console.log("loaded");
            await fetchME();
        }

        onLoad();
    }, []);

    return (
        <code>{JSON.stringify(response)}</code>
    )
}

export default API;