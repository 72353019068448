import {ActionReducerMapBuilder, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import {getStakeStatsData} from "../service/utilServiceProvider";

interface initialStateTypes {
    soldTickets: number,
    globalStakeStats: {
        state: string,
        totalStakedTokens: any,
        totalStakedTokensPrecentages: any
    }
}

const initialState: initialStateTypes = {
    soldTickets: 0,
    globalStakeStats: {
        state: "init",
        totalStakedTokens: "?",
        totalStakedTokensPrecentages: "?"
    }
}

export const utilReduxSlice = createSlice({
    name: 'utilInfo',
    initialState,
    reducers: {
        resetUtilReduxState: () => initialState,
        soldTicketAction: (state: initialStateTypes, action: PayloadAction<number>) => {
            state.soldTickets = action.payload;
        },
    },
    extraReducers: (builder: ActionReducerMapBuilder<any>) => {
        builder.addCase(getStakeStatsData.fulfilled, (state: initialStateTypes, action) => {
            const response = action.payload;

            if (response) {
                state.globalStakeStats = {
                    ...state.globalStakeStats,
                    state: "fulfilled",
                    totalStakedTokens: response.totalStakedTokens,
                    totalStakedTokensPrecentages: response.totalStakedTokensPrecentages + "%",
                }
            }
        });
        builder.addCase(getStakeStatsData.rejected, (state: initialStateTypes, action) => {
            toast.dismiss();
            toast.error("Problem while fetching global stats data...");
        });
    }
});

/*Action creators are generated for each case reducer function*/
export const {resetUtilReduxState, soldTicketAction} = utilReduxSlice.actions

export default utilReduxSlice.reducer
