import {getParsedNftAccountsByOwner} from "@nfteyez/sol-rayz";
import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import RarityJson from "./rarityJson";
import Rarity3dJson from "./rarity3dJson";
import {Connection} from "@solana/web3.js";

export const getNftFromWallet = async (connection: Connection, walletAddress: string) =>
{
    try
    {
        const params = { connection: connection, publicAddress: walletAddress };

        let walletNftData = await getParsedNftAccountsByOwner(params);
        return Object.keys(walletNftData!).map((key: any) => walletNftData![key]);
    }
    catch (error)
    {
        console.log("Error:", error);
    }
};

export const getWalletNftData = async (url: string) =>
{
    let res: any =
    {
        'success': false,
        'data': []
    }

    try
    {
        res.data = await axios.get(url, {timeout: 7 * 1000}); // 7 seconds timeout
        res.success = true;
        return res;
    }
    catch (error)
    {
        console.log("Error:", error);
        res.success = false;
        return res;
    }
};

export const getWallet2d3dNfts = createAsyncThunk(
    "getWallet2d3dNfts",
    async (args: { connection: Connection, walletAddress: string }, { rejectWithValue }) =>
    {
        const {connection, walletAddress} = args;

        try {
            let walletNftResponse: any = {
                wallet2dNft: [],
                wallet3dNft: [],
            }

            /* get all wallet nft data */
            const walletNftData: any = await getNftFromWallet(connection, walletAddress);

            /* get only 2d and 3d sol kitty data from wallet */
            if(walletNftData && walletNftData.length > 0) {
                await Promise.all(
                    walletNftData.map(async (item: any) =>
                    {
                        const checkKitty3DFlag = item.data.name.includes("3D Sol Kitties");
                        const checkKittyFlag = item.data.name.includes("Sol Kitties #") && !item.data.name.includes("Dormant") && !item.data.name.includes("Prime");

                        /* fetch the 2d and 3d image */
                        if(checkKitty3DFlag || checkKittyFlag) {
                            let nftImageResponse = await getWalletNftData(item.data.uri);
                            let nftItemImage = nftImageResponse?.data?.data;
                            if(nftImageResponse.success) {
                                item.data.uri = nftItemImage.image;
                            }
                        }

                        if (checkKitty3DFlag) {
                            let walletNftRarity3d = Rarity3dJson.find(rarityJson => rarityJson.name == item.data.name);
                            item.rarity = walletNftRarity3d?.rarity_rank;
                            walletNftResponse.wallet3dNft.push(item);
                        } else if(checkKittyFlag) {
                            let walletNftRarity = RarityJson.find(rarityJson => rarityJson.name == item.data.name);
                            item.rarity = walletNftRarity?.rarity;
                            walletNftResponse.wallet2dNft.push(item);
                        }
                    })
                );
            }

            return walletNftResponse;
        } catch (error) {
            console.log("Error:", error);
            return rejectWithValue(error);
        }
    }
);
