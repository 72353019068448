import React, { useState } from 'react'
import './styles.css';

import discordIcon from '../../assets/images/social-media/discord.png';
import twitterIcon from '../../assets/images/social-media/twitter.png';
import meIcon from '../../assets/images/social-media/me.png';

export const SocialMediaBar = (): JSX.Element => {
  return (
      <>
        <div className="icon-bar">
            {/*<a className="twitter" href='https://twitter.com/SolKitties' target="_blank">*/}
            {/*    <img src={twitterIcon} alt='twitter' />*/}
            {/*</a>*/}
            {/*<a className="discord" href='http://discord.gg/solkitties' target="_blank">*/}
            {/*    <img src={discordIcon} alt='discord' />*/}
            {/*</a>*/}
            <a className="me" href='https://magiceden.io/creators/sol_kitties' target="_blank">
                <img src={meIcon} alt='me' />
            </a>
        </div>
      </>
  )
}
